import {FC, HTMLProps, ReactNode, useEffect, useRef} from "react";

interface IProps extends HTMLProps<HTMLDivElement> {
    children: ReactNode;
    as?: string;
    scrollIntoViewConfig?: boolean | ScrollIntoViewOptions;
}

export const ScrollToElement = (props: IProps) => {
    const {
        children,
        as: CustomTag = "div",
        scrollIntoViewConfig = {
            behavior: "smooth"
        },
        ...htmlProps
    } = props;
    const elementRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (elementRef.current) {
            elementRef.current.scrollIntoView(scrollIntoViewConfig);
        }
    }, []);

    const Component = CustomTag as unknown as FC<Omit<IProps, "as" | "scrollIntoViewConfig">>;

    return (
        <Component ref={elementRef} {...htmlProps}>
            {children}
        </Component>
    );
};
import {FC, HTMLProps, ReactNode, useEffect, useRef} from "react";

interface IProps extends HTMLProps<HTMLDivElement> {
    children: ReactNode;
    as?: string;
    scrollIntoViewConfig?: boolean | ScrollIntoViewOptions;
}

export const ScrollToElement = (props: IProps) => {
    const {
        children,
        as: CustomTag = "div",
        scrollIntoViewConfig = {
            behavior: "smooth"
        },
        ...htmlProps
    } = props;
    const elementRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (elementRef.current) {
            elementRef.current.scrollIntoView(scrollIntoViewConfig);
        }
    }, []);

    const Component = CustomTag as unknown as FC<Omit<IProps, "as" | "scrollIntoViewConfig">>;

    return (
        <Component ref={elementRef} {...htmlProps}>
            {children}
        </Component>
    );
};
