import * as Yup from "yup";

import {FormFieldType} from "@pg-mono/form";

import {validationMessages} from "../../form/utils/validation_messages";
import {IApplicationFormValues} from "../types/IApplicationFormValues";

export const applicationFormFields = {
    text: FormFieldType.Textarea,
    name: FormFieldType.Input,
    email: FormFieldType.Input,
    phone: FormFieldType.PhoneField,
    financing_services: FormFieldType.Checkbox,
    new_property_notification_consent: FormFieldType.Checkbox,
    investment_services: FormFieldType.Checkbox,
    rooms: FormFieldType.Rooms
};
export const initialEmptyApplicationValues: IApplicationFormValues = {
    text: "",
    name: "",
    email: "",
    phone: {
        code: 48,
        number: ""
    },
    financing_services: false,
    new_property_notification_consent: false,
    investment_services: false,
    rooms: []
};

export const applicationFormValidationSchema = Yup.object({
    email: Yup.string().email(validationMessages.email).required(validationMessages.required),
    name: Yup.string().required(validationMessages.required),
    phone: Yup.object({
        code: Yup.number().required(validationMessages.required),
        number: Yup.string().required(validationMessages.required)
    })
});
import * as Yup from "yup";

import {FormFieldType} from "@pg-mono/form";

import {validationMessages} from "../../form/utils/validation_messages";
import {IApplicationFormValues} from "../types/IApplicationFormValues";

export const applicationFormFields = {
    text: FormFieldType.Textarea,
    name: FormFieldType.Input,
    email: FormFieldType.Input,
    phone: FormFieldType.PhoneField,
    financing_services: FormFieldType.Checkbox,
    new_property_notification_consent: FormFieldType.Checkbox,
    investment_services: FormFieldType.Checkbox,
    rooms: FormFieldType.Rooms
};
export const initialEmptyApplicationValues: IApplicationFormValues = {
    text: "",
    name: "",
    email: "",
    phone: {
        code: 48,
        number: ""
    },
    financing_services: false,
    new_property_notification_consent: false,
    investment_services: false,
    rooms: []
};

export const applicationFormValidationSchema = Yup.object({
    email: Yup.string().email(validationMessages.email).required(validationMessages.required),
    name: Yup.string().required(validationMessages.required),
    phone: Yup.object({
        code: Yup.number().required(validationMessages.required),
        number: Yup.string().required(validationMessages.required)
    })
});
