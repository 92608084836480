import React from "react";
import {css} from "@emotion/react";

import {flexAbsoluteCenter} from "@pg-design/helpers-css";
import {IIcon} from "@pg-design/icons-module";

interface IProps {
    icon: React.ComponentType<IIcon>;
}

export const PromoBoxPictogramIcon = (props: IProps) => {
    const Icon = props.icon;

    return (
        <div css={iconWrapperStyle}>
            <Icon size="15" />
        </div>
    );
};

const iconWrapperStyle = css`
    ${flexAbsoluteCenter};
    height: 13rem;
    width: 13rem;
`;
import React from "react";
import {css} from "@emotion/react";

import {flexAbsoluteCenter} from "@pg-design/helpers-css";
import {IIcon} from "@pg-design/icons-module";

interface IProps {
    icon: React.ComponentType<IIcon>;
}

export const PromoBoxPictogramIcon = (props: IProps) => {
    const Icon = props.icon;

    return (
        <div css={iconWrapperStyle}>
            <Icon size="15" />
        </div>
    );
};

const iconWrapperStyle = css`
    ${flexAbsoluteCenter};
    height: 13rem;
    width: 13rem;
`;
