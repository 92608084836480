export function getStoreHash(ids: {vendorId: number; offerId: number | null; propertyId: number | null}) {
    const offerText = ids.offerId ? `o${ids.offerId}` : "";
    const propertyText = ids.propertyId ? `p${ids.propertyId}` : "";

    return `v${ids.vendorId}${offerText}${propertyText}`;
}
export function getStoreHash(ids: {vendorId: number; offerId: number | null; propertyId: number | null}) {
    const offerText = ids.offerId ? `o${ids.offerId}` : "";
    const propertyText = ids.propertyId ? `p${ids.propertyId}` : "";

    return `v${ids.vendorId}${offerText}${propertyText}`;
}
