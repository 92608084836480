import React from "react";
import {css, Theme, useTheme} from "@emotion/react";

import {borderRadius, display, mr, p} from "@pg-design/helpers-css";
import {CloseIcon, LocationIcon} from "@pg-design/icons-module";
import {Text} from "@pg-design/text-module";

import {IMapOffer} from "../../../types/IMapOffer";

interface IProps {
    onClose: (event: React.MouseEvent) => void;
    onClick: (event: React.MouseEvent) => void;
    offer?: IMapOffer;
}

export const MobileMapInfoWindow = (props: IProps) => {
    const theme = useTheme();

    if (!props.offer) {
        return null;
    }

    return (
        <div css={mobileMapInfoWindow}>
            <span css={closePosition} onClick={props.onClose}>
                <CloseIcon size="1" />
            </span>

            <div css={display("flex")} onClick={props.onClick}>
                <div css={infoWindowImageHolder}>
                    <img css={infoWindowImage} src={props.offer.mainImage?.mobile} alt={props.offer.name} />
                </div>
                <div css={infoWindowTextHolder}>
                    <Text color={theme.colors.gray[700]} variant="info_txt_1" css={offerNameHolder}>
                        {props.offer.name}
                    </Text>
                    <Text color={theme.colors.gray[700]} variant="info_txt_1" strong css={infoWindowRooms}>
                        {props.offer.description}
                    </Text>

                    <div css={display("flex")}>
                        <LocationIcon size="2" css={mr(1)} />
                        <Text variant="info_txt_1">{props.offer.distance} km od Twojej inwestycji</Text>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mobileMapInfoWindow = css`
    ${borderRadius(2)};
    background-color: #fff;
    position: absolute;
    bottom: 1.4rem;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 4rem);
    height: 8.7rem;
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.19);
    z-index: 1;
`;

const closePosition = (theme: Theme) => css`
    position: absolute;
    top: 0;
    right: 0;
    padding: 1rem;
    cursor: pointer;

    @media (min-width: ${theme.breakpoints.xs}) {
        right: 0.5rem;
    }
`;

const offerNameHolder = css`
    padding-right: 2rem; /* to avoid overlapping close button */
`;

const infoWindowImageHolder = (theme: Theme) => css`
    ${borderRadius(1, 0, 0, 1)};
    position: relative;
    overflow: hidden;

    @media (min-width: ${theme.breakpoints.md}) {
        ${borderRadius(1, 1, 0, 0)};
    }
`;

const infoWindowImage = css`
    object-fit: cover;
`;

const infoWindowTextHolder = (theme: Theme) => css`
    ${p(1)};
    flex-direction: row;

    @media (min-width: ${theme.breakpoints.md}) {
        flex-direction: column;
    }
`;

const infoWindowRooms = css`
    display: block;
    margin-bottom: 1.2rem;
`;
import React from "react";
import {css, Theme, useTheme} from "@emotion/react";

import {borderRadius, display, mr, p} from "@pg-design/helpers-css";
import {CloseIcon, LocationIcon} from "@pg-design/icons-module";
import {Text} from "@pg-design/text-module";

import {IMapOffer} from "../../../types/IMapOffer";

interface IProps {
    onClose: (event: React.MouseEvent) => void;
    onClick: (event: React.MouseEvent) => void;
    offer?: IMapOffer;
}

export const MobileMapInfoWindow = (props: IProps) => {
    const theme = useTheme();

    if (!props.offer) {
        return null;
    }

    return (
        <div css={mobileMapInfoWindow}>
            <span css={closePosition} onClick={props.onClose}>
                <CloseIcon size="1" />
            </span>

            <div css={display("flex")} onClick={props.onClick}>
                <div css={infoWindowImageHolder}>
                    <img css={infoWindowImage} src={props.offer.mainImage?.mobile} alt={props.offer.name} />
                </div>
                <div css={infoWindowTextHolder}>
                    <Text color={theme.colors.gray[700]} variant="info_txt_1" css={offerNameHolder}>
                        {props.offer.name}
                    </Text>
                    <Text color={theme.colors.gray[700]} variant="info_txt_1" strong css={infoWindowRooms}>
                        {props.offer.description}
                    </Text>

                    <div css={display("flex")}>
                        <LocationIcon size="2" css={mr(1)} />
                        <Text variant="info_txt_1">{props.offer.distance} km od Twojej inwestycji</Text>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mobileMapInfoWindow = css`
    ${borderRadius(2)};
    background-color: #fff;
    position: absolute;
    bottom: 1.4rem;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 4rem);
    height: 8.7rem;
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.19);
    z-index: 1;
`;

const closePosition = (theme: Theme) => css`
    position: absolute;
    top: 0;
    right: 0;
    padding: 1rem;
    cursor: pointer;

    @media (min-width: ${theme.breakpoints.xs}) {
        right: 0.5rem;
    }
`;

const offerNameHolder = css`
    padding-right: 2rem; /* to avoid overlapping close button */
`;

const infoWindowImageHolder = (theme: Theme) => css`
    ${borderRadius(1, 0, 0, 1)};
    position: relative;
    overflow: hidden;

    @media (min-width: ${theme.breakpoints.md}) {
        ${borderRadius(1, 1, 0, 0)};
    }
`;

const infoWindowImage = css`
    object-fit: cover;
`;

const infoWindowTextHolder = (theme: Theme) => css`
    ${p(1)};
    flex-direction: row;

    @media (min-width: ${theme.breakpoints.md}) {
        flex-direction: column;
    }
`;

const infoWindowRooms = css`
    display: block;
    margin-bottom: 1.2rem;
`;
