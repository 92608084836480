import React from "react";
import {css, Theme} from "@emotion/react";

import {Badge} from "@pg-design/badge-module";
import {flex, flexDirection, mb, mr, p} from "@pg-design/helpers-css";
import {InfoIcon} from "@pg-design/icons-module";
import {Loader} from "@pg-design/loader-module";
import {Text} from "@pg-design/text-module";
import {RequestState} from "@pg-mono/request-state";
import {areaFormat, pluralize} from "@pg-mono/string-utils";

import {getOfferTypeNameCapital} from "../../../../../offer/helpers/OfferType";
import {OfferType} from "../../../../../offer/types/OfferType";
import {ApplicationOfferBoxBase} from "./ApplicationOfferBoxBase";

interface IProps {
    offerAddress: string;
    offerMainImg?: string;
    offerType: OfferType;
    rooms?: number;
    area?: number;
    estimatedPriceInfoText: string | null;
    regionEstimatedPriceRequestState: RequestState;
    className?: string;
}

export const ApplicationOfferRevealedPriceBox = (props: IProps) => {
    const displayType = getOfferTypeNameCapital(props.offerType);

    return (
        <ApplicationOfferBoxBase
            title={`${displayType}, ${props.rooms ? `${props.rooms} ${roomsPluralize(props.rooms)}, ` : ""}${props.area ? areaFormat(props.area) : ""}`}
            address={props.offerAddress}
            imgSrc={props.offerMainImg}
            content={
                <>
                    {props.regionEstimatedPriceRequestState === RequestState.Waiting ? (
                        <Loader size="sm" />
                    ) : (
                        !!props.estimatedPriceInfoText && (
                            <Text variant="body_copy_2" css={revealedPriceBoxStyle}>
                                {props.estimatedPriceInfoText}
                            </Text>
                        )
                    )}

                    <Badge variant="label_success" css={successBadgeStyle} as="span">
                        <strong css={[flex("center", "space-between"), flexDirection("row")]}>
                            <div css={[flex("center", "center"), mr(1)]}>
                                <InfoIcon size="1.6" />
                            </div>
                            Wysłano zapytanie
                        </strong>
                    </Badge>
                </>
            }
            coverCss={coverStyle}
            css={boxWrapperStyle}
            className={props.className}
        />
    );
};

const roomsPluralize = pluralize(["pokój", "pokoje", "pokoi"]);

const boxWrapperStyle = (theme: Theme) => css`
    position: relative;
    margin-right: 2.7rem;
    border: 1px solid ${theme.colors.gray[500]};
    transition: all 0.2s ease-in-out;
`;

const coverStyle = css`
    flex: 0 0 123px;

    img {
        width: 123px;
    }
`;

const revealedPriceBoxStyle = (theme: Theme) => css`
    ${p(0.5, 1)};
    ${mb(1.5)};
    color: #000;
    background-color: ${theme.colors.primary};
    width: fit-content;
`;

const successBadgeStyle = css`
    ${p(0.5, 2)};
    width: fit-content;
`;
import React from "react";
import {css, Theme} from "@emotion/react";

import {Badge} from "@pg-design/badge-module";
import {flex, flexDirection, mb, mr, p} from "@pg-design/helpers-css";
import {InfoIcon} from "@pg-design/icons-module";
import {Loader} from "@pg-design/loader-module";
import {Text} from "@pg-design/text-module";
import {RequestState} from "@pg-mono/request-state";
import {areaFormat, pluralize} from "@pg-mono/string-utils";

import {getOfferTypeNameCapital} from "../../../../../offer/helpers/OfferType";
import {OfferType} from "../../../../../offer/types/OfferType";
import {ApplicationOfferBoxBase} from "./ApplicationOfferBoxBase";

interface IProps {
    offerAddress: string;
    offerMainImg?: string;
    offerType: OfferType;
    rooms?: number;
    area?: number;
    estimatedPriceInfoText: string | null;
    regionEstimatedPriceRequestState: RequestState;
    className?: string;
}

export const ApplicationOfferRevealedPriceBox = (props: IProps) => {
    const displayType = getOfferTypeNameCapital(props.offerType);

    return (
        <ApplicationOfferBoxBase
            title={`${displayType}, ${props.rooms ? `${props.rooms} ${roomsPluralize(props.rooms)}, ` : ""}${props.area ? areaFormat(props.area) : ""}`}
            address={props.offerAddress}
            imgSrc={props.offerMainImg}
            content={
                <>
                    {props.regionEstimatedPriceRequestState === RequestState.Waiting ? (
                        <Loader size="sm" />
                    ) : (
                        !!props.estimatedPriceInfoText && (
                            <Text variant="body_copy_2" css={revealedPriceBoxStyle}>
                                {props.estimatedPriceInfoText}
                            </Text>
                        )
                    )}

                    <Badge variant="label_success" css={successBadgeStyle} as="span">
                        <strong css={[flex("center", "space-between"), flexDirection("row")]}>
                            <div css={[flex("center", "center"), mr(1)]}>
                                <InfoIcon size="1.6" />
                            </div>
                            Wysłano zapytanie
                        </strong>
                    </Badge>
                </>
            }
            coverCss={coverStyle}
            css={boxWrapperStyle}
            className={props.className}
        />
    );
};

const roomsPluralize = pluralize(["pokój", "pokoje", "pokoi"]);

const boxWrapperStyle = (theme: Theme) => css`
    position: relative;
    margin-right: 2.7rem;
    border: 1px solid ${theme.colors.gray[500]};
    transition: all 0.2s ease-in-out;
`;

const coverStyle = css`
    flex: 0 0 123px;

    img {
        width: 123px;
    }
`;

const revealedPriceBoxStyle = (theme: Theme) => css`
    ${p(0.5, 1)};
    ${mb(1.5)};
    color: #000;
    background-color: ${theme.colors.primary};
    width: fit-content;
`;

const successBadgeStyle = css`
    ${p(0.5, 2)};
    width: fit-content;
`;
