import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const BrandFlagIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M3.096 1.614a1.944 1.944 0 0 0-.54.124c-.096.048-.127.078-.15.16-.058.228-.165.94-.182 1.23-.009.168-.028.43-.042.576-.014.146-.04.444-.057.66-.014.217-.039.515-.053.661-.017.147-.042.476-.06.732a12.84 12.84 0 0 1-.055.647c-.014.1-.043.59-.06 1.088-.033.866-.03.911.017.965.065.07.214.073.273.005.104-.112.113-.208.177-1.623.043-.897.088-1.527.119-1.605.028-.076.973-.04 1.33.05a.613.613 0 0 1 .475.447c.082.248.346.38.838.422.383.031.746.07.993.113.101.017.57.036 1.043.042.965.011.968.011.962-.169-.003-.11-.04-.163-.43-.61-.402-.461-.596-.706-.57-.729.19-.171.528-.447.64-.52.355-.228.408-.363.214-.548-.14-.135-.329-.189-.86-.242-.594-.06-.704-.085-.819-.186-.149-.132-.214-.284-.236-.543-.031-.38-.172-.615-.45-.756-.532-.27-1.834-.473-2.517-.391Zm1.462.512c.357.065.686.13.737.146.217.07.363.33.472.847.043.196.06.227.197.37.225.237.391.302.83.33.349.022.72.087.765.132.014.014-.023.06-.082.104-.385.287-.74.675-.74.804.004.105.167.321.785 1.041l.096.11h-.419a9.552 9.552 0 0 1-1.668-.158 2.174 2.174 0 0 0-.343-.04c-.169 0-.413-.05-.495-.1a.47.47 0 0 1-.118-.163c-.132-.304-.326-.479-.65-.585-.168-.057-.23-.062-.793-.057l-.613.006V4.85c.003-.037.014-.155.031-.261.014-.11.04-.54.054-.957.028-.765.036-.888.095-1.35l.034-.253.222-.017c.492-.039.925-.008 1.603.113Z" />
        </SvgIcon>
    );
};
import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const BrandFlagIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M3.096 1.614a1.944 1.944 0 0 0-.54.124c-.096.048-.127.078-.15.16-.058.228-.165.94-.182 1.23-.009.168-.028.43-.042.576-.014.146-.04.444-.057.66-.014.217-.039.515-.053.661-.017.147-.042.476-.06.732a12.84 12.84 0 0 1-.055.647c-.014.1-.043.59-.06 1.088-.033.866-.03.911.017.965.065.07.214.073.273.005.104-.112.113-.208.177-1.623.043-.897.088-1.527.119-1.605.028-.076.973-.04 1.33.05a.613.613 0 0 1 .475.447c.082.248.346.38.838.422.383.031.746.07.993.113.101.017.57.036 1.043.042.965.011.968.011.962-.169-.003-.11-.04-.163-.43-.61-.402-.461-.596-.706-.57-.729.19-.171.528-.447.64-.52.355-.228.408-.363.214-.548-.14-.135-.329-.189-.86-.242-.594-.06-.704-.085-.819-.186-.149-.132-.214-.284-.236-.543-.031-.38-.172-.615-.45-.756-.532-.27-1.834-.473-2.517-.391Zm1.462.512c.357.065.686.13.737.146.217.07.363.33.472.847.043.196.06.227.197.37.225.237.391.302.83.33.349.022.72.087.765.132.014.014-.023.06-.082.104-.385.287-.74.675-.74.804.004.105.167.321.785 1.041l.096.11h-.419a9.552 9.552 0 0 1-1.668-.158 2.174 2.174 0 0 0-.343-.04c-.169 0-.413-.05-.495-.1a.47.47 0 0 1-.118-.163c-.132-.304-.326-.479-.65-.585-.168-.057-.23-.062-.793-.057l-.613.006V4.85c.003-.037.014-.155.031-.261.014-.11.04-.54.054-.957.028-.765.036-.888.095-1.35l.034-.253.222-.017c.492-.039.925-.008 1.603.113Z" />
        </SvgIcon>
    );
};
