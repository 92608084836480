import {createCountryLatLngConverter} from "./create_country_lat_lng_converter";

const MIN_POLAND_LAT = 49.0;
const MAX_POLAND_LAT = 55.0;

const MIN_POLAND_LNG = 14.0;
const MAX_POLAND_LNG = 24.2;

export const convertToLatLngLiteralOfPoland = createCountryLatLngConverter(
    {min: MIN_POLAND_LAT, max: MAX_POLAND_LAT},
    {min: MIN_POLAND_LNG, max: MAX_POLAND_LNG}
);
import {createCountryLatLngConverter} from "./create_country_lat_lng_converter";

const MIN_POLAND_LAT = 49.0;
const MAX_POLAND_LAT = 55.0;

const MIN_POLAND_LNG = 14.0;
const MAX_POLAND_LNG = 24.2;

export const convertToLatLngLiteralOfPoland = createCountryLatLngConverter(
    {min: MIN_POLAND_LAT, max: MAX_POLAND_LAT},
    {min: MIN_POLAND_LNG, max: MAX_POLAND_LNG}
);
