import {useAppSelector} from "../../store/hooks";
import {ApplicationVariant} from "../types/ApplicationVariant";

export const useApplicationVariant = () => {
    const modalApplicationVariant = useAppSelector((state) => state.leadModal.applicationVariant);
    const propertyId = useAppSelector((state) => state.leadModal.propertyId);

    const applicationVariant = modalApplicationVariant ?? ApplicationVariant.PROPERTY_WITH_RETARGETING;

    if (applicationVariant === ApplicationVariant.PROPERTY_WITH_RETARGETING && !propertyId) {
        // PROPERTY_WITH_RETARGETING is only available for properties
        return ApplicationVariant.DEFAULT;
    }

    return applicationVariant;
};
import {useAppSelector} from "../../store/hooks";
import {ApplicationVariant} from "../types/ApplicationVariant";

export const useApplicationVariant = () => {
    const modalApplicationVariant = useAppSelector((state) => state.leadModal.applicationVariant);
    const propertyId = useAppSelector((state) => state.leadModal.propertyId);

    const applicationVariant = modalApplicationVariant ?? ApplicationVariant.PROPERTY_WITH_RETARGETING;

    if (applicationVariant === ApplicationVariant.PROPERTY_WITH_RETARGETING && !propertyId) {
        // PROPERTY_WITH_RETARGETING is only available for properties
        return ApplicationVariant.DEFAULT;
    }

    return applicationVariant;
};
