/* eslint-disable @typescript-eslint/no-explicit-any */

import {FormFieldClassMap, FormFieldType} from "@pg-mono/form";

const isEmpty = (value: any) => value == null || value === "";

// TODO: after rebranding - find a better place for this file
export type JsonValues = Record<string, any>;
/**
 * NOTE: always add values to json (as null or [empty]) that are empty in form (do not use `undefined`)
 * This is because we want to be able to patch keys that user removed values from form.
 */
export const toJsonValues = (types: FormFieldClassMap, formValues: Record<string, any>): JsonValues => {
    return Object.entries(types).reduce((acc: JsonValues, [key, type]) => {
        switch (type) {
            /**
             * Field
             */
            case FormFieldType.Input:
            case FormFieldType.Radio:
            case FormFieldType.Textarea:
                return {...acc, [key]: isEmpty(formValues[key]) ? null : formValues[key]};
            case FormFieldType.Rooms:
                return {...acc, [key]: isEmpty(formValues[key]) ? [] : formValues[key]};
            case FormFieldType.Select:
                return {...acc, [key]: isEmpty(formValues[key]) ? null : formValues[key]};
            case FormFieldType.InputRange:
            case FormFieldType.SelectRange:
            case FormFieldType.SelectRangeNumber:
                const rangeValues = formValues[key];
                return isEmpty(rangeValues.lower) && isEmpty(rangeValues.upper)
                    ? {...acc, [key]: {}}
                    : {
                          ...acc,
                          [key]: {
                              lower: isEmpty(rangeValues.lower) ? undefined : rangeValues.lower,
                              upper: isEmpty(rangeValues.upper) ? undefined : rangeValues.upper
                          }
                      };
            case FormFieldType.PhoneField:
                const phone = formValues[key];
                if (phone == null || phone.code == null || !phone.number) {
                    // invalid formValue
                    return {...acc, [key]: null};
                }
                return {...acc, [key]: `${phone.code} ${phone.number}`};
            case FormFieldType.Checkbox:
                return {...acc, [key]: !!formValues[key]};
            default:
                throw new Error(`toJsonValues: not implemented for ${key}, formFieldType is ${type}`);
        }
    }, {});
};
/* eslint-disable @typescript-eslint/no-explicit-any */

import {FormFieldClassMap, FormFieldType} from "@pg-mono/form";

const isEmpty = (value: any) => value == null || value === "";

// TODO: after rebranding - find a better place for this file
export type JsonValues = Record<string, any>;
/**
 * NOTE: always add values to json (as null or [empty]) that are empty in form (do not use `undefined`)
 * This is because we want to be able to patch keys that user removed values from form.
 */
export const toJsonValues = (types: FormFieldClassMap, formValues: Record<string, any>): JsonValues => {
    return Object.entries(types).reduce((acc: JsonValues, [key, type]) => {
        switch (type) {
            /**
             * Field
             */
            case FormFieldType.Input:
            case FormFieldType.Radio:
            case FormFieldType.Textarea:
                return {...acc, [key]: isEmpty(formValues[key]) ? null : formValues[key]};
            case FormFieldType.Rooms:
                return {...acc, [key]: isEmpty(formValues[key]) ? [] : formValues[key]};
            case FormFieldType.Select:
                return {...acc, [key]: isEmpty(formValues[key]) ? null : formValues[key]};
            case FormFieldType.InputRange:
            case FormFieldType.SelectRange:
            case FormFieldType.SelectRangeNumber:
                const rangeValues = formValues[key];
                return isEmpty(rangeValues.lower) && isEmpty(rangeValues.upper)
                    ? {...acc, [key]: {}}
                    : {
                          ...acc,
                          [key]: {
                              lower: isEmpty(rangeValues.lower) ? undefined : rangeValues.lower,
                              upper: isEmpty(rangeValues.upper) ? undefined : rangeValues.upper
                          }
                      };
            case FormFieldType.PhoneField:
                const phone = formValues[key];
                if (phone == null || phone.code == null || !phone.number) {
                    // invalid formValue
                    return {...acc, [key]: null};
                }
                return {...acc, [key]: `${phone.code} ${phone.number}`};
            case FormFieldType.Checkbox:
                return {...acc, [key]: !!formValues[key]};
            default:
                throw new Error(`toJsonValues: not implemented for ${key}, formFieldType is ${type}`);
        }
    }, {});
};
