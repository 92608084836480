/**
 * TODO: This file probably duplicates functionality we have from packages/core/geo_utils/src/count_distance.ts
 *  Check usages of below functions and compare, if possible, remove duplicates
 */

type ICoordinates = [number, number];

const asin = Math.asin;
const cos = Math.cos;
const sin = Math.sin;
const sqrt = Math.sqrt;
const PI = Math.PI;

// equatorial mean radius of Earth (in meters)
const EARTH_RADIUS = 6378137;

const squared = (x: number) => x * x;
const hav = (x: number) => squared(sin(x / 2));
const toDegrees = (radians: number) => (radians * 180) / PI;
const toRadians = (angleDegrees: number) => (angleDegrees * PI) / 180.0;

// hav(theta) = hav(bLat - aLat) + cos(aLat) * cos(bLat) * hav(bLon - aLon)
const haversineDistance = (a: ICoordinates, b: ICoordinates) => {
    const aLat = toRadians(a[1]);
    const bLat = toRadians(b[1]);
    const aLng = toRadians(a[0]);
    const bLng = toRadians(b[0]);

    const ht = hav(bLat - aLat) + cos(aLat) * cos(bLat) * hav(bLng - aLng);
    return 2 * EARTH_RADIUS * asin(sqrt(ht));
};

export const calcCircleOnMapRadius = (centerCoordinates: ICoordinates, innerMarkersCoordinates: ICoordinates[], extraBufferRadius = 0) => {
    const distancesInStraightLineBetweenCenterAndMarkers = innerMarkersCoordinates.map((markerCoordinates) =>
        haversineDistance(centerCoordinates, markerCoordinates)
    );
    const maxValue = distancesInStraightLineBetweenCenterAndMarkers.length > 0 ? Math.max(...distancesInStraightLineBetweenCenterAndMarkers) : 0;

    return maxValue + extraBufferRadius;
};

export const computeGeolocationOffset = (from: {lat: number; lng: number}, distance: number, heading: number, radius: number = EARTH_RADIUS) => {
    distance /= radius;
    heading = toRadians(heading);

    const fromLat = toRadians(from.lat);
    const cosDistance = cos(distance);
    const sinDistance = sin(distance);
    const sinFromLat = sin(fromLat);
    const cosFromLat = cos(fromLat);
    const sc = cosDistance * sinFromLat + sinDistance * cosFromLat * cos(heading);

    return {
        lat: toDegrees(asin(sc)),
        lng: toDegrees(toRadians(from.lng) + Math.atan2(sinDistance * cosFromLat * sin(heading), cosDistance - sinFromLat * sc))
    };
};
/**
 * TODO: This file probably duplicates functionality we have from packages/core/geo_utils/src/count_distance.ts
 *  Check usages of below functions and compare, if possible, remove duplicates
 */

type ICoordinates = [number, number];

const asin = Math.asin;
const cos = Math.cos;
const sin = Math.sin;
const sqrt = Math.sqrt;
const PI = Math.PI;

// equatorial mean radius of Earth (in meters)
const EARTH_RADIUS = 6378137;

const squared = (x: number) => x * x;
const hav = (x: number) => squared(sin(x / 2));
const toDegrees = (radians: number) => (radians * 180) / PI;
const toRadians = (angleDegrees: number) => (angleDegrees * PI) / 180.0;

// hav(theta) = hav(bLat - aLat) + cos(aLat) * cos(bLat) * hav(bLon - aLon)
const haversineDistance = (a: ICoordinates, b: ICoordinates) => {
    const aLat = toRadians(a[1]);
    const bLat = toRadians(b[1]);
    const aLng = toRadians(a[0]);
    const bLng = toRadians(b[0]);

    const ht = hav(bLat - aLat) + cos(aLat) * cos(bLat) * hav(bLng - aLng);
    return 2 * EARTH_RADIUS * asin(sqrt(ht));
};

export const calcCircleOnMapRadius = (centerCoordinates: ICoordinates, innerMarkersCoordinates: ICoordinates[], extraBufferRadius = 0) => {
    const distancesInStraightLineBetweenCenterAndMarkers = innerMarkersCoordinates.map((markerCoordinates) =>
        haversineDistance(centerCoordinates, markerCoordinates)
    );
    const maxValue = distancesInStraightLineBetweenCenterAndMarkers.length > 0 ? Math.max(...distancesInStraightLineBetweenCenterAndMarkers) : 0;

    return maxValue + extraBufferRadius;
};

export const computeGeolocationOffset = (from: {lat: number; lng: number}, distance: number, heading: number, radius: number = EARTH_RADIUS) => {
    distance /= radius;
    heading = toRadians(heading);

    const fromLat = toRadians(from.lat);
    const cosDistance = cos(distance);
    const sinDistance = sin(distance);
    const sinFromLat = sin(fromLat);
    const cosFromLat = cos(fromLat);
    const sc = cosDistance * sinFromLat + sinDistance * cosFromLat * cos(heading);

    return {
        lat: toDegrees(asin(sc)),
        lng: toDegrees(toRadians(from.lng) + Math.atan2(sinDistance * cosFromLat * sin(heading), cosDistance - sinFromLat * sc))
    };
};
