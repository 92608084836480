import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {mb, mt} from "@pg-design/helpers-css";
import {FormikForm} from "@pg-mono/formik-utils";

export const SubmitButton = styled(FormikForm.Button)`
    width: 100%;
    margin-bottom: 1.5rem;

    ${({theme}) => css`
        @media (max-height: 740px) and (min-width: ${theme.breakpoints.md}) {
            ${mt(1)};
            ${mb(1)};
        }
    `}
`;
import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {mb, mt} from "@pg-design/helpers-css";
import {FormikForm} from "@pg-mono/formik-utils";

export const SubmitButton = styled(FormikForm.Button)`
    width: 100%;
    margin-bottom: 1.5rem;

    ${({theme}) => css`
        @media (max-height: 740px) and (min-width: ${theme.breakpoints.md}) {
            ${mt(1)};
            ${mb(1)};
        }
    `}
`;
