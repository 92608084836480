import {UIEvent, useRef} from "react";
import {useSelector} from "react-redux";
import styled from "@emotion/styled";

import {p} from "@pg-design/helpers-css";
import {useScrollableListItemTracking} from "@pg-mono/hooks";

import {IRPStore} from "../../../../../app/rp_reducer";
import {RecommendOfferItem} from "../../../../../atoms/RecommendOfferItem";
import {notifyBugsnag} from "../../../../../errors/bugsnag/notify_bugsnag";
import {hitMultileadRecommendationShown} from "../../../../../lead/tracking/hit_multilead_recommendation_shown";
import {MultileadVersion} from "../../../../../lead/types/MultileadVersion";
import {RecommendationSource} from "../../../../../lead/types/RecommendationSource";
import {IRecommendedOffer} from "../../../../../recommendations/types/IRecommendedOffer";
import {ViewType} from "../../../../../view_type/ViewType";
import {MultiLeadRecommendationType} from "../../../../types/MultiLeadRecommendationType";

interface IProps {
    recommendedOffers: IRecommendedOffer[];
    isScrolledToBottom: boolean;
    onListScroll: (event: UIEvent) => void;
    selected: number[];
    handleChange: (viewType: ViewType, recommendedItemID: number) => void;
    className?: string;
}

export function RecommendedOfferList(props: IProps) {
    const {recommendedOffers, onListScroll, isScrolledToBottom, selected} = props;

    const viewType = useSelector((store: IRPStore) => store.viewType.current);
    const listRef = useRef<HTMLUListElement>(null);

    useScrollableListItemTracking({
        scrollableListRef: listRef,
        listElementTrackingIdDatasetName: "trackId",
        handleListItemTrackingHit: (trackingId) => {
            hitMultileadRecommendationShown({
                recommendationId: trackingId,
                recommendationType: MultiLeadRecommendationType.OFFER,
                recommendationSource: RecommendationSource.DISTANCE,
                multileadVersion: MultileadVersion.RP_LIST_OFFER,
                viewType
            });
        },
        onError: (error) => {
            console.warn(error);
            notifyBugsnag(new Error(error), error);
        }
    });

    return (
        <ListWrapper onScroll={onListScroll} isScrolledToBottom={isScrolledToBottom} ref={listRef} className={props.className}>
            {recommendedOffers.map((recommendedItem) => {
                const offerID = recommendedItem.id;
                const isChecked = selected.includes(offerID);
                return (
                    <RecommendOfferItem
                        key={offerID}
                        data-track-id={offerID}
                        offer={recommendedItem}
                        checked={isChecked}
                        handleChange={() => props.handleChange(ViewType.OFFER, offerID)}
                    />
                );
            })}
        </ListWrapper>
    );
}

interface IListWrapperProps {
    isScrolledToTop?: boolean;
    isScrolledToBottom?: boolean;
}

const ListWrapper = styled.ul<IListWrapperProps>`
    ${p(1.5, 0)};
    margin: 0;

    align-items: center;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    list-style: none;
    position: relative;

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
        ${p(3, 5)}
        overflow-y: scroll;

        height: 440px;
        padding-right: 7.5rem;
    }
`;
import {UIEvent, useRef} from "react";
import {useSelector} from "react-redux";
import styled from "@emotion/styled";

import {p} from "@pg-design/helpers-css";
import {useScrollableListItemTracking} from "@pg-mono/hooks";

import {IRPStore} from "../../../../../app/rp_reducer";
import {RecommendOfferItem} from "../../../../../atoms/RecommendOfferItem";
import {notifyBugsnag} from "../../../../../errors/bugsnag/notify_bugsnag";
import {hitMultileadRecommendationShown} from "../../../../../lead/tracking/hit_multilead_recommendation_shown";
import {MultileadVersion} from "../../../../../lead/types/MultileadVersion";
import {RecommendationSource} from "../../../../../lead/types/RecommendationSource";
import {IRecommendedOffer} from "../../../../../recommendations/types/IRecommendedOffer";
import {ViewType} from "../../../../../view_type/ViewType";
import {MultiLeadRecommendationType} from "../../../../types/MultiLeadRecommendationType";

interface IProps {
    recommendedOffers: IRecommendedOffer[];
    isScrolledToBottom: boolean;
    onListScroll: (event: UIEvent) => void;
    selected: number[];
    handleChange: (viewType: ViewType, recommendedItemID: number) => void;
    className?: string;
}

export function RecommendedOfferList(props: IProps) {
    const {recommendedOffers, onListScroll, isScrolledToBottom, selected} = props;

    const viewType = useSelector((store: IRPStore) => store.viewType.current);
    const listRef = useRef<HTMLUListElement>(null);

    useScrollableListItemTracking({
        scrollableListRef: listRef,
        listElementTrackingIdDatasetName: "trackId",
        handleListItemTrackingHit: (trackingId) => {
            hitMultileadRecommendationShown({
                recommendationId: trackingId,
                recommendationType: MultiLeadRecommendationType.OFFER,
                recommendationSource: RecommendationSource.DISTANCE,
                multileadVersion: MultileadVersion.RP_LIST_OFFER,
                viewType
            });
        },
        onError: (error) => {
            console.warn(error);
            notifyBugsnag(new Error(error), error);
        }
    });

    return (
        <ListWrapper onScroll={onListScroll} isScrolledToBottom={isScrolledToBottom} ref={listRef} className={props.className}>
            {recommendedOffers.map((recommendedItem) => {
                const offerID = recommendedItem.id;
                const isChecked = selected.includes(offerID);
                return (
                    <RecommendOfferItem
                        key={offerID}
                        data-track-id={offerID}
                        offer={recommendedItem}
                        checked={isChecked}
                        handleChange={() => props.handleChange(ViewType.OFFER, offerID)}
                    />
                );
            })}
        </ListWrapper>
    );
}

interface IListWrapperProps {
    isScrolledToTop?: boolean;
    isScrolledToBottom?: boolean;
}

const ListWrapper = styled.ul<IListWrapperProps>`
    ${p(1.5, 0)};
    margin: 0;

    align-items: center;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    list-style: none;
    position: relative;

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
        ${p(3, 5)}
        overflow-y: scroll;

        height: 440px;
        padding-right: 7.5rem;
    }
`;
