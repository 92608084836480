const suffixes = [
    {valueFrom: 1000, suffix: "tys."},
    {valueFrom: 1000000, suffix: "mln"}
];

export const shortenBigNumbers = (value: number) => {
    suffixes.sort((a, b) => b.valueFrom - a.valueFrom);

    for (let i = 0; i < suffixes.length; i++) {
        if (value >= suffixes[i].valueFrom) {
            return `${value / suffixes[i].valueFrom} ${suffixes[i].suffix}`;
        }
    }

    return value;
};
const suffixes = [
    {valueFrom: 1000, suffix: "tys."},
    {valueFrom: 1000000, suffix: "mln"}
];

export const shortenBigNumbers = (value: number) => {
    suffixes.sort((a, b) => b.valueFrom - a.valueFrom);

    for (let i = 0; i < suffixes.length; i++) {
        if (value >= suffixes[i].valueFrom) {
            return `${value / suffixes[i].valueFrom} ${suffixes[i].suffix}`;
        }
    }

    return value;
};
