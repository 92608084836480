import {apiBigDataLink} from "@pg-mono/rp-api-routes";

import {bdApi} from "../../app/api/bd_api";
import {bigDataApiUrl} from "../../app/read_rp_environment_variables";
import {IGetMonthCityTypeStatsPayload} from "../types/IGetMonthCityTypeStatsPayload";
import {IMonthTypeStats} from "../types/IMonthTypeStats";
import {getMonthStatsPayloadDate} from "../utils/get_month_stats_payload_date";

export const getMonthCityTypeStatsApi = bdApi.injectEndpoints({
    endpoints: (builder) => ({
        getMonthCityTypeStats: builder.query<IMonthTypeStats[], IGetMonthCityTypeStatsPayload>({
            query: (params) => ({
                url: apiBigDataLink.monthCityTypeStats.base(bigDataApiUrl),
                params: {
                    ...params,
                    ...getMonthStatsPayloadDate(),
                    scenario: "rp-avg"
                }
            })
        })
    })
});

export const {getMonthCityTypeStats} = getMonthCityTypeStatsApi.endpoints;
export const {useGetMonthCityTypeStatsQuery} = getMonthCityTypeStatsApi;
import {apiBigDataLink} from "@pg-mono/rp-api-routes";

import {bdApi} from "../../app/api/bd_api";
import {bigDataApiUrl} from "../../app/read_rp_environment_variables";
import {IGetMonthCityTypeStatsPayload} from "../types/IGetMonthCityTypeStatsPayload";
import {IMonthTypeStats} from "../types/IMonthTypeStats";
import {getMonthStatsPayloadDate} from "../utils/get_month_stats_payload_date";

export const getMonthCityTypeStatsApi = bdApi.injectEndpoints({
    endpoints: (builder) => ({
        getMonthCityTypeStats: builder.query<IMonthTypeStats[], IGetMonthCityTypeStatsPayload>({
            query: (params) => ({
                url: apiBigDataLink.monthCityTypeStats.base(bigDataApiUrl),
                params: {
                    ...params,
                    ...getMonthStatsPayloadDate(),
                    scenario: "rp-avg"
                }
            })
        })
    })
});

export const {getMonthCityTypeStats} = getMonthCityTypeStatsApi.endpoints;
export const {useGetMonthCityTypeStatsQuery} = getMonthCityTypeStatsApi;
