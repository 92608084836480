import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const FloorUpIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M2.377 4.733 5.2 1.911v1.222h.533V1H3.6v.533h1.223L2 4.356l.377.377ZM8.933 3.667H6.8v1.6H5.2v1.6H3.6v1.6H2V9h2.133V7.4h1.6V5.8h1.6V4.2h1.6v-.533Z" />
        </SvgIcon>
    );
};
import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const FloorUpIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M2.377 4.733 5.2 1.911v1.222h.533V1H3.6v.533h1.223L2 4.356l.377.377ZM8.933 3.667H6.8v1.6H5.2v1.6H3.6v1.6H2V9h2.133V7.4h1.6V5.8h1.6V4.2h1.6v-.533Z" />
        </SvgIcon>
    );
};
