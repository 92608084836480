import {apiPath} from "@pg-mono/rp-api-routes";

import {rpApi} from "../../app/api/rp_api";
import {IApplication} from "../../application/types/IApplication";
import {notifyBugsnag} from "../../errors/bugsnag/notify_bugsnag";
import {isRtkqError} from "../../store/utils/is_rtkq_error";
import {ICreateMultipleLeadsPayload} from "../types/ICreateMultipleLeadsPayload";

export const createMultipleLeadsApi = rpApi.injectEndpoints({
    endpoints: (build) => ({
        createMultipleApplications: build.mutation<
            {
                applications: IApplication[];
            },
            ICreateMultipleLeadsPayload
        >({
            query: (body) => ({
                url: apiPath.applications.createMultiple.base,
                method: "POST",
                body
            }),
            async onQueryStarted(_, {queryFulfilled}) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    if (isRtkqError(error) && error.status === 403) {
                        notifyBugsnag(error, "createMultipleLeads: catch 403", error.data);
                    }
                }
            }
        })
    })
});

export const {
    useCreateMultipleApplicationsMutation,
    endpoints: {createMultipleApplications}
} = createMultipleLeadsApi;
import {apiPath} from "@pg-mono/rp-api-routes";

import {rpApi} from "../../app/api/rp_api";
import {IApplication} from "../../application/types/IApplication";
import {notifyBugsnag} from "../../errors/bugsnag/notify_bugsnag";
import {isRtkqError} from "../../store/utils/is_rtkq_error";
import {ICreateMultipleLeadsPayload} from "../types/ICreateMultipleLeadsPayload";

export const createMultipleLeadsApi = rpApi.injectEndpoints({
    endpoints: (build) => ({
        createMultipleApplications: build.mutation<
            {
                applications: IApplication[];
            },
            ICreateMultipleLeadsPayload
        >({
            query: (body) => ({
                url: apiPath.applications.createMultiple.base,
                method: "POST",
                body
            }),
            async onQueryStarted(_, {queryFulfilled}) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    if (isRtkqError(error) && error.status === 403) {
                        notifyBugsnag(error, "createMultipleLeads: catch 403", error.data);
                    }
                }
            }
        })
    })
});

export const {
    useCreateMultipleApplicationsMutation,
    endpoints: {createMultipleApplications}
} = createMultipleLeadsApi;
