import {hitAlgolytics} from "@pg-mono/algolytics";

import {ApplicationSource, ApplicationSourceSection} from "../../application/utils/ApplicationSource";
import {getTrackedSiteData} from "./tracked_utils/tracked_site";

export type IVersion = "first_scenario" | "second_scenario" | "third_scenario";

export const goodbyeModalContentInitialized = (source: ApplicationSource, applicationSourceSection: ApplicationSourceSection, version: IVersion) => {
    const payload = {
        source,
        source_section: applicationSourceSection,
        version,
        ...getTrackedSiteData()
    };
    hitAlgolytics("application_phone_number_open", payload);
};
import {hitAlgolytics} from "@pg-mono/algolytics";

import {ApplicationSource, ApplicationSourceSection} from "../../application/utils/ApplicationSource";
import {getTrackedSiteData} from "./tracked_utils/tracked_site";

export type IVersion = "first_scenario" | "second_scenario" | "third_scenario";

export const goodbyeModalContentInitialized = (source: ApplicationSource, applicationSourceSection: ApplicationSourceSection, version: IVersion) => {
    const payload = {
        source,
        source_section: applicationSourceSection,
        version,
        ...getTrackedSiteData()
    };
    hitAlgolytics("application_phone_number_open", payload);
};
