import {toJsonValues} from "../../../form/translate/to_json_values";
import {createMultipleApplications} from "../../../lead/api/create_multiple_applications";
import {ICreateMultipleLeadsPayload} from "../../../lead/types/ICreateMultipleLeadsPayload";
import {addSuccessNotification} from "../../../notifications/utils/add_success_notification";
import {addAppliedOffers} from "../../../offer/map/actions/add_applied_offers";
import {IRecommendedProperty} from "../../../recommendations/types/IRecommendedProperty";
import {IAppDispatch} from "../../../store/store";
import {ViewType} from "../../../view_type/ViewType";
import {IApplicationAdditionalQuestionTypes} from "../../components/application_form/ApplicationFormContext";
import {applicationFormFields} from "../../constants/application_form";
import {IApplication} from "../../types/IApplication";
import {IApplicationFormValues} from "../../types/IApplicationFormValues";
import {MultiLeadRecommendationType} from "../../types/MultiLeadRecommendationType";
import {RecommendedPropertyApplicationsType} from "../../types/recommended_property_applications_type";
import {alertFormSuccessMessage} from "../alert_text";
import {ApplicationSource, ApplicationSourceSection} from "../ApplicationSource";
import {addOffersToStorageSentApplication} from "../manage_storage_sent_applications";
import {addPropertiesToStorageSentApplication} from "../property_application_storage";
import {getGeneratedMessage} from "../text_generator/get_generated_message";
import {report} from "./analytics_events";

// Data used in reporting purposes both by backend and platforms like algolytics tracker and GTM
interface IPropertyMultiApplicationMetadata {
    viewType: ViewType | null;
    source?: ApplicationSource;
    sourceSection: ApplicationSourceSection;
    isAuthenticated: boolean;
    application: IApplication | null | undefined;
    refererApplicationUUID: string | undefined;
    originalFormParams: {
        rooms: number[];
        questions?: IApplicationAdditionalQuestionTypes[];
    };
    recommendationsWithPriceFilter: boolean;
}

interface IPropertyMultiApplicationPayload {
    recommendedProperties: IRecommendedProperty[];
    formValues: IApplicationFormValues;
}

export const sendPropertyMultiLead =
    (payload: IPropertyMultiApplicationPayload, metadata: IPropertyMultiApplicationMetadata, customSourceSection?: string) =>
    (dispatch: IAppDispatch): Promise<RecommendedPropertyApplicationsType[]> => {
        // TODO: this shouldn't be JsonValues type, refactor when migrating to rhf
        const jsonValues = toJsonValues(applicationFormFields, payload.formValues) as Pick<IApplicationFormValues, "name">;
        const source = metadata.source || ApplicationSource.RecommendedProperty;

        if ("text" in jsonValues) {
            delete jsonValues.text;
        }

        const createPayload: ICreateMultipleLeadsPayload = {
            ...jsonValues,
            referer_application_uuid: metadata.refererApplicationUUID,
            source,
            source_section: metadata.sourceSection,
            applications: payload.recommendedProperties.map((property) => ({
                property: property.id,
                text: getGeneratedMessage({
                    offerType: property.offer.type,
                    offerName: property.offer.name,
                    propertyNumber: property.number,
                    name: payload.formValues.name,
                    rooms: property.rooms,
                    area: property.area
                })
            }))
        };

        return dispatch(createMultipleApplications.initiate(createPayload))
            .unwrap()
            .then((response) => {
                dispatch(addSuccessNotification(alertFormSuccessMessage));

                const applications = response.applications;

                if (!applications.filter(Boolean).length) {
                    return [];
                }

                const offerIDs = applications.reduce<number[]>((acc, application) => {
                    return application ? acc.concat(application.offer) : acc;
                }, []);

                addOffersToStorageSentApplication(
                    payload.recommendedProperties.map((property) => {
                        return {
                            id: property.offer.id,
                            coordinates: property.offer.geo_point.coordinates,
                            country: property.region.country,
                            regionId: property.region.parent?.id
                        };
                    })
                );

                addPropertiesToStorageSentApplication(payload.recommendedProperties.map((property) => property.id));

                if (metadata.isAuthenticated) {
                    dispatch(addAppliedOffers(offerIDs));
                }

                const createdApplications = applications.reduce<RecommendedPropertyApplicationsType[]>((acc, application) => {
                    if (application) {
                        const recommendedProperty = payload.recommendedProperties.find(({id}) => id === application.property);

                        report.singleApplicationSuccess(application, metadata.sourceSection, metadata.viewType);

                        if (recommendedProperty) {
                            acc.push([application, recommendedProperty]);
                        }
                    }
                    return acc;
                }, []);

                report.finishedSending(
                    MultiLeadRecommendationType.PROPERTY,
                    createdApplications,
                    metadata.viewType,
                    "standard",
                    metadata.application,
                    metadata.recommendationsWithPriceFilter,
                    customSourceSection
                );

                return createdApplications;
            })
            .catch(() => {
                return [];
            });
    };
import {toJsonValues} from "../../../form/translate/to_json_values";
import {createMultipleApplications} from "../../../lead/api/create_multiple_applications";
import {ICreateMultipleLeadsPayload} from "../../../lead/types/ICreateMultipleLeadsPayload";
import {addSuccessNotification} from "../../../notifications/utils/add_success_notification";
import {addAppliedOffers} from "../../../offer/map/actions/add_applied_offers";
import {IRecommendedProperty} from "../../../recommendations/types/IRecommendedProperty";
import {IAppDispatch} from "../../../store/store";
import {ViewType} from "../../../view_type/ViewType";
import {IApplicationAdditionalQuestionTypes} from "../../components/application_form/ApplicationFormContext";
import {applicationFormFields} from "../../constants/application_form";
import {IApplication} from "../../types/IApplication";
import {IApplicationFormValues} from "../../types/IApplicationFormValues";
import {MultiLeadRecommendationType} from "../../types/MultiLeadRecommendationType";
import {RecommendedPropertyApplicationsType} from "../../types/recommended_property_applications_type";
import {alertFormSuccessMessage} from "../alert_text";
import {ApplicationSource, ApplicationSourceSection} from "../ApplicationSource";
import {addOffersToStorageSentApplication} from "../manage_storage_sent_applications";
import {addPropertiesToStorageSentApplication} from "../property_application_storage";
import {getGeneratedMessage} from "../text_generator/get_generated_message";
import {report} from "./analytics_events";

// Data used in reporting purposes both by backend and platforms like algolytics tracker and GTM
interface IPropertyMultiApplicationMetadata {
    viewType: ViewType | null;
    source?: ApplicationSource;
    sourceSection: ApplicationSourceSection;
    isAuthenticated: boolean;
    application: IApplication | null | undefined;
    refererApplicationUUID: string | undefined;
    originalFormParams: {
        rooms: number[];
        questions?: IApplicationAdditionalQuestionTypes[];
    };
    recommendationsWithPriceFilter: boolean;
}

interface IPropertyMultiApplicationPayload {
    recommendedProperties: IRecommendedProperty[];
    formValues: IApplicationFormValues;
}

export const sendPropertyMultiLead =
    (payload: IPropertyMultiApplicationPayload, metadata: IPropertyMultiApplicationMetadata, customSourceSection?: string) =>
    (dispatch: IAppDispatch): Promise<RecommendedPropertyApplicationsType[]> => {
        // TODO: this shouldn't be JsonValues type, refactor when migrating to rhf
        const jsonValues = toJsonValues(applicationFormFields, payload.formValues) as Pick<IApplicationFormValues, "name">;
        const source = metadata.source || ApplicationSource.RecommendedProperty;

        if ("text" in jsonValues) {
            delete jsonValues.text;
        }

        const createPayload: ICreateMultipleLeadsPayload = {
            ...jsonValues,
            referer_application_uuid: metadata.refererApplicationUUID,
            source,
            source_section: metadata.sourceSection,
            applications: payload.recommendedProperties.map((property) => ({
                property: property.id,
                text: getGeneratedMessage({
                    offerType: property.offer.type,
                    offerName: property.offer.name,
                    propertyNumber: property.number,
                    name: payload.formValues.name,
                    rooms: property.rooms,
                    area: property.area
                })
            }))
        };

        return dispatch(createMultipleApplications.initiate(createPayload))
            .unwrap()
            .then((response) => {
                dispatch(addSuccessNotification(alertFormSuccessMessage));

                const applications = response.applications;

                if (!applications.filter(Boolean).length) {
                    return [];
                }

                const offerIDs = applications.reduce<number[]>((acc, application) => {
                    return application ? acc.concat(application.offer) : acc;
                }, []);

                addOffersToStorageSentApplication(
                    payload.recommendedProperties.map((property) => {
                        return {
                            id: property.offer.id,
                            coordinates: property.offer.geo_point.coordinates,
                            country: property.region.country,
                            regionId: property.region.parent?.id
                        };
                    })
                );

                addPropertiesToStorageSentApplication(payload.recommendedProperties.map((property) => property.id));

                if (metadata.isAuthenticated) {
                    dispatch(addAppliedOffers(offerIDs));
                }

                const createdApplications = applications.reduce<RecommendedPropertyApplicationsType[]>((acc, application) => {
                    if (application) {
                        const recommendedProperty = payload.recommendedProperties.find(({id}) => id === application.property);

                        report.singleApplicationSuccess(application, metadata.sourceSection, metadata.viewType);

                        if (recommendedProperty) {
                            acc.push([application, recommendedProperty]);
                        }
                    }
                    return acc;
                }, []);

                report.finishedSending(
                    MultiLeadRecommendationType.PROPERTY,
                    createdApplications,
                    metadata.viewType,
                    "standard",
                    metadata.application,
                    metadata.recommendationsWithPriceFilter,
                    customSourceSection
                );

                return createdApplications;
            })
            .catch(() => {
                return [];
            });
    };
