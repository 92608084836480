import React, {forwardRef, PropsWithChildren, useRef, useState} from "react";
import {css, SerializedStyles} from "@emotion/react";

import {prettyScroll} from "@pg-design/helpers-css";

interface IProps extends PropsWithChildren {
    height: number;
    scrollShadowHeight?: number;
    hideTopShadowAtScrollTop?: number;
    hideBottomShadowAtScrollLeft?: number;
    scrollTopShadowStyle?: SerializedStyles;
    scrollBottomShadowStyle?: SerializedStyles;
    contentStyle?: SerializedStyles;
    disableScroll?: boolean;
    className?: string;
}

export const AdvancedScroll = forwardRef<HTMLDivElement, IProps>((props, wrapperRef) => {
    const contentRef = useRef<HTMLDivElement | null>(null);
    const [hideTopShadow, setHideTopShadow] = useState(true);
    const [hideBottomShadow, setHideBottomShadow] = useState(false);

    const handleScroll = () => {
        const scrollHeight = contentRef.current?.scrollHeight || 0;
        const scrollPosition = contentRef.current?.scrollTop || 0;
        const scrollLeft = scrollHeight - props.height - scrollPosition;
        const hideTopShadowAtScrollTop = props.hideTopShadowAtScrollTop || 0;
        const hideBottomShadowAtScrollLeft = props.hideBottomShadowAtScrollLeft || 0;

        setHideTopShadow(scrollPosition === hideTopShadowAtScrollTop);
        setHideBottomShadow(scrollLeft <= hideBottomShadowAtScrollLeft);
    };

    return (
        <div ref={wrapperRef} css={wrapperStyle} style={props.disableScroll ? {} : {height: `${props.height}px`}} className={props.className}>
            {props.disableScroll ? (
                <div css={props.contentStyle}>{props.children}</div>
            ) : (
                <div ref={contentRef} onScroll={handleScroll} css={[contentStyle, props.contentStyle]}>
                    {props.children}
                </div>
            )}
            {props.disableScroll ? null : (
                <>
                    <div
                        css={[scrollTopShadowStyle, props.scrollTopShadowStyle, hideTopShadow && scrollShadowHideStyle]}
                        style={{height: `${props.scrollShadowHeight || 70}px`}}
                    />
                    <div
                        css={[scrollBottomShadowStyle, props.scrollBottomShadowStyle, hideBottomShadow && scrollShadowHideStyle]}
                        style={{height: `${props.scrollShadowHeight || 70}px`}}
                    />
                </>
            )}
        </div>
    );
});

const wrapperStyle = css`
    position: relative;
    width: 100%;
`;

const contentStyle = css`
    position: absolute;
    inset: 0;
    z-index: 1;
    overflow: visible;
    ${prettyScroll()};
`;

const scrollTopShadowStyle = css`
    position: absolute;
    inset: 0 0 auto 0;
    z-index: 2;
    background: linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
    user-select: none;
    pointer-events: none;
`;

const scrollBottomShadowStyle = css`
    position: absolute;
    inset: auto 0 0 0;
    z-index: 2;
    background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    user-select: none;
    pointer-events: none;
`;

const scrollShadowHideStyle = css`
    opacity: 0;
`;
import React, {forwardRef, PropsWithChildren, useRef, useState} from "react";
import {css, SerializedStyles} from "@emotion/react";

import {prettyScroll} from "@pg-design/helpers-css";

interface IProps extends PropsWithChildren {
    height: number;
    scrollShadowHeight?: number;
    hideTopShadowAtScrollTop?: number;
    hideBottomShadowAtScrollLeft?: number;
    scrollTopShadowStyle?: SerializedStyles;
    scrollBottomShadowStyle?: SerializedStyles;
    contentStyle?: SerializedStyles;
    disableScroll?: boolean;
    className?: string;
}

export const AdvancedScroll = forwardRef<HTMLDivElement, IProps>((props, wrapperRef) => {
    const contentRef = useRef<HTMLDivElement | null>(null);
    const [hideTopShadow, setHideTopShadow] = useState(true);
    const [hideBottomShadow, setHideBottomShadow] = useState(false);

    const handleScroll = () => {
        const scrollHeight = contentRef.current?.scrollHeight || 0;
        const scrollPosition = contentRef.current?.scrollTop || 0;
        const scrollLeft = scrollHeight - props.height - scrollPosition;
        const hideTopShadowAtScrollTop = props.hideTopShadowAtScrollTop || 0;
        const hideBottomShadowAtScrollLeft = props.hideBottomShadowAtScrollLeft || 0;

        setHideTopShadow(scrollPosition === hideTopShadowAtScrollTop);
        setHideBottomShadow(scrollLeft <= hideBottomShadowAtScrollLeft);
    };

    return (
        <div ref={wrapperRef} css={wrapperStyle} style={props.disableScroll ? {} : {height: `${props.height}px`}} className={props.className}>
            {props.disableScroll ? (
                <div css={props.contentStyle}>{props.children}</div>
            ) : (
                <div ref={contentRef} onScroll={handleScroll} css={[contentStyle, props.contentStyle]}>
                    {props.children}
                </div>
            )}
            {props.disableScroll ? null : (
                <>
                    <div
                        css={[scrollTopShadowStyle, props.scrollTopShadowStyle, hideTopShadow && scrollShadowHideStyle]}
                        style={{height: `${props.scrollShadowHeight || 70}px`}}
                    />
                    <div
                        css={[scrollBottomShadowStyle, props.scrollBottomShadowStyle, hideBottomShadow && scrollShadowHideStyle]}
                        style={{height: `${props.scrollShadowHeight || 70}px`}}
                    />
                </>
            )}
        </div>
    );
});

const wrapperStyle = css`
    position: relative;
    width: 100%;
`;

const contentStyle = css`
    position: absolute;
    inset: 0;
    z-index: 1;
    overflow: visible;
    ${prettyScroll()};
`;

const scrollTopShadowStyle = css`
    position: absolute;
    inset: 0 0 auto 0;
    z-index: 2;
    background: linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
    user-select: none;
    pointer-events: none;
`;

const scrollBottomShadowStyle = css`
    position: absolute;
    inset: auto 0 0 0;
    z-index: 2;
    background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    user-select: none;
    pointer-events: none;
`;

const scrollShadowHideStyle = css`
    opacity: 0;
`;
