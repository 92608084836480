import React, {ReactNode, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {css, Theme} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {elevation} from "@pg-design/elevation";
import {borderRadius, calculateRemSize, flexAbsoluteCenter, mb, mt, mv, onDesktop, p, w100} from "@pg-design/helpers-css";
import {DisLikeIcon, GalleryOutlineIcon, LikeIcon, PropertyPlanIcon} from "@pg-design/icons-module";
import {Image} from "@pg-design/image-module";
import {Loader} from "@pg-design/loader-module";
import {Text} from "@pg-design/text-module";
import {useFetch} from "@pg-mono/hooks";

import {IRPStore} from "../../../../../app/rp_reducer";
import {RodoText} from "../../../../../lead/components/RodoText";
import {hitMultileadRecommendationShown} from "../../../../../lead/tracking/hit_multilead_recommendation_shown";
import {MultileadVersion} from "../../../../../lead/types/MultileadVersion";
import {RecommendationSource} from "../../../../../lead/types/RecommendationSource";
import {fetchOfferDetailById} from "../../../../../offer/api/fetch_offer_detail_by_id";
import {fetchPropertiesDetailByIds} from "../../../../../property/api/fetch_properties_detail_by_ids";
import {IRegionEstimatedPriceSummary} from "../../../../../region/hooks/use_region_estimated_price";
import {Country} from "../../../../../region/types/Country";
import {IPriceFilter} from "../../../../hooks/use_recommendations_filters";
import {IMultiLeadData} from "../../../../types/IMultiLeadData";
import {MultiLeadRecommendationType} from "../../../../types/MultiLeadRecommendationType";
import {MultiLeadCardGallery} from "./MultiLeadCardGallery";
import {MultiLeadCardPictograms} from "./MultiLeadCardPictograms";
import {MultiLeadCardPictogramsForAbroadOffer} from "./MultiLeadCardPictogramsForAbroadOffer";
import {MultiLeadCardPropertyHeader} from "./MultiLeadCardPropertyHeader";
import {MultiLeadCardSentSummary} from "./MultiLeadCardSentSummary";
import {MultiLeadCardStockEffect} from "./MultiLeadCardStockEffect";

interface IProps extends IMultiLeadData {
    hasApplicationBeenSent?: boolean;
    children?: ReactNode;
    hideGallery?: boolean;
    hideCardStockEffect?: boolean;
    regionEstimatedPriceSummary: IRegionEstimatedPriceSummary;
    areRecommendationsFetching: boolean;
    isMultiLeadSending: boolean;
    recommendationsCount?: number;
    onOfferReject: (propertyId: number) => void;
    onAskForDetails: (propertyId: number) => void;
    hasBudgetBeenChanged?: boolean;
    onBudgetChange: (priceFilter: IPriceFilter) => void;
    onShowNextCard: () => void;
    recommendationType: MultiLeadRecommendationType;
}

export const MultiLeadCard = (props: IProps) => {
    const [showPlans, setShowPlans] = useState(false);
    const [showCardAnimation, setShowCardAnimation] = useState(false);
    const recommendationId = Number(props.id);

    const {fetchStart: fetchOfferDetails, response: offerDetails} = useFetch(() => fetchOfferDetailById({}, recommendationId));
    const {fetchStart: fetchPropertiesDetails, response: propertiesDetails} = useFetch(() => fetchPropertiesDetailByIds({}, [recommendationId], true));

    const propertyDetails = propertiesDetails?.results?.find((property) => property.id === recommendationId);
    const viewType = useSelector((store: IRPStore) => store.viewType.current);

    const hideGallery = props.hideGallery || props.hasApplicationBeenSent || props.areRecommendationsFetching;
    const hasPropertyPlans = (propertyDetails?.plan_image_pages || []).length > 0;

    useEffect(() => {
        if (props.recommendationType === MultiLeadRecommendationType.OFFER) {
            fetchOfferDetails();
        }
        if (props.recommendationType === MultiLeadRecommendationType.PROPERTY) {
            fetchPropertiesDetails();
        }
    }, [props.id, recommendationId, props.recommendationType]);

    useEffect(() => {
        hitMultileadRecommendationShown({
            recommendationId: props.id,
            recommendationType: MultiLeadRecommendationType.PROPERTY,
            recommendationSource: RecommendationSource.DISTANCE,
            multileadVersion: MultileadVersion.RP_SWIPE,
            viewType
        });
    }, [props.id]);

    useEffect(() => {
        if (props.hideCardStockEffect) {
            return;
        }

        setShowCardAnimation(true);

        setTimeout(() => {
            setShowCardAnimation(false);
        }, 300);
    }, [props.id, props.hideCardStockEffect]);

    const toggleGalleryMode = () => {
        setShowPlans((prev) => !prev);
    };

    const onOfferReject = () => {
        props.onOfferReject(Number(props.id));
    };
    const onAskForDetails = () => {
        props.onAskForDetails(Number(props.id));
    };

    return (
        <div css={[multiLeadCardWrapperStyle, props.hideCardStockEffect ? "" : mb(6), onDesktop(mb(0))]}>
            {props.hideCardStockEffect ? null : (
                <MultiLeadCardStockEffect
                    maxStockLength={Number.isInteger(props.recommendationsCount) ? (props.recommendationsCount as number) - 1 : undefined}
                    cardId={props.id}
                />
            )}

            <div css={[multiLeadCardContentStyle, showCardAnimation ? cardAnimation : ""]}>
                {hideGallery ? null : (
                    <div css={imageWrapperStyle}>
                        {showCardAnimation || showPlans ? null : (
                            <Image css={vendorLogoStyle} src={props.offerVendorLogo} width="62px" height="43px" alt={`${props.offerVendorName}`} />
                        )}
                        {propertyDetails?.id || offerDetails?.id ? (
                            <MultiLeadCardGallery
                                showPlans={showPlans}
                                propertyDetails={propertyDetails}
                                offerDetails={offerDetails}
                                id={props.id}
                                offerVendorName={props.offerVendorName}
                                offerName={props.offerName}
                            />
                        ) : null}
                        {hasPropertyPlans ? (
                            <Button type="button" variant="filled_primary" onClick={toggleGalleryMode} css={[propertyPlanSwitchButtonStyle]}>
                                {showPlans ? <GalleryOutlineIcon size="2.4" wrapperSize="2.4" /> : <PropertyPlanIcon size="2.4" wrapperSize="2.4" />}
                            </Button>
                        ) : null}
                    </div>
                )}

                <div css={[propertyDataContainerStyles, hideGallery ? borderRadius(2) : ""]}>
                    {props.hasApplicationBeenSent ? (
                        <MultiLeadCardSentSummary {...props} />
                    ) : props.areRecommendationsFetching ? (
                        <div css={loaderWrapperStyle}>
                            <Loader size="lg" />
                        </div>
                    ) : (
                        <>
                            <MultiLeadCardPropertyHeader offerType={props.offerType} offerAddress={props.offerAddress} rooms={props.rooms} area={props.area} />
                            {props.region.country === Country.POLAND ? (
                                <MultiLeadCardPictograms
                                    area={props.area}
                                    constructionDateRange={props.constructionDateRange}
                                    compareAreaTo={props.compareAreaTo}
                                    equalColumns={props.hideGallery}
                                    css={[mv(1)]}
                                />
                            ) : (
                                <MultiLeadCardPictogramsForAbroadOffer
                                    price={props.price}
                                    offerDistanceFromTheAirport={props.offerDistanceFromTheAirport}
                                    offerDistanceFromTheBeach={props.offerDistanceFromTheBeach}
                                    css={[mv(1)]}
                                />
                            )}
                            <div css={buttonsWrapperStyle}>
                                <Button type="button" variant="outlined_secondary" size="x-small" iconLeft={DisLikeIcon} onClick={onOfferReject} css={w100}>
                                    <Text as="span" variant="body_copy_1" strong>
                                        odrzuć
                                    </Text>
                                </Button>
                                <Button
                                    isLoading={props.isMultiLeadSending}
                                    type="button"
                                    variant="filled_primary"
                                    size="x-small"
                                    iconLeft={LikeIcon}
                                    onClick={onAskForDetails}
                                    css={w100}
                                >
                                    <Text as="span" variant="body_copy_1" strong>
                                        zapytaj
                                    </Text>
                                </Button>
                            </div>
                            <RodoText
                                align="center"
                                vendorName={props.offerVendorName}
                                privacyPolicyUrl={props.offerPrivacyPolicyUrl}
                                privacyDataProcessorName={props.offerPrivacyDataProcessorName}
                                personalDataProcessorUrl={props.offerPersonalDataProcessorUrl}
                                css={[w100, mt(1.5), mb(0.5)]}
                            />
                            {props.children}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

const multiLeadCardWrapperStyle = css`
    max-width: 35rem;
    width: 100%;
    position: relative;
    margin: 0 auto;

    ${onDesktop(css`
        max-width: 46rem;
    `)};
`;

const multiLeadCardContentStyle = css`
    display: flex;
    flex-direction: column;
    width: 100%;
    ${elevation()};
    ${borderRadius(2)};
`;

const cardAnimation = css`
    animation: showCard 0.3s forwards;

    @keyframes showCard {
        0% {
            opacity: 1;
        }
        10% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`;

const imageWrapperStyle = css`
    ${borderRadius(2, 2, 0, 0)}
    overflow: hidden;
    background-color: #fff;
    z-index: 2;
    position: relative;
    height: 20rem;
    flex: 0 0 20rem;

    ${onDesktop(css`
        flex: 0 0 26rem;
        height: 26rem;
    `)};
`;

const propertyPlanSwitchButtonStyle = css`
    position: absolute;
    right: ${calculateRemSize(2)};
    bottom: ${calculateRemSize(2)};
    ${p(1)};
    height: 4rem;
    min-width: 4rem;
    width: 4rem;
`;

const propertyDataContainerStyles = (theme: Theme) => css`
    z-index: 2;
    ${p(0.5, 1.5)};
    background-color: #fff;
    ${borderRadius(0, 0, 2, 2)};
    flex: 1 1 100%;
    height: 100%;
    overflow: auto;

    @media (min-width: ${theme.breakpoints.md}) {
        ${p(1, 3)};
    }
`;

const buttonsWrapperStyle = css`
    display: flex;
    width: 100%;
    gap: ${calculateRemSize(2)};
`;

const loaderWrapperStyle = css`
    ${flexAbsoluteCenter};
    width: 100%;
    min-height: 40rem;
`;

const vendorLogoStyle = css`
    position: absolute;
    z-index: 1;
    width: 62px;
    height: 43px;
    top: ${calculateRemSize(1.5)};
    left: ${calculateRemSize(2)};
`;
import React, {ReactNode, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {css, Theme} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {elevation} from "@pg-design/elevation";
import {borderRadius, calculateRemSize, flexAbsoluteCenter, mb, mt, mv, onDesktop, p, w100} from "@pg-design/helpers-css";
import {DisLikeIcon, GalleryOutlineIcon, LikeIcon, PropertyPlanIcon} from "@pg-design/icons-module";
import {Image} from "@pg-design/image-module";
import {Loader} from "@pg-design/loader-module";
import {Text} from "@pg-design/text-module";
import {useFetch} from "@pg-mono/hooks";

import {IRPStore} from "../../../../../app/rp_reducer";
import {RodoText} from "../../../../../lead/components/RodoText";
import {hitMultileadRecommendationShown} from "../../../../../lead/tracking/hit_multilead_recommendation_shown";
import {MultileadVersion} from "../../../../../lead/types/MultileadVersion";
import {RecommendationSource} from "../../../../../lead/types/RecommendationSource";
import {fetchOfferDetailById} from "../../../../../offer/api/fetch_offer_detail_by_id";
import {fetchPropertiesDetailByIds} from "../../../../../property/api/fetch_properties_detail_by_ids";
import {IRegionEstimatedPriceSummary} from "../../../../../region/hooks/use_region_estimated_price";
import {Country} from "../../../../../region/types/Country";
import {IPriceFilter} from "../../../../hooks/use_recommendations_filters";
import {IMultiLeadData} from "../../../../types/IMultiLeadData";
import {MultiLeadRecommendationType} from "../../../../types/MultiLeadRecommendationType";
import {MultiLeadCardGallery} from "./MultiLeadCardGallery";
import {MultiLeadCardPictograms} from "./MultiLeadCardPictograms";
import {MultiLeadCardPictogramsForAbroadOffer} from "./MultiLeadCardPictogramsForAbroadOffer";
import {MultiLeadCardPropertyHeader} from "./MultiLeadCardPropertyHeader";
import {MultiLeadCardSentSummary} from "./MultiLeadCardSentSummary";
import {MultiLeadCardStockEffect} from "./MultiLeadCardStockEffect";

interface IProps extends IMultiLeadData {
    hasApplicationBeenSent?: boolean;
    children?: ReactNode;
    hideGallery?: boolean;
    hideCardStockEffect?: boolean;
    regionEstimatedPriceSummary: IRegionEstimatedPriceSummary;
    areRecommendationsFetching: boolean;
    isMultiLeadSending: boolean;
    recommendationsCount?: number;
    onOfferReject: (propertyId: number) => void;
    onAskForDetails: (propertyId: number) => void;
    hasBudgetBeenChanged?: boolean;
    onBudgetChange: (priceFilter: IPriceFilter) => void;
    onShowNextCard: () => void;
    recommendationType: MultiLeadRecommendationType;
}

export const MultiLeadCard = (props: IProps) => {
    const [showPlans, setShowPlans] = useState(false);
    const [showCardAnimation, setShowCardAnimation] = useState(false);
    const recommendationId = Number(props.id);

    const {fetchStart: fetchOfferDetails, response: offerDetails} = useFetch(() => fetchOfferDetailById({}, recommendationId));
    const {fetchStart: fetchPropertiesDetails, response: propertiesDetails} = useFetch(() => fetchPropertiesDetailByIds({}, [recommendationId], true));

    const propertyDetails = propertiesDetails?.results?.find((property) => property.id === recommendationId);
    const viewType = useSelector((store: IRPStore) => store.viewType.current);

    const hideGallery = props.hideGallery || props.hasApplicationBeenSent || props.areRecommendationsFetching;
    const hasPropertyPlans = (propertyDetails?.plan_image_pages || []).length > 0;

    useEffect(() => {
        if (props.recommendationType === MultiLeadRecommendationType.OFFER) {
            fetchOfferDetails();
        }
        if (props.recommendationType === MultiLeadRecommendationType.PROPERTY) {
            fetchPropertiesDetails();
        }
    }, [props.id, recommendationId, props.recommendationType]);

    useEffect(() => {
        hitMultileadRecommendationShown({
            recommendationId: props.id,
            recommendationType: MultiLeadRecommendationType.PROPERTY,
            recommendationSource: RecommendationSource.DISTANCE,
            multileadVersion: MultileadVersion.RP_SWIPE,
            viewType
        });
    }, [props.id]);

    useEffect(() => {
        if (props.hideCardStockEffect) {
            return;
        }

        setShowCardAnimation(true);

        setTimeout(() => {
            setShowCardAnimation(false);
        }, 300);
    }, [props.id, props.hideCardStockEffect]);

    const toggleGalleryMode = () => {
        setShowPlans((prev) => !prev);
    };

    const onOfferReject = () => {
        props.onOfferReject(Number(props.id));
    };
    const onAskForDetails = () => {
        props.onAskForDetails(Number(props.id));
    };

    return (
        <div css={[multiLeadCardWrapperStyle, props.hideCardStockEffect ? "" : mb(6), onDesktop(mb(0))]}>
            {props.hideCardStockEffect ? null : (
                <MultiLeadCardStockEffect
                    maxStockLength={Number.isInteger(props.recommendationsCount) ? (props.recommendationsCount as number) - 1 : undefined}
                    cardId={props.id}
                />
            )}

            <div css={[multiLeadCardContentStyle, showCardAnimation ? cardAnimation : ""]}>
                {hideGallery ? null : (
                    <div css={imageWrapperStyle}>
                        {showCardAnimation || showPlans ? null : (
                            <Image css={vendorLogoStyle} src={props.offerVendorLogo} width="62px" height="43px" alt={`${props.offerVendorName}`} />
                        )}
                        {propertyDetails?.id || offerDetails?.id ? (
                            <MultiLeadCardGallery
                                showPlans={showPlans}
                                propertyDetails={propertyDetails}
                                offerDetails={offerDetails}
                                id={props.id}
                                offerVendorName={props.offerVendorName}
                                offerName={props.offerName}
                            />
                        ) : null}
                        {hasPropertyPlans ? (
                            <Button type="button" variant="filled_primary" onClick={toggleGalleryMode} css={[propertyPlanSwitchButtonStyle]}>
                                {showPlans ? <GalleryOutlineIcon size="2.4" wrapperSize="2.4" /> : <PropertyPlanIcon size="2.4" wrapperSize="2.4" />}
                            </Button>
                        ) : null}
                    </div>
                )}

                <div css={[propertyDataContainerStyles, hideGallery ? borderRadius(2) : ""]}>
                    {props.hasApplicationBeenSent ? (
                        <MultiLeadCardSentSummary {...props} />
                    ) : props.areRecommendationsFetching ? (
                        <div css={loaderWrapperStyle}>
                            <Loader size="lg" />
                        </div>
                    ) : (
                        <>
                            <MultiLeadCardPropertyHeader offerType={props.offerType} offerAddress={props.offerAddress} rooms={props.rooms} area={props.area} />
                            {props.region.country === Country.POLAND ? (
                                <MultiLeadCardPictograms
                                    area={props.area}
                                    constructionDateRange={props.constructionDateRange}
                                    compareAreaTo={props.compareAreaTo}
                                    equalColumns={props.hideGallery}
                                    css={[mv(1)]}
                                />
                            ) : (
                                <MultiLeadCardPictogramsForAbroadOffer
                                    price={props.price}
                                    offerDistanceFromTheAirport={props.offerDistanceFromTheAirport}
                                    offerDistanceFromTheBeach={props.offerDistanceFromTheBeach}
                                    css={[mv(1)]}
                                />
                            )}
                            <div css={buttonsWrapperStyle}>
                                <Button type="button" variant="outlined_secondary" size="x-small" iconLeft={DisLikeIcon} onClick={onOfferReject} css={w100}>
                                    <Text as="span" variant="body_copy_1" strong>
                                        odrzuć
                                    </Text>
                                </Button>
                                <Button
                                    isLoading={props.isMultiLeadSending}
                                    type="button"
                                    variant="filled_primary"
                                    size="x-small"
                                    iconLeft={LikeIcon}
                                    onClick={onAskForDetails}
                                    css={w100}
                                >
                                    <Text as="span" variant="body_copy_1" strong>
                                        zapytaj
                                    </Text>
                                </Button>
                            </div>
                            <RodoText
                                align="center"
                                vendorName={props.offerVendorName}
                                privacyPolicyUrl={props.offerPrivacyPolicyUrl}
                                privacyDataProcessorName={props.offerPrivacyDataProcessorName}
                                personalDataProcessorUrl={props.offerPersonalDataProcessorUrl}
                                css={[w100, mt(1.5), mb(0.5)]}
                            />
                            {props.children}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

const multiLeadCardWrapperStyle = css`
    max-width: 35rem;
    width: 100%;
    position: relative;
    margin: 0 auto;

    ${onDesktop(css`
        max-width: 46rem;
    `)};
`;

const multiLeadCardContentStyle = css`
    display: flex;
    flex-direction: column;
    width: 100%;
    ${elevation()};
    ${borderRadius(2)};
`;

const cardAnimation = css`
    animation: showCard 0.3s forwards;

    @keyframes showCard {
        0% {
            opacity: 1;
        }
        10% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`;

const imageWrapperStyle = css`
    ${borderRadius(2, 2, 0, 0)}
    overflow: hidden;
    background-color: #fff;
    z-index: 2;
    position: relative;
    height: 20rem;
    flex: 0 0 20rem;

    ${onDesktop(css`
        flex: 0 0 26rem;
        height: 26rem;
    `)};
`;

const propertyPlanSwitchButtonStyle = css`
    position: absolute;
    right: ${calculateRemSize(2)};
    bottom: ${calculateRemSize(2)};
    ${p(1)};
    height: 4rem;
    min-width: 4rem;
    width: 4rem;
`;

const propertyDataContainerStyles = (theme: Theme) => css`
    z-index: 2;
    ${p(0.5, 1.5)};
    background-color: #fff;
    ${borderRadius(0, 0, 2, 2)};
    flex: 1 1 100%;
    height: 100%;
    overflow: auto;

    @media (min-width: ${theme.breakpoints.md}) {
        ${p(1, 3)};
    }
`;

const buttonsWrapperStyle = css`
    display: flex;
    width: 100%;
    gap: ${calculateRemSize(2)};
`;

const loaderWrapperStyle = css`
    ${flexAbsoluteCenter};
    width: 100%;
    min-height: 40rem;
`;

const vendorLogoStyle = css`
    position: absolute;
    z-index: 1;
    width: 62px;
    height: 43px;
    top: ${calculateRemSize(1.5)};
    left: ${calculateRemSize(2)};
`;
