import {useLocation} from "react-router";

import {IAppError} from "@pg-mono/request-state";

import {useApplicationVariant} from "../../application/hooks/use_application_variant";
import {hitLeadSent} from "../../application/tracking/hit_lead_sent";
import {ApplicationVariant} from "../../application/types/ApplicationVariant";
import {IApplicationFormValues} from "../../application/types/IApplicationFormValues";
import {alertFormSuccessMessage} from "../../application/utils/alert_text";
import {getStoreHash} from "../../application/utils/get_store_hash";
import {getGeneratedMessage} from "../../application/utils/text_generator/get_generated_message";
import {creditCalculatorAnalytics, CreditCalculatorGTMEvent, CreditCalculatorInstalmentEventAction} from "../../credit_calculator/tracking/credit_gtm";
import {notifyBugsnag} from "../../errors/bugsnag/notify_bugsnag";
import {addSuccessNotification} from "../../notifications/utils/add_success_notification";
import {addWarningNotification} from "../../notifications/utils/add_warning_notification";
import {useGetOfferDetailApplicationQuery} from "../../offer/api/get_offer_detail";
import {useGetPropertyDetailApplicationQuery} from "../../property/api/get_property_detail";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {hitRotatingApplicationError} from "../../tracking/algolytics_hits/application_hit";
import {useGetVendorDetailApplicationQuery} from "../../vendor/api/get_vendor_detail";
import {ILeadModalPriceConversationChatLeadFormValues} from "../types/ILeadModalPriceConversationChatLeadFormValues";
import {getLeadAnalyticsData} from "../utils/get_lead_analytics_data";
import {sendLead} from "../utils/send_lead";
import {useOpenedLeadModalRequiredParams} from "./use_lead_modal_required_params";

export function useLeadModalSendMainLead() {
    const dispatch = useAppDispatch();

    const {vendorId, offerId, propertyId, source, sourceSection} = useOpenedLeadModalRequiredParams("property");

    const {data: vendorDetail} = useGetVendorDetailApplicationQuery(vendorId);
    const {data: offerDetail} = useGetOfferDetailApplicationQuery(offerId);
    const {data: propertyDetail} = useGetPropertyDetailApplicationQuery(propertyId);

    const location = useLocation();

    const affiliation = useAppSelector((state) => state.leadModal.affiliation);
    const creditPayload = useAppSelector((state) => state.leadModal.creditPayload);
    const customFormText = useAppSelector((state) => state.leadModal.customFormText);
    const leadType = useAppSelector((state) => state.leadModal.mainLeadType);
    const applicationVariant = useApplicationVariant();

    const viewType = useAppSelector((state) => state.viewType.current);
    const isAuthenticated = useAppSelector((state) => state.isAuthenticated);
    const userProfileData = useAppSelector((state) => state.user.profile.data);

    const leadData = {
        offer: {detail: offerDetail},
        property: {detail: propertyDetail},
        vendor: {detail: vendorDetail}
    };

    const analyticsData = getLeadAnalyticsData(leadData, viewType, source, sourceSection);

    // TODO: Until we rewrite/remove Application, keep this inline with logic in Application
    return async (formValues: ILeadModalPriceConversationChatLeadFormValues, handleModalClose: (forceClose: boolean) => void) => {
        const leadFormValues: IApplicationFormValues = {
            name: formValues.name,
            email: formValues.email,
            phone: {
                number: formValues.phone,
                code: 48
            },
            text:
                customFormText ||
                getGeneratedMessage({
                    offerType: offerDetail?.type,
                    offerName: offerDetail?.name,
                    propertyNumber: propertyDetail?.number,
                    name: formValues.name,
                    rooms: propertyDetail?.rooms,
                    area: propertyDetail?.area
                }),
            new_property_notification_consent: formValues.new_property_notification_consent,
            financing_services: formValues.financing_services,
            investment_services: false,
            // TODO if it will be used in offers, add rooms range handling
            rooms: []
        };

        return await sendLead({
            storeHash: getStoreHash({vendorId, offerId, propertyId}),
            leadData,
            leadFormValues,
            leadMeta: {
                source,
                sourceSection,
                locationHash: location.hash,
                viewType
            },
            leadType: leadType || "standard",
            isAuthenticated,
            userProfileData,
            senderState: {
                generatedMessage: null,
                questions: []
            },
            analyticsData,
            affiliation: affiliation || undefined,
            realEstateIds: {
                vendorId,
                offerId,
                propertyId
            },
            creditData: creditPayload || undefined,
            onSendSuccess: (createdApplication) => {
                // TODO: Credit calculator logic should be moved to the credit calculator
                if (applicationVariant !== ApplicationVariant.CREDIT) {
                    hitLeadSent(createdApplication.new_property_notification_consent);
                    creditCalculatorAnalytics.gtm.creditCalculatorEvent(CreditCalculatorGTMEvent.CREDIT_INSTALMENT_CALCULATOR, {
                        credit_action: CreditCalculatorInstalmentEventAction.CALL
                    });
                }

                dispatch(addSuccessNotification(alertFormSuccessMessage));
            },
            postOptions: {
                on400ApplicationBlockedError: (error) => {
                    handleModalClose(true);
                    dispatch(addWarningNotification("Przepraszamy, oferta na którą chcesz wysłać wiadomość została zarchiwizowana.", 7000));

                    hitRotatingApplicationError(source, sourceSection, leadData, {
                        appError: error.appError as IAppError,
                        extraPayloadViewType: viewType ?? undefined
                    });

                    // TODO: remove this bugsnag notification when we confirm that blocking applications this way is not an issue
                    notifyBugsnag({name: "Application blocked info", message: "Application blocked info"}, "Application sent on an archived offer", {error});
                    setTimeout(() => window.location.reload(), 6000);
                },
                on400Response: (error) => {
                    hitRotatingApplicationError(source, sourceSection, leadData, {
                        appError: error.appError as IAppError,
                        extraPayloadViewType: viewType ?? undefined
                    });
                }
            }
        });
    };
}
import {useLocation} from "react-router";

import {IAppError} from "@pg-mono/request-state";

import {useApplicationVariant} from "../../application/hooks/use_application_variant";
import {hitLeadSent} from "../../application/tracking/hit_lead_sent";
import {ApplicationVariant} from "../../application/types/ApplicationVariant";
import {IApplicationFormValues} from "../../application/types/IApplicationFormValues";
import {alertFormSuccessMessage} from "../../application/utils/alert_text";
import {getStoreHash} from "../../application/utils/get_store_hash";
import {getGeneratedMessage} from "../../application/utils/text_generator/get_generated_message";
import {creditCalculatorAnalytics, CreditCalculatorGTMEvent, CreditCalculatorInstalmentEventAction} from "../../credit_calculator/tracking/credit_gtm";
import {notifyBugsnag} from "../../errors/bugsnag/notify_bugsnag";
import {addSuccessNotification} from "../../notifications/utils/add_success_notification";
import {addWarningNotification} from "../../notifications/utils/add_warning_notification";
import {useGetOfferDetailApplicationQuery} from "../../offer/api/get_offer_detail";
import {useGetPropertyDetailApplicationQuery} from "../../property/api/get_property_detail";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {hitRotatingApplicationError} from "../../tracking/algolytics_hits/application_hit";
import {useGetVendorDetailApplicationQuery} from "../../vendor/api/get_vendor_detail";
import {ILeadModalPriceConversationChatLeadFormValues} from "../types/ILeadModalPriceConversationChatLeadFormValues";
import {getLeadAnalyticsData} from "../utils/get_lead_analytics_data";
import {sendLead} from "../utils/send_lead";
import {useOpenedLeadModalRequiredParams} from "./use_lead_modal_required_params";

export function useLeadModalSendMainLead() {
    const dispatch = useAppDispatch();

    const {vendorId, offerId, propertyId, source, sourceSection} = useOpenedLeadModalRequiredParams("property");

    const {data: vendorDetail} = useGetVendorDetailApplicationQuery(vendorId);
    const {data: offerDetail} = useGetOfferDetailApplicationQuery(offerId);
    const {data: propertyDetail} = useGetPropertyDetailApplicationQuery(propertyId);

    const location = useLocation();

    const affiliation = useAppSelector((state) => state.leadModal.affiliation);
    const creditPayload = useAppSelector((state) => state.leadModal.creditPayload);
    const customFormText = useAppSelector((state) => state.leadModal.customFormText);
    const leadType = useAppSelector((state) => state.leadModal.mainLeadType);
    const applicationVariant = useApplicationVariant();

    const viewType = useAppSelector((state) => state.viewType.current);
    const isAuthenticated = useAppSelector((state) => state.isAuthenticated);
    const userProfileData = useAppSelector((state) => state.user.profile.data);

    const leadData = {
        offer: {detail: offerDetail},
        property: {detail: propertyDetail},
        vendor: {detail: vendorDetail}
    };

    const analyticsData = getLeadAnalyticsData(leadData, viewType, source, sourceSection);

    // TODO: Until we rewrite/remove Application, keep this inline with logic in Application
    return async (formValues: ILeadModalPriceConversationChatLeadFormValues, handleModalClose: (forceClose: boolean) => void) => {
        const leadFormValues: IApplicationFormValues = {
            name: formValues.name,
            email: formValues.email,
            phone: {
                number: formValues.phone,
                code: 48
            },
            text:
                customFormText ||
                getGeneratedMessage({
                    offerType: offerDetail?.type,
                    offerName: offerDetail?.name,
                    propertyNumber: propertyDetail?.number,
                    name: formValues.name,
                    rooms: propertyDetail?.rooms,
                    area: propertyDetail?.area
                }),
            new_property_notification_consent: formValues.new_property_notification_consent,
            financing_services: formValues.financing_services,
            investment_services: false,
            // TODO if it will be used in offers, add rooms range handling
            rooms: []
        };

        return await sendLead({
            storeHash: getStoreHash({vendorId, offerId, propertyId}),
            leadData,
            leadFormValues,
            leadMeta: {
                source,
                sourceSection,
                locationHash: location.hash,
                viewType
            },
            leadType: leadType || "standard",
            isAuthenticated,
            userProfileData,
            senderState: {
                generatedMessage: null,
                questions: []
            },
            analyticsData,
            affiliation: affiliation || undefined,
            realEstateIds: {
                vendorId,
                offerId,
                propertyId
            },
            creditData: creditPayload || undefined,
            onSendSuccess: (createdApplication) => {
                // TODO: Credit calculator logic should be moved to the credit calculator
                if (applicationVariant !== ApplicationVariant.CREDIT) {
                    hitLeadSent(createdApplication.new_property_notification_consent);
                    creditCalculatorAnalytics.gtm.creditCalculatorEvent(CreditCalculatorGTMEvent.CREDIT_INSTALMENT_CALCULATOR, {
                        credit_action: CreditCalculatorInstalmentEventAction.CALL
                    });
                }

                dispatch(addSuccessNotification(alertFormSuccessMessage));
            },
            postOptions: {
                on400ApplicationBlockedError: (error) => {
                    handleModalClose(true);
                    dispatch(addWarningNotification("Przepraszamy, oferta na którą chcesz wysłać wiadomość została zarchiwizowana.", 7000));

                    hitRotatingApplicationError(source, sourceSection, leadData, {
                        appError: error.appError as IAppError,
                        extraPayloadViewType: viewType ?? undefined
                    });

                    // TODO: remove this bugsnag notification when we confirm that blocking applications this way is not an issue
                    notifyBugsnag({name: "Application blocked info", message: "Application blocked info"}, "Application sent on an archived offer", {error});
                    setTimeout(() => window.location.reload(), 6000);
                },
                on400Response: (error) => {
                    hitRotatingApplicationError(source, sourceSection, leadData, {
                        appError: error.appError as IAppError,
                        extraPayloadViewType: viewType ?? undefined
                    });
                }
            }
        });
    };
}
