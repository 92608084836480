import {useEffect} from "react";
import {useSelector} from "react-redux";
import {useLocation} from "react-router";
import {css, useTheme} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {borderRadius, mb, mh, mt, onDesktop, p, w100} from "@pg-design/helpers-css";
import {Picture} from "@pg-design/picture-module";
import {Text} from "@pg-design/text-module";

import {IRPStore} from "../../../app/rp_reducer";
import {getLeadAnalyticsData} from "../../../lead/utils/get_lead_analytics_data";
import {sendLead} from "../../../lead/utils/send_lead";
import {IApplicationData} from "../../reducers/application_reducer";
import {PMModalAnalytics, PMModalGTMEventAction} from "../../tracking/pm_modal_tracking";
import {IApplication} from "../../types/IApplication";
import {ApplicationSource, ApplicationSourceSection} from "../../utils/ApplicationSource";
import {generateApplicationText} from "../../utils/generate_application_text";

const propertyExpertMobileImg = require("../../images/multi_lead_promo/pm_sandra_adler_mobile.jpg");
const propertyExpertDesktopImg = require("../../images/multi_lead_promo/pm_sandra_adler.jpg");

interface IProps {
    originalApplicationResponse: IApplication | null;
    storeHash: string;
    leadData: IApplicationData | null;
    onSuccessfulSubmit: () => void;
}

const PM_VENDOR_ID = 8004;

export const PMPromoModalLayout = (props: IProps) => {
    const theme = useTheme();
    const location = useLocation();

    const isAuthenticated = useSelector((state: IRPStore) => state.isAuthenticated);
    const userProfileData = useSelector((state: IRPStore) => state.user.profile.data);
    const viewType = useSelector((state: IRPStore) => state.viewType.current);

    const source = ApplicationSource.Vendor;
    const sourceSection = ApplicationSourceSection.MODAL;

    useEffect(() => {
        PMModalAnalytics.gtm.PMModalEvent(PMModalGTMEventAction.VIEW);
    }, []);

    const onSendLead = async () => {
        if (props.leadData && props.originalApplicationResponse) {
            const phone = props.originalApplicationResponse.phone.split(" ");

            PMModalAnalytics.gtm.PMModalEvent(PMModalGTMEventAction.CALL);
            await sendLead({
                leadFormValues: {
                    email: props.originalApplicationResponse.email,
                    name: props.originalApplicationResponse.name,
                    financing_services: props.originalApplicationResponse.financing_services,
                    rooms: props.originalApplicationResponse.rooms,
                    text: props.originalApplicationResponse.text,
                    investment_services: props.originalApplicationResponse.investment_services,
                    new_property_notification_consent: props.originalApplicationResponse.new_property_notification_consent,
                    phone: {
                        number: phone[1],
                        code: Number(phone[0].replace("+", ""))
                    }
                },
                realEstateIds: {
                    vendorId: PM_VENDOR_ID
                },
                storeHash: props.storeHash,
                leadMeta: {
                    source,
                    sourceSection,
                    locationHash: location.hash,
                    viewType
                },
                leadData: props.leadData,
                leadType: "standard",
                isAuthenticated,
                userProfileData,
                senderState: {
                    generatedMessage: generateApplicationText({source}),
                    questions: []
                },
                analyticsData: getLeadAnalyticsData(props.leadData, viewType, source, sourceSection),
                analyticsParams: {
                    disableGtmInquiry: true
                }
            });

            props.onSuccessfulSubmit();
        }
    };

    return (
        <div css={wrapperStyle}>
            <div css={coverColumnStyle}>
                <Picture
                    css={pictureCoverStyle}
                    sources={[
                        {minWidthPX: 0, src: propertyExpertMobileImg, width: 400, height: 600},
                        {minWidthPX: theme.breakpoints.numeric.md, src: propertyExpertDesktopImg, width: 400, height: 600}
                    ]}
                    alt="Ekspertka nieruchomości"
                />

                <div css={propertyExpertInfoWrapperStyle}>
                    <Text variant="headline_5" mb="0.8rem" align="center" css={w100}>
                        Sandra Adler
                    </Text>
                    <Text variant="info_txt_1" color={theme.colors.gray[700]} align="center" css={w100}>
                        Ekspertka nieruchomości <br />
                        Platforma Mieszkaniowa S.A.
                    </Text>
                </div>
            </div>
            <div css={textColumnStyle}>
                <Text variant="headline_2" mb="1.6rem">
                    Nie masz czasu przeglądać kolejnych ofert na portalu?
                </Text>

                <Text variant="body_copy_1">
                    Nasza ekspertka bezpłatnie przygotuje dla Ciebie najlepsze oferty z rynku pierwotnego spełniające Twoje kryteria i{" "}
                    <strong>zadzwoni do Ciebie z propozycjami.</strong>
                </Text>

                <Button variant="filled_primary" css={[w100, mt(5), mb(1)]} onClick={onSendLead}>
                    Poproś o rozmowę
                </Button>

                <Text variant="info_txt_2" color={theme.colors.gray[700]} align="center">
                    Administratorami Twoich danych osobowych są:
                    <br />
                    Platforma Mieszkaniowa w zakresie odpowiedzi na zapytanie.
                    <br />
                    Property Group sp. z o.o. w zakresie usług wskazanych w regulaminie.
                    <br />
                    <a href="https://rynekpierwotny.pl/regulamin-rodo/" target="_blank">
                        Dowiedz się więcej o przetwarzaniu Twoich danych.
                    </a>
                </Text>
            </div>
        </div>
    );
};

const wrapperStyle = css`
    display: flex;
    flex-direction: column;
    height: 100%;

    ${onDesktop(css`
        flex-direction: row;
    `)};
`;

const textColumnStyle = css`
    order: 2;
    ${p(3, 1.5)};

    ${onDesktop(css`
        order: 1;
        ${p(3)};
    `)};
`;

const coverColumnStyle = css`
    order: 1;
    overflow: hidden;
    position: relative;
    flex: 0 0 34rem;
    background-color: #2e2e2c;

    ${onDesktop(css`
        order: 2;
        height: 100%;
        flex: 0 0 48rem;
    `)};
`;

const pictureCoverStyle = css`
    width: 100%;
    height: auto;
    max-width: 30rem;
    ${mh("auto")};

    ${onDesktop(css`
        max-width: 100%;
    `)};
`;

const propertyExpertInfoWrapperStyle = css`
    ${p(2)};
    position: absolute;
    inset: auto 1.2rem 1.2rem 1.2rem;
    ${borderRadius(1)};
    background-color: #fff;

    ${onDesktop(css`
        inset: auto 6rem 1.2rem 6rem;
    `)};
`;
import {useEffect} from "react";
import {useSelector} from "react-redux";
import {useLocation} from "react-router";
import {css, useTheme} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {borderRadius, mb, mh, mt, onDesktop, p, w100} from "@pg-design/helpers-css";
import {Picture} from "@pg-design/picture-module";
import {Text} from "@pg-design/text-module";

import {IRPStore} from "../../../app/rp_reducer";
import {getLeadAnalyticsData} from "../../../lead/utils/get_lead_analytics_data";
import {sendLead} from "../../../lead/utils/send_lead";
import {IApplicationData} from "../../reducers/application_reducer";
import {PMModalAnalytics, PMModalGTMEventAction} from "../../tracking/pm_modal_tracking";
import {IApplication} from "../../types/IApplication";
import {ApplicationSource, ApplicationSourceSection} from "../../utils/ApplicationSource";
import {generateApplicationText} from "../../utils/generate_application_text";

const propertyExpertMobileImg = require("../../images/multi_lead_promo/pm_sandra_adler_mobile.jpg");
const propertyExpertDesktopImg = require("../../images/multi_lead_promo/pm_sandra_adler.jpg");

interface IProps {
    originalApplicationResponse: IApplication | null;
    storeHash: string;
    leadData: IApplicationData | null;
    onSuccessfulSubmit: () => void;
}

const PM_VENDOR_ID = 8004;

export const PMPromoModalLayout = (props: IProps) => {
    const theme = useTheme();
    const location = useLocation();

    const isAuthenticated = useSelector((state: IRPStore) => state.isAuthenticated);
    const userProfileData = useSelector((state: IRPStore) => state.user.profile.data);
    const viewType = useSelector((state: IRPStore) => state.viewType.current);

    const source = ApplicationSource.Vendor;
    const sourceSection = ApplicationSourceSection.MODAL;

    useEffect(() => {
        PMModalAnalytics.gtm.PMModalEvent(PMModalGTMEventAction.VIEW);
    }, []);

    const onSendLead = async () => {
        if (props.leadData && props.originalApplicationResponse) {
            const phone = props.originalApplicationResponse.phone.split(" ");

            PMModalAnalytics.gtm.PMModalEvent(PMModalGTMEventAction.CALL);
            await sendLead({
                leadFormValues: {
                    email: props.originalApplicationResponse.email,
                    name: props.originalApplicationResponse.name,
                    financing_services: props.originalApplicationResponse.financing_services,
                    rooms: props.originalApplicationResponse.rooms,
                    text: props.originalApplicationResponse.text,
                    investment_services: props.originalApplicationResponse.investment_services,
                    new_property_notification_consent: props.originalApplicationResponse.new_property_notification_consent,
                    phone: {
                        number: phone[1],
                        code: Number(phone[0].replace("+", ""))
                    }
                },
                realEstateIds: {
                    vendorId: PM_VENDOR_ID
                },
                storeHash: props.storeHash,
                leadMeta: {
                    source,
                    sourceSection,
                    locationHash: location.hash,
                    viewType
                },
                leadData: props.leadData,
                leadType: "standard",
                isAuthenticated,
                userProfileData,
                senderState: {
                    generatedMessage: generateApplicationText({source}),
                    questions: []
                },
                analyticsData: getLeadAnalyticsData(props.leadData, viewType, source, sourceSection),
                analyticsParams: {
                    disableGtmInquiry: true
                }
            });

            props.onSuccessfulSubmit();
        }
    };

    return (
        <div css={wrapperStyle}>
            <div css={coverColumnStyle}>
                <Picture
                    css={pictureCoverStyle}
                    sources={[
                        {minWidthPX: 0, src: propertyExpertMobileImg, width: 400, height: 600},
                        {minWidthPX: theme.breakpoints.numeric.md, src: propertyExpertDesktopImg, width: 400, height: 600}
                    ]}
                    alt="Ekspertka nieruchomości"
                />

                <div css={propertyExpertInfoWrapperStyle}>
                    <Text variant="headline_5" mb="0.8rem" align="center" css={w100}>
                        Sandra Adler
                    </Text>
                    <Text variant="info_txt_1" color={theme.colors.gray[700]} align="center" css={w100}>
                        Ekspertka nieruchomości <br />
                        Platforma Mieszkaniowa S.A.
                    </Text>
                </div>
            </div>
            <div css={textColumnStyle}>
                <Text variant="headline_2" mb="1.6rem">
                    Nie masz czasu przeglądać kolejnych ofert na portalu?
                </Text>

                <Text variant="body_copy_1">
                    Nasza ekspertka bezpłatnie przygotuje dla Ciebie najlepsze oferty z rynku pierwotnego spełniające Twoje kryteria i{" "}
                    <strong>zadzwoni do Ciebie z propozycjami.</strong>
                </Text>

                <Button variant="filled_primary" css={[w100, mt(5), mb(1)]} onClick={onSendLead}>
                    Poproś o rozmowę
                </Button>

                <Text variant="info_txt_2" color={theme.colors.gray[700]} align="center">
                    Administratorami Twoich danych osobowych są:
                    <br />
                    Platforma Mieszkaniowa w zakresie odpowiedzi na zapytanie.
                    <br />
                    Property Group sp. z o.o. w zakresie usług wskazanych w regulaminie.
                    <br />
                    <a href="https://rynekpierwotny.pl/regulamin-rodo/" target="_blank">
                        Dowiedz się więcej o przetwarzaniu Twoich danych.
                    </a>
                </Text>
            </div>
        </div>
    );
};

const wrapperStyle = css`
    display: flex;
    flex-direction: column;
    height: 100%;

    ${onDesktop(css`
        flex-direction: row;
    `)};
`;

const textColumnStyle = css`
    order: 2;
    ${p(3, 1.5)};

    ${onDesktop(css`
        order: 1;
        ${p(3)};
    `)};
`;

const coverColumnStyle = css`
    order: 1;
    overflow: hidden;
    position: relative;
    flex: 0 0 34rem;
    background-color: #2e2e2c;

    ${onDesktop(css`
        order: 2;
        height: 100%;
        flex: 0 0 48rem;
    `)};
`;

const pictureCoverStyle = css`
    width: 100%;
    height: auto;
    max-width: 30rem;
    ${mh("auto")};

    ${onDesktop(css`
        max-width: 100%;
    `)};
`;

const propertyExpertInfoWrapperStyle = css`
    ${p(2)};
    position: absolute;
    inset: auto 1.2rem 1.2rem 1.2rem;
    ${borderRadius(1)};
    background-color: #fff;

    ${onDesktop(css`
        inset: auto 6rem 1.2rem 6rem;
    `)};
`;
