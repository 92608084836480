import {apiV2Link, Scenario} from "@pg-mono/rp-api-routes";

import {rpApi} from "../../app/api/rp_api";
import {IVendorDetailApplication} from "../types/IVendorDetailApplication";

export const getVendorDetailApi = rpApi.injectEndpoints({
    endpoints: (build) => ({
        getVendorDetailApplication: build.query<IVendorDetailApplication, number>({
            query: (vendorId) => apiV2Link.vendor.detail(Scenario.VENDOR_DETAIL_APPLICATION, {vendorId})
        })
    })
});

export const {useGetVendorDetailApplicationQuery} = getVendorDetailApi;
import {apiV2Link, Scenario} from "@pg-mono/rp-api-routes";

import {rpApi} from "../../app/api/rp_api";
import {IVendorDetailApplication} from "../types/IVendorDetailApplication";

export const getVendorDetailApi = rpApi.injectEndpoints({
    endpoints: (build) => ({
        getVendorDetailApplication: build.query<IVendorDetailApplication, number>({
            query: (vendorId) => apiV2Link.vendor.detail(Scenario.VENDOR_DETAIL_APPLICATION, {vendorId})
        })
    })
});

export const {useGetVendorDetailApplicationQuery} = getVendorDetailApi;
