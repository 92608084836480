import {useEffect, useRef, useState} from "react";

import {debounce} from "@pg-mono/nodash";

export const useScrollFadeOut = <T extends HTMLElement>() => {
    const fadeOutRef = useRef<T | null>(null);

    const [showTopFadeOut, setShowTopFadeOut] = useState(true);
    const [showBottomFadeOut, setShowBottomFadeOut] = useState(true);

    useEffect(() => {
        const element = fadeOutRef.current;

        if (element) {
            if (element.scrollHeight <= element.clientHeight) {
                setShowTopFadeOut(false);
                setShowBottomFadeOut(false);

                return;
            }

            if (element.scrollTop === 0) {
                setShowTopFadeOut(false);
            }

            const onScroll = debounce(() => {
                const isScrolledToBottom = element.scrollHeight <= element.clientHeight + element.scrollTop + 1;
                const isScrolledToTop = isScrolledToBottom ? false : element.scrollTop === 0;

                setShowTopFadeOut(!isScrolledToTop);
                setShowBottomFadeOut(!isScrolledToBottom);
            }, 50);

            element.addEventListener("scroll", onScroll);

            return () => {
                element.removeEventListener("scroll", onScroll);
            };
        }
    }, [fadeOutRef?.current]);

    return {
        fadeOutRef,
        showTopFadeOut,
        showBottomFadeOut
    };
};
import {useEffect, useRef, useState} from "react";

import {debounce} from "@pg-mono/nodash";

export const useScrollFadeOut = <T extends HTMLElement>() => {
    const fadeOutRef = useRef<T | null>(null);

    const [showTopFadeOut, setShowTopFadeOut] = useState(true);
    const [showBottomFadeOut, setShowBottomFadeOut] = useState(true);

    useEffect(() => {
        const element = fadeOutRef.current;

        if (element) {
            if (element.scrollHeight <= element.clientHeight) {
                setShowTopFadeOut(false);
                setShowBottomFadeOut(false);

                return;
            }

            if (element.scrollTop === 0) {
                setShowTopFadeOut(false);
            }

            const onScroll = debounce(() => {
                const isScrolledToBottom = element.scrollHeight <= element.clientHeight + element.scrollTop + 1;
                const isScrolledToTop = isScrolledToBottom ? false : element.scrollTop === 0;

                setShowTopFadeOut(!isScrolledToTop);
                setShowBottomFadeOut(!isScrolledToBottom);
            }, 50);

            element.addEventListener("scroll", onScroll);

            return () => {
                element.removeEventListener("scroll", onScroll);
            };
        }
    }, [fadeOutRef?.current]);

    return {
        fadeOutRef,
        showTopFadeOut,
        showBottomFadeOut
    };
};
