//  insertIf - This function is to conditionally add elements to array, while doing it with single expression
//
// Example:
// const arr = [
//   ...insertIf(someConditon, 'a'),
//   'b',
// ];
//
// Reason for a separate function, is to for code to be more readable while using such approach
// Also - In my case, intellij support when using this function was better thanks to generic

export function insertIf<ElementType>(condition: unknown, ...elements: ElementType[]): ElementType[] {
    return condition ? elements : [];
}
//  insertIf - This function is to conditionally add elements to array, while doing it with single expression
//
// Example:
// const arr = [
//   ...insertIf(someConditon, 'a'),
//   'b',
// ];
//
// Reason for a separate function, is to for code to be more readable while using such approach
// Also - In my case, intellij support when using this function was better thanks to generic

export function insertIf<ElementType>(condition: unknown, ...elements: ElementType[]): ElementType[] {
    return condition ? elements : [];
}
