import React, {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {css, Theme, useTheme} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {Checkbox} from "@pg-design/checkbox-module";
import {elevation} from "@pg-design/elevation";
import {
    backgroundColor,
    borderRadius,
    calculateRemSize,
    color,
    flex,
    flexAbsoluteCenter,
    flexDirection,
    maxWidth,
    mb,
    mr,
    onDesktop,
    p,
    pb,
    ph,
    position,
    pt,
    w100,
    zIndex
} from "@pg-design/helpers-css";
import {Link} from "@pg-design/link-module";
import {Text} from "@pg-design/text-module";
import {rpAppLink} from "@pg-mono/rp-routes";

import {addWarningNotification} from "../../../notifications/utils/add_warning_notification";
import {IArchitectApplicationRequest, postArchitectApplication} from "../../api/post_architect_application";
import {architectDetailsTracking, GTMEvent} from "../../tracking/architect_details_tracking";
import {architectMultileadTracking, MultileadGTMEvent} from "../../tracking/architect_multilead_tracking";
import {ArchitectLeadSource} from "../../types/ArchitectLeadSource";
import {IArchitectListItem} from "../../types/IArchitectListItem";
import {IArchitectUserLeadData} from "../../types/IArchitectUserLeadData";
import {MultileadTrackingActionType} from "../../types/MultileadTrackingActionType";
import {addArchitectApplicationToStorage} from "../../utils/architect_application_storage";
import {HighlightedPriceFromTo} from "./HighlightedPriceFromTo";
import {MultileadArchitectDetailsOffer} from "./MultileadArchitectDetailsOffer";
import {MultileadArchitectGallery} from "./MultileadArchitectGallery";

interface IProps {
    architectList: IArchitectListItem[];
    userData: IArchitectUserLeadData;
    onAfterApplication: (architectList: IArchitectListItem[]) => void;
}

export const MultileadArchitectList = (props: IProps) => {
    const termsLink = rpAppLink.rodoTerms.base();
    const theme = useTheme();

    const [selectedOffers, setSelectedOffers] = useState<IArchitectListItem[]>([]);
    const [sendingData, setSendingData] = useState<boolean>(false);
    const formErrorRef = useRef<string[] | null>(null);

    const dispatch = useDispatch();

    useEffect(() => {
        setSelectedOffers(props.architectList.slice(0, 2));
    }, [props.architectList]);

    const onSubmit = async () => {
        setSendingData(true);

        architectMultileadTracking.gtm.architectMultileadEvent(MultileadGTMEvent.ARCHITECT_MULTILEAD, {
            architect_multilead_action: MultileadTrackingActionType.SEND,
            architect_multilead_count: selectedOffers.length
        });

        const architectLeadsList = await Promise.all(
            selectedOffers.map(async (architect) => {
                const payload: IArchitectApplicationRequest = {
                    architect: architect.id,
                    name: props.userData.name,
                    email: props.userData.email,
                    phone: props.userData.phone,
                    area: props.userData.area,
                    packages: architect.offer.packages.reduce((acc, curr) => {
                        acc.push(curr.id);
                        return acc;
                    }, [] as number[])
                };
                return await postArchitectApplication(payload)
                    .then(() => {
                        addArchitectApplicationToStorage(architect.id);

                        architectDetailsTracking.gtm.architectDetailsEvent(GTMEvent.ARCHITECT_FORM_SEND, {
                            packagesQuantity: 0,
                            architect_lead_source: ArchitectLeadSource.MULTILEAD
                        });

                        return architect;
                    })
                    .catch((error) => {
                        if (error.data) {
                            formErrorRef.current = Object.values(error.data);
                        }
                        /**
                         * probably occur in edge cases:
                         *  1 - At the time of sending will be interrupted Internet connection or the server goes down and does not send the request.
                         *      We do not throw an error, and information about the submitted requests is given to architects for whom the queries have passed.
                         *  2 - Something went wrong immediately after clicking send, we give notification and leave the modal open.
                         */
                        return null;
                    });
            })
        );
        /**
         * handling the cases described in .catch above
         * if at least one lead succeeds - show success modal witch successful Leads
         * if none succeeds - show notifications and stay at architect list modal
         */
        const architectSuccessfulLeads = architectLeadsList.filter(Boolean) as IArchitectListItem[];

        if (architectSuccessfulLeads.length !== 0) {
            props.onAfterApplication(architectSuccessfulLeads);
        } else if (formErrorRef.current) {
            dispatch(addWarningNotification(formErrorRef.current?.join(", ")));
        } else {
            dispatch(addWarningNotification("Twoje zgłoszenie nie zostało wysłane. Spróbuj ponownie póżniej."));
        }
        setSendingData(false);
    };

    const onCheckboxClick = (checked: boolean, architect: IArchitectListItem) => {
        const includesID = selectedOffers.includes(architect);

        architectMultileadTracking.gtm.architectMultileadEvent(MultileadGTMEvent.ARCHITECT_MULTILEAD, {
            architect_multilead_action: checked ? MultileadTrackingActionType.CHECK : MultileadTrackingActionType.UNCHECK
        });

        if (checked && !includesID) {
            setSelectedOffers([...selectedOffers, architect]);
        }

        if (!checked && includesID) {
            const newArray = selectedOffers.filter((el) => el !== architect);
            setSelectedOffers(newArray);
        }
    };

    return (
        <div css={listWrapper}>
            {props.architectList.map((architect) => {
                const checked = selectedOffers.includes(architect);

                return (
                    <div key={architect.name} css={[listBox, checked && hideBorder]}>
                        <MultileadArchitectGallery architectId={architect.id} />

                        <div css={infoWrapper}>
                            <div css={boxHeader}>
                                <Text variant="body_copy_1">{architect.name}</Text>

                                {architect.min_price_m2 && <HighlightedPriceFromTo price={architect.min_price_m2} />}
                            </div>

                            <MultileadArchitectDetailsOffer architectId={architect.id} />

                            <Text css={rodoStyle} variant="info_txt_3">
                                Administratorami Twoich danych osobowych są: Property Group sp. z o.o. i firma wykończeniowa, której dotyczy zapytanie.{" "}
                                <Link href={termsLink} target="_blank" size="inherit" underline color={theme.colors.gray[700]}>
                                    Dowiedz się więcej o przetwarzaniu Twoich danych.
                                </Link>
                            </Text>
                        </div>

                        <div css={[checkboxStyle, checked && hideBorder]}>
                            <Checkbox
                                id={`${architect.id}`}
                                name={`architect_${architect.name.split(" ").join("_")}`}
                                checked={checked}
                                value={architect.id}
                                onChange={() => {
                                    onCheckboxClick(!checked, architect);
                                }}
                                labelContent={null}
                                checkedMarkColor="#02D054"
                            />
                        </div>
                    </div>
                );
            })}

            <div css={buttonWrapper}>
                <Button
                    variant="filled_primary"
                    onClick={onSubmit}
                    disabled={selectedOffers.length === 0}
                    isLoading={sendingData}
                    css={[w100, onDesktop(maxWidth("200px"))]}
                >
                    Wyślij zapytanie
                </Button>
            </div>
        </div>
    );
};

const hideBorder = css`
    border-color: transparent;
`;

const listWrapper = css`
    ${flexAbsoluteCenter};
    ${flexDirection("column")};
`;

const listBox = (theme: Theme) => css`
    ${flex()};
    ${flexDirection("column")};
    ${mb(5)};
    ${position("relative")};
    ${elevation(3)};
    ${borderRadius(2)};
    border: 1px solid ${theme.colors.gray[500]};
    ${pb(3.5)};
    ${maxWidth("340px")};

    ${onDesktop(flexDirection("row"), mb(1.5), pb(0), mr(5), maxWidth("unset"))};
`;

const boxHeader = css`
    ${flex("center", "space-between")};
    ${mb(2)};
    width: 100%;
`;

const infoWrapper = css`
    ${flex("flex-start", "space-between")};
    ${flexDirection("column")};
    ${w100};
    ${p(1.5)};
`;

const checkboxStyle = (theme: Theme) => css`
    ${backgroundColor(theme.colors.gray[100])};
    ${elevation(2)};
    ${flexAbsoluteCenter};
    ${position("absolute")};
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${calculateRemSize(7)};
    height: ${calculateRemSize(7)};
    border: 1px solid ${theme.colors.gray[500]};
    border-radius: 50%;

    ${onDesktop(css`
        top: 50%;
        left: 100%;
    `)};
`;

const rodoStyle = (theme: Theme) => css`
    ${color(theme.colors.gray[700])};
    ${ph(0.5)};
    ${onDesktop(p(0))};
`;

const buttonWrapper = css`
    ${position("sticky")};
    bottom: -1px;
    ${pt(2)};
    ${w100};
    ${flexAbsoluteCenter};
    ${backgroundColor("#fff")};
    ${elevation(1)};
    ${zIndex(10)};
`;
import React, {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {css, Theme, useTheme} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {Checkbox} from "@pg-design/checkbox-module";
import {elevation} from "@pg-design/elevation";
import {
    backgroundColor,
    borderRadius,
    calculateRemSize,
    color,
    flex,
    flexAbsoluteCenter,
    flexDirection,
    maxWidth,
    mb,
    mr,
    onDesktop,
    p,
    pb,
    ph,
    position,
    pt,
    w100,
    zIndex
} from "@pg-design/helpers-css";
import {Link} from "@pg-design/link-module";
import {Text} from "@pg-design/text-module";
import {rpAppLink} from "@pg-mono/rp-routes";

import {addWarningNotification} from "../../../notifications/utils/add_warning_notification";
import {IArchitectApplicationRequest, postArchitectApplication} from "../../api/post_architect_application";
import {architectDetailsTracking, GTMEvent} from "../../tracking/architect_details_tracking";
import {architectMultileadTracking, MultileadGTMEvent} from "../../tracking/architect_multilead_tracking";
import {ArchitectLeadSource} from "../../types/ArchitectLeadSource";
import {IArchitectListItem} from "../../types/IArchitectListItem";
import {IArchitectUserLeadData} from "../../types/IArchitectUserLeadData";
import {MultileadTrackingActionType} from "../../types/MultileadTrackingActionType";
import {addArchitectApplicationToStorage} from "../../utils/architect_application_storage";
import {HighlightedPriceFromTo} from "./HighlightedPriceFromTo";
import {MultileadArchitectDetailsOffer} from "./MultileadArchitectDetailsOffer";
import {MultileadArchitectGallery} from "./MultileadArchitectGallery";

interface IProps {
    architectList: IArchitectListItem[];
    userData: IArchitectUserLeadData;
    onAfterApplication: (architectList: IArchitectListItem[]) => void;
}

export const MultileadArchitectList = (props: IProps) => {
    const termsLink = rpAppLink.rodoTerms.base();
    const theme = useTheme();

    const [selectedOffers, setSelectedOffers] = useState<IArchitectListItem[]>([]);
    const [sendingData, setSendingData] = useState<boolean>(false);
    const formErrorRef = useRef<string[] | null>(null);

    const dispatch = useDispatch();

    useEffect(() => {
        setSelectedOffers(props.architectList.slice(0, 2));
    }, [props.architectList]);

    const onSubmit = async () => {
        setSendingData(true);

        architectMultileadTracking.gtm.architectMultileadEvent(MultileadGTMEvent.ARCHITECT_MULTILEAD, {
            architect_multilead_action: MultileadTrackingActionType.SEND,
            architect_multilead_count: selectedOffers.length
        });

        const architectLeadsList = await Promise.all(
            selectedOffers.map(async (architect) => {
                const payload: IArchitectApplicationRequest = {
                    architect: architect.id,
                    name: props.userData.name,
                    email: props.userData.email,
                    phone: props.userData.phone,
                    area: props.userData.area,
                    packages: architect.offer.packages.reduce((acc, curr) => {
                        acc.push(curr.id);
                        return acc;
                    }, [] as number[])
                };
                return await postArchitectApplication(payload)
                    .then(() => {
                        addArchitectApplicationToStorage(architect.id);

                        architectDetailsTracking.gtm.architectDetailsEvent(GTMEvent.ARCHITECT_FORM_SEND, {
                            packagesQuantity: 0,
                            architect_lead_source: ArchitectLeadSource.MULTILEAD
                        });

                        return architect;
                    })
                    .catch((error) => {
                        if (error.data) {
                            formErrorRef.current = Object.values(error.data);
                        }
                        /**
                         * probably occur in edge cases:
                         *  1 - At the time of sending will be interrupted Internet connection or the server goes down and does not send the request.
                         *      We do not throw an error, and information about the submitted requests is given to architects for whom the queries have passed.
                         *  2 - Something went wrong immediately after clicking send, we give notification and leave the modal open.
                         */
                        return null;
                    });
            })
        );
        /**
         * handling the cases described in .catch above
         * if at least one lead succeeds - show success modal witch successful Leads
         * if none succeeds - show notifications and stay at architect list modal
         */
        const architectSuccessfulLeads = architectLeadsList.filter(Boolean) as IArchitectListItem[];

        if (architectSuccessfulLeads.length !== 0) {
            props.onAfterApplication(architectSuccessfulLeads);
        } else if (formErrorRef.current) {
            dispatch(addWarningNotification(formErrorRef.current?.join(", ")));
        } else {
            dispatch(addWarningNotification("Twoje zgłoszenie nie zostało wysłane. Spróbuj ponownie póżniej."));
        }
        setSendingData(false);
    };

    const onCheckboxClick = (checked: boolean, architect: IArchitectListItem) => {
        const includesID = selectedOffers.includes(architect);

        architectMultileadTracking.gtm.architectMultileadEvent(MultileadGTMEvent.ARCHITECT_MULTILEAD, {
            architect_multilead_action: checked ? MultileadTrackingActionType.CHECK : MultileadTrackingActionType.UNCHECK
        });

        if (checked && !includesID) {
            setSelectedOffers([...selectedOffers, architect]);
        }

        if (!checked && includesID) {
            const newArray = selectedOffers.filter((el) => el !== architect);
            setSelectedOffers(newArray);
        }
    };

    return (
        <div css={listWrapper}>
            {props.architectList.map((architect) => {
                const checked = selectedOffers.includes(architect);

                return (
                    <div key={architect.name} css={[listBox, checked && hideBorder]}>
                        <MultileadArchitectGallery architectId={architect.id} />

                        <div css={infoWrapper}>
                            <div css={boxHeader}>
                                <Text variant="body_copy_1">{architect.name}</Text>

                                {architect.min_price_m2 && <HighlightedPriceFromTo price={architect.min_price_m2} />}
                            </div>

                            <MultileadArchitectDetailsOffer architectId={architect.id} />

                            <Text css={rodoStyle} variant="info_txt_3">
                                Administratorami Twoich danych osobowych są: Property Group sp. z o.o. i firma wykończeniowa, której dotyczy zapytanie.{" "}
                                <Link href={termsLink} target="_blank" size="inherit" underline color={theme.colors.gray[700]}>
                                    Dowiedz się więcej o przetwarzaniu Twoich danych.
                                </Link>
                            </Text>
                        </div>

                        <div css={[checkboxStyle, checked && hideBorder]}>
                            <Checkbox
                                id={`${architect.id}`}
                                name={`architect_${architect.name.split(" ").join("_")}`}
                                checked={checked}
                                value={architect.id}
                                onChange={() => {
                                    onCheckboxClick(!checked, architect);
                                }}
                                labelContent={null}
                                checkedMarkColor="#02D054"
                            />
                        </div>
                    </div>
                );
            })}

            <div css={buttonWrapper}>
                <Button
                    variant="filled_primary"
                    onClick={onSubmit}
                    disabled={selectedOffers.length === 0}
                    isLoading={sendingData}
                    css={[w100, onDesktop(maxWidth("200px"))]}
                >
                    Wyślij zapytanie
                </Button>
            </div>
        </div>
    );
};

const hideBorder = css`
    border-color: transparent;
`;

const listWrapper = css`
    ${flexAbsoluteCenter};
    ${flexDirection("column")};
`;

const listBox = (theme: Theme) => css`
    ${flex()};
    ${flexDirection("column")};
    ${mb(5)};
    ${position("relative")};
    ${elevation(3)};
    ${borderRadius(2)};
    border: 1px solid ${theme.colors.gray[500]};
    ${pb(3.5)};
    ${maxWidth("340px")};

    ${onDesktop(flexDirection("row"), mb(1.5), pb(0), mr(5), maxWidth("unset"))};
`;

const boxHeader = css`
    ${flex("center", "space-between")};
    ${mb(2)};
    width: 100%;
`;

const infoWrapper = css`
    ${flex("flex-start", "space-between")};
    ${flexDirection("column")};
    ${w100};
    ${p(1.5)};
`;

const checkboxStyle = (theme: Theme) => css`
    ${backgroundColor(theme.colors.gray[100])};
    ${elevation(2)};
    ${flexAbsoluteCenter};
    ${position("absolute")};
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${calculateRemSize(7)};
    height: ${calculateRemSize(7)};
    border: 1px solid ${theme.colors.gray[500]};
    border-radius: 50%;

    ${onDesktop(css`
        top: 50%;
        left: 100%;
    `)};
`;

const rodoStyle = (theme: Theme) => css`
    ${color(theme.colors.gray[700])};
    ${ph(0.5)};
    ${onDesktop(p(0))};
`;

const buttonWrapper = css`
    ${position("sticky")};
    bottom: -1px;
    ${pt(2)};
    ${w100};
    ${flexAbsoluteCenter};
    ${backgroundColor("#fff")};
    ${elevation(1)};
    ${zIndex(10)};
`;
