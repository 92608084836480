import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const CheckIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M8.5 2 4 6.5 1.5 4l-.5.5L3.5 7l.5.5.5-.5L9 2.5 8.5 2Z" />
        </SvgIcon>
    );
};
import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const CheckIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M8.5 2 4 6.5 1.5 4l-.5.5L3.5 7l.5.5.5-.5L9 2.5 8.5 2Z" />
        </SvgIcon>
    );
};
