import {intersection, range} from "@pg-mono/nodash";

import {MAX_ROOMS, MIN_ROOMS} from "../../search/utils/room_field_data_translations";

interface IQueryValues {
    [key: string]: undefined | string | number | (string | number)[];
}

export const getAvailableAndSearchedRooms = (availableRooms: number[], latestQuery?: IQueryValues) => {
    const rooms_0 = parseInt(latestQuery?.rooms_0 as string, 10);
    const rooms_1 = parseInt(latestQuery?.rooms_1 as string, 10);

    if (isFinite(rooms_0) && isFinite(rooms_1)) {
        return intersection(availableRooms, range(rooms_0, rooms_1 + 1));
    }
    if (isFinite(rooms_0)) {
        return intersection(availableRooms, range(rooms_0, MAX_ROOMS + 1));
    }
    if (isFinite(rooms_1)) {
        return intersection(availableRooms, range(MIN_ROOMS, rooms_1 + 1));
    }
    return []; //When there are no available rooms, we want to show all options as available and unchecked
};
import {intersection, range} from "@pg-mono/nodash";

import {MAX_ROOMS, MIN_ROOMS} from "../../search/utils/room_field_data_translations";

interface IQueryValues {
    [key: string]: undefined | string | number | (string | number)[];
}

export const getAvailableAndSearchedRooms = (availableRooms: number[], latestQuery?: IQueryValues) => {
    const rooms_0 = parseInt(latestQuery?.rooms_0 as string, 10);
    const rooms_1 = parseInt(latestQuery?.rooms_1 as string, 10);

    if (isFinite(rooms_0) && isFinite(rooms_1)) {
        return intersection(availableRooms, range(rooms_0, rooms_1 + 1));
    }
    if (isFinite(rooms_0)) {
        return intersection(availableRooms, range(rooms_0, MAX_ROOMS + 1));
    }
    if (isFinite(rooms_1)) {
        return intersection(availableRooms, range(MIN_ROOMS, rooms_1 + 1));
    }
    return []; //When there are no available rooms, we want to show all options as available and unchecked
};
