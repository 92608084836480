import {css} from "@emotion/react";

export const color = (color: string) => css`
    color: ${color};
`;

export const backgroundColor = (color: string) => css`
    background-color: ${color};
`;
import {css} from "@emotion/react";

export const color = (color: string) => css`
    color: ${color};
`;

export const backgroundColor = (color: string) => css`
    background-color: ${color};
`;
