import React, {useEffect} from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";
import * as lscache from "lscache";

import {Button} from "@pg-design/button-module";
import {mb, p} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";
import {pick} from "@pg-mono/nodash";

import {APPLICATION_LOCAL_STORAGE_KEY} from "../../app/constants/storage_keys";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {hitNotificationConsentSuccess, hitNotificationConsentView} from "../../tracking/algolytics_hits/application_hit";
import {gtmEventPostApplicationModal} from "../../tracking/google_tag_manager/gtm_event_modal";
import {applicationPatch} from "../actions/application_form_actions";
import {IApplication} from "../types/IApplication";
import {ApplicationSourceSection} from "../utils/ApplicationSource";

// TODO: This is a fast rewrite from class component, should be investigated further

interface IProps {
    storeHash: string;
    result: IApplication | null;
    onSuccessfulSubmit?: (sentApplication: IApplication | null) => void;
    onModalClose: () => void;
    sourceSection: ApplicationSourceSection;
}

export function NotificationConsentConfirmation(props: IProps) {
    const {storeHash, result, sourceSection, onSuccessfulSubmit, onModalClose} = props;

    const dispatch = useAppDispatch();
    const data = useAppSelector((state) => state.application[storeHash]);
    const viewType = useAppSelector((state) => state.viewType.current);

    useEffect(() => {
        if (data == null) {
            throw new Error("NotusForm componentDidMount: application data should be filled already");
        }

        if (result) {
            // gtm
            gtmEventPostApplicationModal("zgoda marketingowa", "wyswietlenie");
            // algolytics
            hitNotificationConsentView(result, sourceSection, data, {extraPayloadViewType: viewType ?? undefined});
        }
    }, []);

    const onSubmit = () => {
        if (data == null) {
            throw new Error("NotusForm onSubmit: application data should be filled already");
        }

        const newApplication = {...result, new_property_notification_consent: true} as IApplication | null;
        if (!newApplication || !newApplication.uuid) {
            throw new Error("OfferSubscribe submit: no uuid in result");
        }

        const formInfoToBeSaved = pick(newApplication, ["email", "name", "phone", "financing_services", "new_property_notification_consent"]);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        applicationPatch(
            newApplication.uuid,
            {new_property_notification_consent: true},
            storeHash
        )(dispatch).then((application) => {
            if (!application) {
                return;
            }
            // update LS and trigger callback
            lscache.set(APPLICATION_LOCAL_STORAGE_KEY, formInfoToBeSaved);
            onSuccessfulSubmit && onSuccessfulSubmit(newApplication);
            // gtm
            gtmEventPostApplicationModal("zgoda marketingowa", "klikniecie");
            // algolytics
            hitNotificationConsentSuccess({...result, ...application}, sourceSection, data, {
                extraPayloadViewType: viewType ?? undefined
            });
        });
        onModalClose();
    };

    return (
        <div css={bodyHolder}>
            <Text variant="headline_3" as="h1" css={headingStyle}>
                Bądź na bieżąco z najnowszymi inwestycjami i promocjami deweloperskimi!
            </Text>

            <Text variant="body_copy_1" css={subheadingStyle}>
                Kliknij poniższy link, a będziesz pewien, że żadna okazja Cię nie ominie - dowiesz się o niej od razu mailem lub SMSem.
            </Text>

            <ConsentConfirmationButton onClick={onSubmit} variant="filled_primary" css={buttonStyle}>
                Chcę otrzymywać linki do najnowszych ofert
            </ConsentConfirmationButton>

            <GrayText variant="info_txt_1" align="center">
                * możesz dowolnie zmieniać ustawienia tych powiadomień na swoim koncie.
            </GrayText>
        </div>
    );
}

const bodyHolder = (theme: Theme) => css`
    ${p(6, 2, 6, 2)};
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0 auto;

    @media (min-width: ${theme.breakpoints.md}) {
        ${p(5)};
    }
`;

const headingStyle = (theme: Theme) => css`
    ${mb(4)};
    color: ${theme.colors.gray[800]};
    text-align: left;

    @media (min-width: ${theme.breakpoints.md}) {
        ${mb(4)};
        text-align: center;
    }
`;

const subheadingStyle = (theme: Theme) => css`
    color: ${theme.colors.gray[800]};
    margin-bottom: 8rem;
    text-align: left;

    @media (min-width: ${theme.breakpoints.md}) {
        ${mb(4)}
        text-align: center;
    }
`;

const buttonStyle = (theme: Theme) => css`
    ${mb(4)};

    @media (min-width: ${theme.breakpoints.md}) {
        ${mb(3)}
    }
`;

const ConsentConfirmationButton = styled(Button)`
    white-space: break-spaces;
`;

const GrayText = styled(Text)`
    color: ${(props) => props.theme.colors.gray[700]};
`;
import React, {useEffect} from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";
import * as lscache from "lscache";

import {Button} from "@pg-design/button-module";
import {mb, p} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";
import {pick} from "@pg-mono/nodash";

import {APPLICATION_LOCAL_STORAGE_KEY} from "../../app/constants/storage_keys";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {hitNotificationConsentSuccess, hitNotificationConsentView} from "../../tracking/algolytics_hits/application_hit";
import {gtmEventPostApplicationModal} from "../../tracking/google_tag_manager/gtm_event_modal";
import {applicationPatch} from "../actions/application_form_actions";
import {IApplication} from "../types/IApplication";
import {ApplicationSourceSection} from "../utils/ApplicationSource";

// TODO: This is a fast rewrite from class component, should be investigated further

interface IProps {
    storeHash: string;
    result: IApplication | null;
    onSuccessfulSubmit?: (sentApplication: IApplication | null) => void;
    onModalClose: () => void;
    sourceSection: ApplicationSourceSection;
}

export function NotificationConsentConfirmation(props: IProps) {
    const {storeHash, result, sourceSection, onSuccessfulSubmit, onModalClose} = props;

    const dispatch = useAppDispatch();
    const data = useAppSelector((state) => state.application[storeHash]);
    const viewType = useAppSelector((state) => state.viewType.current);

    useEffect(() => {
        if (data == null) {
            throw new Error("NotusForm componentDidMount: application data should be filled already");
        }

        if (result) {
            // gtm
            gtmEventPostApplicationModal("zgoda marketingowa", "wyswietlenie");
            // algolytics
            hitNotificationConsentView(result, sourceSection, data, {extraPayloadViewType: viewType ?? undefined});
        }
    }, []);

    const onSubmit = () => {
        if (data == null) {
            throw new Error("NotusForm onSubmit: application data should be filled already");
        }

        const newApplication = {...result, new_property_notification_consent: true} as IApplication | null;
        if (!newApplication || !newApplication.uuid) {
            throw new Error("OfferSubscribe submit: no uuid in result");
        }

        const formInfoToBeSaved = pick(newApplication, ["email", "name", "phone", "financing_services", "new_property_notification_consent"]);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        applicationPatch(
            newApplication.uuid,
            {new_property_notification_consent: true},
            storeHash
        )(dispatch).then((application) => {
            if (!application) {
                return;
            }
            // update LS and trigger callback
            lscache.set(APPLICATION_LOCAL_STORAGE_KEY, formInfoToBeSaved);
            onSuccessfulSubmit && onSuccessfulSubmit(newApplication);
            // gtm
            gtmEventPostApplicationModal("zgoda marketingowa", "klikniecie");
            // algolytics
            hitNotificationConsentSuccess({...result, ...application}, sourceSection, data, {
                extraPayloadViewType: viewType ?? undefined
            });
        });
        onModalClose();
    };

    return (
        <div css={bodyHolder}>
            <Text variant="headline_3" as="h1" css={headingStyle}>
                Bądź na bieżąco z najnowszymi inwestycjami i promocjami deweloperskimi!
            </Text>

            <Text variant="body_copy_1" css={subheadingStyle}>
                Kliknij poniższy link, a będziesz pewien, że żadna okazja Cię nie ominie - dowiesz się o niej od razu mailem lub SMSem.
            </Text>

            <ConsentConfirmationButton onClick={onSubmit} variant="filled_primary" css={buttonStyle}>
                Chcę otrzymywać linki do najnowszych ofert
            </ConsentConfirmationButton>

            <GrayText variant="info_txt_1" align="center">
                * możesz dowolnie zmieniać ustawienia tych powiadomień na swoim koncie.
            </GrayText>
        </div>
    );
}

const bodyHolder = (theme: Theme) => css`
    ${p(6, 2, 6, 2)};
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0 auto;

    @media (min-width: ${theme.breakpoints.md}) {
        ${p(5)};
    }
`;

const headingStyle = (theme: Theme) => css`
    ${mb(4)};
    color: ${theme.colors.gray[800]};
    text-align: left;

    @media (min-width: ${theme.breakpoints.md}) {
        ${mb(4)};
        text-align: center;
    }
`;

const subheadingStyle = (theme: Theme) => css`
    color: ${theme.colors.gray[800]};
    margin-bottom: 8rem;
    text-align: left;

    @media (min-width: ${theme.breakpoints.md}) {
        ${mb(4)}
        text-align: center;
    }
`;

const buttonStyle = (theme: Theme) => css`
    ${mb(4)};

    @media (min-width: ${theme.breakpoints.md}) {
        ${mb(3)}
    }
`;

const ConsentConfirmationButton = styled(Button)`
    white-space: break-spaces;
`;

const GrayText = styled(Text)`
    color: ${(props) => props.theme.colors.gray[700]};
`;
