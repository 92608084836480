export enum MessageType {
    GENERATED = 0,
    CUSTOM = 1,
    NONE = 2
}
export enum MessageType {
    GENERATED = 0,
    CUSTOM = 1,
    NONE = 2
}
