import {hitGoogleTagManager} from "../../tracking/google_tag_manager/init_google_tag_manager";

export function hitLeadSent(mktOptin: boolean) {
    hitGoogleTagManager({
        event: "marketing_approval",
        marketing_approval_action: `lead - ${mktOptin ? "true" : "false"}`
    });
}
import {hitGoogleTagManager} from "../../tracking/google_tag_manager/init_google_tag_manager";

export function hitLeadSent(mktOptin: boolean) {
    hitGoogleTagManager({
        event: "marketing_approval",
        marketing_approval_action: `lead - ${mktOptin ? "true" : "false"}`
    });
}
