import {useState} from "react";
import {useDispatch} from "react-redux";

import {appendMultiLeadApplicationsForOffers, appendMultiLeadApplicationsForProperties} from "../actions/multi_lead_applications_actions";
import {RecommendedOfferApplicationsType} from "../actions/track_recommended_offers_application";
import {IApplicationFormValues} from "../types/IApplicationFormValues";
import {MultiLeadRecommendationType} from "../types/MultiLeadRecommendationType";
import {RecommendedPropertyApplicationsType} from "../types/recommended_property_applications_type";
import {ApplicationSourceSection} from "../utils/ApplicationSource";
import {sendOfferMultiLead} from "../utils/recommended_application/send_offer_multi_lead";
import {sendPropertyMultiLead} from "../utils/recommended_application/send_property_multi_lead";
import {useMultiLeadData} from "./use_multi_lead_data";

interface IProps {
    storeHash: string;
    recommendationType: MultiLeadRecommendationType;
    sourceSection: ApplicationSourceSection;
    originalApplicationFormValues: IApplicationFormValues;
    recommendationsWithPriceFilter: boolean;
    customSourceSection?: string;
}

interface ISendMultiLeadFormParams {
    selectedRecommendations: number[];
    formValues: IApplicationFormValues;
}

export interface IMultiLeadApiErrors {
    fieldErrors?: {
        [key: string]: string[];
    };
    nonFieldErrors?: string[];
    status: number;
}

export const useMultiLeadFormSend = (props: IProps) => {
    const dispatch = useDispatch();
    const [isMultiLeadSending, setMultiLeadSending] = useState(false);
    const [sentPropertiesApplications, setSentPropertiesApplications] = useState<RecommendedPropertyApplicationsType[]>([]);
    const [apiErrors, setApiErrors] = useState<IMultiLeadApiErrors | null>(null);

    const {applicationData, viewType, isAuthenticated, recommendedProperties, recommendedOffers} = useMultiLeadData(props);
    const refererApplicationUUID = applicationData?.application?.uuid;

    const sendMultiLeadForm = async (
        sendMultiLeadFormParams: ISendMultiLeadFormParams
    ): Promise<RecommendedPropertyApplicationsType[] | RecommendedOfferApplicationsType[]> => {
        setApiErrors(null);
        setMultiLeadSending(true);

        const metadata = {
            sourceSection: props.sourceSection,
            viewType,
            isAuthenticated,
            application: applicationData?.application,
            refererApplicationUUID,
            originalFormParams: {
                rooms: props.originalApplicationFormValues.rooms,
                questions: props.originalApplicationFormValues.questions
            },
            recommendationsWithPriceFilter: props.recommendationsWithPriceFilter
        };

        if (props.recommendationType === MultiLeadRecommendationType.PROPERTY) {
            return sendPropertyMultiLead(
                {
                    recommendedProperties: recommendedProperties.filter((property) => sendMultiLeadFormParams.selectedRecommendations.includes(property.id)),
                    formValues: sendMultiLeadFormParams.formValues
                },
                metadata,
                props.customSourceSection
            )(dispatch)
                .then((response) => {
                    appendMultiLeadApplicationsForProperties(props.storeHash, response)(dispatch);

                    setSentPropertiesApplications((prev) => [...prev, ...response]);

                    return response;
                })
                .catch((error) => {
                    setApiErrors(error.appError);
                    return [];
                })
                .finally(() => {
                    setMultiLeadSending(false);
                });
        }

        if (props.recommendationType === MultiLeadRecommendationType.OFFER) {
            return sendOfferMultiLead(
                {
                    selectedRecommendations: recommendedOffers.filter((offer) => sendMultiLeadFormParams.selectedRecommendations.includes(offer.id)),
                    formValues: sendMultiLeadFormParams.formValues
                },
                metadata
            )(dispatch)
                .then((response) => {
                    appendMultiLeadApplicationsForOffers(props.storeHash, response)(dispatch);

                    return response;
                })
                .catch((error) => {
                    setApiErrors(error.appError);
                    return [];
                })
                .finally(() => {
                    setMultiLeadSending(false);
                });
        }

        setMultiLeadSending(false);
        throw new Error("Unknown recommendation type");
    };

    return {
        sendMultiLeadForm,
        isMultiLeadSending,
        sentPropertiesApplications,
        apiErrors,
        hasFieldErrors: Object.values(apiErrors?.fieldErrors || {}).length > 0
    };
};
import {useState} from "react";
import {useDispatch} from "react-redux";

import {appendMultiLeadApplicationsForOffers, appendMultiLeadApplicationsForProperties} from "../actions/multi_lead_applications_actions";
import {RecommendedOfferApplicationsType} from "../actions/track_recommended_offers_application";
import {IApplicationFormValues} from "../types/IApplicationFormValues";
import {MultiLeadRecommendationType} from "../types/MultiLeadRecommendationType";
import {RecommendedPropertyApplicationsType} from "../types/recommended_property_applications_type";
import {ApplicationSourceSection} from "../utils/ApplicationSource";
import {sendOfferMultiLead} from "../utils/recommended_application/send_offer_multi_lead";
import {sendPropertyMultiLead} from "../utils/recommended_application/send_property_multi_lead";
import {useMultiLeadData} from "./use_multi_lead_data";

interface IProps {
    storeHash: string;
    recommendationType: MultiLeadRecommendationType;
    sourceSection: ApplicationSourceSection;
    originalApplicationFormValues: IApplicationFormValues;
    recommendationsWithPriceFilter: boolean;
    customSourceSection?: string;
}

interface ISendMultiLeadFormParams {
    selectedRecommendations: number[];
    formValues: IApplicationFormValues;
}

export interface IMultiLeadApiErrors {
    fieldErrors?: {
        [key: string]: string[];
    };
    nonFieldErrors?: string[];
    status: number;
}

export const useMultiLeadFormSend = (props: IProps) => {
    const dispatch = useDispatch();
    const [isMultiLeadSending, setMultiLeadSending] = useState(false);
    const [sentPropertiesApplications, setSentPropertiesApplications] = useState<RecommendedPropertyApplicationsType[]>([]);
    const [apiErrors, setApiErrors] = useState<IMultiLeadApiErrors | null>(null);

    const {applicationData, viewType, isAuthenticated, recommendedProperties, recommendedOffers} = useMultiLeadData(props);
    const refererApplicationUUID = applicationData?.application?.uuid;

    const sendMultiLeadForm = async (
        sendMultiLeadFormParams: ISendMultiLeadFormParams
    ): Promise<RecommendedPropertyApplicationsType[] | RecommendedOfferApplicationsType[]> => {
        setApiErrors(null);
        setMultiLeadSending(true);

        const metadata = {
            sourceSection: props.sourceSection,
            viewType,
            isAuthenticated,
            application: applicationData?.application,
            refererApplicationUUID,
            originalFormParams: {
                rooms: props.originalApplicationFormValues.rooms,
                questions: props.originalApplicationFormValues.questions
            },
            recommendationsWithPriceFilter: props.recommendationsWithPriceFilter
        };

        if (props.recommendationType === MultiLeadRecommendationType.PROPERTY) {
            return sendPropertyMultiLead(
                {
                    recommendedProperties: recommendedProperties.filter((property) => sendMultiLeadFormParams.selectedRecommendations.includes(property.id)),
                    formValues: sendMultiLeadFormParams.formValues
                },
                metadata,
                props.customSourceSection
            )(dispatch)
                .then((response) => {
                    appendMultiLeadApplicationsForProperties(props.storeHash, response)(dispatch);

                    setSentPropertiesApplications((prev) => [...prev, ...response]);

                    return response;
                })
                .catch((error) => {
                    setApiErrors(error.appError);
                    return [];
                })
                .finally(() => {
                    setMultiLeadSending(false);
                });
        }

        if (props.recommendationType === MultiLeadRecommendationType.OFFER) {
            return sendOfferMultiLead(
                {
                    selectedRecommendations: recommendedOffers.filter((offer) => sendMultiLeadFormParams.selectedRecommendations.includes(offer.id)),
                    formValues: sendMultiLeadFormParams.formValues
                },
                metadata
            )(dispatch)
                .then((response) => {
                    appendMultiLeadApplicationsForOffers(props.storeHash, response)(dispatch);

                    return response;
                })
                .catch((error) => {
                    setApiErrors(error.appError);
                    return [];
                })
                .finally(() => {
                    setMultiLeadSending(false);
                });
        }

        setMultiLeadSending(false);
        throw new Error("Unknown recommendation type");
    };

    return {
        sendMultiLeadForm,
        isMultiLeadSending,
        sentPropertiesApplications,
        apiErrors,
        hasFieldErrors: Object.values(apiErrors?.fieldErrors || {}).length > 0
    };
};
