import {getRequest} from "@pg-mono/request";
import {apiV2Link, Scenario} from "@pg-mono/rp-api-routes";

import {IPropertyDetailApplication} from "../../property/types/IPropertyDetailApplication";

export const fetchApplicationPropertyDetailById = (propertyId: number): Promise<IPropertyDetailApplication> => {
    const url = apiV2Link.property.detail(Scenario.PROPERTY_APPLICATION, {propertyId});

    return getRequest({}, url);
};
import {getRequest} from "@pg-mono/request";
import {apiV2Link, Scenario} from "@pg-mono/rp-api-routes";

import {IPropertyDetailApplication} from "../../property/types/IPropertyDetailApplication";

export const fetchApplicationPropertyDetailById = (propertyId: number): Promise<IPropertyDetailApplication> => {
    const url = apiV2Link.property.detail(Scenario.PROPERTY_APPLICATION, {propertyId});

    return getRequest({}, url);
};
