import {hitGoogleTagManager} from "../../tracking/google_tag_manager/init_google_tag_manager";

export enum EstimatedPriceModalGTMEvent {
    CURRENT_PRICE_MODAL_SHOW = "current_price_modal_show",
    CURRENT_PRICE_NEXT_STEP = "current_price_next_step",
    CURRENT_PRICE_MODAL_CLOSE = "current_price_modal_close"
}

const priceModalEvent = (event: EstimatedPriceModalGTMEvent, data: Record<string, string> = {}) => {
    hitGoogleTagManager({event, ...data});
};

export const estimatedPricesAnalytics = {
    gtm: {
        priceModalEvent
    }
};
import {hitGoogleTagManager} from "../../tracking/google_tag_manager/init_google_tag_manager";

export enum EstimatedPriceModalGTMEvent {
    CURRENT_PRICE_MODAL_SHOW = "current_price_modal_show",
    CURRENT_PRICE_NEXT_STEP = "current_price_next_step",
    CURRENT_PRICE_MODAL_CLOSE = "current_price_modal_close"
}

const priceModalEvent = (event: EstimatedPriceModalGTMEvent, data: Record<string, string> = {}) => {
    hitGoogleTagManager({event, ...data});
};

export const estimatedPricesAnalytics = {
    gtm: {
        priceModalEvent
    }
};
