/**
 * ATTENTION, EVERYONE WHO EDITS THIS FILE
 * Note that, when adding `ApplicationSource` or `ApplicationSourceSection` we need to edit database model to allow new values.
 */

export enum ApplicationSource {
    Vendor = 1, // Zapytanie do dewelopera
    Offer = 2, // Zapytanie o inwestycję
    Property = 3, // Zapytanie o nieruchomość
    NegotiatePrice = 4, // Negocjacje cenowe
    PriceInquiry = 5, // Zapytanie o cenę
    OfferPriceInquiry = 21, // Zapytanie o inwestycję z przycisku Zapytaj o ceny
    Promotion = 6, // Zapytanie o promocję
    FinancingServices = 7, // Zapytanie o kredyt
    PropertyConsulting = 8, // Znajdziemy dla Ciebie
    Generator = 9, // Zapytanie z generatora
    Chat = 10, // Czat
    Reservation = 13, // Powiadomienie o zwolnieniu rezerwacji
    LandingPage = 16, // Landing Page dla Dewelopera Kampania CPL
    ApplicationOfferScheduleMeeting = 17, // Zapytanie o spotkanie dot. inwestycji
    ApplicationPropertyScheduleMeeting = 18, // Zapytanie o spotkanie dot. nieruchomości
    OfferRevealPrice = 19, // Zapytanie o cenę - ukryta cena inwestycji
    PropertyRevealPrice = 20, // Zapytanie o cenę - ukryta cena nieruchomości
    DiscountNotification = 22, // Zapis do newslettera promocyjnego
    RevealedPrices = 24, // Odkrycie cen nieruchomości
    PropertyPriceFeedback = 25, // Opinia nt. odkrytej ceny
    InvestmentInquiry = 26, // Renters
    Contractor = 27, // Zapytanie z Marketplace
    OfferScheduleVideoMeeting = 28, // Zapytanie o wideo rozmowę dot. inwestycji
    PropertyScheduleVideoMeeting = 29, // Zapytanie o wideo rozmowę dot. nieruchomości
    OfferAutoresponderRevealedPrices = 31, // Test Autorespondera
    PropertyAutoresponderRevealedPrices = 32, // Test Autorespondera
    RecommendedProperty = 33, // Zapytanie o nieruchomość - rekomendacja
    RecommendedOffer = 34, // Zapytanie o inwestycje - rekomendacja
    PromotionProperty = 35, // Zapytanie o promocję - z widoku szczegółów nieruchomości
    QuickViewPropertyModal = 36, // Zapytania z modala quick view
    QuickViewListingRecommendation = 37, // Zapytanie z modala quick view na listingu z kafla rekomendacji
    FavouritesPropertyInquiry = 38, // Zapytanie o ulubione inwestycje
    FavouritesOfferInquiry = 39, // Zapytanie o ulubione nieruchomości
    ChatBot = 40, // Zapytanie o nieruchomość z ChatBota
    OfferChatBot = 41, // Zapytanie o inwestycję z ChatBota
    ComparisonToolOffer = 42,
    ComparisonToolProperty = 43,
    PreSaleOffer = 44,
    PropertyConfigurator = 45,
    CreditCalculator = 46, // Kalkulator kredytowy
    VideoReel = 47, // Zapytanie z reels
    PreMultilead = 48, // Zapytanie z PreMultileada
    OfferPhoneCall = 49, // Zapytanie o numer na widoku oferty
    PropertyPhoneCall = 50 // Zapytanie o numer na widoku nieruchomości
    // ATTENTION: remember to inform backend to add new value to database validation
}

export function getApplicationSourceDisplay(source: ApplicationSource): string {
    switch (source) {
        case ApplicationSource.Vendor:
            return "Zapytanie do dewelopera";
        case ApplicationSource.Offer:
            return "Zapytanie o inwestycję";
        case ApplicationSource.OfferPriceInquiry:
            return "Zapytanie o inwestycję (zapytaj o ceny)";
        case ApplicationSource.OfferRevealPrice:
            return "Zapytanie o cenę (ukryta cena inwestycji)";
        case ApplicationSource.Property:
            return "Zapytanie o nieruchomość";
        case ApplicationSource.PropertyRevealPrice:
            return "Zapytanie o cenę (ukryta cena nieruchomości)";
        case ApplicationSource.NegotiatePrice:
            return "Negocjacje cenowe";
        case ApplicationSource.PriceInquiry:
            return "Zapytanie o cenę";
        case ApplicationSource.Promotion:
            return "Zapytanie o promocję";
        case ApplicationSource.FinancingServices:
            return "Zapytanie o kredyt";
        case ApplicationSource.PropertyConsulting:
            return "Znajdziemy dla Ciebie";
        case ApplicationSource.Generator:
            return "Zapytanie z generatora";
        case ApplicationSource.Chat:
            return "Czat";
        case ApplicationSource.CreditCalculator:
            return "Kalkulator kredytowy";
        case ApplicationSource.Reservation:
            return "Powiadomienie o zwolnieniu rezerwacji";
        case ApplicationSource.LandingPage:
            return "Zapytanie z LandingPage dla Dewelopera";
        case ApplicationSource.ApplicationOfferScheduleMeeting:
            return "Zapytanie o spotkanie dot. inwestycji";
        case ApplicationSource.ApplicationPropertyScheduleMeeting:
            return "Zapytanie o spotkanie dot. nieruchomości";
        case ApplicationSource.OfferScheduleVideoMeeting:
            return "Zapytanie o wideorozmowę dot. inwestycji";
        case ApplicationSource.PropertyScheduleVideoMeeting:
            return "Zapytanie o wideorozmowę dot. nieruchomości";
        case ApplicationSource.DiscountNotification:
            return "Zapis do newslettera promocyjnego";
        case ApplicationSource.RevealedPrices:
            return "Odkrycie cen nieruchomości";
        case ApplicationSource.PropertyPriceFeedback:
            return "Opinia nt. odkrytej ceny";
        case ApplicationSource.InvestmentInquiry:
            return "Zapytanie od inwestora";
        case ApplicationSource.OfferAutoresponderRevealedPrices:
            return "Autoresponder cenowy dot. inwestycji";
        case ApplicationSource.PropertyAutoresponderRevealedPrices:
            return "Autoresponder cenowy dot. nieruchomości";
        case ApplicationSource.RecommendedProperty:
            return "Zapytanie o nieruchomość - rekomendacja";
        case ApplicationSource.RecommendedOffer:
            return "Zapytanie o inwestycje - rekomendacja";
        case ApplicationSource.PromotionProperty:
            return "Zapytanie o promocję z widoku szczegółów nieruchomości";
        case ApplicationSource.QuickViewPropertyModal:
            return "Quick view modal";
        case ApplicationSource.QuickViewListingRecommendation:
            return "Zapytanie o nieruchomość - rekomendacja listing kafel";
        case ApplicationSource.FavouritesOfferInquiry:
            return "Zapytanie o inwestycję - ulubione";
        case ApplicationSource.FavouritesPropertyInquiry:
            return "Zapytanie o nieruchomość - ulubione";
        case ApplicationSource.ChatBot: {
            return "Zapytanie o nieruchomość - ChatBot";
        }
        case ApplicationSource.OfferChatBot: {
            return "Zapytanie o inwestycje - ChatBot";
        }
        case ApplicationSource.ComparisonToolOffer: {
            return "Porównywarka - Inwestycje";
        }
        case ApplicationSource.ComparisonToolProperty: {
            return "Porównywarka - Nieruchomości";
        }
        case ApplicationSource.PreSaleOffer:
            return "Zapytanie o inwestycję w przedsprzedaży";
        case ApplicationSource.PropertyConfigurator:
            return "Konfigurator mieszkania";
        case ApplicationSource.VideoReel:
            return "Zapytanie z reels";
        case ApplicationSource.PreMultilead:
            return "Zapytanie o inwestycję ostatnio odwiedzoną";
        case ApplicationSource.OfferPhoneCall:
            return "Zapytanie o numeru na widoku oferty";
        case ApplicationSource.PropertyPhoneCall:
            return "Zapytanie o numeru na widoku nieruchomości";
        default:
            return "";
    }
}

export enum ApplicationSourceSection {
    MODAL = 1,
    SIDEBAR = 2,
    BOTTOM = 3,
    MAP = 4,
    BUILDING_MODEL = 5,
    BODY = 6,
    VIRTUAL_TOUR = 7,
    GALLERY = 8,
    EMAIL = 9,
    POPUP_MODAL = 10,
    HEADER = 11,
    ESTIMATED_PRICE_TOP = 12,
    ESTIMATED_PRICE_BOTTOM = 13,
    MULTILEAD = 14,
    QUICK_VIEW = 15,
    CHAT = 16,
    FAVOURITES = 17,
    TABLE = 18,
    PROPERTY_CONFIGURATOR = 19,
    PROPERTY_CREDIT_CALCULATOR = 20,
    VENDOR_MULTILEAD = 21,
    VIDEO_REEL = 22,
    PREMULTILEAD = 23
    // ATTENTION: remember to inform backend to add new value to database validation
}

export function getApplicationSourceSectionDisplay(sourceSection: ApplicationSourceSection): string {
    switch (sourceSection) {
        case ApplicationSourceSection.BODY:
            return "Główna zawartość widoku";
        case ApplicationSourceSection.BOTTOM: {
            return "Na dole";
        }
        case ApplicationSourceSection.GALLERY: {
            return "Galeria";
        }
        case ApplicationSourceSection.HEADER: {
            return "Header";
        }
        case ApplicationSourceSection.MAP: {
            return "Mapa";
        }
        case ApplicationSourceSection.MODAL: {
            return "Modal";
        }
        case ApplicationSourceSection.SIDEBAR: {
            return "Sidebar";
        }
        case ApplicationSourceSection.ESTIMATED_PRICE_TOP: {
            return "Moduł ceny prognozowanej góra";
        }
        case ApplicationSourceSection.ESTIMATED_PRICE_BOTTOM: {
            return "Moduł ceny prognozowanej dół";
        }
        case ApplicationSourceSection.MULTILEAD: {
            return "Multilead";
        }
        case ApplicationSourceSection.QUICK_VIEW: {
            return "Quick view modal";
        }
        case ApplicationSourceSection.CHAT: {
            return "ChatBot";
        }
        case ApplicationSourceSection.FAVOURITES: {
            return "Favourites";
        }
        case ApplicationSourceSection.TABLE: {
            return "Tabela";
        }
        case ApplicationSourceSection.PROPERTY_CONFIGURATOR: {
            return "Konfigurator";
        }
        case ApplicationSourceSection.PROPERTY_CREDIT_CALCULATOR: {
            return "Kalkulator kredytowy";
        }
        case ApplicationSourceSection.VIDEO_REEL: {
            return "Reels";
        }
        case ApplicationSourceSection.PREMULTILEAD: {
            return "Pre-Multilead";
        }
        default:
            return "";
    }
}
/**
 * ATTENTION, EVERYONE WHO EDITS THIS FILE
 * Note that, when adding `ApplicationSource` or `ApplicationSourceSection` we need to edit database model to allow new values.
 */

export enum ApplicationSource {
    Vendor = 1, // Zapytanie do dewelopera
    Offer = 2, // Zapytanie o inwestycję
    Property = 3, // Zapytanie o nieruchomość
    NegotiatePrice = 4, // Negocjacje cenowe
    PriceInquiry = 5, // Zapytanie o cenę
    OfferPriceInquiry = 21, // Zapytanie o inwestycję z przycisku Zapytaj o ceny
    Promotion = 6, // Zapytanie o promocję
    FinancingServices = 7, // Zapytanie o kredyt
    PropertyConsulting = 8, // Znajdziemy dla Ciebie
    Generator = 9, // Zapytanie z generatora
    Chat = 10, // Czat
    Reservation = 13, // Powiadomienie o zwolnieniu rezerwacji
    LandingPage = 16, // Landing Page dla Dewelopera Kampania CPL
    ApplicationOfferScheduleMeeting = 17, // Zapytanie o spotkanie dot. inwestycji
    ApplicationPropertyScheduleMeeting = 18, // Zapytanie o spotkanie dot. nieruchomości
    OfferRevealPrice = 19, // Zapytanie o cenę - ukryta cena inwestycji
    PropertyRevealPrice = 20, // Zapytanie o cenę - ukryta cena nieruchomości
    DiscountNotification = 22, // Zapis do newslettera promocyjnego
    RevealedPrices = 24, // Odkrycie cen nieruchomości
    PropertyPriceFeedback = 25, // Opinia nt. odkrytej ceny
    InvestmentInquiry = 26, // Renters
    Contractor = 27, // Zapytanie z Marketplace
    OfferScheduleVideoMeeting = 28, // Zapytanie o wideo rozmowę dot. inwestycji
    PropertyScheduleVideoMeeting = 29, // Zapytanie o wideo rozmowę dot. nieruchomości
    OfferAutoresponderRevealedPrices = 31, // Test Autorespondera
    PropertyAutoresponderRevealedPrices = 32, // Test Autorespondera
    RecommendedProperty = 33, // Zapytanie o nieruchomość - rekomendacja
    RecommendedOffer = 34, // Zapytanie o inwestycje - rekomendacja
    PromotionProperty = 35, // Zapytanie o promocję - z widoku szczegółów nieruchomości
    QuickViewPropertyModal = 36, // Zapytania z modala quick view
    QuickViewListingRecommendation = 37, // Zapytanie z modala quick view na listingu z kafla rekomendacji
    FavouritesPropertyInquiry = 38, // Zapytanie o ulubione inwestycje
    FavouritesOfferInquiry = 39, // Zapytanie o ulubione nieruchomości
    ChatBot = 40, // Zapytanie o nieruchomość z ChatBota
    OfferChatBot = 41, // Zapytanie o inwestycję z ChatBota
    ComparisonToolOffer = 42,
    ComparisonToolProperty = 43,
    PreSaleOffer = 44,
    PropertyConfigurator = 45,
    CreditCalculator = 46, // Kalkulator kredytowy
    VideoReel = 47, // Zapytanie z reels
    PreMultilead = 48, // Zapytanie z PreMultileada
    OfferPhoneCall = 49, // Zapytanie o numer na widoku oferty
    PropertyPhoneCall = 50 // Zapytanie o numer na widoku nieruchomości
    // ATTENTION: remember to inform backend to add new value to database validation
}

export function getApplicationSourceDisplay(source: ApplicationSource): string {
    switch (source) {
        case ApplicationSource.Vendor:
            return "Zapytanie do dewelopera";
        case ApplicationSource.Offer:
            return "Zapytanie o inwestycję";
        case ApplicationSource.OfferPriceInquiry:
            return "Zapytanie o inwestycję (zapytaj o ceny)";
        case ApplicationSource.OfferRevealPrice:
            return "Zapytanie o cenę (ukryta cena inwestycji)";
        case ApplicationSource.Property:
            return "Zapytanie o nieruchomość";
        case ApplicationSource.PropertyRevealPrice:
            return "Zapytanie o cenę (ukryta cena nieruchomości)";
        case ApplicationSource.NegotiatePrice:
            return "Negocjacje cenowe";
        case ApplicationSource.PriceInquiry:
            return "Zapytanie o cenę";
        case ApplicationSource.Promotion:
            return "Zapytanie o promocję";
        case ApplicationSource.FinancingServices:
            return "Zapytanie o kredyt";
        case ApplicationSource.PropertyConsulting:
            return "Znajdziemy dla Ciebie";
        case ApplicationSource.Generator:
            return "Zapytanie z generatora";
        case ApplicationSource.Chat:
            return "Czat";
        case ApplicationSource.CreditCalculator:
            return "Kalkulator kredytowy";
        case ApplicationSource.Reservation:
            return "Powiadomienie o zwolnieniu rezerwacji";
        case ApplicationSource.LandingPage:
            return "Zapytanie z LandingPage dla Dewelopera";
        case ApplicationSource.ApplicationOfferScheduleMeeting:
            return "Zapytanie o spotkanie dot. inwestycji";
        case ApplicationSource.ApplicationPropertyScheduleMeeting:
            return "Zapytanie o spotkanie dot. nieruchomości";
        case ApplicationSource.OfferScheduleVideoMeeting:
            return "Zapytanie o wideorozmowę dot. inwestycji";
        case ApplicationSource.PropertyScheduleVideoMeeting:
            return "Zapytanie o wideorozmowę dot. nieruchomości";
        case ApplicationSource.DiscountNotification:
            return "Zapis do newslettera promocyjnego";
        case ApplicationSource.RevealedPrices:
            return "Odkrycie cen nieruchomości";
        case ApplicationSource.PropertyPriceFeedback:
            return "Opinia nt. odkrytej ceny";
        case ApplicationSource.InvestmentInquiry:
            return "Zapytanie od inwestora";
        case ApplicationSource.OfferAutoresponderRevealedPrices:
            return "Autoresponder cenowy dot. inwestycji";
        case ApplicationSource.PropertyAutoresponderRevealedPrices:
            return "Autoresponder cenowy dot. nieruchomości";
        case ApplicationSource.RecommendedProperty:
            return "Zapytanie o nieruchomość - rekomendacja";
        case ApplicationSource.RecommendedOffer:
            return "Zapytanie o inwestycje - rekomendacja";
        case ApplicationSource.PromotionProperty:
            return "Zapytanie o promocję z widoku szczegółów nieruchomości";
        case ApplicationSource.QuickViewPropertyModal:
            return "Quick view modal";
        case ApplicationSource.QuickViewListingRecommendation:
            return "Zapytanie o nieruchomość - rekomendacja listing kafel";
        case ApplicationSource.FavouritesOfferInquiry:
            return "Zapytanie o inwestycję - ulubione";
        case ApplicationSource.FavouritesPropertyInquiry:
            return "Zapytanie o nieruchomość - ulubione";
        case ApplicationSource.ChatBot: {
            return "Zapytanie o nieruchomość - ChatBot";
        }
        case ApplicationSource.OfferChatBot: {
            return "Zapytanie o inwestycje - ChatBot";
        }
        case ApplicationSource.ComparisonToolOffer: {
            return "Porównywarka - Inwestycje";
        }
        case ApplicationSource.ComparisonToolProperty: {
            return "Porównywarka - Nieruchomości";
        }
        case ApplicationSource.PreSaleOffer:
            return "Zapytanie o inwestycję w przedsprzedaży";
        case ApplicationSource.PropertyConfigurator:
            return "Konfigurator mieszkania";
        case ApplicationSource.VideoReel:
            return "Zapytanie z reels";
        case ApplicationSource.PreMultilead:
            return "Zapytanie o inwestycję ostatnio odwiedzoną";
        case ApplicationSource.OfferPhoneCall:
            return "Zapytanie o numeru na widoku oferty";
        case ApplicationSource.PropertyPhoneCall:
            return "Zapytanie o numeru na widoku nieruchomości";
        default:
            return "";
    }
}

export enum ApplicationSourceSection {
    MODAL = 1,
    SIDEBAR = 2,
    BOTTOM = 3,
    MAP = 4,
    BUILDING_MODEL = 5,
    BODY = 6,
    VIRTUAL_TOUR = 7,
    GALLERY = 8,
    EMAIL = 9,
    POPUP_MODAL = 10,
    HEADER = 11,
    ESTIMATED_PRICE_TOP = 12,
    ESTIMATED_PRICE_BOTTOM = 13,
    MULTILEAD = 14,
    QUICK_VIEW = 15,
    CHAT = 16,
    FAVOURITES = 17,
    TABLE = 18,
    PROPERTY_CONFIGURATOR = 19,
    PROPERTY_CREDIT_CALCULATOR = 20,
    VENDOR_MULTILEAD = 21,
    VIDEO_REEL = 22,
    PREMULTILEAD = 23
    // ATTENTION: remember to inform backend to add new value to database validation
}

export function getApplicationSourceSectionDisplay(sourceSection: ApplicationSourceSection): string {
    switch (sourceSection) {
        case ApplicationSourceSection.BODY:
            return "Główna zawartość widoku";
        case ApplicationSourceSection.BOTTOM: {
            return "Na dole";
        }
        case ApplicationSourceSection.GALLERY: {
            return "Galeria";
        }
        case ApplicationSourceSection.HEADER: {
            return "Header";
        }
        case ApplicationSourceSection.MAP: {
            return "Mapa";
        }
        case ApplicationSourceSection.MODAL: {
            return "Modal";
        }
        case ApplicationSourceSection.SIDEBAR: {
            return "Sidebar";
        }
        case ApplicationSourceSection.ESTIMATED_PRICE_TOP: {
            return "Moduł ceny prognozowanej góra";
        }
        case ApplicationSourceSection.ESTIMATED_PRICE_BOTTOM: {
            return "Moduł ceny prognozowanej dół";
        }
        case ApplicationSourceSection.MULTILEAD: {
            return "Multilead";
        }
        case ApplicationSourceSection.QUICK_VIEW: {
            return "Quick view modal";
        }
        case ApplicationSourceSection.CHAT: {
            return "ChatBot";
        }
        case ApplicationSourceSection.FAVOURITES: {
            return "Favourites";
        }
        case ApplicationSourceSection.TABLE: {
            return "Tabela";
        }
        case ApplicationSourceSection.PROPERTY_CONFIGURATOR: {
            return "Konfigurator";
        }
        case ApplicationSourceSection.PROPERTY_CREDIT_CALCULATOR: {
            return "Kalkulator kredytowy";
        }
        case ApplicationSourceSection.VIDEO_REEL: {
            return "Reels";
        }
        case ApplicationSourceSection.PREMULTILEAD: {
            return "Pre-Multilead";
        }
        default:
            return "";
    }
}
