import React from "react";
import {css, useTheme} from "@emotion/react";

import {calculateRemSize, flex, flexDirection, listUnStyled, m, mr} from "@pg-design/helpers-css";
import {CheckIcon} from "@pg-design/icons-module";

interface IProps {
    texts: string[];
    className?: string;
}

export const ArchitectInfoList = (props: IProps) => {
    const theme = useTheme();

    return (
        <ul css={listStyle} className={props.className}>
            {props.texts.map((text, index) => (
                <li key={index} css={[flex(), flexDirection("row")]}>
                    <CheckIcon size="1.6" wrapperSize="1.6" wrapperColor={theme.colors.primary} fill={theme.colors.secondary} css={[mr(1)]} /> {text}
                </li>
            ))}
        </ul>
    );
};

const listStyle = css`
    ${flex()};
    flex-direction: column;
    gap: ${calculateRemSize(1.5)};
    ${listUnStyled};
    ${m(0)};
`;
import React from "react";
import {css, useTheme} from "@emotion/react";

import {calculateRemSize, flex, flexDirection, listUnStyled, m, mr} from "@pg-design/helpers-css";
import {CheckIcon} from "@pg-design/icons-module";

interface IProps {
    texts: string[];
    className?: string;
}

export const ArchitectInfoList = (props: IProps) => {
    const theme = useTheme();

    return (
        <ul css={listStyle} className={props.className}>
            {props.texts.map((text, index) => (
                <li key={index} css={[flex(), flexDirection("row")]}>
                    <CheckIcon size="1.6" wrapperSize="1.6" wrapperColor={theme.colors.primary} fill={theme.colors.secondary} css={[mr(1)]} /> {text}
                </li>
            ))}
        </ul>
    );
};

const listStyle = css`
    ${flex()};
    flex-direction: column;
    gap: ${calculateRemSize(1.5)};
    ${listUnStyled};
    ${m(0)};
`;
