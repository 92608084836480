export enum ApplicationVariant {
    DEFAULT,
    CREDIT,
    PROPERTY_WITH_RETARGETING
}
export enum ApplicationVariant {
    DEFAULT,
    CREDIT,
    PROPERTY_WITH_RETARGETING
}
