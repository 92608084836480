import {appendQueryString, getRequest} from "@pg-mono/request";
import {apiV2Link, Scenario} from "@pg-mono/rp-api-routes";

import {IVendorDetailApplication} from "../../vendor/types/IVendorDetailApplication";

export const fetchApplicationVendorDetailById = (vendorId: number): Promise<IVendorDetailApplication> => {
    const applicationVendorApiLink = apiV2Link.vendor.detail(Scenario.VENDOR_DETAIL_APPLICATION, {vendorId});

    const url = appendQueryString(applicationVendorApiLink, {});

    return getRequest({}, url);
};
import {appendQueryString, getRequest} from "@pg-mono/request";
import {apiV2Link, Scenario} from "@pg-mono/rp-api-routes";

import {IVendorDetailApplication} from "../../vendor/types/IVendorDetailApplication";

export const fetchApplicationVendorDetailById = (vendorId: number): Promise<IVendorDetailApplication> => {
    const applicationVendorApiLink = apiV2Link.vendor.detail(Scenario.VENDOR_DETAIL_APPLICATION, {vendorId});

    const url = appendQueryString(applicationVendorApiLink, {});

    return getRequest({}, url);
};
