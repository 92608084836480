import React, {Fragment, useEffect, useMemo} from "react";
import {css, Theme, useTheme} from "@emotion/react";

import {flex, mt, onDesktop, p, pr, w100} from "@pg-design/helpers-css";
import {Highlight, Text} from "@pg-design/text-module";
import {last} from "@pg-mono/nodash";
import {areaFormat, pluralize} from "@pg-mono/string-utils";
import {priceFormat} from "@pg-mono/string-utils";

import {getOfferTypeNameCapital} from "../../offer/helpers/OfferType";
import {useFetchPropertyEstimatedPriceData} from "../../property/hooks/useFetchPropertyEstimatedPriceData";
import {usePropertyEstimatedPriceRegionValidation} from "../../property/hooks/usePropertyEstimatedPriceRegionValidation";
import {getApiFieldToEstimatePrice, getTotalPriceRange, roundPriceToNearestTen} from "../../property/utils/estimated_price_utils";
import {RegionType} from "../../region/types/RegionType";
import {getPropertyPriceRange} from "../utils/get_property_price_range";

interface IProps {
    application: {
        property_price: number | null;
    };
    property: {
        area: number;
        configuration?: {show_price: boolean};
        rooms: number;
        offer: {
            address: string;
            name: string;
            type: number;
        };
        region: {id: number; type: RegionType};
    };
}

const roomsPluralize = pluralize(["pokój", "pokoje", "pokoi"]);
export const AppliedProperty = (props: IProps) => {
    const {application, property} = props;

    const theme = useTheme();

    const {apiFieldWithAvgPrice, estimatedPriceData, fetchEstimatedPriceData, setApiFieldWithAvgPrice} = useFetchPropertyEstimatedPriceData();
    const {validatePropertyEstimatedPriceRegion} = usePropertyEstimatedPriceRegionValidation(fetchEstimatedPriceData, setApiFieldWithAvgPrice);

    useEffect(() => {
        if (!application.property_price) {
            setApiFieldWithAvgPrice(getApiFieldToEstimatePrice(property.offer.type, property.area));

            validatePropertyEstimatedPriceRegion(property.region);
        }
    }, []);

    const [estimatedPropertyPriceFrom, estimatedPropertyPriceTo] = useMemo(() => {
        if (estimatedPriceData.length && apiFieldWithAvgPrice) {
            return getTotalPriceRange(property.area, last(estimatedPriceData)?.[apiFieldWithAvgPrice] as number);
        }
        return [];
    }, [estimatedPriceData, apiFieldWithAvgPrice]);

    const {propertyPriceFrom, propertyPriceTo} = getPropertyPriceRange({
        propertyPrice: application.property_price,
        estimatedPropertyPriceFrom,
        estimatedPropertyPriceTo
    });

    const type = getOfferTypeNameCapital(property.offer.type);
    const rooms = `${property.rooms} ${roomsPluralize(property.rooms)}`;
    const area = areaFormat(property.area);

    return (
        <div css={appliedProperty}>
            <div>
                <Text variant="body_copy_1">
                    {type}, {rooms}, {area}
                </Text>
                <Text color={theme.colors.gray[700]} variant="info_txt_3">
                    {property.offer.name}, {property.offer.address}
                </Text>
            </div>

            <Text css={[mt(), onDesktop(mt(0), pr(3))]} variant="body_copy_2">
                {property.configuration?.show_price && !!application.property_price ? (
                    <Highlight>{priceFormat(roundPriceToNearestTen(application.property_price))}</Highlight>
                ) : (
                    <Highlight>
                        {propertyPriceFrom && propertyPriceTo ? (
                            <Fragment>
                                {propertyPriceFrom} - {propertyPriceTo}
                            </Fragment>
                        ) : (
                            "Brak danych"
                        )}
                    </Highlight>
                )}
            </Text>
        </div>
    );
};

const appliedProperty = (theme: Theme) => css`
    ${flex()};
    flex-direction: column;
    ${w100};
    background-color: ${theme.colors.background[100]};
    ${p(2)};
    position: relative;

    @media (min-width: ${theme.breakpoints.md}) {
        ${flex("center", "space-between")};
        flex-direction: row;
        height: 8rem;
        min-height: 8rem;
    }
`;
import React, {Fragment, useEffect, useMemo} from "react";
import {css, Theme, useTheme} from "@emotion/react";

import {flex, mt, onDesktop, p, pr, w100} from "@pg-design/helpers-css";
import {Highlight, Text} from "@pg-design/text-module";
import {last} from "@pg-mono/nodash";
import {areaFormat, pluralize} from "@pg-mono/string-utils";
import {priceFormat} from "@pg-mono/string-utils";

import {getOfferTypeNameCapital} from "../../offer/helpers/OfferType";
import {useFetchPropertyEstimatedPriceData} from "../../property/hooks/useFetchPropertyEstimatedPriceData";
import {usePropertyEstimatedPriceRegionValidation} from "../../property/hooks/usePropertyEstimatedPriceRegionValidation";
import {getApiFieldToEstimatePrice, getTotalPriceRange, roundPriceToNearestTen} from "../../property/utils/estimated_price_utils";
import {RegionType} from "../../region/types/RegionType";
import {getPropertyPriceRange} from "../utils/get_property_price_range";

interface IProps {
    application: {
        property_price: number | null;
    };
    property: {
        area: number;
        configuration?: {show_price: boolean};
        rooms: number;
        offer: {
            address: string;
            name: string;
            type: number;
        };
        region: {id: number; type: RegionType};
    };
}

const roomsPluralize = pluralize(["pokój", "pokoje", "pokoi"]);
export const AppliedProperty = (props: IProps) => {
    const {application, property} = props;

    const theme = useTheme();

    const {apiFieldWithAvgPrice, estimatedPriceData, fetchEstimatedPriceData, setApiFieldWithAvgPrice} = useFetchPropertyEstimatedPriceData();
    const {validatePropertyEstimatedPriceRegion} = usePropertyEstimatedPriceRegionValidation(fetchEstimatedPriceData, setApiFieldWithAvgPrice);

    useEffect(() => {
        if (!application.property_price) {
            setApiFieldWithAvgPrice(getApiFieldToEstimatePrice(property.offer.type, property.area));

            validatePropertyEstimatedPriceRegion(property.region);
        }
    }, []);

    const [estimatedPropertyPriceFrom, estimatedPropertyPriceTo] = useMemo(() => {
        if (estimatedPriceData.length && apiFieldWithAvgPrice) {
            return getTotalPriceRange(property.area, last(estimatedPriceData)?.[apiFieldWithAvgPrice] as number);
        }
        return [];
    }, [estimatedPriceData, apiFieldWithAvgPrice]);

    const {propertyPriceFrom, propertyPriceTo} = getPropertyPriceRange({
        propertyPrice: application.property_price,
        estimatedPropertyPriceFrom,
        estimatedPropertyPriceTo
    });

    const type = getOfferTypeNameCapital(property.offer.type);
    const rooms = `${property.rooms} ${roomsPluralize(property.rooms)}`;
    const area = areaFormat(property.area);

    return (
        <div css={appliedProperty}>
            <div>
                <Text variant="body_copy_1">
                    {type}, {rooms}, {area}
                </Text>
                <Text color={theme.colors.gray[700]} variant="info_txt_3">
                    {property.offer.name}, {property.offer.address}
                </Text>
            </div>

            <Text css={[mt(), onDesktop(mt(0), pr(3))]} variant="body_copy_2">
                {property.configuration?.show_price && !!application.property_price ? (
                    <Highlight>{priceFormat(roundPriceToNearestTen(application.property_price))}</Highlight>
                ) : (
                    <Highlight>
                        {propertyPriceFrom && propertyPriceTo ? (
                            <Fragment>
                                {propertyPriceFrom} - {propertyPriceTo}
                            </Fragment>
                        ) : (
                            "Brak danych"
                        )}
                    </Highlight>
                )}
            </Text>
        </div>
    );
};

const appliedProperty = (theme: Theme) => css`
    ${flex()};
    flex-direction: column;
    ${w100};
    background-color: ${theme.colors.background[100]};
    ${p(2)};
    position: relative;

    @media (min-width: ${theme.breakpoints.md}) {
        ${flex("center", "space-between")};
        flex-direction: row;
        height: 8rem;
        min-height: 8rem;
    }
`;
