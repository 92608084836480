import {useState} from "react";

export interface IPriceFilter {
    lower?: number;
    upper?: number;
}

interface IOptions {
    // TODO: Remove if conditions from TODO below are met
    // recommendationType: MultiLeadRecommendationType;
    defaultPriceLimit: IPriceFilter;
}
export const useRecommendationsFilters = (options: IOptions) => {
    //TODO: It seems that after CU-869648paa only price fiter is needed, if that stays, remove this hook and move logic

    // const recommendedProperties = useSelector((store: IRPStore) => store.property.recommendedProperties);
    // const recommendedOffers = useSelector((store: IRPStore) => store.offerDetail.recommendedOffers);

    // const [distanceFilterInKm, setDistanceFilterInKm] = useState(() => {
    //     const firstOffer = options.recommendationType === MultiLeadRecommendationType.PROPERTY ? recommendedProperties[0] : recommendedOffers[0];
    //     const defaultDistance = firstOffer?.distance > MULTI_LEAD_DISTANCES_IN_KM[0] ? MULTI_LEAD_DISTANCES_IN_KM[1] : MULTI_LEAD_DISTANCES_IN_KM[0];
    //
    //     return defaultDistance || MULTI_LEAD_DISTANCES_IN_KM[0];
    // });
    const [priceFilter, setPriceFilter] = useState<IPriceFilter>(options.defaultPriceLimit);

    // return {priceFilter, setPriceFilter, distanceFilterInKm, setDistanceFilterInKm, canDistanceBeIncreased: distanceFilterInKm < MULTI_LEAD_DISTANCES_IN_KM[1]};
    return {priceFilter, setPriceFilter};
};
import {useState} from "react";

export interface IPriceFilter {
    lower?: number;
    upper?: number;
}

interface IOptions {
    // TODO: Remove if conditions from TODO below are met
    // recommendationType: MultiLeadRecommendationType;
    defaultPriceLimit: IPriceFilter;
}
export const useRecommendationsFilters = (options: IOptions) => {
    //TODO: It seems that after CU-869648paa only price fiter is needed, if that stays, remove this hook and move logic

    // const recommendedProperties = useSelector((store: IRPStore) => store.property.recommendedProperties);
    // const recommendedOffers = useSelector((store: IRPStore) => store.offerDetail.recommendedOffers);

    // const [distanceFilterInKm, setDistanceFilterInKm] = useState(() => {
    //     const firstOffer = options.recommendationType === MultiLeadRecommendationType.PROPERTY ? recommendedProperties[0] : recommendedOffers[0];
    //     const defaultDistance = firstOffer?.distance > MULTI_LEAD_DISTANCES_IN_KM[0] ? MULTI_LEAD_DISTANCES_IN_KM[1] : MULTI_LEAD_DISTANCES_IN_KM[0];
    //
    //     return defaultDistance || MULTI_LEAD_DISTANCES_IN_KM[0];
    // });
    const [priceFilter, setPriceFilter] = useState<IPriceFilter>(options.defaultPriceLimit);

    // return {priceFilter, setPriceFilter, distanceFilterInKm, setDistanceFilterInKm, canDistanceBeIncreased: distanceFilterInKm < MULTI_LEAD_DISTANCES_IN_KM[1]};
    return {priceFilter, setPriceFilter};
};
