import {differenceInCalendarDays} from "date-fns";
import * as lscache from "lscache";

const ARCHITECT_SENT_APPLICATIONS = "sendArchitectApplications";

const getArchitectApplicationStorage = (): {list: number[]; timeStamp: string} => {
    return lscache.get(ARCHITECT_SENT_APPLICATIONS);
};

export const getArchitectApplicationStorageList = (): number[] => {
    const verifyStorage = verifyArchitectApplicationStorage();
    return verifyStorage.list;
};

export const addArchitectApplicationToStorage = (architectId: number): void => {
    const architectApplications = getArchitectApplicationStorage();
    const timeStamp = new Date();

    const foundApplication = architectApplications?.list?.find((sendArchitectId) => sendArchitectId === architectId);

    if (!foundApplication) {
        lscache.set(ARCHITECT_SENT_APPLICATIONS, {
            list: (architectApplications?.list && [...(architectApplications?.list || []), architectId]) || [architectId],
            timeStamp
        });
    }
};

const verifyArchitectApplicationStorage = (): {list: number[]; timeStamp: string} => {
    const architectApplicationStorage = getArchitectApplicationStorage();
    const today = new Date().toString();

    if (!architectApplicationStorage) {
        return {list: [], timeStamp: today};
    }
    const isOldStorage = differenceInCalendarDays(today, architectApplicationStorage.timeStamp) >= 31;

    if (isOldStorage) {
        lscache.set(ARCHITECT_SENT_APPLICATIONS, {list: [], timeStamp: today});
        return {list: [], timeStamp: today};
    }
    return architectApplicationStorage;
};
import {differenceInCalendarDays} from "date-fns";
import * as lscache from "lscache";

const ARCHITECT_SENT_APPLICATIONS = "sendArchitectApplications";

const getArchitectApplicationStorage = (): {list: number[]; timeStamp: string} => {
    return lscache.get(ARCHITECT_SENT_APPLICATIONS);
};

export const getArchitectApplicationStorageList = (): number[] => {
    const verifyStorage = verifyArchitectApplicationStorage();
    return verifyStorage.list;
};

export const addArchitectApplicationToStorage = (architectId: number): void => {
    const architectApplications = getArchitectApplicationStorage();
    const timeStamp = new Date();

    const foundApplication = architectApplications?.list?.find((sendArchitectId) => sendArchitectId === architectId);

    if (!foundApplication) {
        lscache.set(ARCHITECT_SENT_APPLICATIONS, {
            list: (architectApplications?.list && [...(architectApplications?.list || []), architectId]) || [architectId],
            timeStamp
        });
    }
};

const verifyArchitectApplicationStorage = (): {list: number[]; timeStamp: string} => {
    const architectApplicationStorage = getArchitectApplicationStorage();
    const today = new Date().toString();

    if (!architectApplicationStorage) {
        return {list: [], timeStamp: today};
    }
    const isOldStorage = differenceInCalendarDays(today, architectApplicationStorage.timeStamp) >= 31;

    if (isOldStorage) {
        lscache.set(ARCHITECT_SENT_APPLICATIONS, {list: [], timeStamp: today});
        return {list: [], timeStamp: today};
    }
    return architectApplicationStorage;
};
