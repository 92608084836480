import {useMemo} from "react";

import {IMultiLeadApplication} from "../actions/multi_lead_applications_actions";
import {IApplicationData} from "../reducers/application_reducer";
import {IApplication} from "../types/IApplication";
import {IMultiLeadData, MultiLeadStatus} from "../types/IMultiLeadData";
import {IOriginalApplicationProperty} from "../types/IOriginalApplicationProperty";
import {shouldApplicationPriceBeenShown} from "../utils/should_application_price_been_shown";

interface IProps {
    storeHash: string;
    originalApplicationResponse: IApplication | null;
    originalApplication: Pick<IApplicationData, "application" | "vendor" | "offer" | "property" | "multiLeadApplications"> | undefined;
    multiLeadProperty: IOriginalApplicationProperty;
    additionalLeads?: IMultiLeadApplication[];
    includeOriginalApplication?: boolean;
}

export const useTransformApplicationToMultiLead = (props: IProps) => {
    const {originalApplication, multiLeadProperty, additionalLeads, includeOriginalApplication} = props;

    const originalTransformedApplication = useMemo(() => {
        const hasOriginalApplicationBeenSent = !!props.originalApplicationResponse;

        if (
            includeOriginalApplication &&
            hasOriginalApplicationBeenSent &&
            originalApplication &&
            originalApplication.vendor?.detail &&
            originalApplication.offer?.detail
        ) {
            return {
                id: props.storeHash,
                offerType: originalApplication.offer.detail.type || 0,
                offerName: originalApplication.offer.detail.name || "",
                offerVendorName: originalApplication.vendor.detail.name || "",
                offerVendorLogo: originalApplication.vendor.detail.logo.v_log_80x60,
                offerAddress: originalApplication.offer.detail.address || "",
                offerPrivacyDataProcessorName: originalApplication.offer.detail.configuration.personal_data_processor_name || null,
                offerPrivacyPolicyUrl: originalApplication.offer.detail.configuration.privacy_policy_url || null,
                offerPersonalDataProcessorUrl: originalApplication.offer.detail.configuration.personal_data_processor_url,
                rooms: originalApplication.property?.detail?.rooms,
                area: originalApplication.property?.detail?.area,
                constructionDateRange: originalApplication.offer.detail.construction_date_range,
                mainImage: {
                    desktop: originalApplication.offer.detail.main_image.m_img_375x211,
                    mobile: originalApplication.offer.detail.main_image.m_img_350x648
                },
                coordinates: originalApplication.offer.detail.geo_point.coordinates,
                distance: 0,
                region: {
                    country: originalApplication.offer.detail.region.country
                },
                price: props.originalApplicationResponse?.property_price || originalApplication.application?.property_price || multiLeadProperty?.price || null,
                isPriceRevealed:
                    shouldApplicationPriceBeenShown(originalApplication.application) ||
                    shouldApplicationPriceBeenShown(props.originalApplicationResponse) ||
                    multiLeadProperty?.configuration.show_price ||
                    false,
                // TODO: uncomment if propertyStatus for recommendations is implemented
                // propertyStatus: multiLeadProperty.is_reserved ? MultiLeadPropertyStatus.RESERVED : MultiLeadPropertyStatus.FOR_SALE,
                status: props.originalApplicationResponse ? MultiLeadStatus.SENT : MultiLeadStatus.REJECTED // not always status is SENT, it can be REJECTED - you can determine it by checking originalApplicationResponse is not empty
            };
        }
        return null;
    }, [
        originalApplication?.offer?.detail?.id,
        multiLeadProperty?.id,
        originalApplication?.vendor?.detail?.id,
        originalApplication?.multiLeadApplications,
        additionalLeads
    ]);

    const transformedApplications = useMemo(() => {
        const transformedApplications: IMultiLeadData[] = [];

        const allLeads = getAllLeads(originalApplication, additionalLeads);

        if (allLeads.length > 0) {
            const multiLeadApplications: IMultiLeadData[] = allLeads.map((multiLeadApplication) => {
                if (multiLeadApplication.recommendedProperty?.id) {
                    return {
                        id: `ml_p${multiLeadApplication.recommendedProperty?.id}`,
                        offerType: multiLeadApplication.recommendedProperty?.offer.type || 0,
                        offerName: multiLeadApplication.recommendedProperty?.offer?.name || "",
                        offerVendorName: multiLeadApplication.recommendedProperty?.offer.vendor?.name || "",
                        offerVendorLogo: multiLeadApplication.recommendedProperty?.offer?.vendor?.logo.v_log_80x60,
                        offerAddress: multiLeadApplication.recommendedProperty?.offer?.address || "",
                        offerPrivacyDataProcessorName: multiLeadApplication.recommendedProperty?.offer?.configuration.personal_data_processor_name || null,
                        offerPrivacyPolicyUrl: multiLeadApplication.recommendedProperty?.offer?.configuration.privacy_policy_url || null,
                        offerPersonalDataProcessorUrl: multiLeadApplication.recommendedProperty?.offer?.configuration.personal_data_processor_url,
                        rooms: multiLeadApplication.recommendedProperty?.rooms,
                        area: multiLeadApplication.recommendedProperty?.area,
                        constructionDateRange: multiLeadApplication.recommendedProperty?.offer?.construction_date_range,
                        mainImage: {
                            desktop: multiLeadApplication.recommendedProperty?.offer?.main_image?.m_img_350x230,
                            mobile: multiLeadApplication.recommendedProperty?.offer?.main_image?.m_img_350x230
                        },
                        coordinates: multiLeadApplication.recommendedProperty?.offer?.geo_point.coordinates as [number, number],
                        distance: 0,
                        region: {
                            country: originalApplication?.offer?.detail?.region.country
                        },
                        price: multiLeadApplication.application.property_price || null,
                        isPriceRevealed: shouldApplicationPriceBeenShown(multiLeadApplication.application) || false,
                        status: MultiLeadStatus.SENT
                    };
                }

                return {
                    id: `ml_o${multiLeadApplication.recommendedOffer?.id}`,
                    offerType: multiLeadApplication.recommendedOffer?.type || 0,
                    offerName: multiLeadApplication.recommendedOffer?.name || "",
                    offerVendorName: multiLeadApplication.recommendedOffer?.vendor?.name || "",
                    offerVendorLogo: multiLeadApplication.recommendedOffer?.vendor?.logo.v_log_80x60,
                    offerAddress: multiLeadApplication.recommendedOffer?.address || "",
                    offerPrivacyDataProcessorName: multiLeadApplication.recommendedOffer?.configuration.personal_data_processor_name || null,
                    offerPrivacyPolicyUrl: multiLeadApplication.recommendedOffer?.configuration.privacy_policy_url || null,
                    offerPersonalDataProcessorUrl: multiLeadApplication.recommendedOffer?.configuration.personal_data_processor_url,
                    mainImage: {
                        desktop: multiLeadApplication.recommendedOffer?.main_image?.m_img_277x160,
                        mobile: multiLeadApplication.recommendedOffer?.main_image?.m_img_277x160
                    },
                    coordinates: multiLeadApplication.recommendedOffer?.geo_point.coordinates as [number, number],
                    distance: 0,
                    region: {
                        country: originalApplication?.offer?.detail?.region.country
                    },
                    status: MultiLeadStatus.SENT
                };
            });

            transformedApplications.push(...multiLeadApplications);
        }

        return transformedApplications;
    }, [
        originalApplication?.offer?.detail?.id,
        multiLeadProperty?.id,
        originalApplication?.vendor?.detail?.id,
        originalApplication?.multiLeadApplications,
        additionalLeads
    ]);

    return {
        originalApplication,
        transformedApplications,
        originalTransformedApplication
    };
};

function getAllLeads(originalApplication: IProps["originalApplication"], additionalLeads: IProps["additionalLeads"]) {
    if (originalApplication?.multiLeadApplications) {
        return additionalLeads ? [...additionalLeads, ...originalApplication.multiLeadApplications] : originalApplication.multiLeadApplications;
    }

    if (additionalLeads) {
        return originalApplication?.multiLeadApplications ? [...additionalLeads, ...originalApplication.multiLeadApplications] : additionalLeads;
    }

    return [];
}
import {useMemo} from "react";

import {IMultiLeadApplication} from "../actions/multi_lead_applications_actions";
import {IApplicationData} from "../reducers/application_reducer";
import {IApplication} from "../types/IApplication";
import {IMultiLeadData, MultiLeadStatus} from "../types/IMultiLeadData";
import {IOriginalApplicationProperty} from "../types/IOriginalApplicationProperty";
import {shouldApplicationPriceBeenShown} from "../utils/should_application_price_been_shown";

interface IProps {
    storeHash: string;
    originalApplicationResponse: IApplication | null;
    originalApplication: Pick<IApplicationData, "application" | "vendor" | "offer" | "property" | "multiLeadApplications"> | undefined;
    multiLeadProperty: IOriginalApplicationProperty;
    additionalLeads?: IMultiLeadApplication[];
    includeOriginalApplication?: boolean;
}

export const useTransformApplicationToMultiLead = (props: IProps) => {
    const {originalApplication, multiLeadProperty, additionalLeads, includeOriginalApplication} = props;

    const originalTransformedApplication = useMemo(() => {
        const hasOriginalApplicationBeenSent = !!props.originalApplicationResponse;

        if (
            includeOriginalApplication &&
            hasOriginalApplicationBeenSent &&
            originalApplication &&
            originalApplication.vendor?.detail &&
            originalApplication.offer?.detail
        ) {
            return {
                id: props.storeHash,
                offerType: originalApplication.offer.detail.type || 0,
                offerName: originalApplication.offer.detail.name || "",
                offerVendorName: originalApplication.vendor.detail.name || "",
                offerVendorLogo: originalApplication.vendor.detail.logo.v_log_80x60,
                offerAddress: originalApplication.offer.detail.address || "",
                offerPrivacyDataProcessorName: originalApplication.offer.detail.configuration.personal_data_processor_name || null,
                offerPrivacyPolicyUrl: originalApplication.offer.detail.configuration.privacy_policy_url || null,
                offerPersonalDataProcessorUrl: originalApplication.offer.detail.configuration.personal_data_processor_url,
                rooms: originalApplication.property?.detail?.rooms,
                area: originalApplication.property?.detail?.area,
                constructionDateRange: originalApplication.offer.detail.construction_date_range,
                mainImage: {
                    desktop: originalApplication.offer.detail.main_image.m_img_375x211,
                    mobile: originalApplication.offer.detail.main_image.m_img_350x648
                },
                coordinates: originalApplication.offer.detail.geo_point.coordinates,
                distance: 0,
                region: {
                    country: originalApplication.offer.detail.region.country
                },
                price: props.originalApplicationResponse?.property_price || originalApplication.application?.property_price || multiLeadProperty?.price || null,
                isPriceRevealed:
                    shouldApplicationPriceBeenShown(originalApplication.application) ||
                    shouldApplicationPriceBeenShown(props.originalApplicationResponse) ||
                    multiLeadProperty?.configuration.show_price ||
                    false,
                // TODO: uncomment if propertyStatus for recommendations is implemented
                // propertyStatus: multiLeadProperty.is_reserved ? MultiLeadPropertyStatus.RESERVED : MultiLeadPropertyStatus.FOR_SALE,
                status: props.originalApplicationResponse ? MultiLeadStatus.SENT : MultiLeadStatus.REJECTED // not always status is SENT, it can be REJECTED - you can determine it by checking originalApplicationResponse is not empty
            };
        }
        return null;
    }, [
        originalApplication?.offer?.detail?.id,
        multiLeadProperty?.id,
        originalApplication?.vendor?.detail?.id,
        originalApplication?.multiLeadApplications,
        additionalLeads
    ]);

    const transformedApplications = useMemo(() => {
        const transformedApplications: IMultiLeadData[] = [];

        const allLeads = getAllLeads(originalApplication, additionalLeads);

        if (allLeads.length > 0) {
            const multiLeadApplications: IMultiLeadData[] = allLeads.map((multiLeadApplication) => {
                if (multiLeadApplication.recommendedProperty?.id) {
                    return {
                        id: `ml_p${multiLeadApplication.recommendedProperty?.id}`,
                        offerType: multiLeadApplication.recommendedProperty?.offer.type || 0,
                        offerName: multiLeadApplication.recommendedProperty?.offer?.name || "",
                        offerVendorName: multiLeadApplication.recommendedProperty?.offer.vendor?.name || "",
                        offerVendorLogo: multiLeadApplication.recommendedProperty?.offer?.vendor?.logo.v_log_80x60,
                        offerAddress: multiLeadApplication.recommendedProperty?.offer?.address || "",
                        offerPrivacyDataProcessorName: multiLeadApplication.recommendedProperty?.offer?.configuration.personal_data_processor_name || null,
                        offerPrivacyPolicyUrl: multiLeadApplication.recommendedProperty?.offer?.configuration.privacy_policy_url || null,
                        offerPersonalDataProcessorUrl: multiLeadApplication.recommendedProperty?.offer?.configuration.personal_data_processor_url,
                        rooms: multiLeadApplication.recommendedProperty?.rooms,
                        area: multiLeadApplication.recommendedProperty?.area,
                        constructionDateRange: multiLeadApplication.recommendedProperty?.offer?.construction_date_range,
                        mainImage: {
                            desktop: multiLeadApplication.recommendedProperty?.offer?.main_image?.m_img_350x230,
                            mobile: multiLeadApplication.recommendedProperty?.offer?.main_image?.m_img_350x230
                        },
                        coordinates: multiLeadApplication.recommendedProperty?.offer?.geo_point.coordinates as [number, number],
                        distance: 0,
                        region: {
                            country: originalApplication?.offer?.detail?.region.country
                        },
                        price: multiLeadApplication.application.property_price || null,
                        isPriceRevealed: shouldApplicationPriceBeenShown(multiLeadApplication.application) || false,
                        status: MultiLeadStatus.SENT
                    };
                }

                return {
                    id: `ml_o${multiLeadApplication.recommendedOffer?.id}`,
                    offerType: multiLeadApplication.recommendedOffer?.type || 0,
                    offerName: multiLeadApplication.recommendedOffer?.name || "",
                    offerVendorName: multiLeadApplication.recommendedOffer?.vendor?.name || "",
                    offerVendorLogo: multiLeadApplication.recommendedOffer?.vendor?.logo.v_log_80x60,
                    offerAddress: multiLeadApplication.recommendedOffer?.address || "",
                    offerPrivacyDataProcessorName: multiLeadApplication.recommendedOffer?.configuration.personal_data_processor_name || null,
                    offerPrivacyPolicyUrl: multiLeadApplication.recommendedOffer?.configuration.privacy_policy_url || null,
                    offerPersonalDataProcessorUrl: multiLeadApplication.recommendedOffer?.configuration.personal_data_processor_url,
                    mainImage: {
                        desktop: multiLeadApplication.recommendedOffer?.main_image?.m_img_277x160,
                        mobile: multiLeadApplication.recommendedOffer?.main_image?.m_img_277x160
                    },
                    coordinates: multiLeadApplication.recommendedOffer?.geo_point.coordinates as [number, number],
                    distance: 0,
                    region: {
                        country: originalApplication?.offer?.detail?.region.country
                    },
                    status: MultiLeadStatus.SENT
                };
            });

            transformedApplications.push(...multiLeadApplications);
        }

        return transformedApplications;
    }, [
        originalApplication?.offer?.detail?.id,
        multiLeadProperty?.id,
        originalApplication?.vendor?.detail?.id,
        originalApplication?.multiLeadApplications,
        additionalLeads
    ]);

    return {
        originalApplication,
        transformedApplications,
        originalTransformedApplication
    };
};

function getAllLeads(originalApplication: IProps["originalApplication"], additionalLeads: IProps["additionalLeads"]) {
    if (originalApplication?.multiLeadApplications) {
        return additionalLeads ? [...additionalLeads, ...originalApplication.multiLeadApplications] : originalApplication.multiLeadApplications;
    }

    if (additionalLeads) {
        return originalApplication?.multiLeadApplications ? [...additionalLeads, ...originalApplication.multiLeadApplications] : additionalLeads;
    }

    return [];
}
