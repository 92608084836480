import {useSelector} from "react-redux";

import {IRPStore} from "../../app/rp_reducer";
import {isAbroad} from "../utils/is_abroad";

interface IProps {
    storeHash: string;
}

export const useMultiLeadData = (props: IProps) => {
    const isAuthenticated = useSelector((store: IRPStore) => store.isAuthenticated);
    const applicationData = useSelector((store: IRPStore) => store.application[props.storeHash]);
    const viewType = useSelector((store: IRPStore) => store.viewType.current);
    const originalApplicationGeoPoint = useSelector((store: IRPStore) => store.application[props.storeHash]?.offer?.detail?.geo_point);
    const originalApplicationRegionCityID = useSelector(
        (store: IRPStore) => store.application[props.storeHash]?.offer?.detail?.region?.stats?.region_type_city?.id
    );
    const originalApplicationCountry = useSelector((store: IRPStore) => store.application[props.storeHash]?.offer?.detail?.region.country);

    /**
     * @depracated use useRecommendations hook instead
     */
    const recommendedProperties = useSelector((store: IRPStore) => store.property.recommendedProperties);
    /**
     * @depracated use useRecommendations hook instead
     */
    const recommendedOffers = useSelector((store: IRPStore) => store.offerDetail.recommendedOffers);

    return {
        applicationData,
        isAuthenticated,
        viewType,
        originalApplicationGeoPoint,
        originalApplicationRegionCityID,
        recommendedProperties,
        recommendedOffers,
        originalApplicationCountry,
        isAbroadLead: isAbroad(originalApplicationCountry)
    };
};
import {useSelector} from "react-redux";

import {IRPStore} from "../../app/rp_reducer";
import {isAbroad} from "../utils/is_abroad";

interface IProps {
    storeHash: string;
}

export const useMultiLeadData = (props: IProps) => {
    const isAuthenticated = useSelector((store: IRPStore) => store.isAuthenticated);
    const applicationData = useSelector((store: IRPStore) => store.application[props.storeHash]);
    const viewType = useSelector((store: IRPStore) => store.viewType.current);
    const originalApplicationGeoPoint = useSelector((store: IRPStore) => store.application[props.storeHash]?.offer?.detail?.geo_point);
    const originalApplicationRegionCityID = useSelector(
        (store: IRPStore) => store.application[props.storeHash]?.offer?.detail?.region?.stats?.region_type_city?.id
    );
    const originalApplicationCountry = useSelector((store: IRPStore) => store.application[props.storeHash]?.offer?.detail?.region.country);

    /**
     * @depracated use useRecommendations hook instead
     */
    const recommendedProperties = useSelector((store: IRPStore) => store.property.recommendedProperties);
    /**
     * @depracated use useRecommendations hook instead
     */
    const recommendedOffers = useSelector((store: IRPStore) => store.offerDetail.recommendedOffers);

    return {
        applicationData,
        isAuthenticated,
        viewType,
        originalApplicationGeoPoint,
        originalApplicationRegionCityID,
        recommendedProperties,
        recommendedOffers,
        originalApplicationCountry,
        isAbroadLead: isAbroad(originalApplicationCountry)
    };
};
