import {useCallback} from "react";

import {mapInteractionHit, MapInteractionHits} from "../../tracking/algolytics_hits/map_interaction_hits";
import {ViewType} from "../../view_type/ViewType";

export const useOpenStreetMapAlgolyticsTracking = (viewType: ViewType | null) => {
    const onMapInit = useCallback(() => {
        mapInteractionHit(viewType, MapInteractionHits.INITIALIZED);
    }, [viewType]);

    const onMapMove = useCallback(
        () => () => {
            mapInteractionHit(viewType, MapInteractionHits.DRAGGED);
        },
        [viewType]
    );

    return {
        onMapInit,
        onMapMove
    };
};
import {useCallback} from "react";

import {mapInteractionHit, MapInteractionHits} from "../../tracking/algolytics_hits/map_interaction_hits";
import {ViewType} from "../../view_type/ViewType";

export const useOpenStreetMapAlgolyticsTracking = (viewType: ViewType | null) => {
    const onMapInit = useCallback(() => {
        mapInteractionHit(viewType, MapInteractionHits.INITIALIZED);
    }, [viewType]);

    const onMapMove = useCallback(
        () => () => {
            mapInteractionHit(viewType, MapInteractionHits.DRAGGED);
        },
        [viewType]
    );

    return {
        onMapInit,
        onMapMove
    };
};
