import {hitGoogleTagManager} from "./init_google_tag_manager";

export const gtmCreditCheckboxClick = () => {
    const gtmData = {
        event: "credit_checkbox_true"
    };

    hitGoogleTagManager(gtmData);
};
import {hitGoogleTagManager} from "./init_google_tag_manager";

export const gtmCreditCheckboxClick = () => {
    const gtmData = {
        event: "credit_checkbox_true"
    };

    hitGoogleTagManager(gtmData);
};
