import {IRecommendedOffer} from "../../../recommendations/types/IRecommendedOffer";
import {IRecommendedProperty} from "../../../recommendations/types/IRecommendedProperty";

export const isRecommendationRecommendedProperty = (application: IRecommendedProperty | IRecommendedOffer): application is IRecommendedProperty => {
    return "offer" in application;
};
import {IRecommendedOffer} from "../../../recommendations/types/IRecommendedOffer";
import {IRecommendedProperty} from "../../../recommendations/types/IRecommendedProperty";

export const isRecommendationRecommendedProperty = (application: IRecommendedProperty | IRecommendedOffer): application is IRecommendedProperty => {
    return "offer" in application;
};
