import {hitGoogleTagManager} from "../../tracking/google_tag_manager/init_google_tag_manager";

export enum PriceModalGTMEvent {
    PRICE_MODAL_SHOW = "price_modal_show",
    PRICE_MODAL_CLOSE = "price_modal_close",
    PRICE_ACCEPT = "price_accept",
    PRICE_SKIP = "price_skip",
    PRICE_ADD = "price_add"
}

export enum EstimatedPricePlacement {
    OFFER = "oferta",
    MULTILEAD = "multilead"
}

const priceModalEvent = (event: PriceModalGTMEvent, data: Record<string, string> = {}) => {
    hitGoogleTagManager({event, ...data});
};

export const conditionalPricesAnalytics = {
    gtm: {
        priceModalEvent
    }
};
import {hitGoogleTagManager} from "../../tracking/google_tag_manager/init_google_tag_manager";

export enum PriceModalGTMEvent {
    PRICE_MODAL_SHOW = "price_modal_show",
    PRICE_MODAL_CLOSE = "price_modal_close",
    PRICE_ACCEPT = "price_accept",
    PRICE_SKIP = "price_skip",
    PRICE_ADD = "price_add"
}

export enum EstimatedPricePlacement {
    OFFER = "oferta",
    MULTILEAD = "multilead"
}

const priceModalEvent = (event: PriceModalGTMEvent, data: Record<string, string> = {}) => {
    hitGoogleTagManager({event, ...data});
};

export const conditionalPricesAnalytics = {
    gtm: {
        priceModalEvent
    }
};
