import {css} from "@emotion/react";

type Size = 100;

const height = (size: Size) => css`
    height: ${size}%;
`;

export const minHeight = (size: string) => css`
    min-height: ${size};
`;

export const maxHeight = (size: string) => css`
    max-height: ${size};
`;

export const h100 = height(100);
import {css} from "@emotion/react";

type Size = 100;

const height = (size: Size) => css`
    height: ${size}%;
`;

export const minHeight = (size: string) => css`
    min-height: ${size};
`;

export const maxHeight = (size: string) => css`
    max-height: ${size};
`;

export const h100 = height(100);
