import React from "react";
import {css} from "@emotion/react";

import {onDesktop} from "@pg-design/helpers-css";
import {Image} from "@pg-design/image-module";

interface IProps {
    src: string;
}

export const PromoBoxPictogramImg = (props: IProps) => {
    return <Image imageStyle={imgStyle} css={infoBoxPictogramStyle} width="auto" height="130px" src={props.src} alt="" />;
};

const infoBoxPictogramStyle = css`
    width: auto;
    height: 130px;

    ${onDesktop(css`
        width: auto;
        height: 130px;
    `)};
`;

const imgStyle = {
    width: "auto",
    height: "100%"
};
import React from "react";
import {css} from "@emotion/react";

import {onDesktop} from "@pg-design/helpers-css";
import {Image} from "@pg-design/image-module";

interface IProps {
    src: string;
}

export const PromoBoxPictogramImg = (props: IProps) => {
    return <Image imageStyle={imgStyle} css={infoBoxPictogramStyle} width="auto" height="130px" src={props.src} alt="" />;
};

const infoBoxPictogramStyle = css`
    width: auto;
    height: 130px;

    ${onDesktop(css`
        width: auto;
        height: 130px;
    `)};
`;

const imgStyle = {
    width: "auto",
    height: "100%"
};
