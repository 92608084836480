import {Dispatch} from "redux";

import {catch404} from "@pg-mono/request";
import {createRequestActionTypes, IAction} from "@pg-mono/request-state";

import {IVendorDetailApplication} from "../../vendor/types/IVendorDetailApplication";
import {fetchApplicationVendorDetailById} from "../api/fetch_application_vendor_detail_by_id";

const APPLICATION_VENDOR_DETAIL_PREFIX = "desktop/application/vendor_detail/fetch";
export const fetchApplicationVendorDetailTypes = createRequestActionTypes(APPLICATION_VENDOR_DETAIL_PREFIX);

export enum VendorOfficeType {
    HEADQUARTER = 1,
    REGIONAL_OFFICE = 2
}

export const fetchApplicationVendorDetail =
    (vendorId: number, applicationId: string) =>
    (dispatch: Dispatch): Promise<IVendorDetailApplication | void> => {
        dispatch({type: fetchApplicationVendorDetailTypes.start, id: applicationId});

        return fetchApplicationVendorDetailById(vendorId)
            .then((json) => {
                dispatch({type: fetchApplicationVendorDetailTypes.success, id: applicationId, result: json});
                return json;
            })
            .catch(
                catch404(() => {
                    dispatch({type: fetchApplicationVendorDetailTypes.error, id: applicationId}); // TODO: Obsluga
                })
            );
    };

export const resetApplicationVendorDetail = (): IAction => ({type: fetchApplicationVendorDetailTypes.reset});
import {Dispatch} from "redux";

import {catch404} from "@pg-mono/request";
import {createRequestActionTypes, IAction} from "@pg-mono/request-state";

import {IVendorDetailApplication} from "../../vendor/types/IVendorDetailApplication";
import {fetchApplicationVendorDetailById} from "../api/fetch_application_vendor_detail_by_id";

const APPLICATION_VENDOR_DETAIL_PREFIX = "desktop/application/vendor_detail/fetch";
export const fetchApplicationVendorDetailTypes = createRequestActionTypes(APPLICATION_VENDOR_DETAIL_PREFIX);

export enum VendorOfficeType {
    HEADQUARTER = 1,
    REGIONAL_OFFICE = 2
}

export const fetchApplicationVendorDetail =
    (vendorId: number, applicationId: string) =>
    (dispatch: Dispatch): Promise<IVendorDetailApplication | void> => {
        dispatch({type: fetchApplicationVendorDetailTypes.start, id: applicationId});

        return fetchApplicationVendorDetailById(vendorId)
            .then((json) => {
                dispatch({type: fetchApplicationVendorDetailTypes.success, id: applicationId, result: json});
                return json;
            })
            .catch(
                catch404(() => {
                    dispatch({type: fetchApplicationVendorDetailTypes.error, id: applicationId}); // TODO: Obsluga
                })
            );
    };

export const resetApplicationVendorDetail = (): IAction => ({type: fetchApplicationVendorDetailTypes.reset});
