import {getRequest} from "@pg-mono/request";
import {apiV2Link, Scenario} from "@pg-mono/rp-api-routes";

import {IOfferDetailApplication} from "../../offer/types/IOfferDetailApplication";

export const fetchApplicationOfferDetailById = (offerId: number): Promise<IOfferDetailApplication> => {
    const url = apiV2Link.offer.detail(Scenario.OFFER_APPLICATION, {offerId});

    return getRequest({}, url);
};
import {getRequest} from "@pg-mono/request";
import {apiV2Link, Scenario} from "@pg-mono/rp-api-routes";

import {IOfferDetailApplication} from "../../offer/types/IOfferDetailApplication";

export const fetchApplicationOfferDetailById = (offerId: number): Promise<IOfferDetailApplication> => {
    const url = apiV2Link.offer.detail(Scenario.OFFER_APPLICATION, {offerId});

    return getRequest({}, url);
};
