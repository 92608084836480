import {useEffect, useState} from "react";
import {useSelector} from "react-redux";

import {IRPStore} from "../../app/rp_reducer";
import {initialEmptyApplicationValues} from "../../application/constants/application_form";
import {IApplicationFormValues} from "../../application/types/IApplicationFormValues";
import {IOfferDetailApplication} from "../../offer/types/IOfferDetailApplication";
import {IRealEstateIds} from "../types/IRealEstateIds";
import {calculateRoomsField} from "../utils/calculate_room_fields";
import {getLeadFormValuesFromProfile} from "../utils/get_lead_form_values_from_profile";
import {getLeadFormValuesFromStorage} from "../utils/get_lead_form_values_from_storage";

interface IUseInitialLeadFormValuesParams {
    realEstateIds: IRealEstateIds;
    leadData: {
        offer?: {
            detail?: IOfferDetailApplication;
        } | null;
    } | null;
    resetValues?: boolean;
    customFormText?: string;
}

export function useInitialLeadFormValues(params: IUseInitialLeadFormValuesParams) {
    const {realEstateIds, resetValues, leadData} = params;
    const {propertyId} = realEstateIds;

    const [initialLeadFormValues, setInitialLeadFormValues] = useState<IApplicationFormValues | null>(null);

    const isAuthenticated = useSelector((state: IRPStore) => state.isAuthenticated);
    const profile = useSelector((state: IRPStore) => state.user.profile);
    const sentLeadFormValues = useSelector((state: IRPStore) => state.sentLead.storedValues);
    const userPreferencesFilters = useSelector((state: IRPStore) => state.user.preferences?.data?.filters) || {};

    const updateInitialLeadFormValues = () => {
        const formValuesFromStorage = sentLeadFormValues && getLeadFormValuesFromStorage(sentLeadFormValues, initialEmptyApplicationValues);

        const offer = leadData?.offer?.detail;

        if (leadData && isAuthenticated && profile.data) {
            const formValuesFromProfile = getLeadFormValuesFromProfile(profile.data, initialEmptyApplicationValues, sentLeadFormValues);

            setInitialLeadFormValues({
                ...formValuesFromProfile,
                rooms: calculateRoomsField({
                    offer,
                    propertyId,
                    userPreferencesFilters
                }),
                text: params.customFormText
            });
        }

        if (leadData && !isAuthenticated && formValuesFromStorage) {
            setInitialLeadFormValues({
                ...initialEmptyApplicationValues,
                ...formValuesFromStorage,
                rooms: calculateRoomsField({
                    offer,
                    propertyId,
                    userPreferencesFilters
                }),
                text: params.customFormText
            });
        }

        if (leadData && !isAuthenticated && !formValuesFromStorage) {
            setInitialLeadFormValues({
                ...initialEmptyApplicationValues,
                rooms: calculateRoomsField({
                    offer,
                    propertyId,
                    userPreferencesFilters
                }),
                text: params.customFormText
            });
        }
    };

    useEffect(() => {
        if (!initialLeadFormValues) {
            updateInitialLeadFormValues();
        }
    }, [leadData, isAuthenticated]);

    useEffect(() => {
        updateInitialLeadFormValues();
    }, [sentLeadFormValues, profile.data]);

    useEffect(() => {
        if (resetValues && initialLeadFormValues) {
            setInitialLeadFormValues(null);
        }
    }, [resetValues]);

    useEffect(() => {
        if (!resetValues && !initialLeadFormValues) {
            updateInitialLeadFormValues();
        }
    }, [resetValues]);

    return initialLeadFormValues;
}
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";

import {IRPStore} from "../../app/rp_reducer";
import {initialEmptyApplicationValues} from "../../application/constants/application_form";
import {IApplicationFormValues} from "../../application/types/IApplicationFormValues";
import {IOfferDetailApplication} from "../../offer/types/IOfferDetailApplication";
import {IRealEstateIds} from "../types/IRealEstateIds";
import {calculateRoomsField} from "../utils/calculate_room_fields";
import {getLeadFormValuesFromProfile} from "../utils/get_lead_form_values_from_profile";
import {getLeadFormValuesFromStorage} from "../utils/get_lead_form_values_from_storage";

interface IUseInitialLeadFormValuesParams {
    realEstateIds: IRealEstateIds;
    leadData: {
        offer?: {
            detail?: IOfferDetailApplication;
        } | null;
    } | null;
    resetValues?: boolean;
    customFormText?: string;
}

export function useInitialLeadFormValues(params: IUseInitialLeadFormValuesParams) {
    const {realEstateIds, resetValues, leadData} = params;
    const {propertyId} = realEstateIds;

    const [initialLeadFormValues, setInitialLeadFormValues] = useState<IApplicationFormValues | null>(null);

    const isAuthenticated = useSelector((state: IRPStore) => state.isAuthenticated);
    const profile = useSelector((state: IRPStore) => state.user.profile);
    const sentLeadFormValues = useSelector((state: IRPStore) => state.sentLead.storedValues);
    const userPreferencesFilters = useSelector((state: IRPStore) => state.user.preferences?.data?.filters) || {};

    const updateInitialLeadFormValues = () => {
        const formValuesFromStorage = sentLeadFormValues && getLeadFormValuesFromStorage(sentLeadFormValues, initialEmptyApplicationValues);

        const offer = leadData?.offer?.detail;

        if (leadData && isAuthenticated && profile.data) {
            const formValuesFromProfile = getLeadFormValuesFromProfile(profile.data, initialEmptyApplicationValues, sentLeadFormValues);

            setInitialLeadFormValues({
                ...formValuesFromProfile,
                rooms: calculateRoomsField({
                    offer,
                    propertyId,
                    userPreferencesFilters
                }),
                text: params.customFormText
            });
        }

        if (leadData && !isAuthenticated && formValuesFromStorage) {
            setInitialLeadFormValues({
                ...initialEmptyApplicationValues,
                ...formValuesFromStorage,
                rooms: calculateRoomsField({
                    offer,
                    propertyId,
                    userPreferencesFilters
                }),
                text: params.customFormText
            });
        }

        if (leadData && !isAuthenticated && !formValuesFromStorage) {
            setInitialLeadFormValues({
                ...initialEmptyApplicationValues,
                rooms: calculateRoomsField({
                    offer,
                    propertyId,
                    userPreferencesFilters
                }),
                text: params.customFormText
            });
        }
    };

    useEffect(() => {
        if (!initialLeadFormValues) {
            updateInitialLeadFormValues();
        }
    }, [leadData, isAuthenticated]);

    useEffect(() => {
        updateInitialLeadFormValues();
    }, [sentLeadFormValues, profile.data]);

    useEffect(() => {
        if (resetValues && initialLeadFormValues) {
            setInitialLeadFormValues(null);
        }
    }, [resetValues]);

    useEffect(() => {
        if (!resetValues && !initialLeadFormValues) {
            updateInitialLeadFormValues();
        }
    }, [resetValues]);

    return initialLeadFormValues;
}
