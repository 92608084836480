import * as React from "react";
import {css, Theme, useTheme} from "@emotion/react";

import {flex, mb, ml} from "@pg-design/helpers-css";
import {Image} from "@pg-design/image-module";
import {Text} from "@pg-design/text-module";

interface IProps {
    vendorName: string;
    headerText: string;
    vendorLogo: string | null;
}
// https://github.com/yannickcr/eslint-plugin-react/issues/2105
// eslint-disable-next-line react/display-name
export const ApplicationVendorInfo = React.memo((props: IProps) => {
    const theme = useTheme();

    return (
        <div css={header}>
            {props.vendorLogo && <Image css={imageHolder} width="78px" height="58px" alt={`Logo dewelopera: ${props.vendorName}`} src={props.vendorLogo} />}
            <div css={textHolder}>
                <Text variant="body_copy_0" css={askAboutOffer} color={theme.colors.secondary}>
                    {props.headerText}
                </Text>
                <Text variant="info_txt_1" css={vendorName}>
                    {props.vendorName}
                </Text>
            </div>
        </div>
    );
});

const header = css`
    ${mb(2)}

    display: flex;
    align-items: center;
`;

const textHolder = css`
    ${ml(2)};
    ${flex("start", "center")};
    flex-direction: column;
`;

const imageHolder = css`
    min-width: min-content;
    max-width: 78px;
`;

const vendorName = (theme: Theme) => css`
    color: ${theme.colors.gray[700]};
`;

const askAboutOffer = css`
    margin: 0 0 0.2rem 0;
`;
import * as React from "react";
import {css, Theme, useTheme} from "@emotion/react";

import {flex, mb, ml} from "@pg-design/helpers-css";
import {Image} from "@pg-design/image-module";
import {Text} from "@pg-design/text-module";

interface IProps {
    vendorName: string;
    headerText: string;
    vendorLogo: string | null;
}
// https://github.com/yannickcr/eslint-plugin-react/issues/2105
// eslint-disable-next-line react/display-name
export const ApplicationVendorInfo = React.memo((props: IProps) => {
    const theme = useTheme();

    return (
        <div css={header}>
            {props.vendorLogo && <Image css={imageHolder} width="78px" height="58px" alt={`Logo dewelopera: ${props.vendorName}`} src={props.vendorLogo} />}
            <div css={textHolder}>
                <Text variant="body_copy_0" css={askAboutOffer} color={theme.colors.secondary}>
                    {props.headerText}
                </Text>
                <Text variant="info_txt_1" css={vendorName}>
                    {props.vendorName}
                </Text>
            </div>
        </div>
    );
});

const header = css`
    ${mb(2)}

    display: flex;
    align-items: center;
`;

const textHolder = css`
    ${ml(2)};
    ${flex("start", "center")};
    flex-direction: column;
`;

const imageHolder = css`
    min-width: min-content;
    max-width: 78px;
`;

const vendorName = (theme: Theme) => css`
    color: ${theme.colors.gray[700]};
`;

const askAboutOffer = css`
    margin: 0 0 0.2rem 0;
`;
