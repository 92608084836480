export const getNumberFormat = (value: number | string, hideFraction?: boolean): string => {
    if (typeof value === "string") {
        return value;
    }

    return Intl.NumberFormat("pl-PL", {
        useGrouping: true,
        ...(hideFraction
            ? {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0
              }
            : {})
    }).format(value);
};
export const getNumberFormat = (value: number | string, hideFraction?: boolean): string => {
    if (typeof value === "string") {
        return value;
    }

    return Intl.NumberFormat("pl-PL", {
        useGrouping: true,
        ...(hideFraction
            ? {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0
              }
            : {})
    }).format(value);
};
