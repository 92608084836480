import React from "react";
import {useSelector} from "react-redux";
import {css, useTheme} from "@emotion/react";

import {backgroundColor, calculateRemSize, flex, mr, onDesktop, p} from "@pg-design/helpers-css";
import {BrandCheckboxCheckedIcon, BrandWeightIcon} from "@pg-design/icons-module";
import {Text} from "@pg-design/text-module";
import {kmAppLink} from "@pg-mono/km-routes";

import {IRPStore} from "../../../app/rp_reducer";
import {IApplicationData} from "../../../application/reducers/application_reducer";
import {AdditionalOfferCategoryGTMEventAction, multiLeadAdditionalOfferAnalytics} from "../../../application/tracking/multilead_additional_offers_tracking";
import {IApplication} from "../../../application/types/IApplication";
import {IModalStepState} from "../../../application/types/IModalStepState";
import {IMarketplaceArchitectOfferSummary} from "../../../architect_application/api/fetch_marketplace_architect_promoted_offers_by_region";
import {useMarketplaceArchitectOfferForRegion} from "../../../architect_application/hooks/use_marketplace_architect_offer_summary";
import {IRegionAncestor} from "../../../offer/list/actions/offer_list_selected_data_actions";
import {getFullKMLink} from "../../../utils/get_full_km_link";
import {formatApplicationCount} from "../../utils/application_pluralize";
import {getLeadsSummaryIncludingMainLead} from "../../utils/get_leads_summary_including_main_lead";
import {MultiLeadWrapper} from "../MultiLeadWrapper";
import {PromoBoxPictogramIcon} from "./PromoBoxPictogramIcon";
import {PromoBoxPictogramImg} from "./PromoBoxPictogramImg";
import {PromoBoxWithPictogram} from "./PromoBoxWithPictogram";

const additionalOffersPromoImg = require("../../../application/images/multi_lead_promo/multi_lead_offers_promo.png");
const architectPackagesPromoImg = require("../../../application/images/multi_lead_promo/multi_lead_architect_packages_promo.png");

type IProps = {
    storeHash: string;
    leadData: IApplicationData | null;
    leadModalStepState: IModalStepState;
    handleSetNextStep: (originalApplicationResponse: IApplication | null) => void;
    handleCloseModal: () => void;
    handleSetArchitectInteriorDesignOfferStep: (architectData: IMarketplaceArchitectOfferSummary, architectRegion: IRegionAncestor | null) => void;
    handleSetPmOfferStep: () => void;
};

export const MultileadSentSummarySimplifiedWithComparison = (props: IProps) => {
    const theme = useTheme();
    const {leadModalStepState, leadData, storeHash} = props;

    const {multiLeadSummaryItems, originalApplicationResponse} = leadModalStepState;

    const originalApplication = useSelector((store: IRPStore) => store.application[storeHash]);
    const offer = originalApplication?.offer?.detail;
    const property = originalApplication?.property?.detail;

    const {architectOfferSummary, architectRegion} = useMarketplaceArchitectOfferForRegion({
        propertyId: property?.id,
        offerId: offer?.id
    });

    const onCompareSentOffersClick = () => {
        multiLeadAdditionalOfferAnalytics.gtm.multiLeadAdditionalOfferEvent(AdditionalOfferCategoryGTMEventAction.KM);
        window.location.href = getFullKMLink(kmAppLink.site.offers.appliedWithParams);
    };

    const onPmOfferClick = () => {
        multiLeadAdditionalOfferAnalytics.gtm.multiLeadAdditionalOfferEvent(AdditionalOfferCategoryGTMEventAction.PM);
        props.handleSetPmOfferStep();
    };

    const onArchitectPackagesClick = () => {
        multiLeadAdditionalOfferAnalytics.gtm.multiLeadAdditionalOfferEvent(AdditionalOfferCategoryGTMEventAction.ARCHITECTS);

        if (architectOfferSummary) {
            props.handleSetArchitectInteriorDesignOfferStep(architectOfferSummary, architectRegion);
        }
    };

    const {sentApplicationCount} = getLeadsSummaryIncludingMainLead({
        leadData,
        originalApplicationResponse,
        multiLeadSummaryItems
    });

    return (
        <MultiLeadWrapper isScrollable>
            <div css={headerStyle}>
                <div css={[mr(2)]}>
                    <BrandCheckboxCheckedIcon size="5.6" wrapperSize="6.4" wrapperColor={theme.colors.primary} fill={theme.colors.secondary} />
                </div>
                <div>
                    <Text variant="headline_3" mb="0.4rem">
                        Wysłano {formatApplicationCount(sentApplicationCount)}
                    </Text>
                    <Text variant="info_txt_1">Co możesz zrobić teraz?</Text>
                </div>
            </div>
            <div css={infoBoxesWrapperStyle}>
                <PromoBoxWithPictogram
                    pictogram={<PromoBoxPictogramIcon icon={BrandWeightIcon} />}
                    title="Zobacz ceny i porównaj oferty"
                    description="Teraz możesz wygodnie porównać estymowane ceny i wszystkie parametry ofert, o które zapytałeś."
                    buttonText="ZOBACZ CENY"
                    onClick={onCompareSentOffersClick}
                    css={[backgroundColor(theme.colors.primary)]}
                />
                <PromoBoxWithPictogram
                    pictogram={<PromoBoxPictogramImg src={additionalOffersPromoImg} />}
                    title="Uzyskaj dostęp dododatkowych ofert"
                    description="Poszerz zakres poszukiwań. Skorzystaj z doświadczenia naszych ekspertów rynku nieruchomości."
                    buttonText="SPRAWDŹ"
                    onClick={onPmOfferClick}
                />
                {architectOfferSummary && (
                    <PromoBoxWithPictogram
                        pictogram={<PromoBoxPictogramImg src={architectPackagesPromoImg} />}
                        title="Poznaj koszty wykończenia"
                        description="Zobacz atrakcyjne pakiety wykończenia pod klucz z gwarancją ceny i terminu!"
                        buttonText="SPRAWDŹ"
                        onClick={onArchitectPackagesClick}
                    />
                )}
            </div>
        </MultiLeadWrapper>
    );
};

const headerStyle = css`
    ${p(3, 1.5, 2)};
    ${flex("center")};
    flex-direction: row;
    flex: 0 0;

    ${onDesktop(css`
        ${p(4)};
    `)};
`;

const infoBoxesWrapperStyle = css`
    ${p(3, 1.5, 2)};
    display: flex;
    flex-direction: column;
    gap: ${calculateRemSize(3)};

    ${onDesktop(css`
        ${p(0, 4, 4, 4)};
        flex-direction: row;
    `)};
`;
import React from "react";
import {useSelector} from "react-redux";
import {css, useTheme} from "@emotion/react";

import {backgroundColor, calculateRemSize, flex, mr, onDesktop, p} from "@pg-design/helpers-css";
import {BrandCheckboxCheckedIcon, BrandWeightIcon} from "@pg-design/icons-module";
import {Text} from "@pg-design/text-module";
import {kmAppLink} from "@pg-mono/km-routes";

import {IRPStore} from "../../../app/rp_reducer";
import {IApplicationData} from "../../../application/reducers/application_reducer";
import {AdditionalOfferCategoryGTMEventAction, multiLeadAdditionalOfferAnalytics} from "../../../application/tracking/multilead_additional_offers_tracking";
import {IApplication} from "../../../application/types/IApplication";
import {IModalStepState} from "../../../application/types/IModalStepState";
import {IMarketplaceArchitectOfferSummary} from "../../../architect_application/api/fetch_marketplace_architect_promoted_offers_by_region";
import {useMarketplaceArchitectOfferForRegion} from "../../../architect_application/hooks/use_marketplace_architect_offer_summary";
import {IRegionAncestor} from "../../../offer/list/actions/offer_list_selected_data_actions";
import {getFullKMLink} from "../../../utils/get_full_km_link";
import {formatApplicationCount} from "../../utils/application_pluralize";
import {getLeadsSummaryIncludingMainLead} from "../../utils/get_leads_summary_including_main_lead";
import {MultiLeadWrapper} from "../MultiLeadWrapper";
import {PromoBoxPictogramIcon} from "./PromoBoxPictogramIcon";
import {PromoBoxPictogramImg} from "./PromoBoxPictogramImg";
import {PromoBoxWithPictogram} from "./PromoBoxWithPictogram";

const additionalOffersPromoImg = require("../../../application/images/multi_lead_promo/multi_lead_offers_promo.png");
const architectPackagesPromoImg = require("../../../application/images/multi_lead_promo/multi_lead_architect_packages_promo.png");

type IProps = {
    storeHash: string;
    leadData: IApplicationData | null;
    leadModalStepState: IModalStepState;
    handleSetNextStep: (originalApplicationResponse: IApplication | null) => void;
    handleCloseModal: () => void;
    handleSetArchitectInteriorDesignOfferStep: (architectData: IMarketplaceArchitectOfferSummary, architectRegion: IRegionAncestor | null) => void;
    handleSetPmOfferStep: () => void;
};

export const MultileadSentSummarySimplifiedWithComparison = (props: IProps) => {
    const theme = useTheme();
    const {leadModalStepState, leadData, storeHash} = props;

    const {multiLeadSummaryItems, originalApplicationResponse} = leadModalStepState;

    const originalApplication = useSelector((store: IRPStore) => store.application[storeHash]);
    const offer = originalApplication?.offer?.detail;
    const property = originalApplication?.property?.detail;

    const {architectOfferSummary, architectRegion} = useMarketplaceArchitectOfferForRegion({
        propertyId: property?.id,
        offerId: offer?.id
    });

    const onCompareSentOffersClick = () => {
        multiLeadAdditionalOfferAnalytics.gtm.multiLeadAdditionalOfferEvent(AdditionalOfferCategoryGTMEventAction.KM);
        window.location.href = getFullKMLink(kmAppLink.site.offers.appliedWithParams);
    };

    const onPmOfferClick = () => {
        multiLeadAdditionalOfferAnalytics.gtm.multiLeadAdditionalOfferEvent(AdditionalOfferCategoryGTMEventAction.PM);
        props.handleSetPmOfferStep();
    };

    const onArchitectPackagesClick = () => {
        multiLeadAdditionalOfferAnalytics.gtm.multiLeadAdditionalOfferEvent(AdditionalOfferCategoryGTMEventAction.ARCHITECTS);

        if (architectOfferSummary) {
            props.handleSetArchitectInteriorDesignOfferStep(architectOfferSummary, architectRegion);
        }
    };

    const {sentApplicationCount} = getLeadsSummaryIncludingMainLead({
        leadData,
        originalApplicationResponse,
        multiLeadSummaryItems
    });

    return (
        <MultiLeadWrapper isScrollable>
            <div css={headerStyle}>
                <div css={[mr(2)]}>
                    <BrandCheckboxCheckedIcon size="5.6" wrapperSize="6.4" wrapperColor={theme.colors.primary} fill={theme.colors.secondary} />
                </div>
                <div>
                    <Text variant="headline_3" mb="0.4rem">
                        Wysłano {formatApplicationCount(sentApplicationCount)}
                    </Text>
                    <Text variant="info_txt_1">Co możesz zrobić teraz?</Text>
                </div>
            </div>
            <div css={infoBoxesWrapperStyle}>
                <PromoBoxWithPictogram
                    pictogram={<PromoBoxPictogramIcon icon={BrandWeightIcon} />}
                    title="Zobacz ceny i porównaj oferty"
                    description="Teraz możesz wygodnie porównać estymowane ceny i wszystkie parametry ofert, o które zapytałeś."
                    buttonText="ZOBACZ CENY"
                    onClick={onCompareSentOffersClick}
                    css={[backgroundColor(theme.colors.primary)]}
                />
                <PromoBoxWithPictogram
                    pictogram={<PromoBoxPictogramImg src={additionalOffersPromoImg} />}
                    title="Uzyskaj dostęp dododatkowych ofert"
                    description="Poszerz zakres poszukiwań. Skorzystaj z doświadczenia naszych ekspertów rynku nieruchomości."
                    buttonText="SPRAWDŹ"
                    onClick={onPmOfferClick}
                />
                {architectOfferSummary && (
                    <PromoBoxWithPictogram
                        pictogram={<PromoBoxPictogramImg src={architectPackagesPromoImg} />}
                        title="Poznaj koszty wykończenia"
                        description="Zobacz atrakcyjne pakiety wykończenia pod klucz z gwarancją ceny i terminu!"
                        buttonText="SPRAWDŹ"
                        onClick={onArchitectPackagesClick}
                    />
                )}
            </div>
        </MultiLeadWrapper>
    );
};

const headerStyle = css`
    ${p(3, 1.5, 2)};
    ${flex("center")};
    flex-direction: row;
    flex: 0 0;

    ${onDesktop(css`
        ${p(4)};
    `)};
`;

const infoBoxesWrapperStyle = css`
    ${p(3, 1.5, 2)};
    display: flex;
    flex-direction: column;
    gap: ${calculateRemSize(3)};

    ${onDesktop(css`
        ${p(0, 4, 4, 4)};
        flex-direction: row;
    `)};
`;
