import {hitGoogleTagManager} from "../../tracking/google_tag_manager/init_google_tag_manager";

export enum LastSeenPropertiesGTMEventAction {
    OFFER_VIEW = "offer_view",
    CHECK = "check",
    UNCHECK = "uncheck",
    OFFER_ASK = "offer_ask"
}

const lastSeenPropertiesLeadEvent = (category: LastSeenPropertiesGTMEventAction) => {
    hitGoogleTagManager({event: "latest_offers", latest_offer_action: category});
};

export const lastSeenPropertiesLeadAnalytics = {
    gtm: {
        lastSeenPropertiesLeadEvent
    }
};
import {hitGoogleTagManager} from "../../tracking/google_tag_manager/init_google_tag_manager";

export enum LastSeenPropertiesGTMEventAction {
    OFFER_VIEW = "offer_view",
    CHECK = "check",
    UNCHECK = "uncheck",
    OFFER_ASK = "offer_ask"
}

const lastSeenPropertiesLeadEvent = (category: LastSeenPropertiesGTMEventAction) => {
    hitGoogleTagManager({event: "latest_offers", latest_offer_action: category});
};

export const lastSeenPropertiesLeadAnalytics = {
    gtm: {
        lastSeenPropertiesLeadEvent
    }
};
