export enum MultileadTrackingActionType {
    VIEW = "view",
    CHECK = "check",
    UNCHECK = "uncheck",
    SEND = "send_button_click",
    CLOSE = "close"
}
export enum MultileadTrackingActionType {
    VIEW = "view",
    CHECK = "check",
    UNCHECK = "uncheck",
    SEND = "send_button_click",
    CLOSE = "close"
}
