import {useState} from "react";
import {css} from "@emotion/react";

import {elevation} from "@pg-design/elevation/";
import {calculateRemSize} from "@pg-design/helpers-css";
import {onDesktop} from "@pg-design/helpers-css/";
import {useUserDevice} from "@pg-mono/user-device";

import {IApplication} from "../../../../../application/types/IApplication";
import {IMultiLeadStepPayload} from "../../../../../application/types/IMultiLeadStepPayload";
import {ISetMultiLeadSummaryStepPayload} from "../../../../../application/types/ISetMultiLeadSummaryStepPayload";
import {CloseIconButton} from "../../../../../atoms/CloseIconButton";
import {ILeadModalPriceConversationPrice} from "../../../../types/ILeadModalPriceConversationPrice";
import {LeadModalPriceConversationChat} from "./lead_modal_price_conversation_chat/LeadModalPriceConversationChat";
import {LeadModalPriceConversationConsultant} from "./LeadModalPriceConversationConsultant";
import {LeadModalPriceConversationPropertyInfo} from "./LeadModalPriceConversationPropertyInfo";

interface IProps {
    handleModalClose: () => void;
    handleSetMultiLeadStep: (payload: IMultiLeadStepPayload) => void;
    handleSetMultiLeadSummary: (payload: ISetMultiLeadSummaryStepPayload) => void;
    handleUpdateMainLeadResponse: (sentApplication: IApplication) => void;
}

export function LeadModalPriceConversation(props: IProps) {
    const {handleModalClose, handleSetMultiLeadStep, handleSetMultiLeadSummary, handleUpdateMainLeadResponse} = props;

    const {isMobile} = useUserDevice();
    const [price, setPrice] = useState<null | ILeadModalPriceConversationPrice>(null);

    return (
        <div css={conversation}>
            <CloseIconButton css={closeButton} onClick={handleModalClose} size="1.6" top={1.5} right={1.5} />
            {isMobile && <LeadModalPriceConversationConsultant />}
            <LeadModalPriceConversationPropertyInfo estimatedPrice={price} />
            <LeadModalPriceConversationChat
                handleModalClose={handleModalClose}
                handleSetPrice={(priceRange) => {
                    setPrice(priceRange);
                }}
                handleSetMultiLeadStep={handleSetMultiLeadStep}
                handleSetMultiLeadSummary={handleSetMultiLeadSummary}
                handleUpdateMainLeadResponse={handleUpdateMainLeadResponse}
            />
        </div>
    );
}

const conversation = css`
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    width: 100%;

    ${onDesktop(css`
        flex-direction: row;
    `)}
`;

const closeButton = css`
    border-radius: 50%;
    ${elevation(2)};
    background: white;
    width: ${calculateRemSize(4)};
    height: ${calculateRemSize(4)};
    display: flex;
    justify-content: center;
    align-items: center;
`;
import {useState} from "react";
import {css} from "@emotion/react";

import {elevation} from "@pg-design/elevation/";
import {calculateRemSize} from "@pg-design/helpers-css";
import {onDesktop} from "@pg-design/helpers-css/";
import {useUserDevice} from "@pg-mono/user-device";

import {IApplication} from "../../../../../application/types/IApplication";
import {IMultiLeadStepPayload} from "../../../../../application/types/IMultiLeadStepPayload";
import {ISetMultiLeadSummaryStepPayload} from "../../../../../application/types/ISetMultiLeadSummaryStepPayload";
import {CloseIconButton} from "../../../../../atoms/CloseIconButton";
import {ILeadModalPriceConversationPrice} from "../../../../types/ILeadModalPriceConversationPrice";
import {LeadModalPriceConversationChat} from "./lead_modal_price_conversation_chat/LeadModalPriceConversationChat";
import {LeadModalPriceConversationConsultant} from "./LeadModalPriceConversationConsultant";
import {LeadModalPriceConversationPropertyInfo} from "./LeadModalPriceConversationPropertyInfo";

interface IProps {
    handleModalClose: () => void;
    handleSetMultiLeadStep: (payload: IMultiLeadStepPayload) => void;
    handleSetMultiLeadSummary: (payload: ISetMultiLeadSummaryStepPayload) => void;
    handleUpdateMainLeadResponse: (sentApplication: IApplication) => void;
}

export function LeadModalPriceConversation(props: IProps) {
    const {handleModalClose, handleSetMultiLeadStep, handleSetMultiLeadSummary, handleUpdateMainLeadResponse} = props;

    const {isMobile} = useUserDevice();
    const [price, setPrice] = useState<null | ILeadModalPriceConversationPrice>(null);

    return (
        <div css={conversation}>
            <CloseIconButton css={closeButton} onClick={handleModalClose} size="1.6" top={1.5} right={1.5} />
            {isMobile && <LeadModalPriceConversationConsultant />}
            <LeadModalPriceConversationPropertyInfo estimatedPrice={price} />
            <LeadModalPriceConversationChat
                handleModalClose={handleModalClose}
                handleSetPrice={(priceRange) => {
                    setPrice(priceRange);
                }}
                handleSetMultiLeadStep={handleSetMultiLeadStep}
                handleSetMultiLeadSummary={handleSetMultiLeadSummary}
                handleUpdateMainLeadResponse={handleUpdateMainLeadResponse}
            />
        </div>
    );
}

const conversation = css`
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    width: 100%;

    ${onDesktop(css`
        flex-direction: row;
    `)}
`;

const closeButton = css`
    border-radius: 50%;
    ${elevation(2)};
    background: white;
    width: ${calculateRemSize(4)};
    height: ${calculateRemSize(4)};
    display: flex;
    justify-content: center;
    align-items: center;
`;
