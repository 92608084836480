import {Dispatch} from "redux";

import {fetchRecommendedOffers} from "../../offer/detail/actions/fetch_recommended_offers";
import {fetchRecommendedProperties} from "../../property/actions/fetch_recommended_properties";

interface IParams {
    offerId: number | undefined | null;
    propertyId: number | undefined | null;
    numberOfRooms: number[] | undefined | null;
    excludedOfferIds: number[];
    filters?: Record<string, string>;
}

export const fetchRecommendations = (params: IParams) => async (dispatch: Dispatch) => {
    if (params.propertyId) {
        return fetchRecommendedProperties(params.propertyId, params.excludedOfferIds, params.filters || {})(dispatch);
    }

    if (params.offerId) {
        return fetchRecommendedOffers(params.offerId, params.numberOfRooms, params.excludedOfferIds)(dispatch);
    }

    return null;
};
import {Dispatch} from "redux";

import {fetchRecommendedOffers} from "../../offer/detail/actions/fetch_recommended_offers";
import {fetchRecommendedProperties} from "../../property/actions/fetch_recommended_properties";

interface IParams {
    offerId: number | undefined | null;
    propertyId: number | undefined | null;
    numberOfRooms: number[] | undefined | null;
    excludedOfferIds: number[];
    filters?: Record<string, string>;
}

export const fetchRecommendations = (params: IParams) => async (dispatch: Dispatch) => {
    if (params.propertyId) {
        return fetchRecommendedProperties(params.propertyId, params.excludedOfferIds, params.filters || {})(dispatch);
    }

    if (params.offerId) {
        return fetchRecommendedOffers(params.offerId, params.numberOfRooms, params.excludedOfferIds)(dispatch);
    }

    return null;
};
