import {useState} from "react";
import {css, Theme} from "@emotion/react";

import {calculateRemSize} from "@pg-design/helpers-css";
import {borderRadius, onDesktop} from "@pg-design/helpers-css/";
import {Text} from "@pg-design/text-module";
import {useUserDevice} from "@pg-mono/user-device";

import {IApplication} from "../../../../../../application/types/IApplication";
import {IMultiLeadStepPayload} from "../../../../../../application/types/IMultiLeadStepPayload";
import {ISetMultiLeadSummaryStepPayload} from "../../../../../../application/types/ISetMultiLeadSummaryStepPayload";
import {ChatBotConversationItem} from "../../../../../../atoms/chat_bot/ChatBotConversationItem";
import {ChatBotDotsAnimation} from "../../../../../../atoms/chat_bot/ChatBotDotsAnimation";
import {ScrollToElement} from "../../../../../../atoms/ScrollToElement";
import {useGetOfferDetailApplicationQuery} from "../../../../../../offer/api/get_offer_detail";
import {useAppSelector} from "../../../../../../store/hooks";
import {useInitialLeadFormValues} from "../../../../../hooks/use_initial_lead_form_values";
import {useOpenedLeadModalRequiredParams} from "../../../../../hooks/use_lead_modal_required_params";
import {ILeadModalPriceConversationPrice} from "../../../../../types/ILeadModalPriceConversationPrice";
import {LeadModalPriceConversationConsultant} from "../LeadModalPriceConversationConsultant";
import {LeadModalPriceConversationChatLead} from "./lead_modal_price_conversation_chat_lead/LeadModalPriceConversationChatLead";
import {LeadModalPriceConversationChatRedirect} from "./LeadModalPriceConversationChatRedirect";

interface IProps {
    handleModalClose: (forceClose: boolean) => void;
    handleSetPrice: (priceRange: ILeadModalPriceConversationPrice) => void;
    handleSetMultiLeadStep: (payload: IMultiLeadStepPayload) => void;
    handleSetMultiLeadSummary: (payload: ISetMultiLeadSummaryStepPayload) => void;
    handleUpdateMainLeadResponse: (sentApplication: IApplication) => void;
}

export function LeadModalPriceConversationChat(props: IProps) {
    const {handleModalClose, handleSetPrice, handleSetMultiLeadStep, handleSetMultiLeadSummary, handleUpdateMainLeadResponse} = props;

    const {vendorId, offerId, propertyId} = useOpenedLeadModalRequiredParams("property");
    const isOpened = useAppSelector((state) => state.leadModal.isModalOpen);

    const {data: offerDetail} = useGetOfferDetailApplicationQuery(offerId);

    const initialLeadFormValues = useInitialLeadFormValues({
        realEstateIds: {
            vendorId,
            offerId,
            propertyId
        },
        leadData: offerDetail
            ? {
                  offer: {
                      detail: offerDetail
                  }
              }
            : null,
        resetValues: !isOpened
    });

    const [sentApplication, setSentApplication] = useState<null | IApplication>(null);
    const {isMobile} = useUserDevice();

    const handleSuccessfulApplication = async (application: IApplication, price: ILeadModalPriceConversationPrice) => {
        handleUpdateMainLeadResponse(application);

        setSentApplication(application);

        handleSetPrice(price);
    };

    return (
        <div css={conversation}>
            {!isMobile && <LeadModalPriceConversationConsultant />}
            <div css={chat}>
                <ChatBotConversationItem
                    css={sentApplication && rpMessage}
                    messages={[
                        "Dzień dobry! Cena tego mieszkania jest już dostępna. Aby ją teraz zobaczyć, uzupełnij swoje dane. Dzięki temu deweloper będzie mógł przekazać Ci wszystkie szczegóły dotyczące tej nieruchomości."
                    ]}
                    actions={
                        <>
                            {initialLeadFormValues && offerDetail && !sentApplication && (
                                <LeadModalPriceConversationChatLead
                                    initialValues={{
                                        name: initialLeadFormValues.name,
                                        email: initialLeadFormValues.email,
                                        phone: initialLeadFormValues.phone.number,
                                        new_property_notification_consent: initialLeadFormValues.new_property_notification_consent,
                                        financing_services: initialLeadFormValues.financing_services
                                    }}
                                    handleModalClose={handleModalClose}
                                    handleSuccess={handleSuccessfulApplication}
                                />
                            )}
                        </>
                    }
                    messageLoadingRender={
                        <ScrollToElement>
                            <ChatBotDotsAnimation />
                        </ScrollToElement>
                    }
                />
                {sentApplication && (
                    <>
                        <Text variant="info_txt_1" css={userMessage}>
                            {sentApplication.name}
                        </Text>
                        <Text variant="info_txt_1" css={userMessage}>
                            {sentApplication.email}
                        </Text>
                        <Text variant="info_txt_1" css={userMessage}>
                            {sentApplication.phone}
                        </Text>
                        <ChatBotConversationItem
                            css={sentApplication && rpMessage}
                            messages={[
                                "Dziękuje za podanie danych kontaktowych. Deweloper skontaktuje się możliwie jak najszybciej, aby przedstawić dokładną cenę nieruchomości.",
                                "Za chwilę zaprezentuję nieruchomości o podobnych parametrach w niedalekiej okolicy."
                            ]}
                            messageLoadingRender={
                                <ScrollToElement>
                                    <ChatBotDotsAnimation />
                                </ScrollToElement>
                            }
                            actions={
                                <LeadModalPriceConversationChatRedirect
                                    sentApplication={sentApplication}
                                    handleSetMultiLeadStep={handleSetMultiLeadStep}
                                    handleSetMultiLeadSummary={handleSetMultiLeadSummary}
                                />
                            }
                        />
                    </>
                )}
            </div>
        </div>
    );
}

// Styles
const conversation = css`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;

    ${onDesktop(css`
        padding: ${calculateRemSize(4)} ${calculateRemSize(4)} ${calculateRemSize(4)} 0;
    `)}
`;

const chat = (theme: Theme) => css`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    background: ${theme.colors.gray[300]};
    padding: ${calculateRemSize(2)};
    overflow-y: scroll;
`;

const rpMessage = css`
    padding: 0 ${calculateRemSize(4)} 0 0;
`;

const userMessage = (theme: Theme) => css`
    background: ${theme.colors.info};
    ${borderRadius(2)};
    padding: ${calculateRemSize(1)};
    align-self: flex-end;
    margin: 0 0 ${calculateRemSize(1)} 0;
`;
import {useState} from "react";
import {css, Theme} from "@emotion/react";

import {calculateRemSize} from "@pg-design/helpers-css";
import {borderRadius, onDesktop} from "@pg-design/helpers-css/";
import {Text} from "@pg-design/text-module";
import {useUserDevice} from "@pg-mono/user-device";

import {IApplication} from "../../../../../../application/types/IApplication";
import {IMultiLeadStepPayload} from "../../../../../../application/types/IMultiLeadStepPayload";
import {ISetMultiLeadSummaryStepPayload} from "../../../../../../application/types/ISetMultiLeadSummaryStepPayload";
import {ChatBotConversationItem} from "../../../../../../atoms/chat_bot/ChatBotConversationItem";
import {ChatBotDotsAnimation} from "../../../../../../atoms/chat_bot/ChatBotDotsAnimation";
import {ScrollToElement} from "../../../../../../atoms/ScrollToElement";
import {useGetOfferDetailApplicationQuery} from "../../../../../../offer/api/get_offer_detail";
import {useAppSelector} from "../../../../../../store/hooks";
import {useInitialLeadFormValues} from "../../../../../hooks/use_initial_lead_form_values";
import {useOpenedLeadModalRequiredParams} from "../../../../../hooks/use_lead_modal_required_params";
import {ILeadModalPriceConversationPrice} from "../../../../../types/ILeadModalPriceConversationPrice";
import {LeadModalPriceConversationConsultant} from "../LeadModalPriceConversationConsultant";
import {LeadModalPriceConversationChatLead} from "./lead_modal_price_conversation_chat_lead/LeadModalPriceConversationChatLead";
import {LeadModalPriceConversationChatRedirect} from "./LeadModalPriceConversationChatRedirect";

interface IProps {
    handleModalClose: (forceClose: boolean) => void;
    handleSetPrice: (priceRange: ILeadModalPriceConversationPrice) => void;
    handleSetMultiLeadStep: (payload: IMultiLeadStepPayload) => void;
    handleSetMultiLeadSummary: (payload: ISetMultiLeadSummaryStepPayload) => void;
    handleUpdateMainLeadResponse: (sentApplication: IApplication) => void;
}

export function LeadModalPriceConversationChat(props: IProps) {
    const {handleModalClose, handleSetPrice, handleSetMultiLeadStep, handleSetMultiLeadSummary, handleUpdateMainLeadResponse} = props;

    const {vendorId, offerId, propertyId} = useOpenedLeadModalRequiredParams("property");
    const isOpened = useAppSelector((state) => state.leadModal.isModalOpen);

    const {data: offerDetail} = useGetOfferDetailApplicationQuery(offerId);

    const initialLeadFormValues = useInitialLeadFormValues({
        realEstateIds: {
            vendorId,
            offerId,
            propertyId
        },
        leadData: offerDetail
            ? {
                  offer: {
                      detail: offerDetail
                  }
              }
            : null,
        resetValues: !isOpened
    });

    const [sentApplication, setSentApplication] = useState<null | IApplication>(null);
    const {isMobile} = useUserDevice();

    const handleSuccessfulApplication = async (application: IApplication, price: ILeadModalPriceConversationPrice) => {
        handleUpdateMainLeadResponse(application);

        setSentApplication(application);

        handleSetPrice(price);
    };

    return (
        <div css={conversation}>
            {!isMobile && <LeadModalPriceConversationConsultant />}
            <div css={chat}>
                <ChatBotConversationItem
                    css={sentApplication && rpMessage}
                    messages={[
                        "Dzień dobry! Cena tego mieszkania jest już dostępna. Aby ją teraz zobaczyć, uzupełnij swoje dane. Dzięki temu deweloper będzie mógł przekazać Ci wszystkie szczegóły dotyczące tej nieruchomości."
                    ]}
                    actions={
                        <>
                            {initialLeadFormValues && offerDetail && !sentApplication && (
                                <LeadModalPriceConversationChatLead
                                    initialValues={{
                                        name: initialLeadFormValues.name,
                                        email: initialLeadFormValues.email,
                                        phone: initialLeadFormValues.phone.number,
                                        new_property_notification_consent: initialLeadFormValues.new_property_notification_consent,
                                        financing_services: initialLeadFormValues.financing_services
                                    }}
                                    handleModalClose={handleModalClose}
                                    handleSuccess={handleSuccessfulApplication}
                                />
                            )}
                        </>
                    }
                    messageLoadingRender={
                        <ScrollToElement>
                            <ChatBotDotsAnimation />
                        </ScrollToElement>
                    }
                />
                {sentApplication && (
                    <>
                        <Text variant="info_txt_1" css={userMessage}>
                            {sentApplication.name}
                        </Text>
                        <Text variant="info_txt_1" css={userMessage}>
                            {sentApplication.email}
                        </Text>
                        <Text variant="info_txt_1" css={userMessage}>
                            {sentApplication.phone}
                        </Text>
                        <ChatBotConversationItem
                            css={sentApplication && rpMessage}
                            messages={[
                                "Dziękuje za podanie danych kontaktowych. Deweloper skontaktuje się możliwie jak najszybciej, aby przedstawić dokładną cenę nieruchomości.",
                                "Za chwilę zaprezentuję nieruchomości o podobnych parametrach w niedalekiej okolicy."
                            ]}
                            messageLoadingRender={
                                <ScrollToElement>
                                    <ChatBotDotsAnimation />
                                </ScrollToElement>
                            }
                            actions={
                                <LeadModalPriceConversationChatRedirect
                                    sentApplication={sentApplication}
                                    handleSetMultiLeadStep={handleSetMultiLeadStep}
                                    handleSetMultiLeadSummary={handleSetMultiLeadSummary}
                                />
                            }
                        />
                    </>
                )}
            </div>
        </div>
    );
}

// Styles
const conversation = css`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;

    ${onDesktop(css`
        padding: ${calculateRemSize(4)} ${calculateRemSize(4)} ${calculateRemSize(4)} 0;
    `)}
`;

const chat = (theme: Theme) => css`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    background: ${theme.colors.gray[300]};
    padding: ${calculateRemSize(2)};
    overflow-y: scroll;
`;

const rpMessage = css`
    padding: 0 ${calculateRemSize(4)} 0 0;
`;

const userMessage = (theme: Theme) => css`
    background: ${theme.colors.info};
    ${borderRadius(2)};
    padding: ${calculateRemSize(1)};
    align-self: flex-end;
    margin: 0 0 ${calculateRemSize(1)} 0;
`;
