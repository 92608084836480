import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const AirportIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M7.262 8.794H2.738V7.95l1.164-.901V6.013l-2.828.801V5.655l2.84-2.065V2.288a1.085 1.085 0 0 1 1.733-.864c.278.208.439.532.439.87V3.59l2.84 2.064v1.16l-2.828-.801v1.035l1.164.901v.844Zm-3.996-.528h3.468v-.057L5.57 7.308V5.315l2.828.801v-.192l-2.84-2.065V2.293a.557.557 0 0 0-.221-.442.559.559 0 0 0-.679-.002.558.558 0 0 0-.216.439v1.571l-2.84 2.065v.192l2.828-.801v1.993l-1.164.9v.058Z" />
        </SvgIcon>
    );
};
import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const AirportIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M7.262 8.794H2.738V7.95l1.164-.901V6.013l-2.828.801V5.655l2.84-2.065V2.288a1.085 1.085 0 0 1 1.733-.864c.278.208.439.532.439.87V3.59l2.84 2.064v1.16l-2.828-.801v1.035l1.164.901v.844Zm-3.996-.528h3.468v-.057L5.57 7.308V5.315l2.828.801v-.192l-2.84-2.065V2.293a.557.557 0 0 0-.221-.442.559.559 0 0 0-.679-.002.558.558 0 0 0-.216.439v1.571l-2.84 2.065v.192l2.828-.801v1.993l-1.164.9v.058Z" />
        </SvgIcon>
    );
};
