import {apiBigDataLink} from "@pg-mono/rp-api-routes";

import {bdApi} from "../../app/api/bd_api";
import {bigDataApiUrl} from "../../app/read_rp_environment_variables";
import {IMonthTypeStats} from "../types/IMonthTypeStats";
import {getMonthStatsPayloadDate} from "../utils/get_month_stats_payload_date";
import {IGetMonthDistrictTypeStatsPayload} from "./IGetMonthDistrictTypeStatsPayload";

export const getMonthDistrictTypeStatsApi = bdApi.injectEndpoints({
    endpoints: (builder) => ({
        getMonthDistrictCityTypeStats: builder.query<IMonthTypeStats[], IGetMonthDistrictTypeStatsPayload>({
            query: (params) => ({
                url: apiBigDataLink.monthDistrictTypeStats.base(bigDataApiUrl),
                params: {
                    ...params,
                    ...getMonthStatsPayloadDate(),
                    scenario: "rp-avg"
                }
            })
        })
    })
});

export const {getMonthDistrictCityTypeStats} = getMonthDistrictTypeStatsApi.endpoints;
export const {useGetMonthDistrictCityTypeStatsQuery} = getMonthDistrictTypeStatsApi;
import {apiBigDataLink} from "@pg-mono/rp-api-routes";

import {bdApi} from "../../app/api/bd_api";
import {bigDataApiUrl} from "../../app/read_rp_environment_variables";
import {IMonthTypeStats} from "../types/IMonthTypeStats";
import {getMonthStatsPayloadDate} from "../utils/get_month_stats_payload_date";
import {IGetMonthDistrictTypeStatsPayload} from "./IGetMonthDistrictTypeStatsPayload";

export const getMonthDistrictTypeStatsApi = bdApi.injectEndpoints({
    endpoints: (builder) => ({
        getMonthDistrictCityTypeStats: builder.query<IMonthTypeStats[], IGetMonthDistrictTypeStatsPayload>({
            query: (params) => ({
                url: apiBigDataLink.monthDistrictTypeStats.base(bigDataApiUrl),
                params: {
                    ...params,
                    ...getMonthStatsPayloadDate(),
                    scenario: "rp-avg"
                }
            })
        })
    })
});

export const {getMonthDistrictCityTypeStats} = getMonthDistrictTypeStatsApi.endpoints;
export const {useGetMonthDistrictCityTypeStatsQuery} = getMonthDistrictTypeStatsApi;
