import React from "react";
import classNames from "classnames";

import {IFormFieldProps} from "@pg-mono/formik-utils";

import {SelectGroupItem} from "./SelectGroupItem";

import * as styles from "./SelectGroup.module.css";

export type SelectGroupOptionValueTypes = string | number;

export type ISelectGroupOption<ValueType extends SelectGroupOptionValueTypes> = {
    value: ValueType;
    label: string;
    isActive?: boolean;
    isNonActive?: boolean;
    isDisabled?: boolean;
};

export type SelectGroupFormFieldProps<ValueType> = Omit<IFormFieldProps<string, ValueType>, "checked" | "onAfterChange">;

export interface ISelectGroupFieldProps<ValueType extends SelectGroupOptionValueTypes> extends SelectGroupFormFieldProps<ValueType> {
    className?: string;
    options: ISelectGroupOption<ValueType>[];
    id?: string;
    required?: boolean;
}

export const SelectGroupField = <ValueType extends SelectGroupOptionValueTypes>(props: ISelectGroupFieldProps<ValueType>) => {
    const cn = classNames(styles.checkboxList, styles.checkboxListField, props.className);

    return (
        <div className={cn}>
            {props.options.map((option) => (
                <SelectGroupItem
                    key={option.value}
                    name={props.name}
                    value={option.value}
                    onChange={props.onChange}
                    label={option.label}
                    isActive={option.isActive}
                    isNonActive={option.isNonActive}
                    isDisabled={option.isDisabled}
                />
            ))}
        </div>
    );
};
import React from "react";
import classNames from "classnames";

import {IFormFieldProps} from "@pg-mono/formik-utils";

import {SelectGroupItem} from "./SelectGroupItem";

import * as styles from "./SelectGroup.module.css";

export type SelectGroupOptionValueTypes = string | number;

export type ISelectGroupOption<ValueType extends SelectGroupOptionValueTypes> = {
    value: ValueType;
    label: string;
    isActive?: boolean;
    isNonActive?: boolean;
    isDisabled?: boolean;
};

export type SelectGroupFormFieldProps<ValueType> = Omit<IFormFieldProps<string, ValueType>, "checked" | "onAfterChange">;

export interface ISelectGroupFieldProps<ValueType extends SelectGroupOptionValueTypes> extends SelectGroupFormFieldProps<ValueType> {
    className?: string;
    options: ISelectGroupOption<ValueType>[];
    id?: string;
    required?: boolean;
}

export const SelectGroupField = <ValueType extends SelectGroupOptionValueTypes>(props: ISelectGroupFieldProps<ValueType>) => {
    const cn = classNames(styles.checkboxList, styles.checkboxListField, props.className);

    return (
        <div className={cn}>
            {props.options.map((option) => (
                <SelectGroupItem
                    key={option.value}
                    name={props.name}
                    value={option.value}
                    onChange={props.onChange}
                    label={option.label}
                    isActive={option.isActive}
                    isNonActive={option.isNonActive}
                    isDisabled={option.isDisabled}
                />
            ))}
        </div>
    );
};
