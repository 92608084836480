import {css} from "@emotion/react";

import animatedDotsIcon from "./img/animated-dots.svg";

export function ChatBotDotsAnimation() {
    return <object css={animation} type="image/svg+xml" data={animatedDotsIcon} />;
}

const animation = css`
    width: 6rem;
    height: 3.2rem;
`;
import {css} from "@emotion/react";

import animatedDotsIcon from "./img/animated-dots.svg";

export function ChatBotDotsAnimation() {
    return <object css={animation} type="image/svg+xml" data={animatedDotsIcon} />;
}

const animation = css`
    width: 6rem;
    height: 3.2rem;
`;
