import React from "react";
import {css, Theme} from "@emotion/react";

import {backgroundColor, flex, flexDirection, maxHeight, maxWidth, mb, mt, onDesktop, p, pv} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";

import {IArchitectListItem} from "../../types/IArchitectListItem";
import {HighlightedPriceFromTo} from "./HighlightedPriceFromTo";

export type IArchitectOfferSummary = Pick<IArchitectListItem, "name" | "min_price_m2"> & {
    offer: Pick<IArchitectListItem["offer"], "description">;
};

interface IProps {
    appliedList: IArchitectOfferSummary[];
}

export const MultileadArchitectSuccess = (props: IProps) => {
    return (
        <div css={applicationsWrapper}>
            <Text variant="body_copy_1" css={mb(2)}>
                Oczekuj na kontakt specjalistów z następujących firm:
            </Text>

            <div css={appliedList}>
                {props.appliedList.map((architect, index) => {
                    const desc = architect.offer?.description?.split(".");

                    return (
                        <div key={architect.name} css={[listElement, index === props.appliedList.length - 1 && hideBorder]}>
                            <div css={onDesktop(maxWidth("75%"))}>
                                <Text variant="body_copy_1" css={mb(0.5)}>
                                    {architect.name}
                                </Text>
                                <Text variant="info_txt_3">{`${desc[0]}.`}</Text>
                            </div>
                            {architect.min_price_m2 && <HighlightedPriceFromTo price={architect.min_price_m2} customStyle={[mt(1), onDesktop(mt(0))]} />}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

const applicationsWrapper = (theme: Theme) => css`
    ${backgroundColor(theme.colors.background[100])};
    ${p(2)};
    overflow-y: scroll;
    scrollbar-width: none;
    ${onDesktop(maxHeight("425px"))};
`;

const appliedList = css`
    ${flex()};
    ${flexDirection("column")};
`;

const listElement = (theme: Theme) => css`
    ${flex("start", "space-between")};
    ${flexDirection("column")};
    ${pv(1)};
    border-bottom: 1px solid ${theme.colors.gray[400]};

    ${onDesktop(flex("center", "space-between"), flexDirection("row"))};
`;

const hideBorder = css`
    border-color: transparent;
`;
import React from "react";
import {css, Theme} from "@emotion/react";

import {backgroundColor, flex, flexDirection, maxHeight, maxWidth, mb, mt, onDesktop, p, pv} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";

import {IArchitectListItem} from "../../types/IArchitectListItem";
import {HighlightedPriceFromTo} from "./HighlightedPriceFromTo";

export type IArchitectOfferSummary = Pick<IArchitectListItem, "name" | "min_price_m2"> & {
    offer: Pick<IArchitectListItem["offer"], "description">;
};

interface IProps {
    appliedList: IArchitectOfferSummary[];
}

export const MultileadArchitectSuccess = (props: IProps) => {
    return (
        <div css={applicationsWrapper}>
            <Text variant="body_copy_1" css={mb(2)}>
                Oczekuj na kontakt specjalistów z następujących firm:
            </Text>

            <div css={appliedList}>
                {props.appliedList.map((architect, index) => {
                    const desc = architect.offer?.description?.split(".");

                    return (
                        <div key={architect.name} css={[listElement, index === props.appliedList.length - 1 && hideBorder]}>
                            <div css={onDesktop(maxWidth("75%"))}>
                                <Text variant="body_copy_1" css={mb(0.5)}>
                                    {architect.name}
                                </Text>
                                <Text variant="info_txt_3">{`${desc[0]}.`}</Text>
                            </div>
                            {architect.min_price_m2 && <HighlightedPriceFromTo price={architect.min_price_m2} customStyle={[mt(1), onDesktop(mt(0))]} />}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

const applicationsWrapper = (theme: Theme) => css`
    ${backgroundColor(theme.colors.background[100])};
    ${p(2)};
    overflow-y: scroll;
    scrollbar-width: none;
    ${onDesktop(maxHeight("425px"))};
`;

const appliedList = css`
    ${flex()};
    ${flexDirection("column")};
`;

const listElement = (theme: Theme) => css`
    ${flex("start", "space-between")};
    ${flexDirection("column")};
    ${pv(1)};
    border-bottom: 1px solid ${theme.colors.gray[400]};

    ${onDesktop(flex("center", "space-between"), flexDirection("row"))};
`;

const hideBorder = css`
    border-color: transparent;
`;
