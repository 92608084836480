import {apiLink} from "@pg-mono/rp-api-routes";

import {rpApi} from "../../app/api/rp_api";
import {IArchitectDetails} from "../types/IArchitectDetails";

export const readArchitectDetailsApi = rpApi.injectEndpoints({
    endpoints: (builder) => ({
        readArchitectDetails: builder.query<IArchitectDetails, number>({
            query: (architectId) => {
                return {
                    url: apiLink.marketplace.architect.detail({})({architectId})
                };
            }
        })
    })
});

export const {useReadArchitectDetailsQuery} = readArchitectDetailsApi;
import {apiLink} from "@pg-mono/rp-api-routes";

import {rpApi} from "../../app/api/rp_api";
import {IArchitectDetails} from "../types/IArchitectDetails";

export const readArchitectDetailsApi = rpApi.injectEndpoints({
    endpoints: (builder) => ({
        readArchitectDetails: builder.query<IArchitectDetails, number>({
            query: (architectId) => {
                return {
                    url: apiLink.marketplace.architect.detail({})({architectId})
                };
            }
        })
    })
});

export const {useReadArchitectDetailsQuery} = readArchitectDetailsApi;
