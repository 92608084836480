import React from "react";
import {css, useTheme} from "@emotion/react";

import {calculateRemSize, flexAbsoluteCenter, onDesktop, p} from "@pg-design/helpers-css";
import {HotIcon} from "@pg-design/icons-module";
import {Text} from "@pg-design/text-module";

export const ApplicationOfferSelectionHeader = () => {
    const theme = useTheme();

    return (
        <div css={wrapperStyle}>
            <div css={iconWrapperStyle}>
                <HotIcon size="3.2" wrapperSize="3.2" wrapperColor={theme.colors.primary} />
            </div>
            <div>
                <Text variant="body_copy_2" mb="0.4rem">
                    Poniżej znajdziesz oglądane przez Ciebie oferty.
                </Text>
                <Text variant="info_txt_2" color={theme.colors.gray[700]}>
                    Zaznacz, jeżeli chcesz, aby poniżsi deweloperzy skontaktowali się z Tobą w celu przedstawienia oferty.
                </Text>
            </div>
        </div>
    );
};

const wrapperStyle = css`
    ${p(2, 0)};
    display: flex;
    flex-direction: row;
    gap: ${calculateRemSize(1)};

    ${onDesktop(css`
        ${p(2, 2, 2, 1)};
    `)};
`;

const iconWrapperStyle = css`
    ${flexAbsoluteCenter};
    width: 3.2rem;
    height: 3.2rem;
`;
import React from "react";
import {css, useTheme} from "@emotion/react";

import {calculateRemSize, flexAbsoluteCenter, onDesktop, p} from "@pg-design/helpers-css";
import {HotIcon} from "@pg-design/icons-module";
import {Text} from "@pg-design/text-module";

export const ApplicationOfferSelectionHeader = () => {
    const theme = useTheme();

    return (
        <div css={wrapperStyle}>
            <div css={iconWrapperStyle}>
                <HotIcon size="3.2" wrapperSize="3.2" wrapperColor={theme.colors.primary} />
            </div>
            <div>
                <Text variant="body_copy_2" mb="0.4rem">
                    Poniżej znajdziesz oglądane przez Ciebie oferty.
                </Text>
                <Text variant="info_txt_2" color={theme.colors.gray[700]}>
                    Zaznacz, jeżeli chcesz, aby poniżsi deweloperzy skontaktowali się z Tobą w celu przedstawienia oferty.
                </Text>
            </div>
        </div>
    );
};

const wrapperStyle = css`
    ${p(2, 0)};
    display: flex;
    flex-direction: row;
    gap: ${calculateRemSize(1)};

    ${onDesktop(css`
        ${p(2, 2, 2, 1)};
    `)};
`;

const iconWrapperStyle = css`
    ${flexAbsoluteCenter};
    width: 3.2rem;
    height: 3.2rem;
`;
