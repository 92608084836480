import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const DisLikeIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.667 8.13728L3.667 6.80028L1 6.80028L1 6.26728L4.2 6.26728L4.2 8.41228C4.2 8.48236 4.21382 8.55175 4.24067 8.61648C4.26752 8.68122 4.30687 8.74002 4.35647 8.78953C4.40607 8.83903 4.46494 8.87827 4.52972 8.905C4.5945 8.93173 4.66392 8.94541 4.734 8.94528L4.734 7.87928L4.772 7.79128L4.956 7.36728L5.778 5.46728L6.333 5.46728L6.333 2.53409L1.53301 2.53409L1.53301 3.06709L2.82301 3.06709L2.82301 3.60009L1.53301 3.60009L1.53301 4.13409L2.82301 4.13409L2.82301 4.66709L1.53301 4.66709L1.53301 5.20009L2.82301 5.20009L2.82301 5.73409L1.53301 5.73409L1.53301 6.26709L1 6.26728L1.00001 2.00009L9 2.00028L9 6.26728L6.327 6.26728L6.327 6.00028L6.127 6.00028L5.268 7.98928L5.268 9.30428C5.10774 9.40532 4.92336 9.4616 4.734 9.46728L4.7 9.46728C4.46284 9.46756 4.23238 9.3886 4.04523 9.24294C3.85808 9.09727 3.72495 8.89325 3.667 8.66328C3.62166 8.49088 3.62166 8.30969 3.667 8.13728ZM8.467 2.53428L6.867 2.53428L6.867 5.73428L8.467 5.73428L8.467 2.53428Z"
            />
        </SvgIcon>
    );
};
import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const DisLikeIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.667 8.13728L3.667 6.80028L1 6.80028L1 6.26728L4.2 6.26728L4.2 8.41228C4.2 8.48236 4.21382 8.55175 4.24067 8.61648C4.26752 8.68122 4.30687 8.74002 4.35647 8.78953C4.40607 8.83903 4.46494 8.87827 4.52972 8.905C4.5945 8.93173 4.66392 8.94541 4.734 8.94528L4.734 7.87928L4.772 7.79128L4.956 7.36728L5.778 5.46728L6.333 5.46728L6.333 2.53409L1.53301 2.53409L1.53301 3.06709L2.82301 3.06709L2.82301 3.60009L1.53301 3.60009L1.53301 4.13409L2.82301 4.13409L2.82301 4.66709L1.53301 4.66709L1.53301 5.20009L2.82301 5.20009L2.82301 5.73409L1.53301 5.73409L1.53301 6.26709L1 6.26728L1.00001 2.00009L9 2.00028L9 6.26728L6.327 6.26728L6.327 6.00028L6.127 6.00028L5.268 7.98928L5.268 9.30428C5.10774 9.40532 4.92336 9.4616 4.734 9.46728L4.7 9.46728C4.46284 9.46756 4.23238 9.3886 4.04523 9.24294C3.85808 9.09727 3.72495 8.89325 3.667 8.66328C3.62166 8.49088 3.62166 8.30969 3.667 8.13728ZM8.467 2.53428L6.867 2.53428L6.867 5.73428L8.467 5.73428L8.467 2.53428Z"
            />
        </SvgIcon>
    );
};
