import React from "react";
import {css, Theme} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {calculateRemSize, flex, flexAbsoluteCenter, onDesktop, p} from "@pg-design/helpers-css";
import {BrandWeightIcon} from "@pg-design/icons-module";
import {Text} from "@pg-design/text-module";
import {kmAppLink} from "@pg-mono/km-routes";

import {AdditionalOfferCategoryGTMEventAction, multiLeadAdditionalOfferAnalytics} from "../../../application/tracking/multilead_additional_offers_tracking";
import {getFullKMLink} from "../../../utils/get_full_km_link";

interface IProps {
    className?: string;
}

export const KMPromoBoxHorizontal = (props: IProps) => {
    const onClick = () => {
        multiLeadAdditionalOfferAnalytics.gtm.multiLeadAdditionalOfferEvent(AdditionalOfferCategoryGTMEventAction.KM);
        window.location.href = getFullKMLink(kmAppLink.site.offers.appliedWithParams);
    };

    return (
        <div css={wrapperStyle} className={props.className}>
            <div css={infoColumnWrapperStyle}>
                <div css={iconWrapperStyle}>
                    <BrandWeightIcon size="14" />
                </div>
                <div css={textWrapperStyle}>
                    <Text variant="headline_5" mb="0.8rem">
                        Porównaj oferty
                    </Text>
                    <Text variant="info_txt_1">Teraz możesz wygodnie porównać estymowane ceny i wszystkie parametry ofert, o które zapytałeś.</Text>
                </div>
            </div>
            <Button onClick={onClick} variant="filled_secondary" css={buttonStyle}>
                Porównaj
            </Button>
        </div>
    );
};

const wrapperStyle = (theme: Theme) => css`
    ${p(1)};
    background-color: ${theme.colors.primary};
    ${flex("center", "flex-start")};
    flex-direction: column;
    gap: ${calculateRemSize(4)};

    ${onDesktop(css`
        ${p(1, 3)};
        flex-direction: row;
        gap: ${calculateRemSize(3)};
    `)};
`;

const infoColumnWrapperStyle = css`
    ${flex("center", "flex-start")};
    flex-direction: row;
    gap: ${calculateRemSize(1)};

    ${onDesktop(css`
        gap: ${calculateRemSize(3)};
    `)};
`;

const iconWrapperStyle = css`
    ${flexAbsoluteCenter};
    flex: 0 0 auto;
    height: 11.5rem;
    width: 11rem;
`;

const textWrapperStyle = css`
    ${flex("flex-start", "center")};
    flex-direction: column;
    flex: 1 1 100%;
    width: 100%;
`;

const buttonStyle = css`
    width: 100%;
    flex: 0 0 auto;

    ${onDesktop(css`
        width: 25rem;
    `)};
`;
import React from "react";
import {css, Theme} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {calculateRemSize, flex, flexAbsoluteCenter, onDesktop, p} from "@pg-design/helpers-css";
import {BrandWeightIcon} from "@pg-design/icons-module";
import {Text} from "@pg-design/text-module";
import {kmAppLink} from "@pg-mono/km-routes";

import {AdditionalOfferCategoryGTMEventAction, multiLeadAdditionalOfferAnalytics} from "../../../application/tracking/multilead_additional_offers_tracking";
import {getFullKMLink} from "../../../utils/get_full_km_link";

interface IProps {
    className?: string;
}

export const KMPromoBoxHorizontal = (props: IProps) => {
    const onClick = () => {
        multiLeadAdditionalOfferAnalytics.gtm.multiLeadAdditionalOfferEvent(AdditionalOfferCategoryGTMEventAction.KM);
        window.location.href = getFullKMLink(kmAppLink.site.offers.appliedWithParams);
    };

    return (
        <div css={wrapperStyle} className={props.className}>
            <div css={infoColumnWrapperStyle}>
                <div css={iconWrapperStyle}>
                    <BrandWeightIcon size="14" />
                </div>
                <div css={textWrapperStyle}>
                    <Text variant="headline_5" mb="0.8rem">
                        Porównaj oferty
                    </Text>
                    <Text variant="info_txt_1">Teraz możesz wygodnie porównać estymowane ceny i wszystkie parametry ofert, o które zapytałeś.</Text>
                </div>
            </div>
            <Button onClick={onClick} variant="filled_secondary" css={buttonStyle}>
                Porównaj
            </Button>
        </div>
    );
};

const wrapperStyle = (theme: Theme) => css`
    ${p(1)};
    background-color: ${theme.colors.primary};
    ${flex("center", "flex-start")};
    flex-direction: column;
    gap: ${calculateRemSize(4)};

    ${onDesktop(css`
        ${p(1, 3)};
        flex-direction: row;
        gap: ${calculateRemSize(3)};
    `)};
`;

const infoColumnWrapperStyle = css`
    ${flex("center", "flex-start")};
    flex-direction: row;
    gap: ${calculateRemSize(1)};

    ${onDesktop(css`
        gap: ${calculateRemSize(3)};
    `)};
`;

const iconWrapperStyle = css`
    ${flexAbsoluteCenter};
    flex: 0 0 auto;
    height: 11.5rem;
    width: 11rem;
`;

const textWrapperStyle = css`
    ${flex("flex-start", "center")};
    flex-direction: column;
    flex: 1 1 100%;
    width: 100%;
`;

const buttonStyle = css`
    width: 100%;
    flex: 0 0 auto;

    ${onDesktop(css`
        width: 25rem;
    `)};
`;
