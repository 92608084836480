import {getStorageSentApplicationsIdListByOffer} from "../../application/utils/get_storage_sent_applications_id_list_by_offer";
import {IOfferDetailApplication} from "../../offer/types/IOfferDetailApplication";
import {fetchRecommendedProperties} from "../../property/actions/fetch_recommended_properties";
import {IPropertyDetailApplication} from "../../property/types/IPropertyDetailApplication";
import {IAppDispatch} from "../../store/store";

interface IParams {
    dispatch: IAppDispatch;
    offerDetail: IOfferDetailApplication;
    propertyDetail: IPropertyDetailApplication;
    distanceInKm: number;
    maxPrice?: number;
}

export async function getLeadModalPriceConversationRecommendations(params: IParams) {
    const {dispatch, offerDetail, propertyDetail, distanceInKm, maxPrice} = params;

    const sentApplicationIds = getStorageSentApplicationsIdListByOffer(offerDetail, {distance: distanceInKm * 1000});

    const recommendationsPayload: {
        distance: number;
        max_price?: number;
    } = {
        distance: distanceInKm
    };

    if (maxPrice) {
        recommendationsPayload.max_price = maxPrice;
    }

    return await dispatch(fetchRecommendedProperties(propertyDetail.id, sentApplicationIds, recommendationsPayload));
}
import {getStorageSentApplicationsIdListByOffer} from "../../application/utils/get_storage_sent_applications_id_list_by_offer";
import {IOfferDetailApplication} from "../../offer/types/IOfferDetailApplication";
import {fetchRecommendedProperties} from "../../property/actions/fetch_recommended_properties";
import {IPropertyDetailApplication} from "../../property/types/IPropertyDetailApplication";
import {IAppDispatch} from "../../store/store";

interface IParams {
    dispatch: IAppDispatch;
    offerDetail: IOfferDetailApplication;
    propertyDetail: IPropertyDetailApplication;
    distanceInKm: number;
    maxPrice?: number;
}

export async function getLeadModalPriceConversationRecommendations(params: IParams) {
    const {dispatch, offerDetail, propertyDetail, distanceInKm, maxPrice} = params;

    const sentApplicationIds = getStorageSentApplicationsIdListByOffer(offerDetail, {distance: distanceInKm * 1000});

    const recommendationsPayload: {
        distance: number;
        max_price?: number;
    } = {
        distance: distanceInKm
    };

    if (maxPrice) {
        recommendationsPayload.max_price = maxPrice;
    }

    return await dispatch(fetchRecommendedProperties(propertyDetail.id, sentApplicationIds, recommendationsPayload));
}
