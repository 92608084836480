import {ILeadModalLeadType} from "../../lead/types/ILeadModalLeadType";
import {hitGoogleTagManager} from "./init_google_tag_manager";

export const gtmStepApplicationClose = (leadType: ILeadModalLeadType) => {
    hitGoogleTagManager({event: "step_form_close", event_category: leadType === "conversation" ? "conversation_form" : "standard"});

    return true;
};
import {ILeadModalLeadType} from "../../lead/types/ILeadModalLeadType";
import {hitGoogleTagManager} from "./init_google_tag_manager";

export const gtmStepApplicationClose = (leadType: ILeadModalLeadType) => {
    hitGoogleTagManager({event: "step_form_close", event_category: leadType === "conversation" ? "conversation_form" : "standard"});

    return true;
};
