import React from "react";

import {IOpenStreetMapProps} from "./OpenStreetMap";

const OpenStreetMap = React.lazy(() => import(/* webpackChunkName: "lazy_open_street_map_chunk" */ "./OpenStreetMap"));

export const LazyOpenStreetMap = (props: IOpenStreetMapProps) => {
    return <OpenStreetMap {...props} />;
};
import React from "react";

import {IOpenStreetMapProps} from "./OpenStreetMap";

const OpenStreetMap = React.lazy(() => import(/* webpackChunkName: "lazy_open_street_map_chunk" */ "./OpenStreetMap"));

export const LazyOpenStreetMap = (props: IOpenStreetMapProps) => {
    return <OpenStreetMap {...props} />;
};
