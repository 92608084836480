import {getThemeVariable} from "@pg-design/styles-strings";

import {IMessageType} from "../types/IMessageType";

export function getMessageColor(type?: IMessageType) {
    if (type === "action") {
        return getThemeVariable("colors.primary");
    }

    if (type === "success") {
        return getThemeVariable("colors.success");
    }

    if (type === "warning") {
        return getThemeVariable("colors.warning");
    }

    if (type === "failure") {
        return getThemeVariable("colors.secondary");
    }

    if (type === "info") {
        return getThemeVariable("colors.info");
    }

    return "white";
}
import {getThemeVariable} from "@pg-design/styles-strings";

import {IMessageType} from "../types/IMessageType";

export function getMessageColor(type?: IMessageType) {
    if (type === "action") {
        return getThemeVariable("colors.primary");
    }

    if (type === "success") {
        return getThemeVariable("colors.success");
    }

    if (type === "warning") {
        return getThemeVariable("colors.warning");
    }

    if (type === "failure") {
        return getThemeVariable("colors.secondary");
    }

    if (type === "info") {
        return getThemeVariable("colors.info");
    }

    return "white";
}
