import {IRecommendedProperty} from "../../recommendations/types/IRecommendedProperty";
import {IPropertyDetail} from "../types/IPropertyDetail";

export const transformPropertyDetailToRecommendedPropertyPayload = (propertyDetail: IPropertyDetail): IRecommendedProperty => {
    return {
        id: propertyDetail.id,
        area: propertyDetail.area,
        floor: propertyDetail.floor,
        is_luxury: propertyDetail.is_luxury,
        rooms: propertyDetail.rooms,
        number: propertyDetail.number,
        distance: 0,
        price: propertyDetail.price,
        offer: {
            id: propertyDetail.offer.id,
            geo_point: {
                type: "Point",
                coordinates: propertyDetail.offer.geo_point.coordinates
            },
            construction_date_range: propertyDetail.offer.construction_date_range,
            name: propertyDetail.offer.name,
            main_image: {
                m_img_155x87: propertyDetail.offer.main_image?.m_img_155x87,
                m_img_224x147: propertyDetail.offer.main_image?.m_img_224x147,
                m_img_350x230: propertyDetail.offer.main_image?.m_img_350x230
            },
            type: propertyDetail.offer.type,
            address: propertyDetail.offer.address,
            configuration: {
                is_autoresponder_enabled: propertyDetail.offer.configuration.is_autoresponder_enabled,
                privacy_policy_url: propertyDetail.offer.configuration.privacy_policy_url || "",
                personal_data_processor_url: propertyDetail.offer.configuration.personal_data_processor_url,
                personal_data_processor_name: propertyDetail.offer.configuration.personal_data_processor_name || "",
                hide_properties_prices_for_anonymous_users: propertyDetail.offer.configuration.hide_properties_prices_for_anonymous_users,
                revealed_prices_enabled: propertyDetail.offer.configuration.revealed_prices_enabled
            },
            vendor: {
                id: propertyDetail.offer.vendor.id,
                name: propertyDetail.offer.vendor.name,
                logo: {
                    v_log_80x60: propertyDetail.offer.vendor.logo?.v_log_80x60 || ""
                }
            }
        },
        region: {
            country: propertyDetail.offer.region.country,
            id: propertyDetail.offer.region.id,
            type: propertyDetail.offer.region.type,
            parent: undefined // warning! parent may be important to properly handle abroad country, especially if recommended are picked by parent region
        }
    };
};
import {IRecommendedProperty} from "../../recommendations/types/IRecommendedProperty";
import {IPropertyDetail} from "../types/IPropertyDetail";

export const transformPropertyDetailToRecommendedPropertyPayload = (propertyDetail: IPropertyDetail): IRecommendedProperty => {
    return {
        id: propertyDetail.id,
        area: propertyDetail.area,
        floor: propertyDetail.floor,
        is_luxury: propertyDetail.is_luxury,
        rooms: propertyDetail.rooms,
        number: propertyDetail.number,
        distance: 0,
        price: propertyDetail.price,
        offer: {
            id: propertyDetail.offer.id,
            geo_point: {
                type: "Point",
                coordinates: propertyDetail.offer.geo_point.coordinates
            },
            construction_date_range: propertyDetail.offer.construction_date_range,
            name: propertyDetail.offer.name,
            main_image: {
                m_img_155x87: propertyDetail.offer.main_image?.m_img_155x87,
                m_img_224x147: propertyDetail.offer.main_image?.m_img_224x147,
                m_img_350x230: propertyDetail.offer.main_image?.m_img_350x230
            },
            type: propertyDetail.offer.type,
            address: propertyDetail.offer.address,
            configuration: {
                is_autoresponder_enabled: propertyDetail.offer.configuration.is_autoresponder_enabled,
                privacy_policy_url: propertyDetail.offer.configuration.privacy_policy_url || "",
                personal_data_processor_url: propertyDetail.offer.configuration.personal_data_processor_url,
                personal_data_processor_name: propertyDetail.offer.configuration.personal_data_processor_name || "",
                hide_properties_prices_for_anonymous_users: propertyDetail.offer.configuration.hide_properties_prices_for_anonymous_users,
                revealed_prices_enabled: propertyDetail.offer.configuration.revealed_prices_enabled
            },
            vendor: {
                id: propertyDetail.offer.vendor.id,
                name: propertyDetail.offer.vendor.name,
                logo: {
                    v_log_80x60: propertyDetail.offer.vendor.logo?.v_log_80x60 || ""
                }
            }
        },
        region: {
            country: propertyDetail.offer.region.country,
            id: propertyDetail.offer.region.id,
            type: propertyDetail.offer.region.type,
            parent: undefined // warning! parent may be important to properly handle abroad country, especially if recommended are picked by parent region
        }
    };
};
