import {hitAlgolytics} from "@pg-mono/algolytics";

export function hitMultileadSendButton() {
    hitAlgolytics("multilead_send_button");
}
import {hitAlgolytics} from "@pg-mono/algolytics";

export function hitMultileadSendButton() {
    hitAlgolytics("multilead_send_button");
}
