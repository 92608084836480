import {pluralize} from "@pg-mono/string-utils";

export const applicationPluralize = pluralize(["zapytanie", "zapytania", "zapytań"]);

export const formatApplicationCount = (count: number) => {
    return `${count} ${applicationPluralize(count)}`;
};
import {pluralize} from "@pg-mono/string-utils";

export const applicationPluralize = pluralize(["zapytanie", "zapytania", "zapytań"]);

export const formatApplicationCount = (count: number) => {
    return `${count} ${applicationPluralize(count)}`;
};
