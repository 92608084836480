//  Based on SelectRoomsInquiry.tsx
import React from "react";
import {css} from "@emotion/react";

import {FormikForm} from "@pg-mono/formik-utils";

import {useApplicationFormContext} from "../ApplicationFormContext";
import {FieldLabel} from "../components/FieldLabel";

interface IProps {
    label?: string;
    required?: boolean;
    onFormFieldClick?: () => void;
    errorOnBottom?: boolean;
    className?: string;
}

export const SelectRooms = (props: IProps) => {
    const {label, required, onFormFieldClick, className} = props;
    const {offer} = useApplicationFormContext();

    if (offer && offer.stats) {
        const availableRooms = offer.stats.rooms;

        const roomOptions = [
            {value: 1, label: "1"},
            {value: 2, label: "2"},
            {value: 3, label: "3"},
            {value: 4, label: "4"},
            {value: 5, label: "5+"}
        ];

        return (
            <div css={selectRoomsWrapperStyle} className={className}>
                {label && (
                    <FieldLabel>
                        {label} {required && <span data-testid="rooms-inquiry-required-asterisk-rooms">*</span>}
                    </FieldLabel>
                )}

                <div onClick={onFormFieldClick}>
                    <FormikForm.SelectGroupMultiField name="rooms" options={roomOptions} availableOptions={availableRooms} />
                </div>
            </div>
        );
    }

    return null;
};

const selectRoomsWrapperStyle = css`
    display: flex;
    flex-direction: column;
`;
//  Based on SelectRoomsInquiry.tsx
import React from "react";
import {css} from "@emotion/react";

import {FormikForm} from "@pg-mono/formik-utils";

import {useApplicationFormContext} from "../ApplicationFormContext";
import {FieldLabel} from "../components/FieldLabel";

interface IProps {
    label?: string;
    required?: boolean;
    onFormFieldClick?: () => void;
    errorOnBottom?: boolean;
    className?: string;
}

export const SelectRooms = (props: IProps) => {
    const {label, required, onFormFieldClick, className} = props;
    const {offer} = useApplicationFormContext();

    if (offer && offer.stats) {
        const availableRooms = offer.stats.rooms;

        const roomOptions = [
            {value: 1, label: "1"},
            {value: 2, label: "2"},
            {value: 3, label: "3"},
            {value: 4, label: "4"},
            {value: 5, label: "5+"}
        ];

        return (
            <div css={selectRoomsWrapperStyle} className={className}>
                {label && (
                    <FieldLabel>
                        {label} {required && <span data-testid="rooms-inquiry-required-asterisk-rooms">*</span>}
                    </FieldLabel>
                )}

                <div onClick={onFormFieldClick}>
                    <FormikForm.SelectGroupMultiField name="rooms" options={roomOptions} availableOptions={availableRooms} />
                </div>
            </div>
        );
    }

    return null;
};

const selectRoomsWrapperStyle = css`
    display: flex;
    flex-direction: column;
`;
