import {Dispatch} from "redux";

import {catch404} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";

import {IOfferDetailApplication} from "../../offer/types/IOfferDetailApplication";
import {IPropertyDetailApplication} from "../../property/types/IPropertyDetailApplication";
import {IVendorDetailApplication} from "../../vendor/types/IVendorDetailApplication";
import {fetchApplicationOfferDetail} from "./fetch_application_offer_detail";
import {fetchApplicationPropertyDetail, fetchApplicationPropertyDetailTypes} from "./fetch_application_property_detail";
import {fetchApplicationVendorDetail} from "./fetch_application_vendor_detail";

/**
 * Application related data
 */

type IApplicationRelatedDataPromise<T> = [Promise<IVendorDetailApplication | T>, Promise<IOfferDetailApplication | T>, Promise<IPropertyDetailApplication | T>];

const LEAD_DATA_PREFIX = `desktop/application/lead_data`;

export const fetchApplicationRelatedDataTypes = {
    ...createRequestActionTypes(LEAD_DATA_PREFIX),
    initialize: `${LEAD_DATA_PREFIX}_INITIALIZE`
};

export const fetchApplicationRelatedData = (storeHash: string, vendorId?: number, offerId?: number, propertyId?: number) => async (dispatch: Dispatch) => {
    dispatch({
        type: fetchApplicationRelatedDataTypes.start,
        id: storeHash
    });

    const promiseArray: IApplicationRelatedDataPromise<void> = [
        vendorId ? (dispatch(fetchApplicationVendorDetail(vendorId, storeHash)) as unknown as Promise<IVendorDetailApplication | void>) : Promise.resolve(),
        offerId ? (dispatch(fetchApplicationOfferDetail(offerId, storeHash)) as unknown as Promise<IOfferDetailApplication | void>) : Promise.resolve(),
        propertyId
            ? (dispatch(fetchApplicationPropertyDetail(propertyId, storeHash)) as unknown as Promise<IPropertyDetailApplication | void>)
            : Promise.resolve(undefined)
    ];

    return Promise.all(promiseArray as IApplicationRelatedDataPromise<undefined>)
        .then(() => {
            dispatch({
                type: fetchApplicationRelatedDataTypes.success,
                id: storeHash
            });
        })
        .catch(
            catch404(() => {
                dispatch({
                    type: fetchApplicationPropertyDetailTypes.error,
                    id: storeHash
                });
            })
        );
};

export const initializeApplicationRelatedDataAction = (storeHash: string) => {
    return {type: fetchApplicationRelatedDataTypes.initialize, id: storeHash};
};
import {Dispatch} from "redux";

import {catch404} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";

import {IOfferDetailApplication} from "../../offer/types/IOfferDetailApplication";
import {IPropertyDetailApplication} from "../../property/types/IPropertyDetailApplication";
import {IVendorDetailApplication} from "../../vendor/types/IVendorDetailApplication";
import {fetchApplicationOfferDetail} from "./fetch_application_offer_detail";
import {fetchApplicationPropertyDetail, fetchApplicationPropertyDetailTypes} from "./fetch_application_property_detail";
import {fetchApplicationVendorDetail} from "./fetch_application_vendor_detail";

/**
 * Application related data
 */

type IApplicationRelatedDataPromise<T> = [Promise<IVendorDetailApplication | T>, Promise<IOfferDetailApplication | T>, Promise<IPropertyDetailApplication | T>];

const LEAD_DATA_PREFIX = `desktop/application/lead_data`;

export const fetchApplicationRelatedDataTypes = {
    ...createRequestActionTypes(LEAD_DATA_PREFIX),
    initialize: `${LEAD_DATA_PREFIX}_INITIALIZE`
};

export const fetchApplicationRelatedData = (storeHash: string, vendorId?: number, offerId?: number, propertyId?: number) => async (dispatch: Dispatch) => {
    dispatch({
        type: fetchApplicationRelatedDataTypes.start,
        id: storeHash
    });

    const promiseArray: IApplicationRelatedDataPromise<void> = [
        vendorId ? (dispatch(fetchApplicationVendorDetail(vendorId, storeHash)) as unknown as Promise<IVendorDetailApplication | void>) : Promise.resolve(),
        offerId ? (dispatch(fetchApplicationOfferDetail(offerId, storeHash)) as unknown as Promise<IOfferDetailApplication | void>) : Promise.resolve(),
        propertyId
            ? (dispatch(fetchApplicationPropertyDetail(propertyId, storeHash)) as unknown as Promise<IPropertyDetailApplication | void>)
            : Promise.resolve(undefined)
    ];

    return Promise.all(promiseArray as IApplicationRelatedDataPromise<undefined>)
        .then(() => {
            dispatch({
                type: fetchApplicationRelatedDataTypes.success,
                id: storeHash
            });
        })
        .catch(
            catch404(() => {
                dispatch({
                    type: fetchApplicationPropertyDetailTypes.error,
                    id: storeHash
                });
            })
        );
};

export const initializeApplicationRelatedDataAction = (storeHash: string) => {
    return {type: fetchApplicationRelatedDataTypes.initialize, id: storeHash};
};
