import React from "react";
import {css} from "@emotion/react";
import * as Yup from "yup";

import {h100, maxWidth, mb, mt, onDesktop, pb, position, w100, zIndex} from "@pg-design/helpers-css";
import {FormikForm} from "@pg-mono/formik-utils";

import {IArchitectUserLeadData} from "../../types/IArchitectUserLeadData";

const validationSchema = Yup.object({
    name: Yup.string().required(),
    email: Yup.string().required().email(),
    phone: Yup.number().integer("Podaj poprawny numer").required().typeError("Podaj poprawny numer")
});

interface IProps {
    leadData: IArchitectUserLeadData;
    onLeadDataChange: (values: IArchitectUserLeadData) => void;
}

export const MultiLeadArchitectUserDataForm = (props: IProps) => {
    const initialValues = {name: props.leadData.name, email: props.leadData.email, phone: props.leadData.phone, area: ""};

    const onDataChange = (values: IArchitectUserLeadData) => {
        props.onLeadDataChange(values);
    };

    return (
        <div css={dataFormStyle}>
            <FormikForm onSubmit={onDataChange} initialValues={initialValues} validationSchema={validationSchema}>
                <FormikForm.Input name="name" placeholder="Imię i nazwisko" css={mb(2)} />
                <FormikForm.Input name="email" placeholder="E-mail" disabled={!!initialValues.email} css={mb(2)} />
                <FormikForm.Input name="phone" placeholder="Telefon" css={mb(2)} />
                <FormikForm.Button variant="filled_primary" css={btnStyle}>
                    Zatwierdź
                </FormikForm.Button>
            </FormikForm>
        </div>
    );
};

const dataFormStyle = css`
    ${h100};
    ${position("relative")};
    ${pb(9)};
`;

const btnStyle = css`
    ${w100};
    ${position("absolute")};
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    ${mt(2)};
    ${zIndex(5)};

    ${onDesktop(maxWidth("200px"))};
`;
import React from "react";
import {css} from "@emotion/react";
import * as Yup from "yup";

import {h100, maxWidth, mb, mt, onDesktop, pb, position, w100, zIndex} from "@pg-design/helpers-css";
import {FormikForm} from "@pg-mono/formik-utils";

import {IArchitectUserLeadData} from "../../types/IArchitectUserLeadData";

const validationSchema = Yup.object({
    name: Yup.string().required(),
    email: Yup.string().required().email(),
    phone: Yup.number().integer("Podaj poprawny numer").required().typeError("Podaj poprawny numer")
});

interface IProps {
    leadData: IArchitectUserLeadData;
    onLeadDataChange: (values: IArchitectUserLeadData) => void;
}

export const MultiLeadArchitectUserDataForm = (props: IProps) => {
    const initialValues = {name: props.leadData.name, email: props.leadData.email, phone: props.leadData.phone, area: ""};

    const onDataChange = (values: IArchitectUserLeadData) => {
        props.onLeadDataChange(values);
    };

    return (
        <div css={dataFormStyle}>
            <FormikForm onSubmit={onDataChange} initialValues={initialValues} validationSchema={validationSchema}>
                <FormikForm.Input name="name" placeholder="Imię i nazwisko" css={mb(2)} />
                <FormikForm.Input name="email" placeholder="E-mail" disabled={!!initialValues.email} css={mb(2)} />
                <FormikForm.Input name="phone" placeholder="Telefon" css={mb(2)} />
                <FormikForm.Button variant="filled_primary" css={btnStyle}>
                    Zatwierdź
                </FormikForm.Button>
            </FormikForm>
        </div>
    );
};

const dataFormStyle = css`
    ${h100};
    ${position("relative")};
    ${pb(9)};
`;

const btnStyle = css`
    ${w100};
    ${position("absolute")};
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    ${mt(2)};
    ${zIndex(5)};

    ${onDesktop(maxWidth("200px"))};
`;
