import {Dispatch} from "redux";

import {userComUserRegister} from "@pg-mono/user-com";

import {IRPStore} from "../../app/rp_reducer";
import {IApplication} from "../../application/types/IApplication";
import {loadFavouritesToStore} from "../../favourite/actions/load_favourites_to_store";
import {userSignUpHit} from "../../tracking/algolytics_hits/user_sign_up_hit";
import {gtmEventApplicationRegister} from "../../tracking/google_tag_manager/gtm_event_application_register";
import {UserSource} from "../../tracking/tracking_utils/user_source";
import {forceFetchUserProfile} from "../../user/actions/fetch_user_profile";
import {patchUserProfileFromApplication} from "../../user/actions/patch_user_profile_from_application";
import {fetchUserUnsubscribeLink} from "../../user/api/fetch_user_unsubscribe_link";

// INFO: This action should always be used whenever a new user is created after submitting an application
export const authUserAfterApplicationSent =
    (application: IApplication, sendGTMAnalytics = true) =>
    async (dispatch: Dispatch, getState: () => IRPStore) => {
        const userProfileData = await dispatch(forceFetchUserProfile());

        if (userProfileData) {
            await dispatch(loadFavouritesToStore());

            const {email, uuid, email_marketing_consent, phone_marketing_consent} = userProfileData;

            const emailMarketingConsent = application.new_property_notification_consent || email_marketing_consent;
            const phoneMarketingConsent = application.new_property_notification_consent || phone_marketing_consent;

            fetchUserUnsubscribeLink().then(({unsubscribe_url_rp, unsubscribe_url_gh}) => {
                userComUserRegister(uuid, email, emailMarketingConsent, phoneMarketingConsent, unsubscribe_url_rp, unsubscribe_url_gh);
            });

            dispatch(patchUserProfileFromApplication(userProfileData, application));

            // Analytics
            const currentViewType = getState().viewType.current;

            userSignUpHit(UserSource.APPLICATION, userProfileData, currentViewType);
        }

        // Analytics
        if (sendGTMAnalytics) {
            gtmEventApplicationRegister();
        }
    };
import {Dispatch} from "redux";

import {userComUserRegister} from "@pg-mono/user-com";

import {IRPStore} from "../../app/rp_reducer";
import {IApplication} from "../../application/types/IApplication";
import {loadFavouritesToStore} from "../../favourite/actions/load_favourites_to_store";
import {userSignUpHit} from "../../tracking/algolytics_hits/user_sign_up_hit";
import {gtmEventApplicationRegister} from "../../tracking/google_tag_manager/gtm_event_application_register";
import {UserSource} from "../../tracking/tracking_utils/user_source";
import {forceFetchUserProfile} from "../../user/actions/fetch_user_profile";
import {patchUserProfileFromApplication} from "../../user/actions/patch_user_profile_from_application";
import {fetchUserUnsubscribeLink} from "../../user/api/fetch_user_unsubscribe_link";

// INFO: This action should always be used whenever a new user is created after submitting an application
export const authUserAfterApplicationSent =
    (application: IApplication, sendGTMAnalytics = true) =>
    async (dispatch: Dispatch, getState: () => IRPStore) => {
        const userProfileData = await dispatch(forceFetchUserProfile());

        if (userProfileData) {
            await dispatch(loadFavouritesToStore());

            const {email, uuid, email_marketing_consent, phone_marketing_consent} = userProfileData;

            const emailMarketingConsent = application.new_property_notification_consent || email_marketing_consent;
            const phoneMarketingConsent = application.new_property_notification_consent || phone_marketing_consent;

            fetchUserUnsubscribeLink().then(({unsubscribe_url_rp, unsubscribe_url_gh}) => {
                userComUserRegister(uuid, email, emailMarketingConsent, phoneMarketingConsent, unsubscribe_url_rp, unsubscribe_url_gh);
            });

            dispatch(patchUserProfileFromApplication(userProfileData, application));

            // Analytics
            const currentViewType = getState().viewType.current;

            userSignUpHit(UserSource.APPLICATION, userProfileData, currentViewType);
        }

        // Analytics
        if (sendGTMAnalytics) {
            gtmEventApplicationRegister();
        }
    };
