import React from "react";
import {connect} from "react-redux";
import {RouteComponentProps, withRouter} from "react-router";
import {css, Theme} from "@emotion/react";
import {bindActionCreators, Dispatch} from "redux";

import {flex, onDesktop, p} from "@pg-design/helpers-css";
import {handleFormikPost, IFormikSubmitFn} from "@pg-mono/formik-utils";
import {isEqual, noop} from "@pg-mono/nodash";
import {IAppError} from "@pg-mono/request-state";

import {IRPStore} from "../../app/rp_reducer";
import {afterInternalAuth} from "../../auth/actions/after_internal_auth";
import {authUserAfterApplicationSent} from "../../auth/actions/auth_user_after_application_sent";
import {emailSignUp} from "../../auth/api/email_sign_up";
import {creditCalculatorAnalytics, CreditCalculatorGTMEvent, CreditCalculatorInstalmentEventAction} from "../../credit_calculator/tracking/credit_gtm";
import {notifyBugsnag} from "../../errors/bugsnag/notify_bugsnag";
import {setSentLeadValues} from "../../lead/reducers/sent_lead_reducer";
import {IRealEstateIds} from "../../lead/types/IRealEstateIds";
import {calculateRoomsField} from "../../lead/utils/calculate_room_fields";
import {getLeadAnalyticsData} from "../../lead/utils/get_lead_analytics_data";
import {getParsedLeadFormValues} from "../../lead/utils/get_parsed_lead_form_values";
import {isLeadWithPriceConfirmation} from "../../lead/utils/is_lead_with_price_confirmation";
import {sendLead} from "../../lead/utils/send_lead";
import {getSentLeadFormValuesFromStorage} from "../../lead/utils/sent_lead_form_values";
import {addSuccessNotification} from "../../notifications/utils/add_success_notification";
import {addWarningNotification} from "../../notifications/utils/add_warning_notification";
import {addAppliedOffers} from "../../offer/map/actions/add_applied_offers";
import {IOfferDetailApplication} from "../../offer/types/IOfferDetailApplication";
import {IPropertyDetail} from "../../property/types/IPropertyDetail";
import {IPropertyDetailApplication} from "../../property/types/IPropertyDetailApplication";
import {transformPropertyDetailToRecommendedPropertyPayload} from "../../property/utils/transform_property_detail_to_recommended_property_payload";
import {removeLastSeenOfferProperties} from "../../property/utils/user_last_seen_offer_properties";
import {Country} from "../../region/types/Country";
import {BoundAction} from "../../store/utils/bound_action";
import {hitRotatingApplicationError} from "../../tracking/algolytics_hits/application_hit";
import {gtmCheckout} from "../../tracking/google_tag_manager/gtm_checkout";
import {gtmCreditCheckboxClick} from "../../tracking/google_tag_manager/gtm_event_credit_checkbox";
import {gtmProductAdd} from "../../tracking/google_tag_manager/gtm_product_add";
import {UserSource} from "../../tracking/tracking_utils/user_source";
import {fetchUserProfile} from "../../user/actions/fetch_user_profile";
import {patchUserPreferences} from "../../user/actions/patch_user_preferences";
import {patchUserProfileFromApplication} from "../../user/actions/patch_user_profile_from_application";
import {IUserPreferences} from "../../user/interfaces/IUserPreferences";
import {UserAccountType} from "../../user/ts/enums/UserAccountType";
import {IUserProfile} from "../../user/ts/interfaces/IUserProfile";
import {ViewType} from "../../view_type/ViewType";
import {afterPostApplicationActions, resetApplicationPost} from "../actions/application_form_actions";
import {initialEmptyApplicationValues} from "../constants/application_form";
import {lastSeenPropertiesCustomSection} from "../constants/last_seen_properties";
import {IApplicationData} from "../reducers/application_reducer";
import {hitLeadSent} from "../tracking/hit_lead_sent";
import {LastSeenPropertiesGTMEventAction, lastSeenPropertiesLeadAnalytics} from "../tracking/last_seen_properties_lead_tracking";
import {IApplicationAnalyticsData, openApplicationFormHit} from "../tracking/tracking";
import {ApplicationVariant} from "../types/ApplicationVariant";
import {IApplication} from "../types/IApplication";
import {IApplicationFormValues} from "../types/IApplicationFormValues";
import {IMultiLeadData} from "../types/IMultiLeadData";
import {MessageType} from "../types/MessageType";
import {alertFormErrorMessage, alertFormSuccessMessage} from "../utils/alert_text";
import {ApplicationSource, ApplicationSourceSection} from "../utils/ApplicationSource";
import {conditionalPricesAnalytics, PriceModalGTMEvent} from "../utils/conditional_prices_analytics";
import {sendPropertyMultiLead} from "../utils/recommended_application/send_property_multi_lead";
import {transformRecommendedPropertyIntoMultiLeadData} from "../utils/recommended_application/transform_recommended_into_multi_lead_data";
import {shouldApplicationPriceBeenShown} from "../utils/should_application_price_been_shown";
import {getGeneratedMessage} from "../utils/text_generator/get_generated_message";
import {ApplicationForm} from "./application_form/ApplicationForm";
import {IApplicationAdditionalQuestionTypes} from "./application_form/ApplicationFormContext";
import {ApplicationPropertyPrice, ApplicationPropertyPriceUserDecisionType} from "./property_price/ApplicationPropertyPrice";
import {ApplicationSentSuccess} from "./ApplicationSentSuccess";

export type IApplicationUuid = string | null;

type IApplicationOtherProps = {
    initialLeadFormValues: IApplicationFormValues;
    leadData: IApplicationData;
    onSuccessfulSubmit?: (
        sentApplication: IApplication | null,
        offer?: IOfferDetailApplication,
        property?: IPropertyDetailApplication,
        recommendedParams?: Record<string, string>,
        onBeforeNextStep?: () => Promise<void>
    ) => void;
    handleChangeUserBudget: () => void;
    appendPreMultileadApplications: (multiLeadSummaryItems: IMultiLeadData[]) => void;
    hideMessageSection?: boolean;
    storeHash: string;
    // application input data
    source: ApplicationSource;
    sourceSection: ApplicationSourceSection;
    salesOfficeId?: number;
    alwaysShowFields?: boolean;
    hideRodo?: boolean;
    className?: string;
    hideSocialLoginButtons?: boolean;
    hideRooms?: boolean;
    hideSubmitButton?: boolean;
    hideHeaderDivider?: boolean;
    affiliation?: string;
    hideQuestions?: boolean;
    submitOnMount?: boolean;
    forceClose?: boolean; // close without any additional modals
    applicationVariant?: ApplicationVariant;
    creditPayload?: {price: number; contribution: number; period: number};
};

interface IStateProps {
    userPreferencesFilters: IUserPreferences["filters"];
    isAuthenticated: boolean;
    userProfileData: IUserProfile | null;
    viewType: ViewType | null;
    didSubmitOnMount: boolean;
    lastSeenPropertiesDetails: {[key: string]: IPropertyDetail};
}

interface IActionsProps {
    afterPostApplicationActions: BoundAction<typeof afterPostApplicationActions>;
    addAppliedOffers: typeof addAppliedOffers;
    resetApplicationPost: typeof resetApplicationPost;
    fetchUserProfile: BoundAction<typeof fetchUserProfile>;
    authUserAfterApplicationSent: BoundAction<typeof authUserAfterApplicationSent>;
    patchUserProfileFromApplication: BoundAction<typeof patchUserProfileFromApplication>;
    addWarningNotification: typeof addWarningNotification;
    addSuccessNotification: typeof addSuccessNotification;
    patchUserPreferences: BoundAction<typeof patchUserPreferences>;
    afterInternalAuth: BoundAction<typeof afterInternalAuth>;
    setSentLeadValues: BoundAction<typeof setSentLeadValues>;
    sendPropertyMultiLead: BoundAction<typeof sendPropertyMultiLead>;
}

export type IApplicationOwnProps = IRealEstateIds & IApplicationOtherProps;

type IOwnProps = IApplicationOwnProps &
    RouteComponentProps<{}> & {
        closeModal: (forceClose?: boolean) => void;
        handlePropertyPriceModalClose: () => void;
        customFormText?: string;
    };

type IProps = IStateProps & IActionsProps & IOwnProps;

interface IState {
    formPreviouslySent: boolean; // there is data in LS
    applicationUuid: IApplicationUuid; // User just sent an application and get applicationUuid among others.
    initialFormState: IApplicationFormValues;
    propertyPriceState: {showPropertyPriceModal: boolean; userDecision: null | ApplicationPropertyPriceUserDecisionType};
    questions: IApplicationAdditionalQuestionTypes[];
    messageType: MessageType;
    leadIsSubmitting: boolean;
    multiLeadIsSubmitting: boolean;
}

class ApplicationC extends React.Component<IProps, IState> {
    private afterSuccessTimeout = 0;

    constructor(props: IProps) {
        super(props);

        this.state = {
            formPreviouslySent: false,
            applicationUuid: null,
            initialFormState: this.props.initialLeadFormValues,
            propertyPriceState: {showPropertyPriceModal: false, userDecision: null},

            // TODO: consider handling additional questions logic via Formik to get rid of this state
            questions: [],
            messageType: this.props.hideMessageSection ? MessageType.NONE : this.props.hideQuestions ? MessageType.CUSTOM : MessageType.GENERATED,
            leadIsSubmitting: false,
            multiLeadIsSubmitting: false
        };

        this.getAnalyticsData = this.getAnalyticsData.bind(this);
    }

    //  Lifecycle
    public async componentDidMount() {
        const sentLeadFormValues = getSentLeadFormValuesFromStorage();

        //  TODO: check formPreviouslySent across application, probably sentLead from redux should be used instead

        this.setState({formPreviouslySent: Boolean(sentLeadFormValues)});

        // track form opening
        openApplicationFormHit(this.getAnalyticsData());
        gtmProductAdd(this.props.leadData, this.props.viewType, "standard");

        // check whether the form was filled with data
        const {name, email, phone} = this.state.initialFormState;
        if (name || email || phone?.number) {
            this.onInteraction(); // form will be filled with data automatically = interaction
        }
    }

    public componentDidUpdate(prevProps: Readonly<IProps>) {
        const equal = isEqual(prevProps.initialLeadFormValues, this.props.initialLeadFormValues);
        if (!equal) {
            this.setState({
                initialFormState: this.props.initialLeadFormValues
            });
        }
    }

    public componentWillUnmount() {
        this.props.resetApplicationPost(this.props.storeHash);

        clearTimeout(this.afterSuccessTimeout);
        this.afterSuccessTimeout = -1; // do not allow to setTimeout after this assignment
    }

    //  Callbacks
    private onChange = (formValues: IApplicationFormValues) => {
        // Fire GTM checkbox event only when checkbox is checked after change
        formValues.financing_services && gtmCreditCheckboxClick();
    };

    private onBeforeNextStepQueue: (() => Promise<void>)[] = [];

    private onSubmit = async (values: IApplicationFormValues, applicationData: IApplicationData) => {
        // TODO: Until we rewrite/remove Application, keep this inline with use_lead_modal_send_main_lead
        return await sendLead({
            leadFormValues: values,
            realEstateIds: {
                vendorId: this.props.vendorId,
                offerId: this.props.offerId,
                propertyId: this.props.propertyId,
                salesOfficeId: this.props.salesOfficeId
            },
            storeHash: this.props.storeHash,
            leadMeta: {
                source: this.props.source,
                sourceSection: this.props.sourceSection,
                locationHash: this.props.location.hash,
                viewType: this.props.viewType
            },
            leadType: "standard",
            leadData: applicationData,
            isAuthenticated: this.props.isAuthenticated,
            userProfileData: this.props.userProfileData,
            senderState: {
                generatedMessage: null,
                questions: this.state.questions
            },
            analyticsData: this.getAnalyticsData(),
            affiliation: this.props.affiliation,
            creditData: this.props.creditPayload,
            onSendSuccess: async (responseApplication) => {
                // TODO: Credit calculator logic should be moved to the credit calculator
                if (this.props.applicationVariant !== ApplicationVariant.CREDIT) {
                    hitLeadSent(responseApplication.new_property_notification_consent);
                }

                await this.sendMultiplePropertyApplications(values, responseApplication);

                // TODO: Credit calculator logic should be moved to the credit calculator
                if (this.props.applicationVariant === ApplicationVariant.CREDIT) {
                    creditCalculatorAnalytics.gtm.creditCalculatorEvent(CreditCalculatorGTMEvent.CREDIT_INSTALMENT_CALCULATOR, {
                        credit_action: CreditCalculatorInstalmentEventAction.CALL
                    });
                }

                this.onBeforeNextStepQueue.push(
                    () =>
                        new Promise((resolve) => {
                            {
                                const propertyPriceVisible =
                                    !!applicationData.property?.detail?.price && applicationData.property.detail.configuration.show_price;

                                if (propertyPriceVisible) {
                                    this.setState({
                                        propertyPriceState: {
                                            showPropertyPriceModal: true,
                                            userDecision: ApplicationPropertyPriceUserDecisionType.SENT
                                        }
                                    });
                                }

                                setTimeout(
                                    () => {
                                        resolve();
                                    },
                                    propertyPriceVisible ? 2000 : 0
                                );

                                this.setState(this.getClearFormState((applicationData.offer && applicationData.offer.detail) || undefined));

                                // handle new state
                                if (this.afterSuccessTimeout !== -1) {
                                    this.afterSuccessTimeout = window.setTimeout(
                                        () => this.setState({formPreviouslySent: true, applicationUuid: responseApplication.uuid}),
                                        0
                                    );
                                }
                            }
                        })
                );

                this.props.onSuccessfulSubmit?.(responseApplication, applicationData.offer?.detail, applicationData.property?.detail, undefined, async () => {
                    // TODO: {showPropertyPriceModal: true} trigger displaying overlay with vendor contact information,
                    // because this is not a modal step it sometimes caused problems if we want to skipp displaying the overlay.
                    // Reimplementation of whole modal step logic (ApplicationModalArray) is necessary if we want to change this approach.
                    while (this.onBeforeNextStepQueue.length > 0) {
                        const callback = this.onBeforeNextStepQueue.shift();
                        if (callback) {
                            await callback();
                        }
                    }
                });
            },
            postOptions: {
                on400ApplicationBlockedError: (error) => {
                    this.props.closeModal(true);
                    this.props.addWarningNotification("Przepraszamy, oferta na którą chcesz wysłać wiadomość została zarchiwizowana.", 7000);
                    hitRotatingApplicationError(this.props.source, this.props.sourceSection, applicationData, {
                        appError: error.appError as IAppError,
                        extraPayloadViewType: this.props.viewType ?? undefined
                    });
                    // TODO: remove this bugsnag notification when we confirm that blocking applications this way is not an issue
                    notifyBugsnag({name: "Application blocked info", message: "Application blocked info"}, "Application sent on an archived offer", {error});
                    setTimeout(() => window.location.reload(), 6000);
                },
                on400Response: (error) => {
                    hitRotatingApplicationError(this.props.source, this.props.sourceSection, applicationData, {
                        appError: error.appError as IAppError,
                        extraPayloadViewType: this.props.viewType ?? undefined
                    });
                }
            }
        });
    };

    private onPreSubmitResolve = noop;
    private onPreSubmitReject = noop;

    private getPropertyPriceState = (userDecision: ApplicationPropertyPriceUserDecisionType | null, show = true) => {
        return {propertyPriceState: {showPropertyPriceModal: show, userDecision}};
    };

    private sendMultiplePropertyApplications = async (values: IApplicationFormValues, responseApplication?: IApplication | null) => {
        if (
            this.props.applicationVariant === ApplicationVariant.PROPERTY_WITH_RETARGETING &&
            values.multiLeadProperties &&
            values.multiLeadProperties?.length > 0
        ) {
            this.setState({
                leadIsSubmitting: true,
                multiLeadIsSubmitting: true
            });
            const {multiLeadProperties} = values;

            if (responseApplication) {
                // remove current propertyId if lead was sent
                removeLastSeenOfferProperties([responseApplication.property]);
            }

            const selectedProperties = multiLeadProperties.map((propertyId) => this.props.lastSeenPropertiesDetails[propertyId]);
            const selectedPropertiesAsRecommended = selectedProperties.map((property) => transformPropertyDetailToRecommendedPropertyPayload(property));

            return this.props
                .sendPropertyMultiLead(
                    {
                        recommendedProperties: selectedPropertiesAsRecommended,
                        formValues: values
                    },
                    {
                        source: ApplicationSource.PreMultilead,
                        sourceSection: ApplicationSourceSection.PREMULTILEAD,
                        viewType: this.props.viewType,
                        isAuthenticated: this.props.isAuthenticated,
                        application: responseApplication,
                        refererApplicationUUID: responseApplication?.uuid,
                        originalFormParams: {
                            rooms: values.rooms,
                            questions: values.questions
                        },
                        recommendationsWithPriceFilter: false
                    },
                    lastSeenPropertiesCustomSection
                )
                .then((multiLeadPropertyApplicationResponse) => {
                    const appliedRecommendation = selectedPropertiesAsRecommended.map((property) => {
                        const foundMultiLeadApplicationResponse = multiLeadPropertyApplicationResponse.find(
                            ([_, recommendedProperty]) => recommendedProperty.id === property.id
                        );

                        if (!foundMultiLeadApplicationResponse) {
                            return null;
                        }

                        const [application] = foundMultiLeadApplicationResponse;
                        return transformRecommendedPropertyIntoMultiLeadData(property, {isPriceRevealed: shouldApplicationPriceBeenShown(application)});
                    });

                    const appliedRecommendationFiltered = appliedRecommendation.filter(Boolean) as IMultiLeadData[];

                    this.props.appendPreMultileadApplications(appliedRecommendationFiltered);

                    removeLastSeenOfferProperties(multiLeadProperties.map((propertyId) => propertyId));
                    lastSeenPropertiesLeadAnalytics.gtm.lastSeenPropertiesLeadEvent(LastSeenPropertiesGTMEventAction.OFFER_ASK);

                    return multiLeadPropertyApplicationResponse;
                })
                .finally(() => {
                    this.setState({
                        ...this.getClearFormState(this.props.leadData.offer?.detail),
                        leadIsSubmitting: false,
                        multiLeadIsSubmitting: false
                    });
                });
        }

        return Promise.resolve();
    };

    private onPreSubmit = (returnResolved = false, values: IApplicationFormValues): Promise<void> => {
        if (returnResolved) {
            return Promise.resolve();
        }

        if (!this.props.userProfileData || (this.props.userProfileData && this.props.userProfileData.email !== values.email)) {
            // Because we don't send an application right now, and we don't want to lose user data - we are trying to register user
            emailSignUp({email: values.email, type: UserAccountType.LOCAL, source: UserSource.APPLICATION_VISIBLE_PRICE})
                .then(async () => {
                    await this.props.afterInternalAuth(true, UserSource.APPLICATION_VISIBLE_PRICE);
                })
                // Do nothing on catch - probably user is already registered or error happened
                .catch(() => false);
        }

        return new Promise((resolve, reject) => {
            this.onPreSubmitResolve = () => {
                this.onBeforeNextStepQueue.push(() => {
                    this.setState(this.getPropertyPriceState(ApplicationPropertyPriceUserDecisionType.SENT));
                    return Promise.resolve();
                });

                conditionalPricesAnalytics.gtm.priceModalEvent(PriceModalGTMEvent.PRICE_ACCEPT);

                resolve();
            };
            this.onPreSubmitReject = () => {
                this.sendMultiplePropertyApplications(values).then(() => {
                    this.setState(this.getPropertyPriceState(ApplicationPropertyPriceUserDecisionType.CANCEL));

                    conditionalPricesAnalytics.gtm.priceModalEvent(PriceModalGTMEvent.PRICE_SKIP);
                    this.props.handleChangeUserBudget();

                    reject();
                });
            };

            this.setState(this.getPropertyPriceState(null));
        });
    };

    private onFormikSubmit: IFormikSubmitFn<IApplicationFormValues> = (values, formikHelpers) => {
        this.setState({leadIsSubmitting: true});

        // TODO: Until we rewrite/remove Application, keep this inline with use_lead_modal_send_main_lead
        const {offer, property} = this.props.leadData;

        const offerName = offer?.detail ? offer.detail.name : "";

        const completedValues = {
            ...values,
            text:
                this.props.customFormText ||
                values.text ||
                getGeneratedMessage({
                    offerType: offer?.detail?.type,
                    offerName: offerName,
                    propertyNumber: property?.detail?.number,
                    name: values.name,
                    rooms: property?.detail?.rooms,
                    area: property?.detail?.area
                })
        };

        const parsedLeadFormValues = getParsedLeadFormValues(completedValues);
        this.props.setSentLeadValues({
            ...initialEmptyApplicationValues,
            ...parsedLeadFormValues,
            questions: this.state.questions
        });

        const propertyPriceVisible = isLeadWithPriceConfirmation(this.props.leadData);
        /*
         * According to business requirements (CU-8692y4qyv) we need to show prices for specific developers - so we don't need to show
         * modal with confirmation of sending the application.
         */
        const forceShowPrice = this.props.leadData.vendor?.detail?.configuration.force_show_price;
        this.onPreSubmit(!propertyPriceVisible || forceShowPrice, completedValues)
            // The user confirmed sending the application - use normal flow
            .then(() => {
                handleFormikPost(this.onSubmit(completedValues, this.props.leadData), formikHelpers, {
                    onSuccess: () => {
                        this.props.addSuccessNotification(alertFormSuccessMessage);
                        this.setState((prevState) => ({
                            ...(prevState.multiLeadIsSubmitting
                                ? {initialFormState: prevState.initialFormState}
                                : this.getClearFormState(this.props.leadData.offer?.detail)),
                            leadIsSubmitting: prevState.multiLeadIsSubmitting
                        }));
                    },
                    onFieldError: () => {
                        this.setState({...this.getPropertyPriceState(null, false), leadIsSubmitting: false});

                        this.props.addWarningNotification(alertFormErrorMessage);
                    },
                    onNonFieldError: () => {
                        this.setState({...this.getPropertyPriceState(null, false), leadIsSubmitting: false});

                        this.props.addWarningNotification(alertFormErrorMessage);
                    }
                });
            })
            // The user rejected sending the application - use flow with additional user actions related to the property price
            .catch(() => {
                this.setState(this.getPropertyPriceState(ApplicationPropertyPriceUserDecisionType.CANCEL, true));
            });
    };

    //  Helpers
    private calculateRoomsField = (offer: IOfferDetailApplication | undefined) => {
        return calculateRoomsField({
            offer,
            propertyId: this.props.propertyId,
            userPreferencesFilters: this.props.userPreferencesFilters
        });
    };

    private getClearFormState = (offer: IOfferDetailApplication | undefined) => {
        return {
            initialFormState: {
                ...initialEmptyApplicationValues,
                rooms: this.calculateRoomsField(offer),
                text: this.props.customFormText
            }
        };
    };

    private wasInteractionHit = false;
    private onInteraction = () => {
        if (!this.wasInteractionHit) {
            this.wasInteractionHit = true;

            gtmCheckout(this.props.leadData, this.props.viewType, "standard");
        }
    };

    //  A helper that provides analytics data object, shared in algolytics tracking objects
    private getAnalyticsData(): IApplicationAnalyticsData {
        return getLeadAnalyticsData(this.props.leadData, this.props.viewType, this.props.source, this.props.sourceSection);
    }

    //  Render
    public render() {
        const {
            propertyPriceState: {showPropertyPriceModal, userDecision}
        } = this.state;

        return (
            <div css={scrollFormWrap}>
                {this.state.initialFormState && (
                    <div css={showPropertyPriceModal ? propertyPriceOverlay : null}>
                        {/* hideRooms: we show rooms in right column (in form) when we do not ask for property and we consider promotion or vendor (no offerId) application*/}
                        <div css={p(0)}>
                            <ApplicationForm
                                leadIsSubmitting={this.state.leadIsSubmitting}
                                onSubmit={this.onFormikSubmit}
                                initialValues={this.state.initialFormState}
                                onFormControlClick={this.onInteraction}
                                onChange={this.onChange}
                                formPreviouslySent={this.state.formPreviouslySent}
                                hideRooms={!!this.props.propertyId || this.props.hideRooms}
                                hideMessageSection={!!this.props.hideMessageSection}
                                offerId={this.props.offerId}
                                alwaysShowFields={this.props.alwaysShowFields} // For this test - when form is in modal, we do not hide fields.
                                storeHash={this.props.storeHash}
                                enableLegalDetailsHiding={this.props.isAuthenticated}
                                hideSubmitButton={this.props.hideSubmitButton}
                                source={this.props.source}
                                sourceSection={this.props.sourceSection}
                                hideRodo={this.props.hideRodo}
                                applicationMessageLogic={{
                                    currentState: {
                                        selectedQuestions: this.state.questions,
                                        messageType: this.state.messageType
                                    },
                                    helpers: {
                                        setMessageType: (messageType: MessageType) => {
                                            this.setState({
                                                messageType: messageType
                                            });
                                        },
                                        setSelectedQuestions: (questionsIds: IApplicationAdditionalQuestionTypes[]) => {
                                            this.setState({
                                                questions: questionsIds
                                            });
                                        }
                                    }
                                }}
                                getAnalyticsData={this.getAnalyticsData}
                                hideQuestions={this.props.hideQuestions}
                                closeModal={this.props.closeModal}
                                submitOnMount={this.props.submitOnMount}
                                applicationVariant={
                                    this.props.leadData.offer?.detail?.region.country !== Country.POLAND
                                        ? ApplicationVariant.DEFAULT
                                        : this.props.applicationVariant
                                }
                                customFormText={this.props.customFormText}
                                creditData={this.props.creditPayload}
                            />
                        </div>

                        {this.props.submitOnMount && (
                            <div css={mountSubmitMessage}>
                                <ApplicationSentSuccess />
                            </div>
                        )}

                        {showPropertyPriceModal &&
                            this.props.leadData.vendor?.detail &&
                            this.props.leadData.property?.detail &&
                            this.props.leadData.offer?.detail && (
                                <ApplicationPropertyPrice
                                    leadIsSubmitting={this.state.leadIsSubmitting}
                                    onModalClose={this.props.handlePropertyPriceModalClose}
                                    onSendConfirmation={this.onPreSubmitResolve}
                                    onSendRejection={this.onPreSubmitReject}
                                    property={this.props.leadData.property.detail}
                                    offer={this.props.leadData.offer.detail}
                                    userDecision={userDecision}
                                    vendor={this.props.leadData.vendor.detail}
                                />
                            )}
                    </div>
                )}
            </div>
        );
    }
}

const ApplicationConnected = connect<IStateProps, IActionsProps, IOwnProps, IRPStore>(mapStateToProps, mapActionsToProps)(ApplicationC);
export const Application = withRouter<IOwnProps, typeof ApplicationConnected>(ApplicationConnected);

//  Connect
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function mapStateToProps(state: IRPStore, props: IOwnProps): IStateProps {
    return {
        userPreferencesFilters: state.user.preferences?.data?.filters || {},
        isAuthenticated: state.isAuthenticated,
        userProfileData: state.user.profile.data,
        viewType: state.viewType.current,
        didSubmitOnMount: state.leadForm.didSubmitOnMount,
        lastSeenPropertiesDetails: state.property.lastSeenPropertiesDetails
    };
}

function mapActionsToProps(dispatch: Dispatch): IActionsProps {
    return {
        ...bindActionCreators(
            {
                afterPostApplicationActions,
                addAppliedOffers,
                resetApplicationPost,
                fetchUserProfile,
                authUserAfterApplicationSent,
                patchUserProfileFromApplication,
                addWarningNotification,
                addSuccessNotification,
                patchUserPreferences,
                afterInternalAuth,
                setSentLeadValues,
                sendPropertyMultiLead
            },
            dispatch
        )
    };
}

const scrollFormWrap = (theme: Theme) => css`
    height: calc(100% - 6.5rem);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: ${theme.colors.gray[400]};
    }

    &::-webkit-scrollbar {
        width: 6px;
        background-color: ${theme.colors.gray[400]};
        padding-left: 2px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: ${theme.colors.gray[600]};
    }
`;

const mountSubmitMessage = css`
    ${flex("center", "center")};
    background: white;
    position: fixed;
    inset: 0 0;
    z-index: 10000;

    ${onDesktop(css`
        position: absolute;
    `)}
`;

const propertyPriceOverlay = (theme: Theme) => css`
    @media (max-width: ${theme.breakpoints.md}) {
        max-height: 100vh;
        overflow: hidden;
    }
`;
import React from "react";
import {connect} from "react-redux";
import {RouteComponentProps, withRouter} from "react-router";
import {css, Theme} from "@emotion/react";
import {bindActionCreators, Dispatch} from "redux";

import {flex, onDesktop, p} from "@pg-design/helpers-css";
import {handleFormikPost, IFormikSubmitFn} from "@pg-mono/formik-utils";
import {isEqual, noop} from "@pg-mono/nodash";
import {IAppError} from "@pg-mono/request-state";

import {IRPStore} from "../../app/rp_reducer";
import {afterInternalAuth} from "../../auth/actions/after_internal_auth";
import {authUserAfterApplicationSent} from "../../auth/actions/auth_user_after_application_sent";
import {emailSignUp} from "../../auth/api/email_sign_up";
import {creditCalculatorAnalytics, CreditCalculatorGTMEvent, CreditCalculatorInstalmentEventAction} from "../../credit_calculator/tracking/credit_gtm";
import {notifyBugsnag} from "../../errors/bugsnag/notify_bugsnag";
import {setSentLeadValues} from "../../lead/reducers/sent_lead_reducer";
import {IRealEstateIds} from "../../lead/types/IRealEstateIds";
import {calculateRoomsField} from "../../lead/utils/calculate_room_fields";
import {getLeadAnalyticsData} from "../../lead/utils/get_lead_analytics_data";
import {getParsedLeadFormValues} from "../../lead/utils/get_parsed_lead_form_values";
import {isLeadWithPriceConfirmation} from "../../lead/utils/is_lead_with_price_confirmation";
import {sendLead} from "../../lead/utils/send_lead";
import {getSentLeadFormValuesFromStorage} from "../../lead/utils/sent_lead_form_values";
import {addSuccessNotification} from "../../notifications/utils/add_success_notification";
import {addWarningNotification} from "../../notifications/utils/add_warning_notification";
import {addAppliedOffers} from "../../offer/map/actions/add_applied_offers";
import {IOfferDetailApplication} from "../../offer/types/IOfferDetailApplication";
import {IPropertyDetail} from "../../property/types/IPropertyDetail";
import {IPropertyDetailApplication} from "../../property/types/IPropertyDetailApplication";
import {transformPropertyDetailToRecommendedPropertyPayload} from "../../property/utils/transform_property_detail_to_recommended_property_payload";
import {removeLastSeenOfferProperties} from "../../property/utils/user_last_seen_offer_properties";
import {Country} from "../../region/types/Country";
import {BoundAction} from "../../store/utils/bound_action";
import {hitRotatingApplicationError} from "../../tracking/algolytics_hits/application_hit";
import {gtmCheckout} from "../../tracking/google_tag_manager/gtm_checkout";
import {gtmCreditCheckboxClick} from "../../tracking/google_tag_manager/gtm_event_credit_checkbox";
import {gtmProductAdd} from "../../tracking/google_tag_manager/gtm_product_add";
import {UserSource} from "../../tracking/tracking_utils/user_source";
import {fetchUserProfile} from "../../user/actions/fetch_user_profile";
import {patchUserPreferences} from "../../user/actions/patch_user_preferences";
import {patchUserProfileFromApplication} from "../../user/actions/patch_user_profile_from_application";
import {IUserPreferences} from "../../user/interfaces/IUserPreferences";
import {UserAccountType} from "../../user/ts/enums/UserAccountType";
import {IUserProfile} from "../../user/ts/interfaces/IUserProfile";
import {ViewType} from "../../view_type/ViewType";
import {afterPostApplicationActions, resetApplicationPost} from "../actions/application_form_actions";
import {initialEmptyApplicationValues} from "../constants/application_form";
import {lastSeenPropertiesCustomSection} from "../constants/last_seen_properties";
import {IApplicationData} from "../reducers/application_reducer";
import {hitLeadSent} from "../tracking/hit_lead_sent";
import {LastSeenPropertiesGTMEventAction, lastSeenPropertiesLeadAnalytics} from "../tracking/last_seen_properties_lead_tracking";
import {IApplicationAnalyticsData, openApplicationFormHit} from "../tracking/tracking";
import {ApplicationVariant} from "../types/ApplicationVariant";
import {IApplication} from "../types/IApplication";
import {IApplicationFormValues} from "../types/IApplicationFormValues";
import {IMultiLeadData} from "../types/IMultiLeadData";
import {MessageType} from "../types/MessageType";
import {alertFormErrorMessage, alertFormSuccessMessage} from "../utils/alert_text";
import {ApplicationSource, ApplicationSourceSection} from "../utils/ApplicationSource";
import {conditionalPricesAnalytics, PriceModalGTMEvent} from "../utils/conditional_prices_analytics";
import {sendPropertyMultiLead} from "../utils/recommended_application/send_property_multi_lead";
import {transformRecommendedPropertyIntoMultiLeadData} from "../utils/recommended_application/transform_recommended_into_multi_lead_data";
import {shouldApplicationPriceBeenShown} from "../utils/should_application_price_been_shown";
import {getGeneratedMessage} from "../utils/text_generator/get_generated_message";
import {ApplicationForm} from "./application_form/ApplicationForm";
import {IApplicationAdditionalQuestionTypes} from "./application_form/ApplicationFormContext";
import {ApplicationPropertyPrice, ApplicationPropertyPriceUserDecisionType} from "./property_price/ApplicationPropertyPrice";
import {ApplicationSentSuccess} from "./ApplicationSentSuccess";

export type IApplicationUuid = string | null;

type IApplicationOtherProps = {
    initialLeadFormValues: IApplicationFormValues;
    leadData: IApplicationData;
    onSuccessfulSubmit?: (
        sentApplication: IApplication | null,
        offer?: IOfferDetailApplication,
        property?: IPropertyDetailApplication,
        recommendedParams?: Record<string, string>,
        onBeforeNextStep?: () => Promise<void>
    ) => void;
    handleChangeUserBudget: () => void;
    appendPreMultileadApplications: (multiLeadSummaryItems: IMultiLeadData[]) => void;
    hideMessageSection?: boolean;
    storeHash: string;
    // application input data
    source: ApplicationSource;
    sourceSection: ApplicationSourceSection;
    salesOfficeId?: number;
    alwaysShowFields?: boolean;
    hideRodo?: boolean;
    className?: string;
    hideSocialLoginButtons?: boolean;
    hideRooms?: boolean;
    hideSubmitButton?: boolean;
    hideHeaderDivider?: boolean;
    affiliation?: string;
    hideQuestions?: boolean;
    submitOnMount?: boolean;
    forceClose?: boolean; // close without any additional modals
    applicationVariant?: ApplicationVariant;
    creditPayload?: {price: number; contribution: number; period: number};
};

interface IStateProps {
    userPreferencesFilters: IUserPreferences["filters"];
    isAuthenticated: boolean;
    userProfileData: IUserProfile | null;
    viewType: ViewType | null;
    didSubmitOnMount: boolean;
    lastSeenPropertiesDetails: {[key: string]: IPropertyDetail};
}

interface IActionsProps {
    afterPostApplicationActions: BoundAction<typeof afterPostApplicationActions>;
    addAppliedOffers: typeof addAppliedOffers;
    resetApplicationPost: typeof resetApplicationPost;
    fetchUserProfile: BoundAction<typeof fetchUserProfile>;
    authUserAfterApplicationSent: BoundAction<typeof authUserAfterApplicationSent>;
    patchUserProfileFromApplication: BoundAction<typeof patchUserProfileFromApplication>;
    addWarningNotification: typeof addWarningNotification;
    addSuccessNotification: typeof addSuccessNotification;
    patchUserPreferences: BoundAction<typeof patchUserPreferences>;
    afterInternalAuth: BoundAction<typeof afterInternalAuth>;
    setSentLeadValues: BoundAction<typeof setSentLeadValues>;
    sendPropertyMultiLead: BoundAction<typeof sendPropertyMultiLead>;
}

export type IApplicationOwnProps = IRealEstateIds & IApplicationOtherProps;

type IOwnProps = IApplicationOwnProps &
    RouteComponentProps<{}> & {
        closeModal: (forceClose?: boolean) => void;
        handlePropertyPriceModalClose: () => void;
        customFormText?: string;
    };

type IProps = IStateProps & IActionsProps & IOwnProps;

interface IState {
    formPreviouslySent: boolean; // there is data in LS
    applicationUuid: IApplicationUuid; // User just sent an application and get applicationUuid among others.
    initialFormState: IApplicationFormValues;
    propertyPriceState: {showPropertyPriceModal: boolean; userDecision: null | ApplicationPropertyPriceUserDecisionType};
    questions: IApplicationAdditionalQuestionTypes[];
    messageType: MessageType;
    leadIsSubmitting: boolean;
    multiLeadIsSubmitting: boolean;
}

class ApplicationC extends React.Component<IProps, IState> {
    private afterSuccessTimeout = 0;

    constructor(props: IProps) {
        super(props);

        this.state = {
            formPreviouslySent: false,
            applicationUuid: null,
            initialFormState: this.props.initialLeadFormValues,
            propertyPriceState: {showPropertyPriceModal: false, userDecision: null},

            // TODO: consider handling additional questions logic via Formik to get rid of this state
            questions: [],
            messageType: this.props.hideMessageSection ? MessageType.NONE : this.props.hideQuestions ? MessageType.CUSTOM : MessageType.GENERATED,
            leadIsSubmitting: false,
            multiLeadIsSubmitting: false
        };

        this.getAnalyticsData = this.getAnalyticsData.bind(this);
    }

    //  Lifecycle
    public async componentDidMount() {
        const sentLeadFormValues = getSentLeadFormValuesFromStorage();

        //  TODO: check formPreviouslySent across application, probably sentLead from redux should be used instead

        this.setState({formPreviouslySent: Boolean(sentLeadFormValues)});

        // track form opening
        openApplicationFormHit(this.getAnalyticsData());
        gtmProductAdd(this.props.leadData, this.props.viewType, "standard");

        // check whether the form was filled with data
        const {name, email, phone} = this.state.initialFormState;
        if (name || email || phone?.number) {
            this.onInteraction(); // form will be filled with data automatically = interaction
        }
    }

    public componentDidUpdate(prevProps: Readonly<IProps>) {
        const equal = isEqual(prevProps.initialLeadFormValues, this.props.initialLeadFormValues);
        if (!equal) {
            this.setState({
                initialFormState: this.props.initialLeadFormValues
            });
        }
    }

    public componentWillUnmount() {
        this.props.resetApplicationPost(this.props.storeHash);

        clearTimeout(this.afterSuccessTimeout);
        this.afterSuccessTimeout = -1; // do not allow to setTimeout after this assignment
    }

    //  Callbacks
    private onChange = (formValues: IApplicationFormValues) => {
        // Fire GTM checkbox event only when checkbox is checked after change
        formValues.financing_services && gtmCreditCheckboxClick();
    };

    private onBeforeNextStepQueue: (() => Promise<void>)[] = [];

    private onSubmit = async (values: IApplicationFormValues, applicationData: IApplicationData) => {
        // TODO: Until we rewrite/remove Application, keep this inline with use_lead_modal_send_main_lead
        return await sendLead({
            leadFormValues: values,
            realEstateIds: {
                vendorId: this.props.vendorId,
                offerId: this.props.offerId,
                propertyId: this.props.propertyId,
                salesOfficeId: this.props.salesOfficeId
            },
            storeHash: this.props.storeHash,
            leadMeta: {
                source: this.props.source,
                sourceSection: this.props.sourceSection,
                locationHash: this.props.location.hash,
                viewType: this.props.viewType
            },
            leadType: "standard",
            leadData: applicationData,
            isAuthenticated: this.props.isAuthenticated,
            userProfileData: this.props.userProfileData,
            senderState: {
                generatedMessage: null,
                questions: this.state.questions
            },
            analyticsData: this.getAnalyticsData(),
            affiliation: this.props.affiliation,
            creditData: this.props.creditPayload,
            onSendSuccess: async (responseApplication) => {
                // TODO: Credit calculator logic should be moved to the credit calculator
                if (this.props.applicationVariant !== ApplicationVariant.CREDIT) {
                    hitLeadSent(responseApplication.new_property_notification_consent);
                }

                await this.sendMultiplePropertyApplications(values, responseApplication);

                // TODO: Credit calculator logic should be moved to the credit calculator
                if (this.props.applicationVariant === ApplicationVariant.CREDIT) {
                    creditCalculatorAnalytics.gtm.creditCalculatorEvent(CreditCalculatorGTMEvent.CREDIT_INSTALMENT_CALCULATOR, {
                        credit_action: CreditCalculatorInstalmentEventAction.CALL
                    });
                }

                this.onBeforeNextStepQueue.push(
                    () =>
                        new Promise((resolve) => {
                            {
                                const propertyPriceVisible =
                                    !!applicationData.property?.detail?.price && applicationData.property.detail.configuration.show_price;

                                if (propertyPriceVisible) {
                                    this.setState({
                                        propertyPriceState: {
                                            showPropertyPriceModal: true,
                                            userDecision: ApplicationPropertyPriceUserDecisionType.SENT
                                        }
                                    });
                                }

                                setTimeout(
                                    () => {
                                        resolve();
                                    },
                                    propertyPriceVisible ? 2000 : 0
                                );

                                this.setState(this.getClearFormState((applicationData.offer && applicationData.offer.detail) || undefined));

                                // handle new state
                                if (this.afterSuccessTimeout !== -1) {
                                    this.afterSuccessTimeout = window.setTimeout(
                                        () => this.setState({formPreviouslySent: true, applicationUuid: responseApplication.uuid}),
                                        0
                                    );
                                }
                            }
                        })
                );

                this.props.onSuccessfulSubmit?.(responseApplication, applicationData.offer?.detail, applicationData.property?.detail, undefined, async () => {
                    // TODO: {showPropertyPriceModal: true} trigger displaying overlay with vendor contact information,
                    // because this is not a modal step it sometimes caused problems if we want to skipp displaying the overlay.
                    // Reimplementation of whole modal step logic (ApplicationModalArray) is necessary if we want to change this approach.
                    while (this.onBeforeNextStepQueue.length > 0) {
                        const callback = this.onBeforeNextStepQueue.shift();
                        if (callback) {
                            await callback();
                        }
                    }
                });
            },
            postOptions: {
                on400ApplicationBlockedError: (error) => {
                    this.props.closeModal(true);
                    this.props.addWarningNotification("Przepraszamy, oferta na którą chcesz wysłać wiadomość została zarchiwizowana.", 7000);
                    hitRotatingApplicationError(this.props.source, this.props.sourceSection, applicationData, {
                        appError: error.appError as IAppError,
                        extraPayloadViewType: this.props.viewType ?? undefined
                    });
                    // TODO: remove this bugsnag notification when we confirm that blocking applications this way is not an issue
                    notifyBugsnag({name: "Application blocked info", message: "Application blocked info"}, "Application sent on an archived offer", {error});
                    setTimeout(() => window.location.reload(), 6000);
                },
                on400Response: (error) => {
                    hitRotatingApplicationError(this.props.source, this.props.sourceSection, applicationData, {
                        appError: error.appError as IAppError,
                        extraPayloadViewType: this.props.viewType ?? undefined
                    });
                }
            }
        });
    };

    private onPreSubmitResolve = noop;
    private onPreSubmitReject = noop;

    private getPropertyPriceState = (userDecision: ApplicationPropertyPriceUserDecisionType | null, show = true) => {
        return {propertyPriceState: {showPropertyPriceModal: show, userDecision}};
    };

    private sendMultiplePropertyApplications = async (values: IApplicationFormValues, responseApplication?: IApplication | null) => {
        if (
            this.props.applicationVariant === ApplicationVariant.PROPERTY_WITH_RETARGETING &&
            values.multiLeadProperties &&
            values.multiLeadProperties?.length > 0
        ) {
            this.setState({
                leadIsSubmitting: true,
                multiLeadIsSubmitting: true
            });
            const {multiLeadProperties} = values;

            if (responseApplication) {
                // remove current propertyId if lead was sent
                removeLastSeenOfferProperties([responseApplication.property]);
            }

            const selectedProperties = multiLeadProperties.map((propertyId) => this.props.lastSeenPropertiesDetails[propertyId]);
            const selectedPropertiesAsRecommended = selectedProperties.map((property) => transformPropertyDetailToRecommendedPropertyPayload(property));

            return this.props
                .sendPropertyMultiLead(
                    {
                        recommendedProperties: selectedPropertiesAsRecommended,
                        formValues: values
                    },
                    {
                        source: ApplicationSource.PreMultilead,
                        sourceSection: ApplicationSourceSection.PREMULTILEAD,
                        viewType: this.props.viewType,
                        isAuthenticated: this.props.isAuthenticated,
                        application: responseApplication,
                        refererApplicationUUID: responseApplication?.uuid,
                        originalFormParams: {
                            rooms: values.rooms,
                            questions: values.questions
                        },
                        recommendationsWithPriceFilter: false
                    },
                    lastSeenPropertiesCustomSection
                )
                .then((multiLeadPropertyApplicationResponse) => {
                    const appliedRecommendation = selectedPropertiesAsRecommended.map((property) => {
                        const foundMultiLeadApplicationResponse = multiLeadPropertyApplicationResponse.find(
                            ([_, recommendedProperty]) => recommendedProperty.id === property.id
                        );

                        if (!foundMultiLeadApplicationResponse) {
                            return null;
                        }

                        const [application] = foundMultiLeadApplicationResponse;
                        return transformRecommendedPropertyIntoMultiLeadData(property, {isPriceRevealed: shouldApplicationPriceBeenShown(application)});
                    });

                    const appliedRecommendationFiltered = appliedRecommendation.filter(Boolean) as IMultiLeadData[];

                    this.props.appendPreMultileadApplications(appliedRecommendationFiltered);

                    removeLastSeenOfferProperties(multiLeadProperties.map((propertyId) => propertyId));
                    lastSeenPropertiesLeadAnalytics.gtm.lastSeenPropertiesLeadEvent(LastSeenPropertiesGTMEventAction.OFFER_ASK);

                    return multiLeadPropertyApplicationResponse;
                })
                .finally(() => {
                    this.setState({
                        ...this.getClearFormState(this.props.leadData.offer?.detail),
                        leadIsSubmitting: false,
                        multiLeadIsSubmitting: false
                    });
                });
        }

        return Promise.resolve();
    };

    private onPreSubmit = (returnResolved = false, values: IApplicationFormValues): Promise<void> => {
        if (returnResolved) {
            return Promise.resolve();
        }

        if (!this.props.userProfileData || (this.props.userProfileData && this.props.userProfileData.email !== values.email)) {
            // Because we don't send an application right now, and we don't want to lose user data - we are trying to register user
            emailSignUp({email: values.email, type: UserAccountType.LOCAL, source: UserSource.APPLICATION_VISIBLE_PRICE})
                .then(async () => {
                    await this.props.afterInternalAuth(true, UserSource.APPLICATION_VISIBLE_PRICE);
                })
                // Do nothing on catch - probably user is already registered or error happened
                .catch(() => false);
        }

        return new Promise((resolve, reject) => {
            this.onPreSubmitResolve = () => {
                this.onBeforeNextStepQueue.push(() => {
                    this.setState(this.getPropertyPriceState(ApplicationPropertyPriceUserDecisionType.SENT));
                    return Promise.resolve();
                });

                conditionalPricesAnalytics.gtm.priceModalEvent(PriceModalGTMEvent.PRICE_ACCEPT);

                resolve();
            };
            this.onPreSubmitReject = () => {
                this.sendMultiplePropertyApplications(values).then(() => {
                    this.setState(this.getPropertyPriceState(ApplicationPropertyPriceUserDecisionType.CANCEL));

                    conditionalPricesAnalytics.gtm.priceModalEvent(PriceModalGTMEvent.PRICE_SKIP);
                    this.props.handleChangeUserBudget();

                    reject();
                });
            };

            this.setState(this.getPropertyPriceState(null));
        });
    };

    private onFormikSubmit: IFormikSubmitFn<IApplicationFormValues> = (values, formikHelpers) => {
        this.setState({leadIsSubmitting: true});

        // TODO: Until we rewrite/remove Application, keep this inline with use_lead_modal_send_main_lead
        const {offer, property} = this.props.leadData;

        const offerName = offer?.detail ? offer.detail.name : "";

        const completedValues = {
            ...values,
            text:
                this.props.customFormText ||
                values.text ||
                getGeneratedMessage({
                    offerType: offer?.detail?.type,
                    offerName: offerName,
                    propertyNumber: property?.detail?.number,
                    name: values.name,
                    rooms: property?.detail?.rooms,
                    area: property?.detail?.area
                })
        };

        const parsedLeadFormValues = getParsedLeadFormValues(completedValues);
        this.props.setSentLeadValues({
            ...initialEmptyApplicationValues,
            ...parsedLeadFormValues,
            questions: this.state.questions
        });

        const propertyPriceVisible = isLeadWithPriceConfirmation(this.props.leadData);
        /*
         * According to business requirements (CU-8692y4qyv) we need to show prices for specific developers - so we don't need to show
         * modal with confirmation of sending the application.
         */
        const forceShowPrice = this.props.leadData.vendor?.detail?.configuration.force_show_price;
        this.onPreSubmit(!propertyPriceVisible || forceShowPrice, completedValues)
            // The user confirmed sending the application - use normal flow
            .then(() => {
                handleFormikPost(this.onSubmit(completedValues, this.props.leadData), formikHelpers, {
                    onSuccess: () => {
                        this.props.addSuccessNotification(alertFormSuccessMessage);
                        this.setState((prevState) => ({
                            ...(prevState.multiLeadIsSubmitting
                                ? {initialFormState: prevState.initialFormState}
                                : this.getClearFormState(this.props.leadData.offer?.detail)),
                            leadIsSubmitting: prevState.multiLeadIsSubmitting
                        }));
                    },
                    onFieldError: () => {
                        this.setState({...this.getPropertyPriceState(null, false), leadIsSubmitting: false});

                        this.props.addWarningNotification(alertFormErrorMessage);
                    },
                    onNonFieldError: () => {
                        this.setState({...this.getPropertyPriceState(null, false), leadIsSubmitting: false});

                        this.props.addWarningNotification(alertFormErrorMessage);
                    }
                });
            })
            // The user rejected sending the application - use flow with additional user actions related to the property price
            .catch(() => {
                this.setState(this.getPropertyPriceState(ApplicationPropertyPriceUserDecisionType.CANCEL, true));
            });
    };

    //  Helpers
    private calculateRoomsField = (offer: IOfferDetailApplication | undefined) => {
        return calculateRoomsField({
            offer,
            propertyId: this.props.propertyId,
            userPreferencesFilters: this.props.userPreferencesFilters
        });
    };

    private getClearFormState = (offer: IOfferDetailApplication | undefined) => {
        return {
            initialFormState: {
                ...initialEmptyApplicationValues,
                rooms: this.calculateRoomsField(offer),
                text: this.props.customFormText
            }
        };
    };

    private wasInteractionHit = false;
    private onInteraction = () => {
        if (!this.wasInteractionHit) {
            this.wasInteractionHit = true;

            gtmCheckout(this.props.leadData, this.props.viewType, "standard");
        }
    };

    //  A helper that provides analytics data object, shared in algolytics tracking objects
    private getAnalyticsData(): IApplicationAnalyticsData {
        return getLeadAnalyticsData(this.props.leadData, this.props.viewType, this.props.source, this.props.sourceSection);
    }

    //  Render
    public render() {
        const {
            propertyPriceState: {showPropertyPriceModal, userDecision}
        } = this.state;

        return (
            <div css={scrollFormWrap}>
                {this.state.initialFormState && (
                    <div css={showPropertyPriceModal ? propertyPriceOverlay : null}>
                        {/* hideRooms: we show rooms in right column (in form) when we do not ask for property and we consider promotion or vendor (no offerId) application*/}
                        <div css={p(0)}>
                            <ApplicationForm
                                leadIsSubmitting={this.state.leadIsSubmitting}
                                onSubmit={this.onFormikSubmit}
                                initialValues={this.state.initialFormState}
                                onFormControlClick={this.onInteraction}
                                onChange={this.onChange}
                                formPreviouslySent={this.state.formPreviouslySent}
                                hideRooms={!!this.props.propertyId || this.props.hideRooms}
                                hideMessageSection={!!this.props.hideMessageSection}
                                offerId={this.props.offerId}
                                alwaysShowFields={this.props.alwaysShowFields} // For this test - when form is in modal, we do not hide fields.
                                storeHash={this.props.storeHash}
                                enableLegalDetailsHiding={this.props.isAuthenticated}
                                hideSubmitButton={this.props.hideSubmitButton}
                                source={this.props.source}
                                sourceSection={this.props.sourceSection}
                                hideRodo={this.props.hideRodo}
                                applicationMessageLogic={{
                                    currentState: {
                                        selectedQuestions: this.state.questions,
                                        messageType: this.state.messageType
                                    },
                                    helpers: {
                                        setMessageType: (messageType: MessageType) => {
                                            this.setState({
                                                messageType: messageType
                                            });
                                        },
                                        setSelectedQuestions: (questionsIds: IApplicationAdditionalQuestionTypes[]) => {
                                            this.setState({
                                                questions: questionsIds
                                            });
                                        }
                                    }
                                }}
                                getAnalyticsData={this.getAnalyticsData}
                                hideQuestions={this.props.hideQuestions}
                                closeModal={this.props.closeModal}
                                submitOnMount={this.props.submitOnMount}
                                applicationVariant={
                                    this.props.leadData.offer?.detail?.region.country !== Country.POLAND
                                        ? ApplicationVariant.DEFAULT
                                        : this.props.applicationVariant
                                }
                                customFormText={this.props.customFormText}
                                creditData={this.props.creditPayload}
                            />
                        </div>

                        {this.props.submitOnMount && (
                            <div css={mountSubmitMessage}>
                                <ApplicationSentSuccess />
                            </div>
                        )}

                        {showPropertyPriceModal &&
                            this.props.leadData.vendor?.detail &&
                            this.props.leadData.property?.detail &&
                            this.props.leadData.offer?.detail && (
                                <ApplicationPropertyPrice
                                    leadIsSubmitting={this.state.leadIsSubmitting}
                                    onModalClose={this.props.handlePropertyPriceModalClose}
                                    onSendConfirmation={this.onPreSubmitResolve}
                                    onSendRejection={this.onPreSubmitReject}
                                    property={this.props.leadData.property.detail}
                                    offer={this.props.leadData.offer.detail}
                                    userDecision={userDecision}
                                    vendor={this.props.leadData.vendor.detail}
                                />
                            )}
                    </div>
                )}
            </div>
        );
    }
}

const ApplicationConnected = connect<IStateProps, IActionsProps, IOwnProps, IRPStore>(mapStateToProps, mapActionsToProps)(ApplicationC);
export const Application = withRouter<IOwnProps, typeof ApplicationConnected>(ApplicationConnected);

//  Connect
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function mapStateToProps(state: IRPStore, props: IOwnProps): IStateProps {
    return {
        userPreferencesFilters: state.user.preferences?.data?.filters || {},
        isAuthenticated: state.isAuthenticated,
        userProfileData: state.user.profile.data,
        viewType: state.viewType.current,
        didSubmitOnMount: state.leadForm.didSubmitOnMount,
        lastSeenPropertiesDetails: state.property.lastSeenPropertiesDetails
    };
}

function mapActionsToProps(dispatch: Dispatch): IActionsProps {
    return {
        ...bindActionCreators(
            {
                afterPostApplicationActions,
                addAppliedOffers,
                resetApplicationPost,
                fetchUserProfile,
                authUserAfterApplicationSent,
                patchUserProfileFromApplication,
                addWarningNotification,
                addSuccessNotification,
                patchUserPreferences,
                afterInternalAuth,
                setSentLeadValues,
                sendPropertyMultiLead
            },
            dispatch
        )
    };
}

const scrollFormWrap = (theme: Theme) => css`
    height: calc(100% - 6.5rem);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: ${theme.colors.gray[400]};
    }

    &::-webkit-scrollbar {
        width: 6px;
        background-color: ${theme.colors.gray[400]};
        padding-left: 2px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: ${theme.colors.gray[600]};
    }
`;

const mountSubmitMessage = css`
    ${flex("center", "center")};
    background: white;
    position: fixed;
    inset: 0 0;
    z-index: 10000;

    ${onDesktop(css`
        position: absolute;
    `)}
`;

const propertyPriceOverlay = (theme: Theme) => css`
    @media (max-width: ${theme.breakpoints.md}) {
        max-height: 100vh;
        overflow: hidden;
    }
`;
