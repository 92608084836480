import React from "react";
import {css, Theme} from "@emotion/react";

import {borderRadius, flex, flexDirection, mt, p, pr} from "@pg-design/helpers-css";
import {InfoIcon} from "@pg-design/icons-module";
import {Loader} from "@pg-design/loader-module";
import {Text} from "@pg-design/text-module";
import {OFFER_TEST_IDS} from "@pg-mono/e2e-test-utils";

import {SubmitButton} from "./SubmitButton";

interface IProps {
    leadIsSubmitting: boolean;
    leadBtnDefaultText: string;
    leadBtnRevealPriceText: string;
}

export const ApplicationRevealButtonWithWrapper = (props: IProps) => {
    const leadBtnText = props.leadBtnRevealPriceText;

    const submitButton = (
        <SubmitButton
            dataTestId={OFFER_TEST_IDS.LEAD_FORM.SEND_LEAD_BUTTON}
            css={mt(2)}
            type="submit"
            variant="filled_primary"
            disabled={props.leadIsSubmitting}
        >
            {props.leadIsSubmitting ? <Loader /> : <>{leadBtnText}</>}
        </SubmitButton>
    );

    return (
        <div css={revealPriceButtonWrapperStyle}>
            {submitButton}
            <div css={[flex(), flexDirection("row")]}>
                <div css={iconWrapperStyle}>
                    <InfoIcon size="2.4" />
                </div>
                <Text color="#000" variant="info_txt_1">
                    W ciągu kilku sekund otrzymasz estymowaną cenę tej nieruchomości, a następnie deweloper skontaktuje się z Tobą i przedstawi szczegóły
                    oferty.
                </Text>
            </div>
        </div>
    );
};

const revealPriceButtonWrapperStyle = (theme: Theme) => css`
    ${mt(2)};
    ${p(0, 2, 2, 2)};
    display: flex;
    flex-direction: column;
    background-color: ${theme.colors.gray[300]};
    ${borderRadius(1)};
`;

const iconWrapperStyle = css`
    ${pr(1)};
    ${flex("center", "center")};
    flex: 0 0 2.4rem;
    width: 2.4rem;
    height: 2.4rem;
`;
import React from "react";
import {css, Theme} from "@emotion/react";

import {borderRadius, flex, flexDirection, mt, p, pr} from "@pg-design/helpers-css";
import {InfoIcon} from "@pg-design/icons-module";
import {Loader} from "@pg-design/loader-module";
import {Text} from "@pg-design/text-module";
import {OFFER_TEST_IDS} from "@pg-mono/e2e-test-utils";

import {SubmitButton} from "./SubmitButton";

interface IProps {
    leadIsSubmitting: boolean;
    leadBtnDefaultText: string;
    leadBtnRevealPriceText: string;
}

export const ApplicationRevealButtonWithWrapper = (props: IProps) => {
    const leadBtnText = props.leadBtnRevealPriceText;

    const submitButton = (
        <SubmitButton
            dataTestId={OFFER_TEST_IDS.LEAD_FORM.SEND_LEAD_BUTTON}
            css={mt(2)}
            type="submit"
            variant="filled_primary"
            disabled={props.leadIsSubmitting}
        >
            {props.leadIsSubmitting ? <Loader /> : <>{leadBtnText}</>}
        </SubmitButton>
    );

    return (
        <div css={revealPriceButtonWrapperStyle}>
            {submitButton}
            <div css={[flex(), flexDirection("row")]}>
                <div css={iconWrapperStyle}>
                    <InfoIcon size="2.4" />
                </div>
                <Text color="#000" variant="info_txt_1">
                    W ciągu kilku sekund otrzymasz estymowaną cenę tej nieruchomości, a następnie deweloper skontaktuje się z Tobą i przedstawi szczegóły
                    oferty.
                </Text>
            </div>
        </div>
    );
};

const revealPriceButtonWrapperStyle = (theme: Theme) => css`
    ${mt(2)};
    ${p(0, 2, 2, 2)};
    display: flex;
    flex-direction: column;
    background-color: ${theme.colors.gray[300]};
    ${borderRadius(1)};
`;

const iconWrapperStyle = css`
    ${pr(1)};
    ${flex("center", "center")};
    flex: 0 0 2.4rem;
    width: 2.4rem;
    height: 2.4rem;
`;
