import {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {uniq} from "@pg-mono/nodash";

import {IRPStore} from "../../app/rp_reducer";
import {Country} from "../../region/types/Country";
import {fetchRecommendations} from "../actions/fetch_recommendations";
import {MultiLeadRecommendationType} from "../types/MultiLeadRecommendationType";
import {getStorageSentApplicationsIdListByOffer} from "../utils/get_storage_sent_applications_id_list_by_offer";
import {useRecommendationsSelection} from "./use_recommendations_selection";
import {useTransformRecommendations} from "./use_transform_recommendations";

interface IProps {
    recommendationType: MultiLeadRecommendationType;
    price?: {lower?: number; upper?: number};
    distance?: number;
    storeHash: string;
    selectAllOnRefresh?: boolean;
    useAnalyticsRecommendations?: boolean;
}

export const useRecommendations = (props: IProps) => {
    const dispatch = useDispatch();
    const recommendedProperties = useSelector((store: IRPStore) => store.property.recommendedProperties);
    const recommendedOffers = useSelector((store: IRPStore) => store.offerDetail.recommendedOffers);

    const applicationData = useSelector((store: IRPStore) => store.application[props.storeHash]);
    const originalApplicationCountry = useSelector((store: IRPStore) => store.application[props.storeHash]?.offer?.detail?.region.country);
    const isAbroadOffer = !!originalApplicationCountry && originalApplicationCountry !== Country.POLAND;

    const [areRecommendationsFetching, setRecommendationsFetching] = useState(false);
    const [rejectedRecommendations, setRejectedRecommendations] = useState<number[]>([]);
    const [excludedOfferIds, setExcludedOfferIds] = useState<number[]>([]);

    const offerId = applicationData?.offer?.detail?.id;
    const propertyId = applicationData?.property?.detail?.id;

    const reFetchRecommendations = async () => {
        const filters = isAbroadOffer
            ? {} // ignore filters for abroad offers
            : {
                  ...(props.price?.lower ? {min_price: props.price.lower.toString()} : {}),
                  ...(props.price?.upper ? {max_price: props.price.upper.toString()} : {}),
                  ...(props.distance ? {radius: props.distance.toString()} : {})
              };

        // For abroad offers we need to fetch recommendations by region, for Poland by distance
        const sentApplicationIds = getStorageSentApplicationsIdListByOffer(applicationData?.offer?.detail);
        const sentApplicationIdsWithoutOfferId = sentApplicationIds.filter((id) => id !== offerId);

        await fetchRecommendations({
            offerId,
            propertyId,
            numberOfRooms: applicationData?.application?.rooms || null,
            excludedOfferIds: uniq([...excludedOfferIds, ...sentApplicationIdsWithoutOfferId]),
            filters
        })(dispatch);

        setRecommendationsFetching(false);
    };

    useEffect(() => {
        if (areRecommendationsFetching) {
            reFetchRecommendations();
        }
    }, [areRecommendationsFetching, props.price?.lower, props.price?.upper, props.distance]);

    const refreshRecommendations = () => {
        setRecommendationsFetching(true);
    };

    const params = {
        recommendedProperties,
        recommendedOffers,
        recommendationType: props.recommendationType,
        rejectedRecommendations,
        selectAllOnRefresh: props.selectAllOnRefresh
    };
    const recommendationsSelection = useRecommendationsSelection(params);
    const recommendations = useTransformRecommendations({...params, acceptedRecommendations: recommendationsSelection.selectedRecommendations});
    const rejectRecommendation = useCallback(
        (id: number) => {
            const offerId =
                props.recommendationType === MultiLeadRecommendationType.OFFER ? id : recommendedProperties.find((property) => property.id === id)?.offer.id;

            if (offerId) {
                setRejectedRecommendations((prev) => uniq([...prev, id]));
                setExcludedOfferIds((prev) => uniq([...prev, offerId]));
            }

            if (recommendations.length <= 1) {
                // try fetching new recommendations if there are no more recommendations
                setRecommendationsFetching(true);
            }
        },
        [setRejectedRecommendations, props.recommendationType, recommendations.length, recommendedProperties, recommendedOffers]
    );

    return {
        recommendationsOriginal: props.recommendationType === MultiLeadRecommendationType.PROPERTY ? recommendedProperties : recommendedOffers,
        recommendations,
        recommendationsSelection,
        rejectedRecommendations,
        rejectRecommendation,
        areRecommendationsFetching,
        isRecommendationListEmpty: !areRecommendationsFetching && recommendations.length === 0,
        refreshRecommendations
    };
};
import {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {uniq} from "@pg-mono/nodash";

import {IRPStore} from "../../app/rp_reducer";
import {Country} from "../../region/types/Country";
import {fetchRecommendations} from "../actions/fetch_recommendations";
import {MultiLeadRecommendationType} from "../types/MultiLeadRecommendationType";
import {getStorageSentApplicationsIdListByOffer} from "../utils/get_storage_sent_applications_id_list_by_offer";
import {useRecommendationsSelection} from "./use_recommendations_selection";
import {useTransformRecommendations} from "./use_transform_recommendations";

interface IProps {
    recommendationType: MultiLeadRecommendationType;
    price?: {lower?: number; upper?: number};
    distance?: number;
    storeHash: string;
    selectAllOnRefresh?: boolean;
    useAnalyticsRecommendations?: boolean;
}

export const useRecommendations = (props: IProps) => {
    const dispatch = useDispatch();
    const recommendedProperties = useSelector((store: IRPStore) => store.property.recommendedProperties);
    const recommendedOffers = useSelector((store: IRPStore) => store.offerDetail.recommendedOffers);

    const applicationData = useSelector((store: IRPStore) => store.application[props.storeHash]);
    const originalApplicationCountry = useSelector((store: IRPStore) => store.application[props.storeHash]?.offer?.detail?.region.country);
    const isAbroadOffer = !!originalApplicationCountry && originalApplicationCountry !== Country.POLAND;

    const [areRecommendationsFetching, setRecommendationsFetching] = useState(false);
    const [rejectedRecommendations, setRejectedRecommendations] = useState<number[]>([]);
    const [excludedOfferIds, setExcludedOfferIds] = useState<number[]>([]);

    const offerId = applicationData?.offer?.detail?.id;
    const propertyId = applicationData?.property?.detail?.id;

    const reFetchRecommendations = async () => {
        const filters = isAbroadOffer
            ? {} // ignore filters for abroad offers
            : {
                  ...(props.price?.lower ? {min_price: props.price.lower.toString()} : {}),
                  ...(props.price?.upper ? {max_price: props.price.upper.toString()} : {}),
                  ...(props.distance ? {radius: props.distance.toString()} : {})
              };

        // For abroad offers we need to fetch recommendations by region, for Poland by distance
        const sentApplicationIds = getStorageSentApplicationsIdListByOffer(applicationData?.offer?.detail);
        const sentApplicationIdsWithoutOfferId = sentApplicationIds.filter((id) => id !== offerId);

        await fetchRecommendations({
            offerId,
            propertyId,
            numberOfRooms: applicationData?.application?.rooms || null,
            excludedOfferIds: uniq([...excludedOfferIds, ...sentApplicationIdsWithoutOfferId]),
            filters
        })(dispatch);

        setRecommendationsFetching(false);
    };

    useEffect(() => {
        if (areRecommendationsFetching) {
            reFetchRecommendations();
        }
    }, [areRecommendationsFetching, props.price?.lower, props.price?.upper, props.distance]);

    const refreshRecommendations = () => {
        setRecommendationsFetching(true);
    };

    const params = {
        recommendedProperties,
        recommendedOffers,
        recommendationType: props.recommendationType,
        rejectedRecommendations,
        selectAllOnRefresh: props.selectAllOnRefresh
    };
    const recommendationsSelection = useRecommendationsSelection(params);
    const recommendations = useTransformRecommendations({...params, acceptedRecommendations: recommendationsSelection.selectedRecommendations});
    const rejectRecommendation = useCallback(
        (id: number) => {
            const offerId =
                props.recommendationType === MultiLeadRecommendationType.OFFER ? id : recommendedProperties.find((property) => property.id === id)?.offer.id;

            if (offerId) {
                setRejectedRecommendations((prev) => uniq([...prev, id]));
                setExcludedOfferIds((prev) => uniq([...prev, offerId]));
            }

            if (recommendations.length <= 1) {
                // try fetching new recommendations if there are no more recommendations
                setRecommendationsFetching(true);
            }
        },
        [setRejectedRecommendations, props.recommendationType, recommendations.length, recommendedProperties, recommendedOffers]
    );

    return {
        recommendationsOriginal: props.recommendationType === MultiLeadRecommendationType.PROPERTY ? recommendedProperties : recommendedOffers,
        recommendations,
        recommendationsSelection,
        rejectedRecommendations,
        rejectRecommendation,
        areRecommendationsFetching,
        isRecommendationListEmpty: !areRecommendationsFetching && recommendations.length === 0,
        refreshRecommendations
    };
};
