import React, {ReactNode, useEffect, useState} from "react";
import {css, SerializedStyles} from "@emotion/react";

import {ScrollToElement} from "../ScrollToElement";
import {ChatBotDotsAnimation} from "./ChatBotDotsAnimation";
import {ChatBotMessage} from "./ChatBotMessage";

export interface IChatBotConversationItemProps {
    actions?: React.ReactNode;
    messages: (string | ReactNode | IMessageConfig)[];
    initialVisibleMessagesCount?: number | null;
    className?: string;
    messageCss?: SerializedStyles;
    messageLoadingRender?: ReactNode;
}

interface IMessageConfig {
    messageContent: string | ReactNode;
    scrollToMessage?: boolean;
}

export const ChatBotConversationItem = (props: IChatBotConversationItemProps) => {
    const messageLoadingRender = props.messageLoadingRender ?? <ChatBotDotsAnimation />;
    const [visibleMessagesCount, setVisibleMessagesCount] = useState(props.initialVisibleMessagesCount ?? -1);

    const totalMessagesCount = props.messages.length;
    const showLoader = visibleMessagesCount < totalMessagesCount - 1;
    const showActions = visibleMessagesCount >= totalMessagesCount - 1;

    useEffect(() => {
        let newVisibleMessages = visibleMessagesCount;

        const interval = window.setInterval(() => {
            if (newVisibleMessages === totalMessagesCount + 1) {
                return window.clearInterval(interval);
            }

            setVisibleMessagesCount(newVisibleMessages++);
        }, 2000);

        return () => {
            if (interval) {
                window.clearInterval(interval);
            }
        };
    }, []);

    return (
        <div css={chatBotConversationItem} className={props.className}>
            {props.messages.map((message, index) => {
                if (index > visibleMessagesCount) {
                    return null;
                }

                if (isMessageConfig(message) && message.scrollToMessage) {
                    return (
                        <ScrollToElement key={`cci_${index}`} as="span">
                            <ChatBotMessage css={props.messageCss}>{message.messageContent}</ChatBotMessage>
                        </ScrollToElement>
                    );
                }

                if (isMessageConfig(message) && !message.scrollToMessage) {
                    return (
                        <ChatBotMessage key={`cci_${index}`} css={props.messageCss}>
                            {message.messageContent}
                        </ChatBotMessage>
                    );
                }

                if (!isMessageConfig(message)) {
                    return (
                        <ChatBotMessage key={`cci_${index}`} css={props.messageCss}>
                            {message}
                        </ChatBotMessage>
                    );
                }

                return null;
            })}

            {showActions && props.actions && props.actions}

            {showLoader && <>{messageLoadingRender}</>}
        </div>
    );
};

//  Utils
function isMessageConfig(message: string | ReactNode | IMessageConfig): message is IMessageConfig {
    return Boolean(typeof message === "object" && message && "messageContent" in message);
}

//  Styles
const chatBotConversationItem = css`
    display: flex;
    flex-direction: column;
`;
import React, {ReactNode, useEffect, useState} from "react";
import {css, SerializedStyles} from "@emotion/react";

import {ScrollToElement} from "../ScrollToElement";
import {ChatBotDotsAnimation} from "./ChatBotDotsAnimation";
import {ChatBotMessage} from "./ChatBotMessage";

export interface IChatBotConversationItemProps {
    actions?: React.ReactNode;
    messages: (string | ReactNode | IMessageConfig)[];
    initialVisibleMessagesCount?: number | null;
    className?: string;
    messageCss?: SerializedStyles;
    messageLoadingRender?: ReactNode;
}

interface IMessageConfig {
    messageContent: string | ReactNode;
    scrollToMessage?: boolean;
}

export const ChatBotConversationItem = (props: IChatBotConversationItemProps) => {
    const messageLoadingRender = props.messageLoadingRender ?? <ChatBotDotsAnimation />;
    const [visibleMessagesCount, setVisibleMessagesCount] = useState(props.initialVisibleMessagesCount ?? -1);

    const totalMessagesCount = props.messages.length;
    const showLoader = visibleMessagesCount < totalMessagesCount - 1;
    const showActions = visibleMessagesCount >= totalMessagesCount - 1;

    useEffect(() => {
        let newVisibleMessages = visibleMessagesCount;

        const interval = window.setInterval(() => {
            if (newVisibleMessages === totalMessagesCount + 1) {
                return window.clearInterval(interval);
            }

            setVisibleMessagesCount(newVisibleMessages++);
        }, 2000);

        return () => {
            if (interval) {
                window.clearInterval(interval);
            }
        };
    }, []);

    return (
        <div css={chatBotConversationItem} className={props.className}>
            {props.messages.map((message, index) => {
                if (index > visibleMessagesCount) {
                    return null;
                }

                if (isMessageConfig(message) && message.scrollToMessage) {
                    return (
                        <ScrollToElement key={`cci_${index}`} as="span">
                            <ChatBotMessage css={props.messageCss}>{message.messageContent}</ChatBotMessage>
                        </ScrollToElement>
                    );
                }

                if (isMessageConfig(message) && !message.scrollToMessage) {
                    return (
                        <ChatBotMessage key={`cci_${index}`} css={props.messageCss}>
                            {message.messageContent}
                        </ChatBotMessage>
                    );
                }

                if (!isMessageConfig(message)) {
                    return (
                        <ChatBotMessage key={`cci_${index}`} css={props.messageCss}>
                            {message}
                        </ChatBotMessage>
                    );
                }

                return null;
            })}

            {showActions && props.actions && props.actions}

            {showLoader && <>{messageLoadingRender}</>}
        </div>
    );
};

//  Utils
function isMessageConfig(message: string | ReactNode | IMessageConfig): message is IMessageConfig {
    return Boolean(typeof message === "object" && message && "messageContent" in message);
}

//  Styles
const chatBotConversationItem = css`
    display: flex;
    flex-direction: column;
`;
