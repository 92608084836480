import {hitGoogleTagManager} from "../../tracking/google_tag_manager/init_google_tag_manager";

export function hitMktOptinClick(check: boolean, variant: number) {
    hitGoogleTagManager({
        event: "marketing_approval",
        marketing_approval_action: `${check ? "check" : "uncheck"} - ${variant}`
    });
}
import {hitGoogleTagManager} from "../../tracking/google_tag_manager/init_google_tag_manager";

export function hitMktOptinClick(check: boolean, variant: number) {
    hitGoogleTagManager({
        event: "marketing_approval",
        marketing_approval_action: `${check ? "check" : "uncheck"} - ${variant}`
    });
}
