import {useEffect, useMemo, useState} from "react";

import {priceFormat} from "@pg-mono/string-utils";

import {IRegionEstimatedPriceSummary} from "../../region/hooks/use_region_estimated_price";
import {Country} from "../../region/types/Country";
import {MULTI_LEAD_ESTIMATE_PRICE_DELAY, REVEALED_PRICE_ESTIMATED_LOWER_FACTOR, REVEALED_PRICE_ESTIMATED_UPPER_FACTOR} from "../constants/multi_lead_constants";
import {IMultiLeadData} from "../types/IMultiLeadData";

interface IProps extends Pick<IMultiLeadData, "price" | "isPriceRevealed"> {
    regionEstimatedPriceSummary?: IRegionEstimatedPriceSummary;
    country?: Country;
    area?: number;
}

export interface IUseEstimatedPriceByAreaResult {
    estimatedPriceInfoText: string | null;
    isEstimatedPriceLoading: boolean;
    estimatedPriceMin?: number;
    estimatedPriceMax?: number;
}

export const useEstimatedPriceByArea = (props: IProps): IUseEstimatedPriceByAreaResult => {
    const [isEstimatedPriceLoading, setIsEstimatedPriceLoading] = useState(true);
    const [estimatedPriceMin, setEstimatedPriceMin] = useState(0);
    const [estimatedPriceMax, setEstimatedPriceMax] = useState(0);

    useEffect(() => {
        if (!props.isPriceRevealed && props.price) {
            setEstimatedPriceMin(props.price * REVEALED_PRICE_ESTIMATED_LOWER_FACTOR);
            setEstimatedPriceMax(props.price * REVEALED_PRICE_ESTIMATED_UPPER_FACTOR);
            setIsEstimatedPriceLoading(false);
            return;
        }

        if (!props.regionEstimatedPriceSummary?.avgPriceM2Min && !props.regionEstimatedPriceSummary?.avgPriceM2Max) {
            setIsEstimatedPriceLoading(false);
            return;
        }

        setTimeout(() => {
            setIsEstimatedPriceLoading(false);

            const avgPriceMin = props.regionEstimatedPriceSummary?.avgPriceM2Min || 0;
            const avgPriceMax = props.regionEstimatedPriceSummary?.avgPriceM2Max || 0;

            setEstimatedPriceMax(avgPriceMax * (props.area || 0));
            setEstimatedPriceMin(avgPriceMin * (props.area || 0));
        }, MULTI_LEAD_ESTIMATE_PRICE_DELAY);
    }, [props.regionEstimatedPriceSummary?.avgPriceM2Min, props.regionEstimatedPriceSummary?.avgPriceM2Max, props.isPriceRevealed, props.price]);

    const estimatedPriceInfoText = useMemo(() => {
        const priceFormatWithUnit = (price: number) => priceFormat(price, {unit: props.country === Country.POLAND ? "zł" : "eur"}) || "";

        if (props.isPriceRevealed && props.price) {
            return priceFormatWithUnit(props.price);
        }

        if (estimatedPriceMin || estimatedPriceMax) {
            return `${estimatedPriceMin ? priceFormatWithUnit(estimatedPriceMin) : ""}${estimatedPriceMin && estimatedPriceMax ? ` - ` : ""}${
                estimatedPriceMax ? priceFormatWithUnit(estimatedPriceMax) : ""
            }`;
        }

        return null;
    }, [props.country, estimatedPriceMin, estimatedPriceMax]);

    return {
        estimatedPriceInfoText,
        isEstimatedPriceLoading,
        estimatedPriceMin,
        estimatedPriceMax
    };
};
import {useEffect, useMemo, useState} from "react";

import {priceFormat} from "@pg-mono/string-utils";

import {IRegionEstimatedPriceSummary} from "../../region/hooks/use_region_estimated_price";
import {Country} from "../../region/types/Country";
import {MULTI_LEAD_ESTIMATE_PRICE_DELAY, REVEALED_PRICE_ESTIMATED_LOWER_FACTOR, REVEALED_PRICE_ESTIMATED_UPPER_FACTOR} from "../constants/multi_lead_constants";
import {IMultiLeadData} from "../types/IMultiLeadData";

interface IProps extends Pick<IMultiLeadData, "price" | "isPriceRevealed"> {
    regionEstimatedPriceSummary?: IRegionEstimatedPriceSummary;
    country?: Country;
    area?: number;
}

export interface IUseEstimatedPriceByAreaResult {
    estimatedPriceInfoText: string | null;
    isEstimatedPriceLoading: boolean;
    estimatedPriceMin?: number;
    estimatedPriceMax?: number;
}

export const useEstimatedPriceByArea = (props: IProps): IUseEstimatedPriceByAreaResult => {
    const [isEstimatedPriceLoading, setIsEstimatedPriceLoading] = useState(true);
    const [estimatedPriceMin, setEstimatedPriceMin] = useState(0);
    const [estimatedPriceMax, setEstimatedPriceMax] = useState(0);

    useEffect(() => {
        if (!props.isPriceRevealed && props.price) {
            setEstimatedPriceMin(props.price * REVEALED_PRICE_ESTIMATED_LOWER_FACTOR);
            setEstimatedPriceMax(props.price * REVEALED_PRICE_ESTIMATED_UPPER_FACTOR);
            setIsEstimatedPriceLoading(false);
            return;
        }

        if (!props.regionEstimatedPriceSummary?.avgPriceM2Min && !props.regionEstimatedPriceSummary?.avgPriceM2Max) {
            setIsEstimatedPriceLoading(false);
            return;
        }

        setTimeout(() => {
            setIsEstimatedPriceLoading(false);

            const avgPriceMin = props.regionEstimatedPriceSummary?.avgPriceM2Min || 0;
            const avgPriceMax = props.regionEstimatedPriceSummary?.avgPriceM2Max || 0;

            setEstimatedPriceMax(avgPriceMax * (props.area || 0));
            setEstimatedPriceMin(avgPriceMin * (props.area || 0));
        }, MULTI_LEAD_ESTIMATE_PRICE_DELAY);
    }, [props.regionEstimatedPriceSummary?.avgPriceM2Min, props.regionEstimatedPriceSummary?.avgPriceM2Max, props.isPriceRevealed, props.price]);

    const estimatedPriceInfoText = useMemo(() => {
        const priceFormatWithUnit = (price: number) => priceFormat(price, {unit: props.country === Country.POLAND ? "zł" : "eur"}) || "";

        if (props.isPriceRevealed && props.price) {
            return priceFormatWithUnit(props.price);
        }

        if (estimatedPriceMin || estimatedPriceMax) {
            return `${estimatedPriceMin ? priceFormatWithUnit(estimatedPriceMin) : ""}${estimatedPriceMin && estimatedPriceMax ? ` - ` : ""}${
                estimatedPriceMax ? priceFormatWithUnit(estimatedPriceMax) : ""
            }`;
        }

        return null;
    }, [props.country, estimatedPriceMin, estimatedPriceMax]);

    return {
        estimatedPriceInfoText,
        isEstimatedPriceLoading,
        estimatedPriceMin,
        estimatedPriceMax
    };
};
