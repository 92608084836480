import {hitGoogleTagManager} from "./init_google_tag_manager";

type EventTypeRotatingPhoneNumber = "phone_number_reveal" | "phone_number_call";
type PhonePlaceTypeRotatingPhoneNumber = "sidebar" | "modal";

interface IGtmRotatingPhoneNumbers {
    event: EventTypeRotatingPhoneNumber;
    phone_place: PhonePlaceTypeRotatingPhoneNumber;
}

/**
 * When you click on "show number"
 */
export const gtmRotatingPhoneNumbersReveal = (place: PhonePlaceTypeRotatingPhoneNumber = "sidebar"): boolean => {
    const gtmData: IGtmRotatingPhoneNumbers = {
        event: "phone_number_reveal",
        phone_place: place
    };
    hitGoogleTagManager(gtmData);
    return true;
};

/**
 * While clicking on the number to call
 */
export const gtmRotatingPhoneNumbersCall = (place: PhonePlaceTypeRotatingPhoneNumber = "sidebar"): boolean => {
    const gtmData: IGtmRotatingPhoneNumbers = {
        event: "phone_number_call",
        phone_place: place
    };
    hitGoogleTagManager(gtmData);
    return true;
};
import {hitGoogleTagManager} from "./init_google_tag_manager";

type EventTypeRotatingPhoneNumber = "phone_number_reveal" | "phone_number_call";
type PhonePlaceTypeRotatingPhoneNumber = "sidebar" | "modal";

interface IGtmRotatingPhoneNumbers {
    event: EventTypeRotatingPhoneNumber;
    phone_place: PhonePlaceTypeRotatingPhoneNumber;
}

/**
 * When you click on "show number"
 */
export const gtmRotatingPhoneNumbersReveal = (place: PhonePlaceTypeRotatingPhoneNumber = "sidebar"): boolean => {
    const gtmData: IGtmRotatingPhoneNumbers = {
        event: "phone_number_reveal",
        phone_place: place
    };
    hitGoogleTagManager(gtmData);
    return true;
};

/**
 * While clicking on the number to call
 */
export const gtmRotatingPhoneNumbersCall = (place: PhonePlaceTypeRotatingPhoneNumber = "sidebar"): boolean => {
    const gtmData: IGtmRotatingPhoneNumbers = {
        event: "phone_number_call",
        phone_place: place
    };
    hitGoogleTagManager(gtmData);
    return true;
};
