export const deg2rad = (deg: number): number => deg * (Math.PI / 180);

// units: 1 = kilometres, 1000 = meters
export const countPosition = (lat1: number, lon1: number, lat2: number, lon2: number, radix = 2, unit: 1 | 1000 = 1) => {
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1); // deg2rad below
    const dLon = deg2rad(lon2 - lon1);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c * unit; // Distance in km

    return d.toFixed(radix);
};

export const countDistance = (subjectCoords: {lat: number; lng: number}, targetCoords: {lat: number; lng: number}): number => {
    return parseInt(countPosition(targetCoords.lat, targetCoords.lng, subjectCoords.lat, subjectCoords.lng, 2, 1000), 10);
};
export const deg2rad = (deg: number): number => deg * (Math.PI / 180);

// units: 1 = kilometres, 1000 = meters
export const countPosition = (lat1: number, lon1: number, lat2: number, lon2: number, radix = 2, unit: 1 | 1000 = 1) => {
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1); // deg2rad below
    const dLon = deg2rad(lon2 - lon1);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c * unit; // Distance in km

    return d.toFixed(radix);
};

export const countDistance = (subjectCoords: {lat: number; lng: number}, targetCoords: {lat: number; lng: number}): number => {
    return parseInt(countPosition(targetCoords.lat, targetCoords.lng, subjectCoords.lat, subjectCoords.lng, 2, 1000), 10);
};
