import {useSelector} from "react-redux";

import {IRPStore} from "../../app/rp_reducer";

interface IProps {
    offerId?: number;
    propertyId?: number;
}

export const useRegionByOfferOrPropertyId = (props: IProps) => {
    const property = useSelector((state: IRPStore) => state.property.property);
    const offer = useSelector((state: IRPStore) => state.offerDetail.offer);

    if (props.propertyId && property?.id === props.propertyId) {
        return property.offer.region;
    }

    if (props.offerId && props.offerId === offer?.id) {
        return offer.region;
    }

    return null;
};
import {useSelector} from "react-redux";

import {IRPStore} from "../../app/rp_reducer";

interface IProps {
    offerId?: number;
    propertyId?: number;
}

export const useRegionByOfferOrPropertyId = (props: IProps) => {
    const property = useSelector((state: IRPStore) => state.property.property);
    const offer = useSelector((state: IRPStore) => state.offerDetail.offer);

    if (props.propertyId && property?.id === props.propertyId) {
        return property.offer.region;
    }

    if (props.offerId && props.offerId === offer?.id) {
        return offer.region;
    }

    return null;
};
