import {Country} from "../types/Country";
import {CountryEN} from "../types/CountryEN";
import {CountryPL} from "../types/CountryPL";

type Lang = "en" | "pl";

type CountryName<T extends Lang> = T extends "pl" ? CountryPL : CountryEN;

export const getCountryName = <T extends Lang = "en">(country: Country | undefined, lang: T = "en" as T): CountryName<T> | undefined => {
    switch (country) {
        case Country.POLAND:
            return (lang === "en" ? CountryEN.POLAND : CountryPL.POLAND) as CountryName<T>;
        case Country.SPAIN:
            return (lang === "en" ? CountryEN.SPAIN : CountryPL.SPAIN) as CountryName<T>;
        default:
            return undefined;
    }
};
import {Country} from "../types/Country";
import {CountryEN} from "../types/CountryEN";
import {CountryPL} from "../types/CountryPL";

type Lang = "en" | "pl";

type CountryName<T extends Lang> = T extends "pl" ? CountryPL : CountryEN;

export const getCountryName = <T extends Lang = "en">(country: Country | undefined, lang: T = "en" as T): CountryName<T> | undefined => {
    switch (country) {
        case Country.POLAND:
            return (lang === "en" ? CountryEN.POLAND : CountryPL.POLAND) as CountryName<T>;
        case Country.SPAIN:
            return (lang === "en" ? CountryEN.SPAIN : CountryPL.SPAIN) as CountryName<T>;
        default:
            return undefined;
    }
};
