import React, {FC, useCallback, useState} from "react";
import {css} from "@emotion/react";

import {CenterBox} from "@pg-design/grid-module";
import {Loader} from "@pg-design/loader-module";
import {notifyBugsnagClient} from "@pg-mono/bugsnag-client";
import {useIsMounted} from "@pg-mono/hooks";
import type {ICircle, IMarker, IMarkerEventCallback} from "@pg-mono/open-street-map";
import {convertToCountryLatLngLiteral, LazyOpenStreetMap} from "@pg-mono/open-street-map";
import {priceFormat} from "@pg-mono/string-utils";
import {useUserDevice} from "@pg-mono/user-device";

import {osmPublicTileUrl} from "../../../../app/read_rp_environment_variables";
import {Country} from "../../../../region/types/Country";
import {ViewType} from "../../../../view_type/ViewType";
import {useOpenStreetMapAlgolyticsTracking} from "../../../hooks/use_open_street_map_algolytics_tracking";
import {IMapOffer} from "../../../types/IMapOffer";
import {calcCircleOnMapRadius} from "../../../utils/calc_circle_on_map_radius";
import {MarkerPopupWithDistance} from "./MarkerPopupWithDistance";
import {MobileMapInfoWindow} from "./MobileMapInfoWindow";

interface IProps {
    viewType: ViewType | null;
    offers: IMapOffer[];
    customMarkers?: IMarker[];
    originalMarkerCoordinates: [number, number];
    originalMarkerCountry: Country;
    originalMarkerIcon: string;
    originalMarkerLabel?: string | null;
    hideOriginalMarkerOnMap?: boolean;
    getMarkerIcon: (offerId: number) => string;
    getMarkerLabel?: (offerId: number) => string;
    hoveredItemID?: number | null;
    drawCircleAroundMarkers?: boolean;
    additionalCircleRadiusInMeters?: number;
    disableRadiusCalcByMarkers?: boolean;
    boundsPadding?: [number, number];
    boundsPaddingTopLeft?: [number, number];
    boundsPaddingBottomRight?: [number, number];
    onMobileInfoWindowOpen?: (id: number) => void;
    onMobileInfoWindowClose?: () => void;
    onDesktopInfoWindowOpen?: (id: number) => void;
    onMobileInfoWindowClick?: (id: number) => void;
}

const DEFAULT_ADDITIONAL_RADIUS_TO_CIRCLE_IN_METERS = 500;

const OSMGenericNearbyOffersMapC: FC<IProps> = (props) => {
    const {
        originalMarkerCoordinates,
        originalMarkerCountry,
        offers,
        viewType,
        originalMarkerIcon,
        originalMarkerLabel,
        getMarkerIcon,
        getMarkerLabel,
        onDesktopInfoWindowOpen,
        onMobileInfoWindowOpen,
        onMobileInfoWindowClose,
        onMobileInfoWindowClick,
        hoveredItemID = null,
        drawCircleAroundMarkers = false,
        additionalCircleRadiusInMeters = DEFAULT_ADDITIONAL_RADIUS_TO_CIRCLE_IN_METERS
    } = props;
    const [activeMobileInfoWindowOfferId, setActiveMobileInfoWindowOfferId] = useState<null | number>(null);
    const activeMobileInfoWindowOffer = offers.find((property) => property.id === activeMobileInfoWindowOfferId);
    const {isMobile} = useUserDevice();
    const isMounted = useIsMounted();

    const osmAlgolyticsTracking = useOpenStreetMapAlgolyticsTracking(viewType);

    const handleOnMobileInfoWindowClose = (event: React.MouseEvent) => {
        event.stopPropagation(); // to avoid call handleOnMobileInfoWindowClick

        setActiveMobileInfoWindowOfferId(null);
        onMobileInfoWindowClose && onMobileInfoWindowClose();
    };

    const handleOnMobileInfoWindowClick = () => {
        if (activeMobileInfoWindowOfferId && onMobileInfoWindowClick) {
            onMobileInfoWindowClick(activeMobileInfoWindowOfferId);
        }
    };

    const nearbyOfferMarkers: IMarker[] = props.offers.map((offer) => ({
        id: offer.id,
        coords: convertToCountryLatLngLiteral(offer.coordinates, offer.region.country),
        icon: {
            url: getMarkerIcon(offer.id),
            sizes: [32, 32]
        },
        popup: isMobile ? undefined : () => <MarkerPopupWithDistance {...offer} />,
        label: getMarkerLabel ? getMarkerLabel?.(offer.id) : offer.price ? priceFormat(offer.price) : undefined,
        isPopupInitiallyOpened: hoveredItemID === offer.id
    }));

    const originalMarker: IMarker = {
        id: "original",
        coords: convertToCountryLatLngLiteral(originalMarkerCoordinates, originalMarkerCountry),
        label: originalMarkerLabel || undefined,
        icon: {
            url: originalMarkerIcon,
            sizes: [30, 38]
        }
    };

    const markers = [...nearbyOfferMarkers, ...(props.hideOriginalMarkerOnMap ? [] : [originalMarker]), ...(props.customMarkers || [])];

    const onMarkerClick: IMarkerEventCallback = useCallback(
        ({marker}) => {
            const offerId = marker.id as number;

            if (isMobile) {
                setActiveMobileInfoWindowOfferId(offerId);
                onMobileInfoWindowOpen && onMobileInfoWindowOpen(offerId);
            } else {
                onDesktopInfoWindowOpen && onDesktopInfoWindowOpen(offerId);
            }
        },
        [isMobile, setActiveMobileInfoWindowOfferId, onMobileInfoWindowOpen, onDesktopInfoWindowOpen]
    );

    const circles: ICircle[] | undefined = drawCircleAroundMarkers
        ? [
              {
                  center: {lat: originalMarkerCoordinates[1], lng: originalMarkerCoordinates[0]},
                  radius: props.disableRadiusCalcByMarkers
                      ? additionalCircleRadiusInMeters
                      : calcCircleOnMapRadius(
                            originalMarkerCoordinates,
                            props.offers.map((offer) => offer.coordinates),
                            additionalCircleRadiusInMeters
                        ),
                  pathOptions: {
                      color: "#EBFF00",
                      opacity: 0.3
                  }
              }
          ]
        : [];

    return isMounted ? (
        <>
            <React.Suspense
                fallback={
                    <CenterBox>
                        <Loader />
                    </CenterBox>
                }
            >
                <div css={mapHolderSection}>
                    <LazyOpenStreetMap
                        {...osmAlgolyticsTracking}
                        circles={circles}
                        markers={markers}
                        onMarkerClick={onMarkerClick}
                        maxZoom={16}
                        scrollWheelZoom={false}
                        boundsPadding={props.boundsPadding || (drawCircleAroundMarkers ? [0, 0] : undefined)}
                        boundsPaddingTopLeft={props.boundsPaddingTopLeft}
                        boundsPaddingBottomRight={props.boundsPaddingBottomRight}
                        tileUrl={originalMarkerCountry !== Country.POLAND ? osmPublicTileUrl : null}
                        onMarkerInvalidCoords={(marker) => {
                            const message = "Invalid marker coords in OSMGenericNearbyOffersMapC";

                            notifyBugsnagClient(new Error(message), message, JSON.stringify(marker));
                        }}
                    />
                    {isMobile && activeMobileInfoWindowOfferId && (
                        <MobileMapInfoWindow
                            onClick={handleOnMobileInfoWindowClick}
                            onClose={handleOnMobileInfoWindowClose}
                            offer={activeMobileInfoWindowOffer}
                        />
                    )}
                </div>
            </React.Suspense>
        </>
    ) : null;
};

export const OSMGenericNearbyOffersMap = React.memo(OSMGenericNearbyOffersMapC);

const mapHolderSection = css`
    position: absolute;
    inset: 0;
`;
import React, {FC, useCallback, useState} from "react";
import {css} from "@emotion/react";

import {CenterBox} from "@pg-design/grid-module";
import {Loader} from "@pg-design/loader-module";
import {notifyBugsnagClient} from "@pg-mono/bugsnag-client";
import {useIsMounted} from "@pg-mono/hooks";
import type {ICircle, IMarker, IMarkerEventCallback} from "@pg-mono/open-street-map";
import {convertToCountryLatLngLiteral, LazyOpenStreetMap} from "@pg-mono/open-street-map";
import {priceFormat} from "@pg-mono/string-utils";
import {useUserDevice} from "@pg-mono/user-device";

import {osmPublicTileUrl} from "../../../../app/read_rp_environment_variables";
import {Country} from "../../../../region/types/Country";
import {ViewType} from "../../../../view_type/ViewType";
import {useOpenStreetMapAlgolyticsTracking} from "../../../hooks/use_open_street_map_algolytics_tracking";
import {IMapOffer} from "../../../types/IMapOffer";
import {calcCircleOnMapRadius} from "../../../utils/calc_circle_on_map_radius";
import {MarkerPopupWithDistance} from "./MarkerPopupWithDistance";
import {MobileMapInfoWindow} from "./MobileMapInfoWindow";

interface IProps {
    viewType: ViewType | null;
    offers: IMapOffer[];
    customMarkers?: IMarker[];
    originalMarkerCoordinates: [number, number];
    originalMarkerCountry: Country;
    originalMarkerIcon: string;
    originalMarkerLabel?: string | null;
    hideOriginalMarkerOnMap?: boolean;
    getMarkerIcon: (offerId: number) => string;
    getMarkerLabel?: (offerId: number) => string;
    hoveredItemID?: number | null;
    drawCircleAroundMarkers?: boolean;
    additionalCircleRadiusInMeters?: number;
    disableRadiusCalcByMarkers?: boolean;
    boundsPadding?: [number, number];
    boundsPaddingTopLeft?: [number, number];
    boundsPaddingBottomRight?: [number, number];
    onMobileInfoWindowOpen?: (id: number) => void;
    onMobileInfoWindowClose?: () => void;
    onDesktopInfoWindowOpen?: (id: number) => void;
    onMobileInfoWindowClick?: (id: number) => void;
}

const DEFAULT_ADDITIONAL_RADIUS_TO_CIRCLE_IN_METERS = 500;

const OSMGenericNearbyOffersMapC: FC<IProps> = (props) => {
    const {
        originalMarkerCoordinates,
        originalMarkerCountry,
        offers,
        viewType,
        originalMarkerIcon,
        originalMarkerLabel,
        getMarkerIcon,
        getMarkerLabel,
        onDesktopInfoWindowOpen,
        onMobileInfoWindowOpen,
        onMobileInfoWindowClose,
        onMobileInfoWindowClick,
        hoveredItemID = null,
        drawCircleAroundMarkers = false,
        additionalCircleRadiusInMeters = DEFAULT_ADDITIONAL_RADIUS_TO_CIRCLE_IN_METERS
    } = props;
    const [activeMobileInfoWindowOfferId, setActiveMobileInfoWindowOfferId] = useState<null | number>(null);
    const activeMobileInfoWindowOffer = offers.find((property) => property.id === activeMobileInfoWindowOfferId);
    const {isMobile} = useUserDevice();
    const isMounted = useIsMounted();

    const osmAlgolyticsTracking = useOpenStreetMapAlgolyticsTracking(viewType);

    const handleOnMobileInfoWindowClose = (event: React.MouseEvent) => {
        event.stopPropagation(); // to avoid call handleOnMobileInfoWindowClick

        setActiveMobileInfoWindowOfferId(null);
        onMobileInfoWindowClose && onMobileInfoWindowClose();
    };

    const handleOnMobileInfoWindowClick = () => {
        if (activeMobileInfoWindowOfferId && onMobileInfoWindowClick) {
            onMobileInfoWindowClick(activeMobileInfoWindowOfferId);
        }
    };

    const nearbyOfferMarkers: IMarker[] = props.offers.map((offer) => ({
        id: offer.id,
        coords: convertToCountryLatLngLiteral(offer.coordinates, offer.region.country),
        icon: {
            url: getMarkerIcon(offer.id),
            sizes: [32, 32]
        },
        popup: isMobile ? undefined : () => <MarkerPopupWithDistance {...offer} />,
        label: getMarkerLabel ? getMarkerLabel?.(offer.id) : offer.price ? priceFormat(offer.price) : undefined,
        isPopupInitiallyOpened: hoveredItemID === offer.id
    }));

    const originalMarker: IMarker = {
        id: "original",
        coords: convertToCountryLatLngLiteral(originalMarkerCoordinates, originalMarkerCountry),
        label: originalMarkerLabel || undefined,
        icon: {
            url: originalMarkerIcon,
            sizes: [30, 38]
        }
    };

    const markers = [...nearbyOfferMarkers, ...(props.hideOriginalMarkerOnMap ? [] : [originalMarker]), ...(props.customMarkers || [])];

    const onMarkerClick: IMarkerEventCallback = useCallback(
        ({marker}) => {
            const offerId = marker.id as number;

            if (isMobile) {
                setActiveMobileInfoWindowOfferId(offerId);
                onMobileInfoWindowOpen && onMobileInfoWindowOpen(offerId);
            } else {
                onDesktopInfoWindowOpen && onDesktopInfoWindowOpen(offerId);
            }
        },
        [isMobile, setActiveMobileInfoWindowOfferId, onMobileInfoWindowOpen, onDesktopInfoWindowOpen]
    );

    const circles: ICircle[] | undefined = drawCircleAroundMarkers
        ? [
              {
                  center: {lat: originalMarkerCoordinates[1], lng: originalMarkerCoordinates[0]},
                  radius: props.disableRadiusCalcByMarkers
                      ? additionalCircleRadiusInMeters
                      : calcCircleOnMapRadius(
                            originalMarkerCoordinates,
                            props.offers.map((offer) => offer.coordinates),
                            additionalCircleRadiusInMeters
                        ),
                  pathOptions: {
                      color: "#EBFF00",
                      opacity: 0.3
                  }
              }
          ]
        : [];

    return isMounted ? (
        <>
            <React.Suspense
                fallback={
                    <CenterBox>
                        <Loader />
                    </CenterBox>
                }
            >
                <div css={mapHolderSection}>
                    <LazyOpenStreetMap
                        {...osmAlgolyticsTracking}
                        circles={circles}
                        markers={markers}
                        onMarkerClick={onMarkerClick}
                        maxZoom={16}
                        scrollWheelZoom={false}
                        boundsPadding={props.boundsPadding || (drawCircleAroundMarkers ? [0, 0] : undefined)}
                        boundsPaddingTopLeft={props.boundsPaddingTopLeft}
                        boundsPaddingBottomRight={props.boundsPaddingBottomRight}
                        tileUrl={originalMarkerCountry !== Country.POLAND ? osmPublicTileUrl : null}
                        onMarkerInvalidCoords={(marker) => {
                            const message = "Invalid marker coords in OSMGenericNearbyOffersMapC";

                            notifyBugsnagClient(new Error(message), message, JSON.stringify(marker));
                        }}
                    />
                    {isMobile && activeMobileInfoWindowOfferId && (
                        <MobileMapInfoWindow
                            onClick={handleOnMobileInfoWindowClick}
                            onClose={handleOnMobileInfoWindowClose}
                            offer={activeMobileInfoWindowOffer}
                        />
                    )}
                </div>
            </React.Suspense>
        </>
    ) : null;
};

export const OSMGenericNearbyOffersMap = React.memo(OSMGenericNearbyOffersMapC);

const mapHolderSection = css`
    position: absolute;
    inset: 0;
`;
