import React from "react";
import styled from "@emotion/styled";

import {calculateRemSize, pointer, Size} from "@pg-design/helpers-css";
import {CloseIcon} from "@pg-design/icons-module";

interface IProps {
    className?: string;
    onClick: () => void;
    size?: string;
    top?: Size;
    right?: Size;
}

export const CloseIconButton = (props: IProps) => {
    const {top = 3, right = 3} = props;

    return (
        <CloseIconWrapper className={props.className} top={top} right={right}>
            <CloseIcon onClick={props.onClick} size={props.size || "2.4"} />
        </CloseIconWrapper>
    );
};

const CloseIconWrapper = styled.div<Pick<IProps, "top" | "right">>`
    position: absolute;
    top: ${(props) => calculateRemSize(props.top)};
    right: ${(props) => calculateRemSize(props.right)};
    ${pointer};
`;
import React from "react";
import styled from "@emotion/styled";

import {calculateRemSize, pointer, Size} from "@pg-design/helpers-css";
import {CloseIcon} from "@pg-design/icons-module";

interface IProps {
    className?: string;
    onClick: () => void;
    size?: string;
    top?: Size;
    right?: Size;
}

export const CloseIconButton = (props: IProps) => {
    const {top = 3, right = 3} = props;

    return (
        <CloseIconWrapper className={props.className} top={top} right={right}>
            <CloseIcon onClick={props.onClick} size={props.size || "2.4"} />
        </CloseIconWrapper>
    );
};

const CloseIconWrapper = styled.div<Pick<IProps, "top" | "right">>`
    position: absolute;
    top: ${(props) => calculateRemSize(props.top)};
    right: ${(props) => calculateRemSize(props.right)};
    ${pointer};
`;
