import {useEffect, useState} from "react";

import {useGetOfferDetailApplicationQuery} from "../../offer/api/get_offer_detail";
import {IOfferDetailApplication} from "../../offer/types/IOfferDetailApplication";
import {useGetPropertyDetailApplicationQuery} from "../../property/api/get_property_detail";
import {IPropertyDetailApplication} from "../../property/types/IPropertyDetailApplication";
import {useAppDispatch} from "../../store/hooks";
import {IAppDispatch} from "../../store/store";
import {useGetVendorDetailApplicationQuery} from "../../vendor/api/get_vendor_detail";
import {IVendorDetailApplication} from "../../vendor/types/IVendorDetailApplication";
import {getBigDataPriceRange} from "../utils/get_big_data_price_range";
import {useConversationModalAbTest} from "./use_conversation_modal_ab_test";
import {useOpenedLeadModalRequiredParams} from "./use_lead_modal_required_params";

export function usePriceConversationPrice() {
    const [isPriceAvailable, setIsPriceAvailable] = useState<boolean | null>(null);

    const dispatch = useAppDispatch();
    const {vendorId, offerId, propertyId} = useOpenedLeadModalRequiredParams();
    const {abTestVariant: conversationModalVariant} = useConversationModalAbTest();

    const isWithoutABTestScenario = conversationModalVariant === 0;
    const isCalculationDisabled = isWithoutABTestScenario || !offerId || !propertyId;

    const vendorDetailQuery = useGetVendorDetailApplicationQuery(vendorId, {skip: isCalculationDisabled});
    const propertyDetailQuery = useGetPropertyDetailApplicationQuery(propertyId as number, {skip: isCalculationDisabled});
    const offerDetailQuery = useGetOfferDetailApplicationQuery(offerId as number, {skip: isCalculationDisabled});

    const isSuccess = vendorDetailQuery.isSuccess && propertyDetailQuery.isSuccess && offerDetailQuery.isSuccess;

    useEffect(() => {
        // This calculation is used only in AB test scenario and if it's a property modal
        if (isCalculationDisabled) {
            setIsPriceAvailable(false);
            return;
        }

        if (isPriceAvailable === null && vendorDetailQuery.data && propertyDetailQuery.data && offerDetailQuery.data) {
            isLeadModalPriceAvailable(propertyDetailQuery.data, offerDetailQuery.data, vendorDetailQuery.data, dispatch).then((priceAvailable) => {
                setIsPriceAvailable(priceAvailable);
            });
        }
    }, [isSuccess]);

    return {
        isPriceAvailable
    };
}

async function isLeadModalPriceAvailable(
    property: IPropertyDetailApplication,
    offer: IOfferDetailApplication,
    vendor: IVendorDetailApplication,
    dispatch: IAppDispatch
) {
    const leadModalPriceAvailable = !vendor.configuration.force_show_price && !property.configuration.show_price;

    if (!leadModalPriceAvailable) {
        return false;
    }

    if (property.is_price_set) {
        return true;
    }

    const regionEstimatedPrice = await getBigDataPriceRange(offer, property, dispatch);

    if (regionEstimatedPrice && regionEstimatedPrice.length) {
        return true;
    }

    return false;
}
import {useEffect, useState} from "react";

import {useGetOfferDetailApplicationQuery} from "../../offer/api/get_offer_detail";
import {IOfferDetailApplication} from "../../offer/types/IOfferDetailApplication";
import {useGetPropertyDetailApplicationQuery} from "../../property/api/get_property_detail";
import {IPropertyDetailApplication} from "../../property/types/IPropertyDetailApplication";
import {useAppDispatch} from "../../store/hooks";
import {IAppDispatch} from "../../store/store";
import {useGetVendorDetailApplicationQuery} from "../../vendor/api/get_vendor_detail";
import {IVendorDetailApplication} from "../../vendor/types/IVendorDetailApplication";
import {getBigDataPriceRange} from "../utils/get_big_data_price_range";
import {useConversationModalAbTest} from "./use_conversation_modal_ab_test";
import {useOpenedLeadModalRequiredParams} from "./use_lead_modal_required_params";

export function usePriceConversationPrice() {
    const [isPriceAvailable, setIsPriceAvailable] = useState<boolean | null>(null);

    const dispatch = useAppDispatch();
    const {vendorId, offerId, propertyId} = useOpenedLeadModalRequiredParams();
    const {abTestVariant: conversationModalVariant} = useConversationModalAbTest();

    const isWithoutABTestScenario = conversationModalVariant === 0;
    const isCalculationDisabled = isWithoutABTestScenario || !offerId || !propertyId;

    const vendorDetailQuery = useGetVendorDetailApplicationQuery(vendorId, {skip: isCalculationDisabled});
    const propertyDetailQuery = useGetPropertyDetailApplicationQuery(propertyId as number, {skip: isCalculationDisabled});
    const offerDetailQuery = useGetOfferDetailApplicationQuery(offerId as number, {skip: isCalculationDisabled});

    const isSuccess = vendorDetailQuery.isSuccess && propertyDetailQuery.isSuccess && offerDetailQuery.isSuccess;

    useEffect(() => {
        // This calculation is used only in AB test scenario and if it's a property modal
        if (isCalculationDisabled) {
            setIsPriceAvailable(false);
            return;
        }

        if (isPriceAvailable === null && vendorDetailQuery.data && propertyDetailQuery.data && offerDetailQuery.data) {
            isLeadModalPriceAvailable(propertyDetailQuery.data, offerDetailQuery.data, vendorDetailQuery.data, dispatch).then((priceAvailable) => {
                setIsPriceAvailable(priceAvailable);
            });
        }
    }, [isSuccess]);

    return {
        isPriceAvailable
    };
}

async function isLeadModalPriceAvailable(
    property: IPropertyDetailApplication,
    offer: IOfferDetailApplication,
    vendor: IVendorDetailApplication,
    dispatch: IAppDispatch
) {
    const leadModalPriceAvailable = !vendor.configuration.force_show_price && !property.configuration.show_price;

    if (!leadModalPriceAvailable) {
        return false;
    }

    if (property.is_price_set) {
        return true;
    }

    const regionEstimatedPrice = await getBigDataPriceRange(offer, property, dispatch);

    if (regionEstimatedPrice && regionEstimatedPrice.length) {
        return true;
    }

    return false;
}
