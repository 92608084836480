import type {LatLngLiteral} from "leaflet";

import {Country} from "../types/Country";
import {convertToLatLngLiteralOfPoland} from "./convert_to_lat_lng_literal_of_poland";
import {convertToLatLngLiteralOfSpain} from "./convert_to_lat_lng_literal_of_spain";

export const convertToCountryLatLngLiteral = (values: number[], country: Country = Country.POLAND): LatLngLiteral => {
    switch (country) {
        case Country.POLAND:
            return convertToLatLngLiteralOfPoland(values);
        case Country.SPAIN:
            return convertToLatLngLiteralOfSpain(values);
        default:
            throw new Error(`Incorrect country: ${country}`);
    }
};
import type {LatLngLiteral} from "leaflet";

import {Country} from "../types/Country";
import {convertToLatLngLiteralOfPoland} from "./convert_to_lat_lng_literal_of_poland";
import {convertToLatLngLiteralOfSpain} from "./convert_to_lat_lng_literal_of_spain";

export const convertToCountryLatLngLiteral = (values: number[], country: Country = Country.POLAND): LatLngLiteral => {
    switch (country) {
        case Country.POLAND:
            return convertToLatLngLiteralOfPoland(values);
        case Country.SPAIN:
            return convertToLatLngLiteralOfSpain(values);
        default:
            throw new Error(`Incorrect country: ${country}`);
    }
};
