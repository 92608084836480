import {apiLink} from "@pg-mono/rp-api-routes";

import {rpApi} from "../../app/api/rp_api";
import {IPaginatedResponse} from "../../request/IPaginatedResponse";
import {IArchitectGalleryItem} from "../types/IArchitectGalleryItem";

export const readArchitectGalleryApi = rpApi.injectEndpoints({
    endpoints: (builder) => ({
        readArchitectGallery: builder.query<IPaginatedResponse<IArchitectGalleryItem>, number>({
            query: (architectId) => {
                return {
                    url: apiLink.marketplace.gallery.base(),
                    params: {architect_offer: architectId, page_size: 1000}
                };
            }
        })
    })
});

export const {useReadArchitectGalleryQuery} = readArchitectGalleryApi;
import {apiLink} from "@pg-mono/rp-api-routes";

import {rpApi} from "../../app/api/rp_api";
import {IPaginatedResponse} from "../../request/IPaginatedResponse";
import {IArchitectGalleryItem} from "../types/IArchitectGalleryItem";

export const readArchitectGalleryApi = rpApi.injectEndpoints({
    endpoints: (builder) => ({
        readArchitectGallery: builder.query<IPaginatedResponse<IArchitectGalleryItem>, number>({
            query: (architectId) => {
                return {
                    url: apiLink.marketplace.gallery.base(),
                    params: {architect_offer: architectId, page_size: 1000}
                };
            }
        })
    })
});

export const {useReadArchitectGalleryQuery} = readArchitectGalleryApi;
