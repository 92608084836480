import {IMarketplaceArchitectOfferSummary} from "../api/fetch_marketplace_architect_promoted_offers_by_region";

export const architectServiceTexts: Partial<{[key in keyof IMarketplaceArchitectOfferSummary["offer"]]: string}> = {
    show_architect_supervision: "Opieka architekta",
    show_project_visualisation: "Wizualizacja pojektu",
    show_work_acceptance_support: "Wsparcie przy odbiorze mieszkania",
    show_custom_made_furniture: "Meble na wymiar",
    show_construction_crew: "Ekipa budowalna",
    show_construction_supervision: "Nadzór budowlany",
    show_cleaning_services: "Sprzątanie mieszkania"
};
import {IMarketplaceArchitectOfferSummary} from "../api/fetch_marketplace_architect_promoted_offers_by_region";

export const architectServiceTexts: Partial<{[key in keyof IMarketplaceArchitectOfferSummary["offer"]]: string}> = {
    show_architect_supervision: "Opieka architekta",
    show_project_visualisation: "Wizualizacja pojektu",
    show_work_acceptance_support: "Wsparcie przy odbiorze mieszkania",
    show_custom_made_furniture: "Meble na wymiar",
    show_construction_crew: "Ekipa budowalna",
    show_construction_supervision: "Nadzór budowlany",
    show_cleaning_services: "Sprzątanie mieszkania"
};
