import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const PhoneIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M8.963 6.705a1.636 1.636 0 0 0-.425-.798l-.798.045-.931.054-.799.046c-.107.142-.192.3-.25.468a4.896 4.896 0 0 1-2.488-2.305 1.666 1.666 0 0 0 .935-.934l-.373-.706-.44-.828-.367-.705A1.653 1.653 0 0 0 2.66 1a1.76 1.76 0 0 0-.218.016c-.033 0-.066.009-.1.015A1.665 1.665 0 0 0 1 2.605a7.428 7.428 0 0 0 5.819 6.033c.164.033.33.06.499.082a1.643 1.643 0 0 0 1.197-.5 1.664 1.664 0 0 0 .448-1.515Zm-.968 1.328c-.111.075-.235.13-.365.164a6.825 6.825 0 0 1-1.8-.431A6.885 6.885 0 0 1 2.074 4.22a6.718 6.718 0 0 1-.3-.79 6.697 6.697 0 0 1-.248-1.072 1.18 1.18 0 0 1 1.135-.872h.031l.073.137.885 1.681a1.182 1.182 0 0 1-.989.538H2.57a5.501 5.501 0 0 0 3.608 3.42 1.177 1.177 0 0 1 .1-.717l2.05-.119a1.172 1.172 0 0 1-.333 1.606Z" />
        </SvgIcon>
    );
};
import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const PhoneIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M8.963 6.705a1.636 1.636 0 0 0-.425-.798l-.798.045-.931.054-.799.046c-.107.142-.192.3-.25.468a4.896 4.896 0 0 1-2.488-2.305 1.666 1.666 0 0 0 .935-.934l-.373-.706-.44-.828-.367-.705A1.653 1.653 0 0 0 2.66 1a1.76 1.76 0 0 0-.218.016c-.033 0-.066.009-.1.015A1.665 1.665 0 0 0 1 2.605a7.428 7.428 0 0 0 5.819 6.033c.164.033.33.06.499.082a1.643 1.643 0 0 0 1.197-.5 1.664 1.664 0 0 0 .448-1.515Zm-.968 1.328c-.111.075-.235.13-.365.164a6.825 6.825 0 0 1-1.8-.431A6.885 6.885 0 0 1 2.074 4.22a6.718 6.718 0 0 1-.3-.79 6.697 6.697 0 0 1-.248-1.072 1.18 1.18 0 0 1 1.135-.872h.031l.073.137.885 1.681a1.182 1.182 0 0 1-.989.538H2.57a5.501 5.501 0 0 0 3.608 3.42 1.177 1.177 0 0 1 .1-.717l2.05-.119a1.172 1.172 0 0 1-.333 1.606Z" />
        </SvgIcon>
    );
};
