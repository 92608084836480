import {delayHit, hitAlgolytics} from "@pg-mono/algolytics";

import {MultiLeadRecommendationType} from "../../application/types/MultiLeadRecommendationType";
// we use this for unification process
import {ViewType} from "../../view_type/ViewType";
import {getTrackedSiteData} from "./tracked_utils/tracked_site";

export const multiLeadModalCheckboxClickHit = (
    viewType: ViewType | null,
    isChecked: boolean,
    propertyId: number,
    recommendationType: MultiLeadRecommendationType
) => {
    const payload = {
        ...getTrackedSiteData(),
        view_type: viewType,
        is_checked: isChecked,
        property_id: propertyId,
        recommendationType
    };
    multiLeadModalCheckboxClickHitAlgolytics(payload);
};

export const multileadPinClickHit = (viewType: ViewType | null, offerID: number, isChecked: boolean) => {
    const payload = {
        ...getTrackedSiteData(),
        modal_name: "multi_application",
        view_type: viewType,
        is_checked: isChecked,
        offer_id: offerID
    };
    multiLeadPinClickHitAlgolytics(payload);
};

export const multiLeadModalCheckboxClickHitAlgolytics = delayHit((payload: Object) => hitAlgolytics("multilead_modal_checkbox_click", payload), 500);
export const multiLeadPinClickHitAlgolytics = delayHit((payload: Object) => hitAlgolytics("multilead_point", payload), 500);
import {delayHit, hitAlgolytics} from "@pg-mono/algolytics";

import {MultiLeadRecommendationType} from "../../application/types/MultiLeadRecommendationType";
// we use this for unification process
import {ViewType} from "../../view_type/ViewType";
import {getTrackedSiteData} from "./tracked_utils/tracked_site";

export const multiLeadModalCheckboxClickHit = (
    viewType: ViewType | null,
    isChecked: boolean,
    propertyId: number,
    recommendationType: MultiLeadRecommendationType
) => {
    const payload = {
        ...getTrackedSiteData(),
        view_type: viewType,
        is_checked: isChecked,
        property_id: propertyId,
        recommendationType
    };
    multiLeadModalCheckboxClickHitAlgolytics(payload);
};

export const multileadPinClickHit = (viewType: ViewType | null, offerID: number, isChecked: boolean) => {
    const payload = {
        ...getTrackedSiteData(),
        modal_name: "multi_application",
        view_type: viewType,
        is_checked: isChecked,
        offer_id: offerID
    };
    multiLeadPinClickHitAlgolytics(payload);
};

export const multiLeadModalCheckboxClickHitAlgolytics = delayHit((payload: Object) => hitAlgolytics("multilead_modal_checkbox_click", payload), 500);
export const multiLeadPinClickHitAlgolytics = delayHit((payload: Object) => hitAlgolytics("multilead_point", payload), 500);
