import {css, Theme} from "@emotion/react";

import {calculateRemSize} from "@pg-design/helpers-css";
import {Image} from "@pg-design/image-module";
import {Text} from "@pg-design/text-module";

import {useAppSelector} from "../../../../../store/hooks";

export function LeadModalPriceConversationConsultant() {
    const selectedConsultant = useAppSelector((state) => state.leadModal.consultant);

    if (!selectedConsultant) {
        return null;
    }

    return (
        <div css={consultant}>
            <Image
                src={selectedConsultant.image.s72}
                src2x={selectedConsultant.image.s144}
                src3x={selectedConsultant.image.s216}
                css={consultantImage}
                width="72px"
                height="72px"
                alt={selectedConsultant.fullName}
            />
            <div css={consultantInfo}>
                <Text variant="headline_6" as="span">
                    {selectedConsultant.fullName}
                </Text>
                <Text variant="body_copy_2" as="span">
                    Konsultant rynekpierwotny.pl
                </Text>
            </div>
        </div>
    );
}

// Styles
const consultant = (theme: Theme) => css`
    display: flex;
    gap: ${calculateRemSize(2)};
    padding: ${calculateRemSize(2)};
    align-items: center;
    justify-content: center;
    background: ${theme.colors.gray[100]};
    border-bottom: 1px solid ${theme.colors.gray[500]};
`;

const consultantImage = css`
    flex: 0 1 72px;
    border-radius: 50%;
`;

const consultantInfo = css`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
`;
import {css, Theme} from "@emotion/react";

import {calculateRemSize} from "@pg-design/helpers-css";
import {Image} from "@pg-design/image-module";
import {Text} from "@pg-design/text-module";

import {useAppSelector} from "../../../../../store/hooks";

export function LeadModalPriceConversationConsultant() {
    const selectedConsultant = useAppSelector((state) => state.leadModal.consultant);

    if (!selectedConsultant) {
        return null;
    }

    return (
        <div css={consultant}>
            <Image
                src={selectedConsultant.image.s72}
                src2x={selectedConsultant.image.s144}
                src3x={selectedConsultant.image.s216}
                css={consultantImage}
                width="72px"
                height="72px"
                alt={selectedConsultant.fullName}
            />
            <div css={consultantInfo}>
                <Text variant="headline_6" as="span">
                    {selectedConsultant.fullName}
                </Text>
                <Text variant="body_copy_2" as="span">
                    Konsultant rynekpierwotny.pl
                </Text>
            </div>
        </div>
    );
}

// Styles
const consultant = (theme: Theme) => css`
    display: flex;
    gap: ${calculateRemSize(2)};
    padding: ${calculateRemSize(2)};
    align-items: center;
    justify-content: center;
    background: ${theme.colors.gray[100]};
    border-bottom: 1px solid ${theme.colors.gray[500]};
`;

const consultantImage = css`
    flex: 0 1 72px;
    border-radius: 50%;
`;

const consultantInfo = css`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
`;
