import * as React from "react";
import {useEffect} from "react";
import {useSelector} from "react-redux";

import {IRPStore} from "../../app/rp_reducer";
import {getSentLeadFormValuesFromStorage} from "../../lead/utils/sent_lead_form_values";
import {gtmApplicationPutAside} from "../../tracking/google_tag_manager/gtm_application_put_aside";
import {ApplicationGoodbyeContent, IApplicationGoodbyeContentProps} from "./ApplicationGoodbyeContent";

interface IProps extends IApplicationGoodbyeContentProps {}

export function ApplicationGoodbyeContentModal(props: IProps) {
    const data = useSelector((state: IRPStore) => state.application[props.storeHash]);

    useEffect(() => {
        const sentLeadFormValues = getSentLeadFormValuesFromStorage();

        const areFormValuesAvailable =
            sentLeadFormValues && Boolean(sentLeadFormValues.name) && Boolean(sentLeadFormValues.email) && Boolean(sentLeadFormValues.phone);

        if (data && areFormValuesAvailable) {
            gtmApplicationPutAside(data.vendor?.detail, data.offer?.detail);
        }
    }, []);

    return <ApplicationGoodbyeContent {...props} />;
}
import * as React from "react";
import {useEffect} from "react";
import {useSelector} from "react-redux";

import {IRPStore} from "../../app/rp_reducer";
import {getSentLeadFormValuesFromStorage} from "../../lead/utils/sent_lead_form_values";
import {gtmApplicationPutAside} from "../../tracking/google_tag_manager/gtm_application_put_aside";
import {ApplicationGoodbyeContent, IApplicationGoodbyeContentProps} from "./ApplicationGoodbyeContent";

interface IProps extends IApplicationGoodbyeContentProps {}

export function ApplicationGoodbyeContentModal(props: IProps) {
    const data = useSelector((state: IRPStore) => state.application[props.storeHash]);

    useEffect(() => {
        const sentLeadFormValues = getSentLeadFormValuesFromStorage();

        const areFormValuesAvailable =
            sentLeadFormValues && Boolean(sentLeadFormValues.name) && Boolean(sentLeadFormValues.email) && Boolean(sentLeadFormValues.phone);

        if (data && areFormValuesAvailable) {
            gtmApplicationPutAside(data.vendor?.detail, data.offer?.detail);
        }
    }, []);

    return <ApplicationGoodbyeContent {...props} />;
}
