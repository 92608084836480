import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const LocationIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M8.25 1.31 1.755 3.992 1 4.306l.755.311 2.57 1.057 1.078 2.571.314.757.309-.757 2.666-6.488L9 1l-.75.31ZM5.712 7.495 5.08 5.982l-.314-.757-.755-.31-1.501-.61L7.942 2.06l-2.23 5.435Z" />
        </SvgIcon>
    );
};
import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const LocationIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M8.25 1.31 1.755 3.992 1 4.306l.755.311 2.57 1.057 1.078 2.571.314.757.309-.757 2.666-6.488L9 1l-.75.31ZM5.712 7.495 5.08 5.982l-.314-.757-.755-.31-1.501-.61L7.942 2.06l-2.23 5.435Z" />
        </SvgIcon>
    );
};
