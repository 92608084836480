import React, {ReactElement} from "react";
import {css, Theme} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {borderRadius, flex, flexAbsoluteCenter, flexDirection, mt, onDesktop, p, w100} from "@pg-design/helpers-css";
import {Image} from "@pg-design/image-module";
import {Text} from "@pg-design/text-module";

interface IProps {
    imgSrc: string;
    text: ReactElement;
    onClick: () => void;
    className?: string;
}

export const MultiLeadPromoBox = (props: IProps) => {
    return (
        <div css={wrapperStyle} className={props.className}>
            <div css={imgWrapperStyle}>
                <Image css={imgStyle} width="160px" height="160px" src={props.imgSrc} alt="" />
            </div>
            <div css={contentWrapperStyle}>
                <Text variant="body_copy_1" mb="1.6rem">
                    {props.text}
                </Text>

                <div css={buttonWrapperStyle}>
                    <Button variant="filled_secondary" css={w100} onClick={props.onClick}>
                        Sprawdź
                    </Button>
                </div>
            </div>
        </div>
    );
};

const wrapperStyle = (theme: Theme) => css`
    ${p(2)};
    ${flex()};
    ${flexDirection("row")};
    ${borderRadius(2)};
    width: 100%;
    background-color: ${theme.colors.background[200]};
`;

const imgWrapperStyle = css`
    ${p(1)};
    ${flexAbsoluteCenter};

    ${onDesktop(css`
        ${p(1.5)};
    `)};
`;

const imgStyle = css`
    width: 130px;
    height: auto;

    & > img {
        width: 100%;
        height: auto;
    }

    ${onDesktop(css`
        width: 160px;
        height: auto;
    `)};
`;

const contentWrapperStyle = css`
    ${flex("flex-start", "space-between")};
    ${flexDirection("column")};
    height: 100%;
    width: 100%;
`;

const buttonWrapperStyle = css`
    ${mt(3)};
    width: 100%;
    ${flex("flex-end")};

    ${onDesktop(css`
        ${mt(0)};
    `)};
`;
import React, {ReactElement} from "react";
import {css, Theme} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {borderRadius, flex, flexAbsoluteCenter, flexDirection, mt, onDesktop, p, w100} from "@pg-design/helpers-css";
import {Image} from "@pg-design/image-module";
import {Text} from "@pg-design/text-module";

interface IProps {
    imgSrc: string;
    text: ReactElement;
    onClick: () => void;
    className?: string;
}

export const MultiLeadPromoBox = (props: IProps) => {
    return (
        <div css={wrapperStyle} className={props.className}>
            <div css={imgWrapperStyle}>
                <Image css={imgStyle} width="160px" height="160px" src={props.imgSrc} alt="" />
            </div>
            <div css={contentWrapperStyle}>
                <Text variant="body_copy_1" mb="1.6rem">
                    {props.text}
                </Text>

                <div css={buttonWrapperStyle}>
                    <Button variant="filled_secondary" css={w100} onClick={props.onClick}>
                        Sprawdź
                    </Button>
                </div>
            </div>
        </div>
    );
};

const wrapperStyle = (theme: Theme) => css`
    ${p(2)};
    ${flex()};
    ${flexDirection("row")};
    ${borderRadius(2)};
    width: 100%;
    background-color: ${theme.colors.background[200]};
`;

const imgWrapperStyle = css`
    ${p(1)};
    ${flexAbsoluteCenter};

    ${onDesktop(css`
        ${p(1.5)};
    `)};
`;

const imgStyle = css`
    width: 130px;
    height: auto;

    & > img {
        width: 100%;
        height: auto;
    }

    ${onDesktop(css`
        width: 160px;
        height: auto;
    `)};
`;

const contentWrapperStyle = css`
    ${flex("flex-start", "space-between")};
    ${flexDirection("column")};
    height: 100%;
    width: 100%;
`;

const buttonWrapperStyle = css`
    ${mt(3)};
    width: 100%;
    ${flex("flex-end")};

    ${onDesktop(css`
        ${mt(0)};
    `)};
`;
