import {useABTest} from "@pg-mono/ab-testing";

import {abTestingInstance} from "../../ab_testing/ab_testing_instance";
import {OngoingABTests} from "../../ab_testing/ongoing_ab_tests";

const abTestProps = abTestingInstance.getABTestContainerProps(OngoingABTests.CONVERSATION_LEAD_MODAL);

export const useConversationModalAbTest = () => {
    return useABTest(abTestProps);
};
import {useABTest} from "@pg-mono/ab-testing";

import {abTestingInstance} from "../../ab_testing/ab_testing_instance";
import {OngoingABTests} from "../../ab_testing/ongoing_ab_tests";

const abTestProps = abTestingInstance.getABTestContainerProps(OngoingABTests.CONVERSATION_LEAD_MODAL);

export const useConversationModalAbTest = () => {
    return useABTest(abTestProps);
};
