import React, {useMemo} from "react";
import {css} from "@emotion/react";

import {borderRadius, flexAbsoluteCenter, h100, m, maxWidth, onDesktop, p, position, w100} from "@pg-design/helpers-css";
import {Placeholder} from "@pg-design/image-module";
import {Loader} from "@pg-design/loader-module";
import {SliderGallery, SliderGalleryIcon} from "@pg-design/slider-gallery-module";

import {useReadArchitectGalleryQuery} from "../../api/read_architect_gallery";

interface IProps {
    architectId: number;
}

export const MultileadArchitectGallery = (props: IProps) => {
    const {data: gallery, isLoading} = useReadArchitectGalleryQuery(props.architectId);

    const slides = useMemo(
        () =>
            gallery?.results?.map((image, index) => {
                return (
                    <picture key={index}>
                        <img src={image.image_thumbnails.m_img_300} alt={`Zdjęcie ${index + 1}`} css={imgStyle} />
                    </picture>
                );
            }),
        [gallery]
    );

    if (isLoading) {
        return (
            <div css={[w100, h100, flexAbsoluteCenter]}>
                <Loader />
            </div>
        );
    }

    return (
        <div css={galleryWrapper}>
            {slides && slides.length > 0 ? (
                <SliderGallery
                    arrows={{
                        left: <SliderGalleryIcon position="left" iconSize="1" iconWrapperSize="2.4" wrapperColor="#fff" enableOnMobile />,
                        right: <SliderGalleryIcon position="right" iconSize="1" iconWrapperSize="2.4" wrapperColor="#fff" enableOnMobile />
                    }}
                    slides={slides}
                    className="gallery"
                />
            ) : (
                <div css={placeholderWrapper}>
                    <Placeholder />
                </div>
            )}
        </div>
    );
};

const galleryWrapper = css`
    width: 100%;
    height: 212px;
    overflow: hidden;
    ${borderRadius(2, 2, 0, 0)};

    ${onDesktop(css`
        ${borderRadius(2, 0, 0, 2)};
        ${maxWidth("275px")};
    `)};

    div {
        ${p(0)};
        ${m(0)};
    }
`;

const placeholderWrapper = css`
    width: 100%;
    height: 212px;
    ${flexAbsoluteCenter};
    ${position("relative")};
`;

const imgStyle = css`
    object-fit: cover !important;
`;
import React, {useMemo} from "react";
import {css} from "@emotion/react";

import {borderRadius, flexAbsoluteCenter, h100, m, maxWidth, onDesktop, p, position, w100} from "@pg-design/helpers-css";
import {Placeholder} from "@pg-design/image-module";
import {Loader} from "@pg-design/loader-module";
import {SliderGallery, SliderGalleryIcon} from "@pg-design/slider-gallery-module";

import {useReadArchitectGalleryQuery} from "../../api/read_architect_gallery";

interface IProps {
    architectId: number;
}

export const MultileadArchitectGallery = (props: IProps) => {
    const {data: gallery, isLoading} = useReadArchitectGalleryQuery(props.architectId);

    const slides = useMemo(
        () =>
            gallery?.results?.map((image, index) => {
                return (
                    <picture key={index}>
                        <img src={image.image_thumbnails.m_img_300} alt={`Zdjęcie ${index + 1}`} css={imgStyle} />
                    </picture>
                );
            }),
        [gallery]
    );

    if (isLoading) {
        return (
            <div css={[w100, h100, flexAbsoluteCenter]}>
                <Loader />
            </div>
        );
    }

    return (
        <div css={galleryWrapper}>
            {slides && slides.length > 0 ? (
                <SliderGallery
                    arrows={{
                        left: <SliderGalleryIcon position="left" iconSize="1" iconWrapperSize="2.4" wrapperColor="#fff" enableOnMobile />,
                        right: <SliderGalleryIcon position="right" iconSize="1" iconWrapperSize="2.4" wrapperColor="#fff" enableOnMobile />
                    }}
                    slides={slides}
                    className="gallery"
                />
            ) : (
                <div css={placeholderWrapper}>
                    <Placeholder />
                </div>
            )}
        </div>
    );
};

const galleryWrapper = css`
    width: 100%;
    height: 212px;
    overflow: hidden;
    ${borderRadius(2, 2, 0, 0)};

    ${onDesktop(css`
        ${borderRadius(2, 0, 0, 2)};
        ${maxWidth("275px")};
    `)};

    div {
        ${p(0)};
        ${m(0)};
    }
`;

const placeholderWrapper = css`
    width: 100%;
    height: 212px;
    ${flexAbsoluteCenter};
    ${position("relative")};
`;

const imgStyle = css`
    object-fit: cover !important;
`;
