import {IApplicationData} from "../../application/reducers/application_reducer";
import {IApplication} from "../../application/types/IApplication";
import {IMultiLeadData} from "../../application/types/IMultiLeadData";
import {transformLeadDataOfferIntoLeadSummaryItem} from "../../application/utils/recommended_application/transform_lead_data_offer_into_lead_summary_item";
import {transformLeadDataPropertyIntoLeadSummaryItem} from "../../application/utils/recommended_application/transform_lead_data_property_into_lead_summary_item";
import {shouldApplicationPriceBeenShown} from "../../application/utils/should_application_price_been_shown";
import {ILeadSummaryItem} from "../types/ILeadSummaryItem";

interface IParams {
    leadData: IApplicationData | null;
    originalApplicationResponse: IApplication | null;
    multiLeadSummaryItems: IMultiLeadData[];
}

export const getLeadsSummaryIncludingMainLead = (params: IParams) => {
    const {leadData, originalApplicationResponse, multiLeadSummaryItems} = params;

    const summaryProperty = leadData
        ? transformLeadDataPropertyIntoLeadSummaryItem({
              property: leadData.property,
              offer: leadData.offer,
              vendor: leadData.vendor,
              isPriceRevealed: shouldApplicationPriceBeenShown(originalApplicationResponse),
              price: originalApplicationResponse?.property_price
          })
        : null;

    const summaryOffer =
        !summaryProperty && leadData
            ? transformLeadDataOfferIntoLeadSummaryItem({
                  offer: leadData.offer,
                  vendor: leadData.vendor,
                  isPriceRevealed: shouldApplicationPriceBeenShown(originalApplicationResponse)
              })
            : null;

    const summaryItems: Omit<ILeadSummaryItem, "regionEstimatedPriceSummary">[] = [...multiLeadSummaryItems];

    if (summaryProperty) {
        summaryItems.unshift(summaryProperty);
    }

    if (summaryOffer) {
        summaryItems.unshift(summaryOffer);
    }

    return {
        summaryItems,
        sentApplicationCount: summaryItems.length + (!summaryProperty && !summaryOffer && leadData?.vendor ? 1 : 0)
    };
};
import {IApplicationData} from "../../application/reducers/application_reducer";
import {IApplication} from "../../application/types/IApplication";
import {IMultiLeadData} from "../../application/types/IMultiLeadData";
import {transformLeadDataOfferIntoLeadSummaryItem} from "../../application/utils/recommended_application/transform_lead_data_offer_into_lead_summary_item";
import {transformLeadDataPropertyIntoLeadSummaryItem} from "../../application/utils/recommended_application/transform_lead_data_property_into_lead_summary_item";
import {shouldApplicationPriceBeenShown} from "../../application/utils/should_application_price_been_shown";
import {ILeadSummaryItem} from "../types/ILeadSummaryItem";

interface IParams {
    leadData: IApplicationData | null;
    originalApplicationResponse: IApplication | null;
    multiLeadSummaryItems: IMultiLeadData[];
}

export const getLeadsSummaryIncludingMainLead = (params: IParams) => {
    const {leadData, originalApplicationResponse, multiLeadSummaryItems} = params;

    const summaryProperty = leadData
        ? transformLeadDataPropertyIntoLeadSummaryItem({
              property: leadData.property,
              offer: leadData.offer,
              vendor: leadData.vendor,
              isPriceRevealed: shouldApplicationPriceBeenShown(originalApplicationResponse),
              price: originalApplicationResponse?.property_price
          })
        : null;

    const summaryOffer =
        !summaryProperty && leadData
            ? transformLeadDataOfferIntoLeadSummaryItem({
                  offer: leadData.offer,
                  vendor: leadData.vendor,
                  isPriceRevealed: shouldApplicationPriceBeenShown(originalApplicationResponse)
              })
            : null;

    const summaryItems: Omit<ILeadSummaryItem, "regionEstimatedPriceSummary">[] = [...multiLeadSummaryItems];

    if (summaryProperty) {
        summaryItems.unshift(summaryProperty);
    }

    if (summaryOffer) {
        summaryItems.unshift(summaryOffer);
    }

    return {
        summaryItems,
        sentApplicationCount: summaryItems.length + (!summaryProperty && !summaryOffer && leadData?.vendor ? 1 : 0)
    };
};
