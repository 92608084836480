import {IOfferDetailApplication} from "../../../../offer/types/IOfferDetailApplication";
import {IVendorDetailApplication} from "../../../../vendor/types/IVendorDetailApplication";
import {RodoFormInfo} from "../../RodoFormInfo";

interface IRodoTermsProps {
    offer: IOfferDetailApplication | null;
    vendor?: IVendorDetailApplication | null;
    className?: string;
}

export const ApplicationFormRodoTerms = (props: IRodoTermsProps) => {
    const {offer, vendor, className} = props;

    return (
        <RodoFormInfo
            vendor={vendor}
            offerPrivacyPolicyUrl={offer?.configuration.privacy_policy_url}
            offerPersonalDataProcessorName={offer?.configuration.personal_data_processor_name}
            offerPersonalDataProcessorUrl={offer?.configuration.personal_data_processor_url}
            className={className}
        />
    );
};
import {IOfferDetailApplication} from "../../../../offer/types/IOfferDetailApplication";
import {IVendorDetailApplication} from "../../../../vendor/types/IVendorDetailApplication";
import {RodoFormInfo} from "../../RodoFormInfo";

interface IRodoTermsProps {
    offer: IOfferDetailApplication | null;
    vendor?: IVendorDetailApplication | null;
    className?: string;
}

export const ApplicationFormRodoTerms = (props: IRodoTermsProps) => {
    const {offer, vendor, className} = props;

    return (
        <RodoFormInfo
            vendor={vendor}
            offerPrivacyPolicyUrl={offer?.configuration.privacy_policy_url}
            offerPersonalDataProcessorName={offer?.configuration.personal_data_processor_name}
            offerPersonalDataProcessorUrl={offer?.configuration.personal_data_processor_url}
            className={className}
        />
    );
};
