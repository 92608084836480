import React from "react";

import {ApplicationModalArray} from "../../../application/components/ApplicationModalArray";
import {ApplicationSource, ApplicationSourceSection} from "../../../application/utils/ApplicationSource";
import {useAppSelector} from "../../../store/hooks";
import {ILeadModalState} from "../../types/ILeadModalState";

export function LeadModal() {
    const leadModalState = useAppSelector((state) => state.leadModal);

    return (
        <>
            {isModalOpened(leadModalState) && (
                <ApplicationModalArray
                    isOpened={leadModalState.isModalOpen}
                    submitOnMount={leadModalState.openWithSubmit}
                    source={leadModalState.source}
                    sourceSection={leadModalState.sourceSection}
                    hideQuestions={leadModalState.hideQuestions}
                    affiliation={leadModalState.affiliation || undefined}
                    forceClose={leadModalState.forceClose}
                    creditPayload={leadModalState.creditPayload || undefined}
                    salesOfficeId={leadModalState.salesOfficeId || undefined}
                />
            )}
        </>
    );
}

type IOpenedModalProps = Omit<ILeadModalState, "vendorId" | "source" | "sourceSection"> & {
    vendorId: number;
    source: ApplicationSource;
    sourceSection: ApplicationSourceSection;
};

function isModalOpened(leadModalState: ILeadModalState): leadModalState is IOpenedModalProps {
    return leadModalState.isModalOpen;
}
import React from "react";

import {ApplicationModalArray} from "../../../application/components/ApplicationModalArray";
import {ApplicationSource, ApplicationSourceSection} from "../../../application/utils/ApplicationSource";
import {useAppSelector} from "../../../store/hooks";
import {ILeadModalState} from "../../types/ILeadModalState";

export function LeadModal() {
    const leadModalState = useAppSelector((state) => state.leadModal);

    return (
        <>
            {isModalOpened(leadModalState) && (
                <ApplicationModalArray
                    isOpened={leadModalState.isModalOpen}
                    submitOnMount={leadModalState.openWithSubmit}
                    source={leadModalState.source}
                    sourceSection={leadModalState.sourceSection}
                    hideQuestions={leadModalState.hideQuestions}
                    affiliation={leadModalState.affiliation || undefined}
                    forceClose={leadModalState.forceClose}
                    creditPayload={leadModalState.creditPayload || undefined}
                    salesOfficeId={leadModalState.salesOfficeId || undefined}
                />
            )}
        </>
    );
}

type IOpenedModalProps = Omit<ILeadModalState, "vendorId" | "source" | "sourceSection"> & {
    vendorId: number;
    source: ApplicationSource;
    sourceSection: ApplicationSourceSection;
};

function isModalOpened(leadModalState: ILeadModalState): leadModalState is IOpenedModalProps {
    return leadModalState.isModalOpen;
}
