import {getCookie, getRequest, postRequest} from "@pg-mono/request";
import {apiLink} from "@pg-mono/rp-api-routes";

export interface IArchitectApplicationRequest {
    architect: number; // ID
    name: string;
    email: string;
    phone: string;
    area?: string | number;
    packages?: number[];
    offer_id?: number;
    property_id?: number;
}

const getCsrfToken = () => getCookie("mp-csrftoken");

export const postArchitectApplication = async (values: IArchitectApplicationRequest): Promise<unknown | null> => {
    if (!getCsrfToken()) {
        await getRequest({}, apiLink.marketplace.cookies.base());
    }

    const area = Number(values.area);

    const jsonData = {
        area: null,
        packages: [],
        ...values,
        ...(area ? {area} : {})
    };

    // offer_id and property_id should be sent only if any value has been passed, otherwise those fields must be deleted
    if (!jsonData["offer_id"]) {
        delete jsonData["offer_id"];
    }
    if (!jsonData["property_id"]) {
        delete jsonData["property_id"];
    }

    const headers = {
        "X-CSRFToken": getCsrfToken() || ""
    };

    return postRequest({}, apiLink.marketplace.application.create(), jsonData, undefined, headers)
        .then((result) => {
            return result;
        })
        .catch(() => {
            return null;
        });
};
import {getCookie, getRequest, postRequest} from "@pg-mono/request";
import {apiLink} from "@pg-mono/rp-api-routes";

export interface IArchitectApplicationRequest {
    architect: number; // ID
    name: string;
    email: string;
    phone: string;
    area?: string | number;
    packages?: number[];
    offer_id?: number;
    property_id?: number;
}

const getCsrfToken = () => getCookie("mp-csrftoken");

export const postArchitectApplication = async (values: IArchitectApplicationRequest): Promise<unknown | null> => {
    if (!getCsrfToken()) {
        await getRequest({}, apiLink.marketplace.cookies.base());
    }

    const area = Number(values.area);

    const jsonData = {
        area: null,
        packages: [],
        ...values,
        ...(area ? {area} : {})
    };

    // offer_id and property_id should be sent only if any value has been passed, otherwise those fields must be deleted
    if (!jsonData["offer_id"]) {
        delete jsonData["offer_id"];
    }
    if (!jsonData["property_id"]) {
        delete jsonData["property_id"];
    }

    const headers = {
        "X-CSRFToken": getCsrfToken() || ""
    };

    return postRequest({}, apiLink.marketplace.application.create(), jsonData, undefined, headers)
        .then((result) => {
            return result;
        })
        .catch(() => {
            return null;
        });
};
