import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const ArrowIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M9 4.459H2.164L3.681 3h-.87L1 4.741l1.884 1.815h.87L2.194 5.05H9V4.46Z" />
        </SvgIcon>
    );
};
import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const ArrowIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M9 4.459H2.164L3.681 3h-.87L1 4.741l1.884 1.815h.87L2.194 5.05H9V4.46Z" />
        </SvgIcon>
    );
};
