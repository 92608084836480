import {IOfferDetailApplication} from "../../offer/types/IOfferDetailApplication";
import {cityWithEstimatedPriceStats, districtsWithEstimatedPriceStats} from "../../property/utils/estimated_price_utils";
import {IGetMonthDistrictTypeStatsPayload} from "../../region/api/IGetMonthDistrictTypeStatsPayload";
import {IGetMonthCityTypeStatsPayload} from "../../region/types/IGetMonthCityTypeStatsPayload";
import {RegionType} from "../../region/types/RegionType";
import {getMonthStatsPayloadDate} from "../../region/utils/get_month_stats_payload_date";

export function getLeadModalBigDataPayload(offer: IOfferDetailApplication) {
    const regionType = offer.region.type;
    const cityId = offer.region.stats?.region_type_city?.id || null;
    const datePayload = getMonthStatsPayloadDate();

    if (!regionType) {
        return null;
    }

    const cityEligibleTypes = [RegionType.VOIVODESHIP, RegionType.AGGREGATION, RegionType.CITY, RegionType.TOWN];
    const slugCity = cityId ? cityWithEstimatedPriceStats.get(cityId) : null;
    if (cityEligibleTypes.includes(regionType) && slugCity) {
        return {
            type: "city",
            payload: {
                ...datePayload,
                slug_city: slugCity
            } as IGetMonthCityTypeStatsPayload
        };
    }

    const districtId = offer.region.stats?.region_type_district?.id || null;
    const districtEligibleTypes = [RegionType.DISTRICT, RegionType.NEIGHBORHOOD];
    const districtRecord = districtId ? districtsWithEstimatedPriceStats.get(districtId) : null;
    if (districtEligibleTypes.includes(regionType) && districtRecord) {
        return {
            type: "district",
            payload: {
                ...datePayload,
                slug_city: districtRecord.city,
                slug_district: districtRecord.district
            } as IGetMonthDistrictTypeStatsPayload
        };
    }

    return null;
}
import {IOfferDetailApplication} from "../../offer/types/IOfferDetailApplication";
import {cityWithEstimatedPriceStats, districtsWithEstimatedPriceStats} from "../../property/utils/estimated_price_utils";
import {IGetMonthDistrictTypeStatsPayload} from "../../region/api/IGetMonthDistrictTypeStatsPayload";
import {IGetMonthCityTypeStatsPayload} from "../../region/types/IGetMonthCityTypeStatsPayload";
import {RegionType} from "../../region/types/RegionType";
import {getMonthStatsPayloadDate} from "../../region/utils/get_month_stats_payload_date";

export function getLeadModalBigDataPayload(offer: IOfferDetailApplication) {
    const regionType = offer.region.type;
    const cityId = offer.region.stats?.region_type_city?.id || null;
    const datePayload = getMonthStatsPayloadDate();

    if (!regionType) {
        return null;
    }

    const cityEligibleTypes = [RegionType.VOIVODESHIP, RegionType.AGGREGATION, RegionType.CITY, RegionType.TOWN];
    const slugCity = cityId ? cityWithEstimatedPriceStats.get(cityId) : null;
    if (cityEligibleTypes.includes(regionType) && slugCity) {
        return {
            type: "city",
            payload: {
                ...datePayload,
                slug_city: slugCity
            } as IGetMonthCityTypeStatsPayload
        };
    }

    const districtId = offer.region.stats?.region_type_district?.id || null;
    const districtEligibleTypes = [RegionType.DISTRICT, RegionType.NEIGHBORHOOD];
    const districtRecord = districtId ? districtsWithEstimatedPriceStats.get(districtId) : null;
    if (districtEligibleTypes.includes(regionType) && districtRecord) {
        return {
            type: "district",
            payload: {
                ...datePayload,
                slug_city: districtRecord.city,
                slug_district: districtRecord.district
            } as IGetMonthDistrictTypeStatsPayload
        };
    }

    return null;
}
