export const ADD_APPLIED_OFFERS_TYPE = "map/ADD_APPLIED_OFFERS";

export const addAppliedOffers = (appliedOffers: number[]) => {
    return {
        type: ADD_APPLIED_OFFERS_TYPE,
        payload: appliedOffers
    };
};
export const ADD_APPLIED_OFFERS_TYPE = "map/ADD_APPLIED_OFFERS";

export const addAppliedOffers = (appliedOffers: number[]) => {
    return {
        type: ADD_APPLIED_OFFERS_TYPE,
        payload: appliedOffers
    };
};
