import {RefObject, useEffect, useRef} from "react";

/**
 * Hook for tracking which list items are currently in view in a scrollable list.
 * @param params
 * @param params.scrollableListWrapRef - Ref to the scrollable list wrapper element, usually container for ul.
 * @param params.listRef - Ref to the list element, usually ul.
 * @param params.listElementTrackingIdDatasetName - Name of the dataset attribute that contains the tracking id,
 * usually in li and should have string or number value.
 * @param params.onError - Callback for handling missing refs or missing tracking id.
 */
interface IUseScrollableListItemTrackingParams {
    scrollableListRef: RefObject<HTMLDivElement | HTMLUListElement>;
    listElementTrackingIdDatasetName: string;
    handleListItemTrackingHit: (id: TrackingId) => void;
    directAncestorListRef?: RefObject<HTMLDivElement | HTMLUListElement>;
    onError?: (error: string) => void;
}

type TrackingId = number | string;

const errorContextName = "useScrollableListItemTracking";
const ignoredClassName = "icn";

export function useScrollableListItemTracking(params: IUseScrollableListItemTrackingParams) {
    const {scrollableListRef, directAncestorListRef, listElementTrackingIdDatasetName, handleListItemTrackingHit, onError} = params;
    const viewedIdsListRef = useRef<TrackingId[]>([]);

    const updateViewedIdsList = (id: TrackingId) => {
        if (!viewedIdsListRef.current.includes(id)) {
            viewedIdsListRef.current = [...viewedIdsListRef.current, id];
            handleListItemTrackingHit(id);
        }
    };

    useEffect(() => {
        const scrollableListWrap = scrollableListRef.current;
        const list = directAncestorListRef ? directAncestorListRef.current : scrollableListRef.current;
        const listItems = list?.children;

        if (onError && !list) {
            onError(`${errorContextName}: List ref is missing`);
        }

        if (!list) {
            return;
        }

        const observer = new IntersectionObserver(
            (entries) => {
                for (const entry of entries) {
                    const listItem = entry.target as HTMLUListElement;

                    if (listItem.classList.contains(ignoredClassName)) {
                        return;
                    }

                    const trackingId = listItem.dataset[listElementTrackingIdDatasetName];
                    const missingIdError = "Tracking ID is missing in list item dataset";

                    if (onError && !trackingId) {
                        onError(`${errorContextName}: ${missingIdError}`);
                    }

                    if (!trackingId) {
                        return;
                    }

                    if (entry.isIntersecting) {
                        updateViewedIdsList(trackingId);
                    }
                }
            },
            {
                root: scrollableListWrap,
                threshold: 0.9
            }
        );

        if (listItems) {
            for (const listItem of Array.from(listItems)) {
                observer.observe(listItem);
            }
        }

        return () => {
            if (list) {
                observer.disconnect();
            }
        };
    }, []);

    // Returns className that should be added to ignored elements (that shouldn't be tracked)
    return ignoredClassName;
}
import {RefObject, useEffect, useRef} from "react";

/**
 * Hook for tracking which list items are currently in view in a scrollable list.
 * @param params
 * @param params.scrollableListWrapRef - Ref to the scrollable list wrapper element, usually container for ul.
 * @param params.listRef - Ref to the list element, usually ul.
 * @param params.listElementTrackingIdDatasetName - Name of the dataset attribute that contains the tracking id,
 * usually in li and should have string or number value.
 * @param params.onError - Callback for handling missing refs or missing tracking id.
 */
interface IUseScrollableListItemTrackingParams {
    scrollableListRef: RefObject<HTMLDivElement | HTMLUListElement>;
    listElementTrackingIdDatasetName: string;
    handleListItemTrackingHit: (id: TrackingId) => void;
    directAncestorListRef?: RefObject<HTMLDivElement | HTMLUListElement>;
    onError?: (error: string) => void;
}

type TrackingId = number | string;

const errorContextName = "useScrollableListItemTracking";
const ignoredClassName = "icn";

export function useScrollableListItemTracking(params: IUseScrollableListItemTrackingParams) {
    const {scrollableListRef, directAncestorListRef, listElementTrackingIdDatasetName, handleListItemTrackingHit, onError} = params;
    const viewedIdsListRef = useRef<TrackingId[]>([]);

    const updateViewedIdsList = (id: TrackingId) => {
        if (!viewedIdsListRef.current.includes(id)) {
            viewedIdsListRef.current = [...viewedIdsListRef.current, id];
            handleListItemTrackingHit(id);
        }
    };

    useEffect(() => {
        const scrollableListWrap = scrollableListRef.current;
        const list = directAncestorListRef ? directAncestorListRef.current : scrollableListRef.current;
        const listItems = list?.children;

        if (onError && !list) {
            onError(`${errorContextName}: List ref is missing`);
        }

        if (!list) {
            return;
        }

        const observer = new IntersectionObserver(
            (entries) => {
                for (const entry of entries) {
                    const listItem = entry.target as HTMLUListElement;

                    if (listItem.classList.contains(ignoredClassName)) {
                        return;
                    }

                    const trackingId = listItem.dataset[listElementTrackingIdDatasetName];
                    const missingIdError = "Tracking ID is missing in list item dataset";

                    if (onError && !trackingId) {
                        onError(`${errorContextName}: ${missingIdError}`);
                    }

                    if (!trackingId) {
                        return;
                    }

                    if (entry.isIntersecting) {
                        updateViewedIdsList(trackingId);
                    }
                }
            },
            {
                root: scrollableListWrap,
                threshold: 0.9
            }
        );

        if (listItems) {
            for (const listItem of Array.from(listItems)) {
                observer.observe(listItem);
            }
        }

        return () => {
            if (list) {
                observer.disconnect();
            }
        };
    }, []);

    // Returns className that should be added to ignored elements (that shouldn't be tracked)
    return ignoredClassName;
}
