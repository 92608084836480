import {useEffect, useState} from "react";
import {useSelector} from "react-redux";

import {IRPStore} from "../../app/rp_reducer";
import {useGetOfferDetailApplicationQuery} from "../../offer/api/get_offer_detail";
import {useGetPropertyDetailApplicationQuery} from "../../property/api/get_property_detail";
import {useAppDispatch} from "../../store/hooks";
import {getLeadModalPriceConversationRecommendations} from "../utils/get_lead_modal_price_conversation_recommendations";
import {useOpenedLeadModalRequiredParams} from "./use_lead_modal_required_params";

interface IRecommendationsFetchParams {
    distanceInKm: number;
    maxPrice?: number;
    skip?: boolean;
}

export function useLeadModalParamsRecommendationsFetch(params: IRecommendationsFetchParams) {
    const {maxPrice, distanceInKm, skip} = params;

    const dispatch = useAppDispatch();
    const {offerId, propertyId} = useOpenedLeadModalRequiredParams("property");
    const {data: propertyDetail, isLoading: propertyLoading, isUninitialized: propertyUninitialized} = useGetPropertyDetailApplicationQuery(propertyId);
    const {data: offerDetail, isLoading: offerLoading, isUninitialized: offerUninitialized} = useGetOfferDetailApplicationQuery(offerId);

    const recommendedProperties = useSelector((store: IRPStore) => store.property.recommendedProperties);

    const [isLoading, setIsLoading] = useState(false);

    const triggerFetchRecommendations = async () => {
        setIsLoading(true);

        if (offerDetail && propertyDetail) {
            await getLeadModalPriceConversationRecommendations({
                dispatch,
                offerDetail,
                propertyDetail,
                distanceInKm,
                maxPrice
            });
        }

        setIsLoading(false);
    };

    useEffect(() => {
        if (!skip) {
            triggerFetchRecommendations();
        }
    }, [distanceInKm, maxPrice, skip, propertyDetail, offerDetail]);

    const detailsUninitialized = propertyUninitialized || offerUninitialized;

    return {
        isLoading: isLoading || propertyLoading || offerLoading || detailsUninitialized,
        recommendedProperties
    };
}
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";

import {IRPStore} from "../../app/rp_reducer";
import {useGetOfferDetailApplicationQuery} from "../../offer/api/get_offer_detail";
import {useGetPropertyDetailApplicationQuery} from "../../property/api/get_property_detail";
import {useAppDispatch} from "../../store/hooks";
import {getLeadModalPriceConversationRecommendations} from "../utils/get_lead_modal_price_conversation_recommendations";
import {useOpenedLeadModalRequiredParams} from "./use_lead_modal_required_params";

interface IRecommendationsFetchParams {
    distanceInKm: number;
    maxPrice?: number;
    skip?: boolean;
}

export function useLeadModalParamsRecommendationsFetch(params: IRecommendationsFetchParams) {
    const {maxPrice, distanceInKm, skip} = params;

    const dispatch = useAppDispatch();
    const {offerId, propertyId} = useOpenedLeadModalRequiredParams("property");
    const {data: propertyDetail, isLoading: propertyLoading, isUninitialized: propertyUninitialized} = useGetPropertyDetailApplicationQuery(propertyId);
    const {data: offerDetail, isLoading: offerLoading, isUninitialized: offerUninitialized} = useGetOfferDetailApplicationQuery(offerId);

    const recommendedProperties = useSelector((store: IRPStore) => store.property.recommendedProperties);

    const [isLoading, setIsLoading] = useState(false);

    const triggerFetchRecommendations = async () => {
        setIsLoading(true);

        if (offerDetail && propertyDetail) {
            await getLeadModalPriceConversationRecommendations({
                dispatch,
                offerDetail,
                propertyDetail,
                distanceInKm,
                maxPrice
            });
        }

        setIsLoading(false);
    };

    useEffect(() => {
        if (!skip) {
            triggerFetchRecommendations();
        }
    }, [distanceInKm, maxPrice, skip, propertyDetail, offerDetail]);

    const detailsUninitialized = propertyUninitialized || offerUninitialized;

    return {
        isLoading: isLoading || propertyLoading || offerLoading || detailsUninitialized,
        recommendedProperties
    };
}
