import {createCountryLatLngConverter} from "./create_country_lat_lng_converter";

const MIN_SPAIN_LAT = 27.633333;
const MAX_SPAIN_LAT = 43.783333;

const MIN_SPAIN_LNG = -18.147496;
const MAX_SPAIN_LNG = 4.316667;

export const convertToLatLngLiteralOfSpain = createCountryLatLngConverter({min: MIN_SPAIN_LAT, max: MAX_SPAIN_LAT}, {min: MIN_SPAIN_LNG, max: MAX_SPAIN_LNG});
import {createCountryLatLngConverter} from "./create_country_lat_lng_converter";

const MIN_SPAIN_LAT = 27.633333;
const MAX_SPAIN_LAT = 43.783333;

const MIN_SPAIN_LNG = -18.147496;
const MAX_SPAIN_LNG = 4.316667;

export const convertToLatLngLiteralOfSpain = createCountryLatLngConverter({min: MIN_SPAIN_LAT, max: MAX_SPAIN_LAT}, {min: MIN_SPAIN_LNG, max: MAX_SPAIN_LNG});
