import React, {forwardRef, PropsWithChildren} from "react";
import {css, Theme} from "@emotion/react";

import {prettyScroll} from "@pg-design/helpers-css";

type IProps = PropsWithChildren<{className?: string}>;

export const FullHeightContent = (props: IProps) => {
    return (
        <div className={props.className} css={container}>
            {props.children}
        </div>
    );
};

const Header = (props: IProps) => {
    return <div className={props.className}>{props.children}</div>;
};

const Footer = (props: IProps) => {
    return <div className={props.className}>{props.children}</div>;
};

export const Main = forwardRef<HTMLDivElement, IProps>((props, ref) => {
    return (
        <div css={main} className={props.className} ref={ref}>
            <div css={section}>{props.children}</div>
        </div>
    );
});

const container = (theme: Theme) => css`
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    flex: 1;

    @media (min-width: ${theme.breakpoints.md}) {
        height: 100%;
    }
`;

const main = css`
    flex: 1;
    ${prettyScroll()};
`;

const section = css`
    height: 100%;
`;

FullHeightContent.Header = Header;
FullHeightContent.Main = Main;
FullHeightContent.Footer = Footer;
import React, {forwardRef, PropsWithChildren} from "react";
import {css, Theme} from "@emotion/react";

import {prettyScroll} from "@pg-design/helpers-css";

type IProps = PropsWithChildren<{className?: string}>;

export const FullHeightContent = (props: IProps) => {
    return (
        <div className={props.className} css={container}>
            {props.children}
        </div>
    );
};

const Header = (props: IProps) => {
    return <div className={props.className}>{props.children}</div>;
};

const Footer = (props: IProps) => {
    return <div className={props.className}>{props.children}</div>;
};

export const Main = forwardRef<HTMLDivElement, IProps>((props, ref) => {
    return (
        <div css={main} className={props.className} ref={ref}>
            <div css={section}>{props.children}</div>
        </div>
    );
});

const container = (theme: Theme) => css`
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    flex: 1;

    @media (min-width: ${theme.breakpoints.md}) {
        height: 100%;
    }
`;

const main = css`
    flex: 1;
    ${prettyScroll()};
`;

const section = css`
    height: 100%;
`;

FullHeightContent.Header = Header;
FullHeightContent.Main = Main;
FullHeightContent.Footer = Footer;
