import {convertToCountryLatLngLiteral} from "@pg-mono/open-street-map";

import {getOfferTypeNamePlural} from "../../../offer/helpers/OfferType";
import {Country} from "../../../region/types/Country";

export interface ITrackedOfferRegion {
    country: Country;
    full_name: string;
    id: number;
}

export interface ITrackedOfferWithoutRegion {
    geo_point: {
        coordinates: [number, number];
    };
    id: number;
    name: string;
    type: number;
}

export interface ITrackedOffer extends ITrackedOfferWithoutRegion {
    region: ITrackedOfferRegion;
}

export const getTrackedOfferData = (offer: ITrackedOffer) => {
    const {lat, lng} = convertToCountryLatLngLiteral(offer.geo_point.coordinates, offer.region.country);

    return {
        offer_id: offer.id,
        offer_longitude: lng,
        offer_latitude: lat,
        offer_name: offer.name,
        offer_region: offer.region.full_name,
        offer_region_id: offer.region.id,
        offer_type: getOfferTypeNamePlural(offer.type),
        offer_type_id: offer.type
    };
};
import {convertToCountryLatLngLiteral} from "@pg-mono/open-street-map";

import {getOfferTypeNamePlural} from "../../../offer/helpers/OfferType";
import {Country} from "../../../region/types/Country";

export interface ITrackedOfferRegion {
    country: Country;
    full_name: string;
    id: number;
}

export interface ITrackedOfferWithoutRegion {
    geo_point: {
        coordinates: [number, number];
    };
    id: number;
    name: string;
    type: number;
}

export interface ITrackedOffer extends ITrackedOfferWithoutRegion {
    region: ITrackedOfferRegion;
}

export const getTrackedOfferData = (offer: ITrackedOffer) => {
    const {lat, lng} = convertToCountryLatLngLiteral(offer.geo_point.coordinates, offer.region.country);

    return {
        offer_id: offer.id,
        offer_longitude: lng,
        offer_latitude: lat,
        offer_name: offer.name,
        offer_region: offer.region.full_name,
        offer_region_id: offer.region.id,
        offer_type: getOfferTypeNamePlural(offer.type),
        offer_type_id: offer.type
    };
};
