import {IRange} from "../types/IRange";
import {RangeType} from "../types/RangeType";

export const getInitialValue = (value: IRange<RangeType>, min: number) => {
    const values = [value?.lower, value?.upper].filter((value) => Number.isFinite(Number(value))) as number[];

    return values.length === 0 ? [min] : values;
};
import {IRange} from "../types/IRange";
import {RangeType} from "../types/RangeType";

export const getInitialValue = (value: IRange<RangeType>, min: number) => {
    const values = [value?.lower, value?.upper].filter((value) => Number.isFinite(Number(value))) as number[];

    return values.length === 0 ? [min] : values;
};
