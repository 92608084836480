import {useEffect} from "react";
import {css} from "@emotion/react";

import {onDesktop} from "@pg-design/helpers-css/";

import {IApplication} from "../../../../application/types/IApplication";
import {IMultiLeadStepPayload} from "../../../../application/types/IMultiLeadStepPayload";
import {ISetMultiLeadSummaryStepPayload} from "../../../../application/types/ISetMultiLeadSummaryStepPayload";
import {useAppDispatch} from "../../../../store/hooks";
import {useLeadModalConversationFormPriceCheck} from "../../../hooks/use_lead_modal_conversation_form_price_check";
import {setPriceConversationConsultant} from "../../../state/lead_modal_slice";
import {ILeadModalPriceConversationConsultant} from "../../../types/ILeadModalPriceConversationConsultant";
import {LeadModalPriceConversation} from "./lead_modal_price_conversation/LeadModalPriceConversation";
import {LeadModalCheckPriceStepLoader} from "./LeadModalCheckPriceStepLoader";

import f1_72 from "../../../images/consultants/1_72x72.png";
import f1_144 from "../../../images/consultants/1_144x144.png";
import f1_216 from "../../../images/consultants/1_216x216.png";
import m1_72 from "../../../images/consultants/2_72x72.png";
import m1_144 from "../../../images/consultants/2_144x144.png";
import m1_216 from "../../../images/consultants/2_216x216.png";
import m2_72 from "../../../images/consultants/3_72x72.png";
import m2_144 from "../../../images/consultants/3_144x144.png";
import m2_216 from "../../../images/consultants/3_216x216.png";
import f2_72 from "../../../images/consultants/4_72x72.png";
import f2_144 from "../../../images/consultants/4_144x144.png";
import f2_216 from "../../../images/consultants/4_216x216.png";
import f3_72 from "../../../images/consultants/5_72x72.png";
import f3_144 from "../../../images/consultants/5_144x144.png";
import f3_216 from "../../../images/consultants/5_216x216.png";
import f4_72 from "../../../images/consultants/6_72x72.png";
import f4_144 from "../../../images/consultants/6_144x144.png";
import f4_216 from "../../../images/consultants/6_216x216.png";

interface IProps {
    handleModalClose: () => void;
    handleSetMultiLeadStep: (payload: IMultiLeadStepPayload) => void;
    handleSetMultiLeadSummary: (payload: ISetMultiLeadSummaryStepPayload) => void;
    handleUpdateMainLeadResponse: (sentApplication: IApplication) => void;
}

export function LeadModalPriceConversationStep(props: IProps) {
    const {handleModalClose, handleSetMultiLeadStep, handleSetMultiLeadSummary, handleUpdateMainLeadResponse} = props;

    const dispatch = useAppDispatch();
    const checkingPrice = useLeadModalConversationFormPriceCheck();

    useEffect(() => {
        dispatch(setPriceConversationConsultant(getConsultant()));
    }, []);

    return (
        <div css={conversationFormStep}>
            {checkingPrice ? (
                <LeadModalCheckPriceStepLoader />
            ) : (
                <LeadModalPriceConversation
                    handleModalClose={handleModalClose}
                    handleSetMultiLeadStep={handleSetMultiLeadStep}
                    handleSetMultiLeadSummary={handleSetMultiLeadSummary}
                    handleUpdateMainLeadResponse={handleUpdateMainLeadResponse}
                />
            )}
        </div>
    );
}

// Utils
function getConsultant() {
    const randomConsultantIndex = Math.floor(Math.random() * 6);

    const consultantList: ILeadModalPriceConversationConsultant[] = [
        {
            image: {
                s72: f1_72,
                s144: f1_144,
                s216: f1_216
            },
            gender: "female",
            fullName: getConsultantName("female")
        },
        {
            image: {
                s72: f2_72,
                s144: f2_144,
                s216: f2_216
            },
            gender: "female",
            fullName: getConsultantName("female")
        },
        {
            image: {
                s72: f3_72,
                s144: f3_144,
                s216: f3_216
            },
            gender: "female",
            fullName: getConsultantName("female")
        },
        {
            image: {
                s72: f4_72,
                s144: f4_144,
                s216: f4_216
            },
            gender: "female",
            fullName: getConsultantName("female")
        },
        {
            image: {
                s72: m1_72,
                s144: m1_144,
                s216: m1_216
            },
            gender: "male",
            fullName: getConsultantName("male")
        },
        {
            image: {
                s72: m2_72,
                s144: m2_144,
                s216: m2_216
            },
            gender: "male",
            fullName: getConsultantName("male")
        }
    ];

    return consultantList[randomConsultantIndex];
}

function getConsultantName(gender: "male" | "female") {
    const randomNameIndex = Math.floor(Math.random() * 4);
    const maleName = ["Adam Wróbel", "Marek Lewandowski", "Paweł Malinowski", "Piotr Nowak"];
    const femaleNames = ["Monika Piotrowska", "Ewa Zielińska", "Katarzyna Wiśniewska", "Marta Górska"];

    if (gender === "male") {
        return maleName[randomNameIndex];
    }

    return femaleNames[randomNameIndex];
}

// Styles
const conversationFormStep = css`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    ${onDesktop(css`
        width: 851px;
        height: 677px;
    `)}
`;
import {useEffect} from "react";
import {css} from "@emotion/react";

import {onDesktop} from "@pg-design/helpers-css/";

import {IApplication} from "../../../../application/types/IApplication";
import {IMultiLeadStepPayload} from "../../../../application/types/IMultiLeadStepPayload";
import {ISetMultiLeadSummaryStepPayload} from "../../../../application/types/ISetMultiLeadSummaryStepPayload";
import {useAppDispatch} from "../../../../store/hooks";
import {useLeadModalConversationFormPriceCheck} from "../../../hooks/use_lead_modal_conversation_form_price_check";
import {setPriceConversationConsultant} from "../../../state/lead_modal_slice";
import {ILeadModalPriceConversationConsultant} from "../../../types/ILeadModalPriceConversationConsultant";
import {LeadModalPriceConversation} from "./lead_modal_price_conversation/LeadModalPriceConversation";
import {LeadModalCheckPriceStepLoader} from "./LeadModalCheckPriceStepLoader";

import f1_72 from "../../../images/consultants/1_72x72.png";
import f1_144 from "../../../images/consultants/1_144x144.png";
import f1_216 from "../../../images/consultants/1_216x216.png";
import m1_72 from "../../../images/consultants/2_72x72.png";
import m1_144 from "../../../images/consultants/2_144x144.png";
import m1_216 from "../../../images/consultants/2_216x216.png";
import m2_72 from "../../../images/consultants/3_72x72.png";
import m2_144 from "../../../images/consultants/3_144x144.png";
import m2_216 from "../../../images/consultants/3_216x216.png";
import f2_72 from "../../../images/consultants/4_72x72.png";
import f2_144 from "../../../images/consultants/4_144x144.png";
import f2_216 from "../../../images/consultants/4_216x216.png";
import f3_72 from "../../../images/consultants/5_72x72.png";
import f3_144 from "../../../images/consultants/5_144x144.png";
import f3_216 from "../../../images/consultants/5_216x216.png";
import f4_72 from "../../../images/consultants/6_72x72.png";
import f4_144 from "../../../images/consultants/6_144x144.png";
import f4_216 from "../../../images/consultants/6_216x216.png";

interface IProps {
    handleModalClose: () => void;
    handleSetMultiLeadStep: (payload: IMultiLeadStepPayload) => void;
    handleSetMultiLeadSummary: (payload: ISetMultiLeadSummaryStepPayload) => void;
    handleUpdateMainLeadResponse: (sentApplication: IApplication) => void;
}

export function LeadModalPriceConversationStep(props: IProps) {
    const {handleModalClose, handleSetMultiLeadStep, handleSetMultiLeadSummary, handleUpdateMainLeadResponse} = props;

    const dispatch = useAppDispatch();
    const checkingPrice = useLeadModalConversationFormPriceCheck();

    useEffect(() => {
        dispatch(setPriceConversationConsultant(getConsultant()));
    }, []);

    return (
        <div css={conversationFormStep}>
            {checkingPrice ? (
                <LeadModalCheckPriceStepLoader />
            ) : (
                <LeadModalPriceConversation
                    handleModalClose={handleModalClose}
                    handleSetMultiLeadStep={handleSetMultiLeadStep}
                    handleSetMultiLeadSummary={handleSetMultiLeadSummary}
                    handleUpdateMainLeadResponse={handleUpdateMainLeadResponse}
                />
            )}
        </div>
    );
}

// Utils
function getConsultant() {
    const randomConsultantIndex = Math.floor(Math.random() * 6);

    const consultantList: ILeadModalPriceConversationConsultant[] = [
        {
            image: {
                s72: f1_72,
                s144: f1_144,
                s216: f1_216
            },
            gender: "female",
            fullName: getConsultantName("female")
        },
        {
            image: {
                s72: f2_72,
                s144: f2_144,
                s216: f2_216
            },
            gender: "female",
            fullName: getConsultantName("female")
        },
        {
            image: {
                s72: f3_72,
                s144: f3_144,
                s216: f3_216
            },
            gender: "female",
            fullName: getConsultantName("female")
        },
        {
            image: {
                s72: f4_72,
                s144: f4_144,
                s216: f4_216
            },
            gender: "female",
            fullName: getConsultantName("female")
        },
        {
            image: {
                s72: m1_72,
                s144: m1_144,
                s216: m1_216
            },
            gender: "male",
            fullName: getConsultantName("male")
        },
        {
            image: {
                s72: m2_72,
                s144: m2_144,
                s216: m2_216
            },
            gender: "male",
            fullName: getConsultantName("male")
        }
    ];

    return consultantList[randomConsultantIndex];
}

function getConsultantName(gender: "male" | "female") {
    const randomNameIndex = Math.floor(Math.random() * 4);
    const maleName = ["Adam Wróbel", "Marek Lewandowski", "Paweł Malinowski", "Piotr Nowak"];
    const femaleNames = ["Monika Piotrowska", "Ewa Zielińska", "Katarzyna Wiśniewska", "Marta Górska"];

    if (gender === "male") {
        return maleName[randomNameIndex];
    }

    return femaleNames[randomNameIndex];
}

// Styles
const conversationFormStep = css`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    ${onDesktop(css`
        width: 851px;
        height: 677px;
    `)}
`;
