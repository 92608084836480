import lscache from "lscache";

import {USER_LAST_SEEN_OFFER_PROPERTIES} from "../../app/constants/storage_keys";

export interface ILastSeenOfferProperty {
    offerId: number;
    id: number;
}

const DEFAULT_STORAGE_LIMIT = 7;
export enum LastSeenOfferPropertiesOrderType {
    LAST_SEEN_FIRST,
    LAST_SEEN_LAST,
    VARIOUS_OFFER_ONLY
}

export const getLastSeenOfferProperties = (
    offerIdsToExclude?: number[],
    orderType: LastSeenOfferPropertiesOrderType = LastSeenOfferPropertiesOrderType.LAST_SEEN_LAST
) => {
    const properties: ILastSeenOfferProperty[] = lscache.get(USER_LAST_SEEN_OFFER_PROPERTIES);
    const excludeOfferIds = offerIdsToExclude || [];

    const lastSeenProperties = (
        Array.isArray(properties)
            ? properties
                  .filter((lastSeenProperty) => {
                      return Number.isFinite(lastSeenProperty.id) && Number.isFinite(lastSeenProperty.offerId);
                  })
                  .filter((lastSeenProperty) => excludeOfferIds.indexOf(lastSeenProperty.offerId) === -1)
            : []
    ) as ILastSeenOfferProperty[];

    switch (orderType) {
        case LastSeenOfferPropertiesOrderType.VARIOUS_OFFER_ONLY:
            return lastSeenProperties.reverse().reduce((acc, lastSeenProperty) => {
                const hasOfferIdExists = acc.some((property) => property.offerId === lastSeenProperty.offerId);

                if (!hasOfferIdExists) {
                    acc.push(lastSeenProperty);
                }
                return acc;
            }, [] as ILastSeenOfferProperty[]);
        case LastSeenOfferPropertiesOrderType.LAST_SEEN_FIRST:
            return lastSeenProperties.reverse();
        default:
            return lastSeenProperties;
    }
};

export const addLastSeenOfferProperty = (property: {id: number; offer: {id: number}}, options: {limit?: number} = {}) => {
    const limit = options?.limit || DEFAULT_STORAGE_LIMIT;

    let newLastSeenProperties = getLastSeenOfferProperties().filter((lastSeenProperty) => property.id !== lastSeenProperty.id);
    if (newLastSeenProperties.length >= limit) {
        // remove first property from the same investment or slice the array to the limit
        const firstPropertyFromSameInvestment = newLastSeenProperties.find((lastSeenProperty) => {
            return property.offer.id === lastSeenProperty.offerId;
        });
        newLastSeenProperties = newLastSeenProperties
            .filter((lastSeenProperty) => {
                return firstPropertyFromSameInvestment?.id !== lastSeenProperty.id;
            })
            .slice(-limit);
    }
    newLastSeenProperties.push({id: property.id, offerId: property.offer.id});

    lscache.set(USER_LAST_SEEN_OFFER_PROPERTIES, newLastSeenProperties);
};

export const removeLastSeenOfferProperties = (propertyIds: number[]) => {
    const lastSeenProperties = getLastSeenOfferProperties();
    const newLastSeenProperties = lastSeenProperties.filter((lastSeenProperty) => propertyIds.indexOf(lastSeenProperty.id) === -1);

    lscache.set(USER_LAST_SEEN_OFFER_PROPERTIES, newLastSeenProperties);
};

export const removeLastSeenPropertiesByOfferIds = (offerIds: number[]) => {
    const lastSeenProperties = getLastSeenOfferProperties();
    const newLastSeenProperties = lastSeenProperties.filter((lastSeenProperty) => offerIds.indexOf(lastSeenProperty.offerId) === -1);

    lscache.set(USER_LAST_SEEN_OFFER_PROPERTIES, newLastSeenProperties);
};
import lscache from "lscache";

import {USER_LAST_SEEN_OFFER_PROPERTIES} from "../../app/constants/storage_keys";

export interface ILastSeenOfferProperty {
    offerId: number;
    id: number;
}

const DEFAULT_STORAGE_LIMIT = 7;
export enum LastSeenOfferPropertiesOrderType {
    LAST_SEEN_FIRST,
    LAST_SEEN_LAST,
    VARIOUS_OFFER_ONLY
}

export const getLastSeenOfferProperties = (
    offerIdsToExclude?: number[],
    orderType: LastSeenOfferPropertiesOrderType = LastSeenOfferPropertiesOrderType.LAST_SEEN_LAST
) => {
    const properties: ILastSeenOfferProperty[] = lscache.get(USER_LAST_SEEN_OFFER_PROPERTIES);
    const excludeOfferIds = offerIdsToExclude || [];

    const lastSeenProperties = (
        Array.isArray(properties)
            ? properties
                  .filter((lastSeenProperty) => {
                      return Number.isFinite(lastSeenProperty.id) && Number.isFinite(lastSeenProperty.offerId);
                  })
                  .filter((lastSeenProperty) => excludeOfferIds.indexOf(lastSeenProperty.offerId) === -1)
            : []
    ) as ILastSeenOfferProperty[];

    switch (orderType) {
        case LastSeenOfferPropertiesOrderType.VARIOUS_OFFER_ONLY:
            return lastSeenProperties.reverse().reduce((acc, lastSeenProperty) => {
                const hasOfferIdExists = acc.some((property) => property.offerId === lastSeenProperty.offerId);

                if (!hasOfferIdExists) {
                    acc.push(lastSeenProperty);
                }
                return acc;
            }, [] as ILastSeenOfferProperty[]);
        case LastSeenOfferPropertiesOrderType.LAST_SEEN_FIRST:
            return lastSeenProperties.reverse();
        default:
            return lastSeenProperties;
    }
};

export const addLastSeenOfferProperty = (property: {id: number; offer: {id: number}}, options: {limit?: number} = {}) => {
    const limit = options?.limit || DEFAULT_STORAGE_LIMIT;

    let newLastSeenProperties = getLastSeenOfferProperties().filter((lastSeenProperty) => property.id !== lastSeenProperty.id);
    if (newLastSeenProperties.length >= limit) {
        // remove first property from the same investment or slice the array to the limit
        const firstPropertyFromSameInvestment = newLastSeenProperties.find((lastSeenProperty) => {
            return property.offer.id === lastSeenProperty.offerId;
        });
        newLastSeenProperties = newLastSeenProperties
            .filter((lastSeenProperty) => {
                return firstPropertyFromSameInvestment?.id !== lastSeenProperty.id;
            })
            .slice(-limit);
    }
    newLastSeenProperties.push({id: property.id, offerId: property.offer.id});

    lscache.set(USER_LAST_SEEN_OFFER_PROPERTIES, newLastSeenProperties);
};

export const removeLastSeenOfferProperties = (propertyIds: number[]) => {
    const lastSeenProperties = getLastSeenOfferProperties();
    const newLastSeenProperties = lastSeenProperties.filter((lastSeenProperty) => propertyIds.indexOf(lastSeenProperty.id) === -1);

    lscache.set(USER_LAST_SEEN_OFFER_PROPERTIES, newLastSeenProperties);
};

export const removeLastSeenPropertiesByOfferIds = (offerIds: number[]) => {
    const lastSeenProperties = getLastSeenOfferProperties();
    const newLastSeenProperties = lastSeenProperties.filter((lastSeenProperty) => offerIds.indexOf(lastSeenProperty.offerId) === -1);

    lscache.set(USER_LAST_SEEN_OFFER_PROPERTIES, newLastSeenProperties);
};
