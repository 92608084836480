import {getAvailableAndSearchedRooms} from "../../application/utils/getAvailableAndSearchedRooms";
import {IOfferDetailApplication} from "../../offer/types/IOfferDetailApplication";
import {IUserPreferences} from "../../user/interfaces/IUserPreferences";

interface ICalculateRoomsFieldParams {
    offer?: IOfferDetailApplication;
    propertyId?: number;
    userPreferencesFilters: IUserPreferences["filters"];
}

export function calculateRoomsField(params: ICalculateRoomsFieldParams) {
    const {offer, propertyId} = params;
    const availableRooms = (offer && offer.stats && offer.stats.rooms) || [];

    if (propertyId) {
        return [];
    }

    return getAvailableAndSearchedRooms(availableRooms, params.userPreferencesFilters);
}
import {getAvailableAndSearchedRooms} from "../../application/utils/getAvailableAndSearchedRooms";
import {IOfferDetailApplication} from "../../offer/types/IOfferDetailApplication";
import {IUserPreferences} from "../../user/interfaces/IUserPreferences";

interface ICalculateRoomsFieldParams {
    offer?: IOfferDetailApplication;
    propertyId?: number;
    userPreferencesFilters: IUserPreferences["filters"];
}

export function calculateRoomsField(params: ICalculateRoomsFieldParams) {
    const {offer, propertyId} = params;
    const availableRooms = (offer && offer.stats && offer.stats.rooms) || [];

    if (propertyId) {
        return [];
    }

    return getAvailableAndSearchedRooms(availableRooms, params.userPreferencesFilters);
}
