import React from "react";

import {ShortInfoBar} from "../../atoms/ShortInfoBar";
import {getCreditInfo} from "../../credit_calculator/utils/get_credit_info";
import {OfferType} from "../../offer/types/OfferType";

interface IProps {
    number: string;
    area: number;
    rooms: number;
    floor: number;
    propertyType: number | null;
    className?: string;
    creditData?: {contribution: number; period: number};
}

export const PropertyInformation = (props: IProps) => {
    const {creditData} = props;

    const additionalInfoItems = creditData && getCreditInfo({own_contribution: creditData.contribution, term_of_the_loan: creditData.period});

    return (
        <ShortInfoBar
            infoItems={[
                {value: props.number, label: "Nieruchomość"},
                {
                    value: (
                        <>
                            {Math.round(props.area)} m<sup>2</sup>
                        </>
                    ),
                    label: "Powierzchnia",
                    blockValueTranslation: true
                },
                {value: props.rooms.toString(), label: "Pokoje"},
                {value: props.floor === 0 ? "Parter" : props.floor.toString(), label: props.propertyType === OfferType.HOUSE ? "Piętra" : "Piętro"}
            ]}
            className={props.className}
            additionalInfoItems={additionalInfoItems}
        />
    );
};
import React from "react";

import {ShortInfoBar} from "../../atoms/ShortInfoBar";
import {getCreditInfo} from "../../credit_calculator/utils/get_credit_info";
import {OfferType} from "../../offer/types/OfferType";

interface IProps {
    number: string;
    area: number;
    rooms: number;
    floor: number;
    propertyType: number | null;
    className?: string;
    creditData?: {contribution: number; period: number};
}

export const PropertyInformation = (props: IProps) => {
    const {creditData} = props;

    const additionalInfoItems = creditData && getCreditInfo({own_contribution: creditData.contribution, term_of_the_loan: creditData.period});

    return (
        <ShortInfoBar
            infoItems={[
                {value: props.number, label: "Nieruchomość"},
                {
                    value: (
                        <>
                            {Math.round(props.area)} m<sup>2</sup>
                        </>
                    ),
                    label: "Powierzchnia",
                    blockValueTranslation: true
                },
                {value: props.rooms.toString(), label: "Pokoje"},
                {value: props.floor === 0 ? "Parter" : props.floor.toString(), label: props.propertyType === OfferType.HOUSE ? "Piętra" : "Piętro"}
            ]}
            className={props.className}
            additionalInfoItems={additionalInfoItems}
        />
    );
};
