import {noop, omit} from "@pg-mono/nodash";
import {getRequest} from "@pg-mono/request";
import {apiV2Link, Scenario} from "@pg-mono/rp-api-routes";

import {clientRpRequestMeta} from "../../../client/client_rp_request_meta";
import {gtmInquiryOnApplicationSuccess} from "../../lead/tracking/gtm_inquiry_on_application_success";
import {ILeadModalLeadType} from "../../lead/types/ILeadModalLeadType";
import {IOfferDetailApplication} from "../../offer/types/IOfferDetailApplication";
import {ViewType} from "../../view_type/ViewType";
import {IApplicationData} from "../reducers/application_reducer";
import {RecommendedPropertyApplicationsType} from "../types/recommended_property_applications_type";
import {getApplicationSourceSectionDisplay} from "../utils/ApplicationSource";

const fetchRecommendedPropertyOffer = (offerId: number): Promise<IOfferDetailApplication> => {
    return getRequest(clientRpRequestMeta, apiV2Link.offer.detail(Scenario.OFFER_APPLICATION, {offerId}));
};

export const trackRecommendedPropertiesApplication = (
    applications: RecommendedPropertyApplicationsType[],
    viewType: ViewType | null,
    leadType: ILeadModalLeadType,
    recommendationsWithPriceFilter: boolean,
    sourceSection?: string
): void => {
    // We need to fetch property offer data in order to make GTM event after application submit success
    Promise.all(applications.map(([application]) => fetchRecommendedPropertyOffer(application.offer))).then((offers) => {
        offers.forEach((offer, index) => {
            // Order of response from Promise.all method is preserved, so we can get value from `applications` basing on the index
            const [application, recommendedProperty] = applications[index];

            //GTM purchase event
            const data = {
                offer: {detail: offer},
                vendor: {detail: recommendedProperty.offer.vendor},
                property: {detail: omit(recommendedProperty, ["offer"])}
            } as unknown as IApplicationData;

            const customSourceSection =
                sourceSection || `${getApplicationSourceSectionDisplay(application.source_section)} - ${recommendationsWithPriceFilter ? "low" : "similar"}`;

            gtmInquiryOnApplicationSuccess(application, data, viewType, leadType, true, noop(), customSourceSection);
        });
    });
};
import {noop, omit} from "@pg-mono/nodash";
import {getRequest} from "@pg-mono/request";
import {apiV2Link, Scenario} from "@pg-mono/rp-api-routes";

import {clientRpRequestMeta} from "../../../client/client_rp_request_meta";
import {gtmInquiryOnApplicationSuccess} from "../../lead/tracking/gtm_inquiry_on_application_success";
import {ILeadModalLeadType} from "../../lead/types/ILeadModalLeadType";
import {IOfferDetailApplication} from "../../offer/types/IOfferDetailApplication";
import {ViewType} from "../../view_type/ViewType";
import {IApplicationData} from "../reducers/application_reducer";
import {RecommendedPropertyApplicationsType} from "../types/recommended_property_applications_type";
import {getApplicationSourceSectionDisplay} from "../utils/ApplicationSource";

const fetchRecommendedPropertyOffer = (offerId: number): Promise<IOfferDetailApplication> => {
    return getRequest(clientRpRequestMeta, apiV2Link.offer.detail(Scenario.OFFER_APPLICATION, {offerId}));
};

export const trackRecommendedPropertiesApplication = (
    applications: RecommendedPropertyApplicationsType[],
    viewType: ViewType | null,
    leadType: ILeadModalLeadType,
    recommendationsWithPriceFilter: boolean,
    sourceSection?: string
): void => {
    // We need to fetch property offer data in order to make GTM event after application submit success
    Promise.all(applications.map(([application]) => fetchRecommendedPropertyOffer(application.offer))).then((offers) => {
        offers.forEach((offer, index) => {
            // Order of response from Promise.all method is preserved, so we can get value from `applications` basing on the index
            const [application, recommendedProperty] = applications[index];

            //GTM purchase event
            const data = {
                offer: {detail: offer},
                vendor: {detail: recommendedProperty.offer.vendor},
                property: {detail: omit(recommendedProperty, ["offer"])}
            } as unknown as IApplicationData;

            const customSourceSection =
                sourceSection || `${getApplicationSourceSectionDisplay(application.source_section)} - ${recommendationsWithPriceFilter ? "low" : "similar"}`;

            gtmInquiryOnApplicationSuccess(application, data, viewType, leadType, true, noop(), customSourceSection);
        });
    });
};
