import {css} from "@emotion/react";

import {calculateRemSize} from "@pg-design/helpers-css";
import {Loader} from "@pg-design/loader-module";
import {Text} from "@pg-design/text-module";

export function LeadModalCheckPriceStepLoader() {
    return (
        <div css={checkingPrice}>
            <Text variant="headline_3">Sprawdzamy cenę mieszkania</Text>
            <Loader size="xl" />
        </div>
    );
}

const checkingPrice = css`
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: ${calculateRemSize(1.5)};
    gap: ${calculateRemSize(8)};
`;
import {css} from "@emotion/react";

import {calculateRemSize} from "@pg-design/helpers-css";
import {Loader} from "@pg-design/loader-module";
import {Text} from "@pg-design/text-module";

export function LeadModalCheckPriceStepLoader() {
    return (
        <div css={checkingPrice}>
            <Text variant="headline_3">Sprawdzamy cenę mieszkania</Text>
            <Loader size="xl" />
        </div>
    );
}

const checkingPrice = css`
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: ${calculateRemSize(1.5)};
    gap: ${calculateRemSize(8)};
`;
