import {ILeadSummaryItem} from "../../../lead/types/ILeadSummaryItem";
import {IOfferDetailApplication} from "../../../offer/types/IOfferDetailApplication";
import {IPropertyDetailApplication} from "../../../property/types/IPropertyDetailApplication";
import {IVendorDetailApplication} from "../../../vendor/types/IVendorDetailApplication";
import {IAdditionalData} from "../../reducers/application_reducer";

interface IParams {
    property: IAdditionalData<IPropertyDetailApplication> | null;
    offer: IAdditionalData<IOfferDetailApplication> | null;
    vendor: IAdditionalData<IVendorDetailApplication> | null;
    isPriceRevealed?: boolean;
    price?: number | null;
}

export function transformLeadDataPropertyIntoLeadSummaryItem(params: IParams) {
    const {property: propertyData, offer: offerData, vendor: vendorData, isPriceRevealed} = params;
    const offer = offerData?.detail;
    const property = propertyData?.detail;
    const vendor = vendorData?.detail;

    if (!property || !offer || !vendor) {
        return null;
    }

    return {
        area: property.area,
        mainImage: {
            desktop: offer.main_image.m_img_375x211,
            mobile: offer.main_image.m_img_375x211
        },
        offerType: offer.type,
        offerName: offer.name,
        region: offer.region,
        rooms: property.rooms,
        offerVendorName: vendor.name,
        offerAddress: offer.address,
        isPriceRevealed,
        price: params.price || property.price
    } as Omit<ILeadSummaryItem, "regionEstimatedPriceSummary">;
}
import {ILeadSummaryItem} from "../../../lead/types/ILeadSummaryItem";
import {IOfferDetailApplication} from "../../../offer/types/IOfferDetailApplication";
import {IPropertyDetailApplication} from "../../../property/types/IPropertyDetailApplication";
import {IVendorDetailApplication} from "../../../vendor/types/IVendorDetailApplication";
import {IAdditionalData} from "../../reducers/application_reducer";

interface IParams {
    property: IAdditionalData<IPropertyDetailApplication> | null;
    offer: IAdditionalData<IOfferDetailApplication> | null;
    vendor: IAdditionalData<IVendorDetailApplication> | null;
    isPriceRevealed?: boolean;
    price?: number | null;
}

export function transformLeadDataPropertyIntoLeadSummaryItem(params: IParams) {
    const {property: propertyData, offer: offerData, vendor: vendorData, isPriceRevealed} = params;
    const offer = offerData?.detail;
    const property = propertyData?.detail;
    const vendor = vendorData?.detail;

    if (!property || !offer || !vendor) {
        return null;
    }

    return {
        area: property.area,
        mainImage: {
            desktop: offer.main_image.m_img_375x211,
            mobile: offer.main_image.m_img_375x211
        },
        offerType: offer.type,
        offerName: offer.name,
        region: offer.region,
        rooms: property.rooms,
        offerVendorName: vendor.name,
        offerAddress: offer.address,
        isPriceRevealed,
        price: params.price || property.price
    } as Omit<ILeadSummaryItem, "regionEstimatedPriceSummary">;
}
