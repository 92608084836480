import React, {useEffect, useState} from "react";
import {css} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {CenterBox} from "@pg-design/grid-module";
import {m} from "@pg-design/helpers-css";
import {ChevronLeftIcon, ChevronRightIcon} from "@pg-design/icons-module";
import {Image} from "@pg-design/image-module";
import {Loader} from "@pg-design/loader-module";
import {SliderGallery} from "@pg-design/slider-gallery-module";
import {RequestState, useFetch} from "@pg-mono/hooks";

import {fetchOfferGalleryPictures} from "../../../../../offer/actions/fetch_gallery_pictures";
import {IPropertyDetail} from "../../../../../property/types/IPropertyDetail";

interface IProps {
    id: number | string;
    offerName: string;
    offerVendorName: string;
    showPlans?: boolean;
    propertyDetails?: {
        plan_image_pages: IPropertyDetail["plan_image_pages"];
        offer: {
            id: number;
        };
    } | null;
    offerDetails?: {
        id: number;
    } | null;
}

export const MultiLeadCardGallery = (props: IProps) => {
    const [initialGalleryIndex, setInitialGalleryIndex] = useState(0);

    const plans = props.propertyDetails?.plan_image_pages || [];

    const {
        fetchStart: fetchOfferGalleryPicture,
        response: galleryPictures,
        fetchState
    } = useFetch(() => {
        const offerId = props.propertyDetails?.offer.id || props.offerDetails?.id;
        return offerId ? fetchOfferGalleryPictures(offerId) : Promise.resolve(null);
    });
    useEffect(() => {
        if (!props.showPlans) {
            fetchOfferGalleryPicture();
        }
    }, []);
    const gallery = galleryPictures && galleryPictures.map((photo) => photo?.image?.g_img_760x428).filter((photo) => !!photo);

    const images = props.showPlans ? plans.map((plan) => plan.image.p_img_760x428) : gallery;

    useEffect(() => {
        setInitialGalleryIndex(0);
    }, [props.id]);

    if (!props.showPlans && fetchState !== RequestState.Success) {
        return (
            <CenterBox>
                <Loader size="lg" />
            </CenterBox>
        );
    }

    return images && images.length > 0 ? (
        <SliderGallery
            key={`${props.showPlans}`}
            initialSlide={initialGalleryIndex}
            arrows={{
                left: (
                    <Button variant="none_secondary" css={[arrowIconStyle, iconLeftStyle]}>
                        <ChevronLeftIcon size="1.6" wrapperSize="3.2" wrapperColor="#fff" />
                    </Button>
                ),
                right: (
                    <Button variant="none_secondary" css={[arrowIconStyle, iconRightStyle]}>
                        <ChevronRightIcon size="1.6" wrapperSize="3.2" wrapperColor="#fff" />
                    </Button>
                )
            }}
            slides={images.map((image, index) => {
                return (
                    <Image
                        key={index}
                        css={[propertyImageStyle, props.showPlans ? planImageStyle : ""]}
                        src={image}
                        width="auto"
                        height="auto"
                        alt={`${props.offerVendorName} - ${props.offerName}`}
                    />
                );
            })}
            slideStyles={singleSlideStyle}
            css={m(0)}
        />
    ) : null;
};

const arrowIconStyle = css`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    display: block;
    padding: 0;
`;

const iconLeftStyle = css`
    left: 0.8rem;
`;

const iconRightStyle = css`
    right: 0.8rem;
`;
const singleSlideStyle: React.CSSProperties = {
    padding: "0",
    width: "100%"
};

const propertyImageStyle = css`
    width: 100%;
    height: 100%;

    & > img {
        object-fit: cover;
        width: 100%;
        animation: opacityAnimation 0.5s linear;
    }

    @keyframes opacityAnimation {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
`;

const planImageStyle = css`
    & > img {
        object-fit: contain;
        height: 100%;
    }
`;
import React, {useEffect, useState} from "react";
import {css} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {CenterBox} from "@pg-design/grid-module";
import {m} from "@pg-design/helpers-css";
import {ChevronLeftIcon, ChevronRightIcon} from "@pg-design/icons-module";
import {Image} from "@pg-design/image-module";
import {Loader} from "@pg-design/loader-module";
import {SliderGallery} from "@pg-design/slider-gallery-module";
import {RequestState, useFetch} from "@pg-mono/hooks";

import {fetchOfferGalleryPictures} from "../../../../../offer/actions/fetch_gallery_pictures";
import {IPropertyDetail} from "../../../../../property/types/IPropertyDetail";

interface IProps {
    id: number | string;
    offerName: string;
    offerVendorName: string;
    showPlans?: boolean;
    propertyDetails?: {
        plan_image_pages: IPropertyDetail["plan_image_pages"];
        offer: {
            id: number;
        };
    } | null;
    offerDetails?: {
        id: number;
    } | null;
}

export const MultiLeadCardGallery = (props: IProps) => {
    const [initialGalleryIndex, setInitialGalleryIndex] = useState(0);

    const plans = props.propertyDetails?.plan_image_pages || [];

    const {
        fetchStart: fetchOfferGalleryPicture,
        response: galleryPictures,
        fetchState
    } = useFetch(() => {
        const offerId = props.propertyDetails?.offer.id || props.offerDetails?.id;
        return offerId ? fetchOfferGalleryPictures(offerId) : Promise.resolve(null);
    });
    useEffect(() => {
        if (!props.showPlans) {
            fetchOfferGalleryPicture();
        }
    }, []);
    const gallery = galleryPictures && galleryPictures.map((photo) => photo?.image?.g_img_760x428).filter((photo) => !!photo);

    const images = props.showPlans ? plans.map((plan) => plan.image.p_img_760x428) : gallery;

    useEffect(() => {
        setInitialGalleryIndex(0);
    }, [props.id]);

    if (!props.showPlans && fetchState !== RequestState.Success) {
        return (
            <CenterBox>
                <Loader size="lg" />
            </CenterBox>
        );
    }

    return images && images.length > 0 ? (
        <SliderGallery
            key={`${props.showPlans}`}
            initialSlide={initialGalleryIndex}
            arrows={{
                left: (
                    <Button variant="none_secondary" css={[arrowIconStyle, iconLeftStyle]}>
                        <ChevronLeftIcon size="1.6" wrapperSize="3.2" wrapperColor="#fff" />
                    </Button>
                ),
                right: (
                    <Button variant="none_secondary" css={[arrowIconStyle, iconRightStyle]}>
                        <ChevronRightIcon size="1.6" wrapperSize="3.2" wrapperColor="#fff" />
                    </Button>
                )
            }}
            slides={images.map((image, index) => {
                return (
                    <Image
                        key={index}
                        css={[propertyImageStyle, props.showPlans ? planImageStyle : ""]}
                        src={image}
                        width="auto"
                        height="auto"
                        alt={`${props.offerVendorName} - ${props.offerName}`}
                    />
                );
            })}
            slideStyles={singleSlideStyle}
            css={m(0)}
        />
    ) : null;
};

const arrowIconStyle = css`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    display: block;
    padding: 0;
`;

const iconLeftStyle = css`
    left: 0.8rem;
`;

const iconRightStyle = css`
    right: 0.8rem;
`;
const singleSlideStyle: React.CSSProperties = {
    padding: "0",
    width: "100%"
};

const propertyImageStyle = css`
    width: 100%;
    height: 100%;

    & > img {
        object-fit: cover;
        width: 100%;
        animation: opacityAnimation 0.5s linear;
    }

    @keyframes opacityAnimation {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
`;

const planImageStyle = css`
    & > img {
        object-fit: contain;
        height: 100%;
    }
`;
