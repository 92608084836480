export enum MultileadVersion {
    RP_LIST_OFFER,
    RP_LIST_SAME_OFFER,
    RP_LIST_PROPERTY,
    RP_LIST_VENDOR,
    RP_SWIPE,
    MP_LIST
}
export enum MultileadVersion {
    RP_LIST_OFFER,
    RP_LIST_SAME_OFFER,
    RP_LIST_PROPERTY,
    RP_LIST_VENDOR,
    RP_SWIPE,
    MP_LIST
}
