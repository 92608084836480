import {Dispatch} from "redux";

import {IRecommendedOffer} from "../../recommendations/types/IRecommendedOffer";
import {IRecommendedProperty} from "../../recommendations/types/IRecommendedProperty";
import {IApplication} from "../types/IApplication";

const MULTI_LEAD_APPLICATIONS_ACTIONS_PREFIX = "multi_lead/applications";

export interface IMultiLeadApplication {
    application: IApplication;
    recommendedOffer?: IRecommendedOffer;
    recommendedProperty?: IRecommendedProperty;
}
export const multiLeadApplicationsActionsTypes = {
    reset: `${MULTI_LEAD_APPLICATIONS_ACTIONS_PREFIX}_reset`,
    append: `${MULTI_LEAD_APPLICATIONS_ACTIONS_PREFIX}_append`
} as const;

export type IMultiLeadApplicationsResetAction = {
    type: typeof multiLeadApplicationsActionsTypes.reset;
    id: string;
};

export type IMultiLeadApplicationsAppendAction = {
    type: typeof multiLeadApplicationsActionsTypes.append;
    id: string;
    applications: IMultiLeadApplication[];
};

export type IMultiLeadApplicationsActions = IMultiLeadApplicationsResetAction | IMultiLeadApplicationsAppendAction;

export const appendMultiLeadApplicationsForProperties =
    (storeHash: string, recommendedProperties: [IApplication, IRecommendedProperty][]) => (dispatch: Dispatch) => {
        const applications = recommendedProperties.map(([application, recommendedProperty]) => ({
            application,
            recommendedProperty
        }));

        dispatch({type: multiLeadApplicationsActionsTypes.append, id: storeHash, applications: applications});
    };

export const appendMultiLeadApplicationsForOffers = (storeHash: string, recommendedOffers: [IApplication, IRecommendedOffer][]) => (dispatch: Dispatch) => {
    const applications = recommendedOffers.map(([application, recommendedOffer]) => ({
        application,
        recommendedOffer
    }));

    dispatch({type: multiLeadApplicationsActionsTypes.append, id: storeHash, applications: applications});
};
import {Dispatch} from "redux";

import {IRecommendedOffer} from "../../recommendations/types/IRecommendedOffer";
import {IRecommendedProperty} from "../../recommendations/types/IRecommendedProperty";
import {IApplication} from "../types/IApplication";

const MULTI_LEAD_APPLICATIONS_ACTIONS_PREFIX = "multi_lead/applications";

export interface IMultiLeadApplication {
    application: IApplication;
    recommendedOffer?: IRecommendedOffer;
    recommendedProperty?: IRecommendedProperty;
}
export const multiLeadApplicationsActionsTypes = {
    reset: `${MULTI_LEAD_APPLICATIONS_ACTIONS_PREFIX}_reset`,
    append: `${MULTI_LEAD_APPLICATIONS_ACTIONS_PREFIX}_append`
} as const;

export type IMultiLeadApplicationsResetAction = {
    type: typeof multiLeadApplicationsActionsTypes.reset;
    id: string;
};

export type IMultiLeadApplicationsAppendAction = {
    type: typeof multiLeadApplicationsActionsTypes.append;
    id: string;
    applications: IMultiLeadApplication[];
};

export type IMultiLeadApplicationsActions = IMultiLeadApplicationsResetAction | IMultiLeadApplicationsAppendAction;

export const appendMultiLeadApplicationsForProperties =
    (storeHash: string, recommendedProperties: [IApplication, IRecommendedProperty][]) => (dispatch: Dispatch) => {
        const applications = recommendedProperties.map(([application, recommendedProperty]) => ({
            application,
            recommendedProperty
        }));

        dispatch({type: multiLeadApplicationsActionsTypes.append, id: storeHash, applications: applications});
    };

export const appendMultiLeadApplicationsForOffers = (storeHash: string, recommendedOffers: [IApplication, IRecommendedOffer][]) => (dispatch: Dispatch) => {
    const applications = recommendedOffers.map(([application, recommendedOffer]) => ({
        application,
        recommendedOffer
    }));

    dispatch({type: multiLeadApplicationsActionsTypes.append, id: storeHash, applications: applications});
};
