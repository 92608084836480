import type {LatLngLiteral} from "leaflet";

export const createCountryLatLngConverter = (lat: {min: number; max: number}, lng: {min: number; max: number}) => {
    return (values: number[]): LatLngLiteral | never => {
        if (values?.length !== 2) {
            throw new Error("Incorrect coordinates");
        }

        const coords =
            values[0] > lat.min
                ? ({
                      lat: values[0],
                      lng: values[1]
                  } as LatLngLiteral)
                : ({
                      lat: values[1],
                      lng: values[0]
                  } as LatLngLiteral);

        if (coords.lat < lat.min || coords.lat > lat.max) {
            throw new Error(`Incorrect latitude: ${coords.lat}`);
        }

        if (coords.lng < lng.min || coords.lng > lng.max) {
            throw new Error(`Incorrect longitude: ${coords.lng}`);
        }

        return coords;
    };
};
import type {LatLngLiteral} from "leaflet";

export const createCountryLatLngConverter = (lat: {min: number; max: number}, lng: {min: number; max: number}) => {
    return (values: number[]): LatLngLiteral | never => {
        if (values?.length !== 2) {
            throw new Error("Incorrect coordinates");
        }

        const coords =
            values[0] > lat.min
                ? ({
                      lat: values[0],
                      lng: values[1]
                  } as LatLngLiteral)
                : ({
                      lat: values[1],
                      lng: values[0]
                  } as LatLngLiteral);

        if (coords.lat < lat.min || coords.lat > lat.max) {
            throw new Error(`Incorrect latitude: ${coords.lat}`);
        }

        if (coords.lng < lng.min || coords.lng > lng.max) {
            throw new Error(`Incorrect longitude: ${coords.lng}`);
        }

        return coords;
    };
};
