import {Dispatch} from "redux";

import {catch400, catch403, Response400} from "@pg-mono/request";
import {patchRequest, postRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiLink} from "@pg-mono/rp-api-routes";

import {notifyBugsnag} from "../../errors/bugsnag/notify_bugsnag";
import {addSuccessNotification} from "../../notifications/utils/add_success_notification";
import {fetchCookies} from "../../user/api/fetch_cookies";
import {IApplication} from "../types/IApplication";
import {alertFormSuccessMessage} from "../utils/alert_text";

export enum ConsumerSegment {
    INVESTOR = 1,
    SINGLE,
    FAMILY,
    DREAMER
}

/**
 * Form
 */

const APPLICATION_PREFIX = `desktop/application/CREATE`;
export const applicationCreateTypes = createRequestActionTypes(APPLICATION_PREFIX);

/**
 * Post
 */
export interface IApplicationOptions {
    // provide modifiers that for default behaviour are falsy
    // modifiers
    useReactAlerts?: boolean;
    // callbacks
    on400Response?: (error: Response400) => void;
    on400ApplicationBlockedError?: (error: Response400) => void;
}

const applicationApiLink = apiLink.application.create({});
export const applicationPost = (
    jsonData: Partial<IApplication>,
    storeHash: string,
    options: IApplicationOptions = {},
    triesLeft = 1
): Promise<IApplication | void> => {
    return postRequest({}, applicationApiLink(null), jsonData)
        .then((application: IApplication) => {
            return application;
        })
        .catch(
            catch400((error) => {
                if (options.on400ApplicationBlockedError) {
                    const applicationBlockedError = error.appError?.fieldErrors[0] as unknown as {error: string};
                    if (applicationBlockedError && applicationBlockedError.error.match(/Zgłoszenie nie może być wysłane na [\wśćę]+ archiwalną/gm)) {
                        // BE blocks application post when lead is sent to an archived offer (ie. view was cached by FE etc.)
                        // casting error because this one is kinda special :pikachu:
                        options.on400ApplicationBlockedError(error);
                        // do not rethrow, we don't want
                        return;
                    }
                }

                options.on400Response && options.on400Response(error);
                // rethrow error to be handled in form
                throw error;
            })
        )
        .catch(
            catch403(async (error) => {
                notifyBugsnag(error, `applicationPost: catch 403 (triesLeft: ${triesLeft})`, {originalError: JSON.stringify(error)});
                if (!triesLeft) {
                    // die silently [']
                    // at this point I'm not sure if void is a business requirement, but this can happen if user has too many session/csrftoken/auth issues
                    return;
                }

                await fetchCookies();
                return applicationPost(jsonData, storeHash, options, --triesLeft);
            })
        );
};

export const afterPostApplicationActions = (storeHash: string, applicationResponse: IApplication) => (dispatch: Dispatch) => {
    if (applicationResponse) {
        // dispatching applicationCreateTypes.success makes sense because we store application response in redux - this can possibly be moved to ApplicationModalArray local state
        dispatch({type: applicationCreateTypes.success, id: storeHash, result: applicationResponse});

        dispatch(addSuccessNotification(alertFormSuccessMessage));
    }
};

export const resetApplicationPost = (id: string) => ({type: applicationCreateTypes.reset, id});

/**
 * Patch
 */

interface IExtendedApplication extends IApplication {
    consumer_segment: ConsumerSegment;
}

const applicationPatchApiLink = apiLink.application.detail;
export const applicationPatch = (uuid: string, jsonData: Partial<IExtendedApplication>, storeHash: string) => (dispatch: Dispatch) => {
    dispatch({type: applicationCreateTypes.start, id: storeHash});

    return patchRequest({}, applicationPatchApiLink({})({uuid}), jsonData)
        .then((application: IApplication) => {
            dispatch({type: applicationCreateTypes.success, id: storeHash, result: application});
            dispatch(addSuccessNotification(alertFormSuccessMessage));
            return application;
        })
        .catch(
            catch400((error) => {
                dispatch({type: applicationCreateTypes.error, id: storeHash, error: error.appError});
                // TODO: display alert about form error
                console.error("Popraw błędy formularza");
            })
        )
        .catch(
            catch403((err) => {
                dispatch({type: applicationCreateTypes.error, id: storeHash, error: null});
                notifyBugsnag(err, "applicationPatch: catch 403", err.responseError ?? {});
            })
        );
};
import {Dispatch} from "redux";

import {catch400, catch403, Response400} from "@pg-mono/request";
import {patchRequest, postRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiLink} from "@pg-mono/rp-api-routes";

import {notifyBugsnag} from "../../errors/bugsnag/notify_bugsnag";
import {addSuccessNotification} from "../../notifications/utils/add_success_notification";
import {fetchCookies} from "../../user/api/fetch_cookies";
import {IApplication} from "../types/IApplication";
import {alertFormSuccessMessage} from "../utils/alert_text";

export enum ConsumerSegment {
    INVESTOR = 1,
    SINGLE,
    FAMILY,
    DREAMER
}

/**
 * Form
 */

const APPLICATION_PREFIX = `desktop/application/CREATE`;
export const applicationCreateTypes = createRequestActionTypes(APPLICATION_PREFIX);

/**
 * Post
 */
export interface IApplicationOptions {
    // provide modifiers that for default behaviour are falsy
    // modifiers
    useReactAlerts?: boolean;
    // callbacks
    on400Response?: (error: Response400) => void;
    on400ApplicationBlockedError?: (error: Response400) => void;
}

const applicationApiLink = apiLink.application.create({});
export const applicationPost = (
    jsonData: Partial<IApplication>,
    storeHash: string,
    options: IApplicationOptions = {},
    triesLeft = 1
): Promise<IApplication | void> => {
    return postRequest({}, applicationApiLink(null), jsonData)
        .then((application: IApplication) => {
            return application;
        })
        .catch(
            catch400((error) => {
                if (options.on400ApplicationBlockedError) {
                    const applicationBlockedError = error.appError?.fieldErrors[0] as unknown as {error: string};
                    if (applicationBlockedError && applicationBlockedError.error.match(/Zgłoszenie nie może być wysłane na [\wśćę]+ archiwalną/gm)) {
                        // BE blocks application post when lead is sent to an archived offer (ie. view was cached by FE etc.)
                        // casting error because this one is kinda special :pikachu:
                        options.on400ApplicationBlockedError(error);
                        // do not rethrow, we don't want
                        return;
                    }
                }

                options.on400Response && options.on400Response(error);
                // rethrow error to be handled in form
                throw error;
            })
        )
        .catch(
            catch403(async (error) => {
                notifyBugsnag(error, `applicationPost: catch 403 (triesLeft: ${triesLeft})`, {originalError: JSON.stringify(error)});
                if (!triesLeft) {
                    // die silently [']
                    // at this point I'm not sure if void is a business requirement, but this can happen if user has too many session/csrftoken/auth issues
                    return;
                }

                await fetchCookies();
                return applicationPost(jsonData, storeHash, options, --triesLeft);
            })
        );
};

export const afterPostApplicationActions = (storeHash: string, applicationResponse: IApplication) => (dispatch: Dispatch) => {
    if (applicationResponse) {
        // dispatching applicationCreateTypes.success makes sense because we store application response in redux - this can possibly be moved to ApplicationModalArray local state
        dispatch({type: applicationCreateTypes.success, id: storeHash, result: applicationResponse});

        dispatch(addSuccessNotification(alertFormSuccessMessage));
    }
};

export const resetApplicationPost = (id: string) => ({type: applicationCreateTypes.reset, id});

/**
 * Patch
 */

interface IExtendedApplication extends IApplication {
    consumer_segment: ConsumerSegment;
}

const applicationPatchApiLink = apiLink.application.detail;
export const applicationPatch = (uuid: string, jsonData: Partial<IExtendedApplication>, storeHash: string) => (dispatch: Dispatch) => {
    dispatch({type: applicationCreateTypes.start, id: storeHash});

    return patchRequest({}, applicationPatchApiLink({})({uuid}), jsonData)
        .then((application: IApplication) => {
            dispatch({type: applicationCreateTypes.success, id: storeHash, result: application});
            dispatch(addSuccessNotification(alertFormSuccessMessage));
            return application;
        })
        .catch(
            catch400((error) => {
                dispatch({type: applicationCreateTypes.error, id: storeHash, error: error.appError});
                // TODO: display alert about form error
                console.error("Popraw błędy formularza");
            })
        )
        .catch(
            catch403((err) => {
                dispatch({type: applicationCreateTypes.error, id: storeHash, error: null});
                notifyBugsnag(err, "applicationPatch: catch 403", err.responseError ?? {});
            })
        );
};
