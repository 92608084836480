// Is used by algolytics to differentiate between recommendation types. When adding new events please update big data documentation regarding web evens
export enum MultiLeadRecommendationType {
    PROPERTY = 0,
    OFFER = 1,
    VENDOR = 2
}
// Is used by algolytics to differentiate between recommendation types. When adding new events please update big data documentation regarding web evens
export enum MultiLeadRecommendationType {
    PROPERTY = 0,
    OFFER = 1,
    VENDOR = 2
}
