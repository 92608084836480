import React from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {fadeInAnimation, flex, h100, p, w100} from "@pg-design/helpers-css";
import {IModalCore, SystemModal} from "@pg-design/modal-module";

import {leadModalZindex} from "../../lead/constants/lead_modal_zindex";

interface IProps extends IModalCore {
    animated?: boolean;
    zIndex?: number;
}

// this is very similar to `ResponsiveModal` but it uses different breakpoints and has minor style adjustments
export const ApplicationStyledModal: React.FC<IProps> = (props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {animated = true, children, ...restProps} = props;

    return (
        <StyledSystemModal css={animated ? fadeInAnimation("0.2s") : null} zIndex={leadModalZindex} {...restProps}>
            <div css={content}>{props.children}</div>
        </StyledSystemModal>
    );
};

const StyledSystemModal = styled(SystemModal)`
    ${w100};
    ${h100};

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
        height: 630px;
        width: 810px;
        ${p(0)};
    }
`;

const content = css`
    position: relative;
    max-width: 100vw;
    overflow: hidden;
    ${flex()};
    flex-direction: column;
    ${w100};
`;
import React from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {fadeInAnimation, flex, h100, p, w100} from "@pg-design/helpers-css";
import {IModalCore, SystemModal} from "@pg-design/modal-module";

import {leadModalZindex} from "../../lead/constants/lead_modal_zindex";

interface IProps extends IModalCore {
    animated?: boolean;
    zIndex?: number;
}

// this is very similar to `ResponsiveModal` but it uses different breakpoints and has minor style adjustments
export const ApplicationStyledModal: React.FC<IProps> = (props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {animated = true, children, ...restProps} = props;

    return (
        <StyledSystemModal css={animated ? fadeInAnimation("0.2s") : null} zIndex={leadModalZindex} {...restProps}>
            <div css={content}>{props.children}</div>
        </StyledSystemModal>
    );
};

const StyledSystemModal = styled(SystemModal)`
    ${w100};
    ${h100};

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
        height: 630px;
        width: 810px;
        ${p(0)};
    }
`;

const content = css`
    position: relative;
    max-width: 100vw;
    overflow: hidden;
    ${flex()};
    flex-direction: column;
    ${w100};
`;
