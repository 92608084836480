import React from "react";
import {css, Theme, useTheme} from "@emotion/react";

import {borderRadius, flex, listUnStyled, m, mt, p} from "@pg-design/helpers-css";
import {BrandCoinIcon, BrandFlagIcon, BrandGuardIcon} from "@pg-design/icons-module";
import {Text} from "@pg-design/text-module";
import {pluralize} from "@pg-mono/string-utils";

import {IMarketplaceArchitectOfferSummary} from "../../api/fetch_marketplace_architect_promoted_offers_by_region";

interface IProps {
    architectOfferDetails: IMarketplaceArchitectOfferSummary | null;
    className?: string;
}

const yearsPluralize = pluralize(["rok", "lata", "lat"]);

export const ArchitectInfoGraphics = (props: IProps) => {
    const theme = useTheme();

    // only max 3 icons without insurance information
    return (
        <ul css={infoGraphicsWrapperStyle} className={props.className}>
            {props.architectOfferDetails?.offer.has_guaranteed_deadline ? (
                <li css={[infoGraphicElementStyle]}>
                    <BrandFlagIcon size="3.2" wrapperSize="3.2" wrapperColor={theme.colors.primary} wrapperType="circle" />
                    <Text variant="info_txt_1" align="center" css={[mt(1)]}>
                        Gwarantowany
                        <br />
                        termin realizacji
                    </Text>
                </li>
            ) : null}
            {props.architectOfferDetails?.offer.has_guaranteed_price ? (
                <li css={[infoGraphicElementStyle]}>
                    <BrandCoinIcon size="3.2" wrapperSize="3.2" wrapperColor={theme.colors.primary} wrapperType="circle" />
                    <Text variant="info_txt_1" align="center" css={[mt(1)]}>
                        Gwarantowana
                        <br />
                        cena
                    </Text>
                </li>
            ) : null}
            {props.architectOfferDetails?.offer.warranty_duration ? (
                <li css={[infoGraphicElementStyle]}>
                    <BrandGuardIcon size="3.2" wrapperSize="3.2" wrapperColor={theme.colors.primary} wrapperType="circle" />
                    <Text variant="info_txt_1" align="center" css={[mt(1)]}>
                        {props.architectOfferDetails.offer.warranty_duration} {yearsPluralize(props.architectOfferDetails.offer.warranty_duration)} gwarancji
                    </Text>
                </li>
            ) : null}
        </ul>
    );
};

const infoGraphicsWrapperStyle = (theme: Theme) => css`
    ${flex("flex-start", "space-around")};
    ${listUnStyled};
    ${m(0)};
    ${p(2)};
    ${borderRadius(2)};
    background-color: ${theme.colors.secondary};
    color: white;
`;

const infoGraphicElementStyle = css`
    ${flex("center", "center")};
    flex-direction: column;
`;
import React from "react";
import {css, Theme, useTheme} from "@emotion/react";

import {borderRadius, flex, listUnStyled, m, mt, p} from "@pg-design/helpers-css";
import {BrandCoinIcon, BrandFlagIcon, BrandGuardIcon} from "@pg-design/icons-module";
import {Text} from "@pg-design/text-module";
import {pluralize} from "@pg-mono/string-utils";

import {IMarketplaceArchitectOfferSummary} from "../../api/fetch_marketplace_architect_promoted_offers_by_region";

interface IProps {
    architectOfferDetails: IMarketplaceArchitectOfferSummary | null;
    className?: string;
}

const yearsPluralize = pluralize(["rok", "lata", "lat"]);

export const ArchitectInfoGraphics = (props: IProps) => {
    const theme = useTheme();

    // only max 3 icons without insurance information
    return (
        <ul css={infoGraphicsWrapperStyle} className={props.className}>
            {props.architectOfferDetails?.offer.has_guaranteed_deadline ? (
                <li css={[infoGraphicElementStyle]}>
                    <BrandFlagIcon size="3.2" wrapperSize="3.2" wrapperColor={theme.colors.primary} wrapperType="circle" />
                    <Text variant="info_txt_1" align="center" css={[mt(1)]}>
                        Gwarantowany
                        <br />
                        termin realizacji
                    </Text>
                </li>
            ) : null}
            {props.architectOfferDetails?.offer.has_guaranteed_price ? (
                <li css={[infoGraphicElementStyle]}>
                    <BrandCoinIcon size="3.2" wrapperSize="3.2" wrapperColor={theme.colors.primary} wrapperType="circle" />
                    <Text variant="info_txt_1" align="center" css={[mt(1)]}>
                        Gwarantowana
                        <br />
                        cena
                    </Text>
                </li>
            ) : null}
            {props.architectOfferDetails?.offer.warranty_duration ? (
                <li css={[infoGraphicElementStyle]}>
                    <BrandGuardIcon size="3.2" wrapperSize="3.2" wrapperColor={theme.colors.primary} wrapperType="circle" />
                    <Text variant="info_txt_1" align="center" css={[mt(1)]}>
                        {props.architectOfferDetails.offer.warranty_duration} {yearsPluralize(props.architectOfferDetails.offer.warranty_duration)} gwarancji
                    </Text>
                </li>
            ) : null}
        </ul>
    );
};

const infoGraphicsWrapperStyle = (theme: Theme) => css`
    ${flex("flex-start", "space-around")};
    ${listUnStyled};
    ${m(0)};
    ${p(2)};
    ${borderRadius(2)};
    background-color: ${theme.colors.secondary};
    color: white;
`;

const infoGraphicElementStyle = css`
    ${flex("center", "center")};
    flex-direction: column;
`;
