import {hitAlgolytics} from "@pg-mono/algolytics";

import {MultiLeadRecommendationType} from "../../application/types/MultiLeadRecommendationType";
import {RecommendedPropertyApplicationsType} from "../../application/types/recommended_property_applications_type";
import {ITrackedApplication} from "../../tracking/algolytics_hits/application_hit";
import {getTrackedSiteData} from "../../tracking/algolytics_hits/tracked_utils/tracked_site";
import {ViewType} from "../../view_type/ViewType";

export const hitPropertyMultileadSent = (
    recommendedProperties: RecommendedPropertyApplicationsType[],
    viewType: ViewType | null,
    recommendationType: MultiLeadRecommendationType,
    application: ITrackedApplication | null | undefined
) => {
    const payload = {
        ...getTrackedSiteData(),
        ...(viewType ? {view_type: viewType} : {}),
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        properties: recommendedProperties.map(([application, property]) => property.id),
        recommendationType,
        financing_services: application?.financing_services ?? null,
        email: application?.email ?? null,
        name: application?.name ?? null,
        phone: application?.phone ?? null
    };
    hitAlgolytics("multi_application", payload);
};
import {hitAlgolytics} from "@pg-mono/algolytics";

import {MultiLeadRecommendationType} from "../../application/types/MultiLeadRecommendationType";
import {RecommendedPropertyApplicationsType} from "../../application/types/recommended_property_applications_type";
import {ITrackedApplication} from "../../tracking/algolytics_hits/application_hit";
import {getTrackedSiteData} from "../../tracking/algolytics_hits/tracked_utils/tracked_site";
import {ViewType} from "../../view_type/ViewType";

export const hitPropertyMultileadSent = (
    recommendedProperties: RecommendedPropertyApplicationsType[],
    viewType: ViewType | null,
    recommendationType: MultiLeadRecommendationType,
    application: ITrackedApplication | null | undefined
) => {
    const payload = {
        ...getTrackedSiteData(),
        ...(viewType ? {view_type: viewType} : {}),
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        properties: recommendedProperties.map(([application, property]) => property.id),
        recommendationType,
        financing_services: application?.financing_services ?? null,
        email: application?.email ?? null,
        name: application?.name ?? null,
        phone: application?.phone ?? null
    };
    hitAlgolytics("multi_application", payload);
};
