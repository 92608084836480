import {IAppError} from "@pg-mono/request-state";

import {hitOfferMultileadSent} from "../../../lead/tracking/hit_offer_multilead_sent";
import {hitPropertyMultileadSent} from "../../../lead/tracking/hit_property_multilead_sent";
import {ILeadModalLeadType} from "../../../lead/types/ILeadModalLeadType";
import {hitApplicationRecommendedPropertiesError, hitApplicationRecommendedPropertiesSuccess} from "../../../tracking/algolytics_hits/application_hit";
import {modalCloseClickHit} from "../../../tracking/algolytics_hits/modal_close_click_hit";
import {multiLeadModalCheckboxClickHit, multileadPinClickHit} from "../../../tracking/algolytics_hits/multilead_modal_checkbox_click_hit";
import {
    gtmMultileadButtonClick,
    gtmMultileadModalCheckboxClick,
    gtmMultileadModalClose,
    gtmMultileadModalOpen,
    gtmMultileadPinClick,
    MultileadButtonType
} from "../../../tracking/google_tag_manager/gtm_event_multilead";
import {ViewType} from "../../../view_type/ViewType";
import {RecommendedOfferApplicationsType, trackRecommendedOffersApplication} from "../../actions/track_recommended_offers_application";
import {trackRecommendedPropertiesApplication} from "../../actions/track_recommended_properties_application";
import {IApplication} from "../../types/IApplication";
import {IMultiLeadData} from "../../types/IMultiLeadData";
import {MultiLeadRecommendationType} from "../../types/MultiLeadRecommendationType";
import {RecommendedPropertyApplicationsType} from "../../types/recommended_property_applications_type";
import {ApplicationSource, ApplicationSourceSection} from "../ApplicationSource";

// Always send same data to GTM, no matter if it is offer or investment
// Alogolytics requires different collection name depending on offer and investment. Also collection names should not be changed due to historical consistency

const isProperty = (type: MultiLeadRecommendationType) => type === MultiLeadRecommendationType.PROPERTY;
const isOffer = (type: MultiLeadRecommendationType) => type === MultiLeadRecommendationType.OFFER;
const isVendor = (type: MultiLeadRecommendationType) => type === MultiLeadRecommendationType.VENDOR;

const checkboxChange = (recommendationType: MultiLeadRecommendationType, viewType: ViewType, elementSelected: boolean, recommendedElementId: number) => {
    gtmMultileadModalCheckboxClick(elementSelected, recommendedElementId);
    multiLeadModalCheckboxClickHit(viewType, elementSelected, recommendedElementId, recommendationType);
};

const clickShowMore = (updateRecommendationCount: number) => gtmMultileadButtonClick(MultileadButtonType.SHOW_MORE_RECOMMENDATIONS, updateRecommendationCount);

const modalClose = (recommendationType: MultiLeadRecommendationType, viewType: ViewType | null) => {
    gtmMultileadModalClose();
    modalCloseClickHit(viewType, "recommended_offers", recommendationType);
};

const modalOpen = (recommendationType: MultiLeadRecommendationType, recommendations: IMultiLeadData[]) => {
    gtmMultileadModalOpen(recommendations, recommendationType);
};

const finishedSending = (
    recommendationType: MultiLeadRecommendationType,
    applications: RecommendedPropertyApplicationsType[] | RecommendedOfferApplicationsType[],
    viewType: ViewType | null,
    leadType: ILeadModalLeadType,
    originalApplication: IApplication | null | undefined,
    recommendationsWithPriceFilter = false,
    customSourceSection?: string
) => {
    if (recommendationType !== MultiLeadRecommendationType.VENDOR) {
        gtmMultileadButtonClick(MultileadButtonType.SEND_APPLICATIONS);
    }

    if (isProperty(recommendationType)) {
        hitPropertyMultileadSent(applications as RecommendedPropertyApplicationsType[], viewType, recommendationType, originalApplication);
        trackRecommendedPropertiesApplication(
            applications as RecommendedPropertyApplicationsType[],
            viewType,
            leadType,
            recommendationsWithPriceFilter,
            customSourceSection
        );
    }

    if (isOffer(recommendationType)) {
        hitOfferMultileadSent(applications as RecommendedOfferApplicationsType[], viewType, recommendationType, originalApplication);
        trackRecommendedOffersApplication(applications as RecommendedOfferApplicationsType[], viewType, leadType);
    }

    if (isVendor(recommendationType)) {
        hitPropertyMultileadSent(applications as RecommendedPropertyApplicationsType[], viewType, recommendationType, originalApplication);
        trackRecommendedPropertiesApplication(
            applications as RecommendedPropertyApplicationsType[],
            viewType,
            leadType,
            recommendationsWithPriceFilter,
            customSourceSection
        );
    }
};

const singleApplicationSuccess = (application: IApplication, sourceSection: ApplicationSourceSection, viewType: ViewType | null) => {
    hitApplicationRecommendedPropertiesSuccess(application, sourceSection, {
        extraPayloadViewType: viewType ?? undefined
    });
};

const singleApplicationError = (appError: IAppError, source: ApplicationSource, sourceSection: ApplicationSourceSection, viewType: ViewType | null) => {
    hitApplicationRecommendedPropertiesError(source, sourceSection, {
        appError: appError as IAppError,
        extraPayloadViewType: viewType ?? undefined
    });
};

const mapPinClick = (viewType: ViewType | null, recommendedElementId: number, elementSelected: boolean) => {
    gtmMultileadPinClick();
    multileadPinClickHit(viewType, recommendedElementId, elementSelected);
};

// Reports actions to GTM & Algolytics related to recommendation modal events
export const report = {
    checkboxChange,
    clickShowMore,
    modalOpen,
    modalClose,
    finishedSending,
    singleApplicationSuccess,
    singleApplicationError,
    mapPinClick
};
import {IAppError} from "@pg-mono/request-state";

import {hitOfferMultileadSent} from "../../../lead/tracking/hit_offer_multilead_sent";
import {hitPropertyMultileadSent} from "../../../lead/tracking/hit_property_multilead_sent";
import {ILeadModalLeadType} from "../../../lead/types/ILeadModalLeadType";
import {hitApplicationRecommendedPropertiesError, hitApplicationRecommendedPropertiesSuccess} from "../../../tracking/algolytics_hits/application_hit";
import {modalCloseClickHit} from "../../../tracking/algolytics_hits/modal_close_click_hit";
import {multiLeadModalCheckboxClickHit, multileadPinClickHit} from "../../../tracking/algolytics_hits/multilead_modal_checkbox_click_hit";
import {
    gtmMultileadButtonClick,
    gtmMultileadModalCheckboxClick,
    gtmMultileadModalClose,
    gtmMultileadModalOpen,
    gtmMultileadPinClick,
    MultileadButtonType
} from "../../../tracking/google_tag_manager/gtm_event_multilead";
import {ViewType} from "../../../view_type/ViewType";
import {RecommendedOfferApplicationsType, trackRecommendedOffersApplication} from "../../actions/track_recommended_offers_application";
import {trackRecommendedPropertiesApplication} from "../../actions/track_recommended_properties_application";
import {IApplication} from "../../types/IApplication";
import {IMultiLeadData} from "../../types/IMultiLeadData";
import {MultiLeadRecommendationType} from "../../types/MultiLeadRecommendationType";
import {RecommendedPropertyApplicationsType} from "../../types/recommended_property_applications_type";
import {ApplicationSource, ApplicationSourceSection} from "../ApplicationSource";

// Always send same data to GTM, no matter if it is offer or investment
// Alogolytics requires different collection name depending on offer and investment. Also collection names should not be changed due to historical consistency

const isProperty = (type: MultiLeadRecommendationType) => type === MultiLeadRecommendationType.PROPERTY;
const isOffer = (type: MultiLeadRecommendationType) => type === MultiLeadRecommendationType.OFFER;
const isVendor = (type: MultiLeadRecommendationType) => type === MultiLeadRecommendationType.VENDOR;

const checkboxChange = (recommendationType: MultiLeadRecommendationType, viewType: ViewType, elementSelected: boolean, recommendedElementId: number) => {
    gtmMultileadModalCheckboxClick(elementSelected, recommendedElementId);
    multiLeadModalCheckboxClickHit(viewType, elementSelected, recommendedElementId, recommendationType);
};

const clickShowMore = (updateRecommendationCount: number) => gtmMultileadButtonClick(MultileadButtonType.SHOW_MORE_RECOMMENDATIONS, updateRecommendationCount);

const modalClose = (recommendationType: MultiLeadRecommendationType, viewType: ViewType | null) => {
    gtmMultileadModalClose();
    modalCloseClickHit(viewType, "recommended_offers", recommendationType);
};

const modalOpen = (recommendationType: MultiLeadRecommendationType, recommendations: IMultiLeadData[]) => {
    gtmMultileadModalOpen(recommendations, recommendationType);
};

const finishedSending = (
    recommendationType: MultiLeadRecommendationType,
    applications: RecommendedPropertyApplicationsType[] | RecommendedOfferApplicationsType[],
    viewType: ViewType | null,
    leadType: ILeadModalLeadType,
    originalApplication: IApplication | null | undefined,
    recommendationsWithPriceFilter = false,
    customSourceSection?: string
) => {
    if (recommendationType !== MultiLeadRecommendationType.VENDOR) {
        gtmMultileadButtonClick(MultileadButtonType.SEND_APPLICATIONS);
    }

    if (isProperty(recommendationType)) {
        hitPropertyMultileadSent(applications as RecommendedPropertyApplicationsType[], viewType, recommendationType, originalApplication);
        trackRecommendedPropertiesApplication(
            applications as RecommendedPropertyApplicationsType[],
            viewType,
            leadType,
            recommendationsWithPriceFilter,
            customSourceSection
        );
    }

    if (isOffer(recommendationType)) {
        hitOfferMultileadSent(applications as RecommendedOfferApplicationsType[], viewType, recommendationType, originalApplication);
        trackRecommendedOffersApplication(applications as RecommendedOfferApplicationsType[], viewType, leadType);
    }

    if (isVendor(recommendationType)) {
        hitPropertyMultileadSent(applications as RecommendedPropertyApplicationsType[], viewType, recommendationType, originalApplication);
        trackRecommendedPropertiesApplication(
            applications as RecommendedPropertyApplicationsType[],
            viewType,
            leadType,
            recommendationsWithPriceFilter,
            customSourceSection
        );
    }
};

const singleApplicationSuccess = (application: IApplication, sourceSection: ApplicationSourceSection, viewType: ViewType | null) => {
    hitApplicationRecommendedPropertiesSuccess(application, sourceSection, {
        extraPayloadViewType: viewType ?? undefined
    });
};

const singleApplicationError = (appError: IAppError, source: ApplicationSource, sourceSection: ApplicationSourceSection, viewType: ViewType | null) => {
    hitApplicationRecommendedPropertiesError(source, sourceSection, {
        appError: appError as IAppError,
        extraPayloadViewType: viewType ?? undefined
    });
};

const mapPinClick = (viewType: ViewType | null, recommendedElementId: number, elementSelected: boolean) => {
    gtmMultileadPinClick();
    multileadPinClickHit(viewType, recommendedElementId, elementSelected);
};

// Reports actions to GTM & Algolytics related to recommendation modal events
export const report = {
    checkboxChange,
    clickShowMore,
    modalOpen,
    modalClose,
    finishedSending,
    singleApplicationSuccess,
    singleApplicationError,
    mapPinClick
};
