import React, {ReactNode, useCallback, useEffect, useMemo, useRef} from "react";

import {openLeadModal} from "../../lead/state/lead_modal_slice";
import {ILeadModalOpenPayload} from "../../lead/types/ILeadModalOpenPayload";
import {useAppDispatch, useAppSelector} from "../../store/hooks";

export interface IApplicationModalButtonRenderProps {
    isOpened: boolean;
    openModal: () => void;
    openModalWithSubmit: () => void;
}

type IProps = {
    render: (props: IApplicationModalButtonRenderProps) => ReactNode;
    onOpen?: () => void;
    onClose?: () => void;
    openOnMount?: boolean;
} & Omit<ILeadModalOpenPayload, "openWithSubmit">;

export const ApplicationModalButton = (props: IProps) => {
    const {render, openOnMount, onOpen, onClose, ...openModalPayload} = props;
    const dispatch = useAppDispatch();

    const isOpened = useAppSelector((state) => state.leadModal.isModalOpen);
    const wasOpenedRef = useRef(false);

    const handleOpenModal = (withSubmit: boolean) => {
        dispatch(
            openLeadModal({
                ...openModalPayload,
                openWithSubmit: withSubmit
            })
        );
    };

    const openModal = useCallback(() => {
        handleOpenModal(false);
    }, []);

    const openModalWithSubmit = useCallback(() => {
        handleOpenModal(true);
    }, []);

    useEffect(() => {
        if (openOnMount) {
            openModal();
        }
    }, []);

    useEffect(() => {
        const wasOpened = Boolean(wasOpenedRef.current);
        if (!wasOpened && isOpened) {
            onOpen?.();
        }

        if (wasOpened && !isOpened) {
            onClose?.();
        }

        wasOpenedRef.current = isOpened;
    }, [isOpened]);

    const renderProps = useMemo(() => ({isOpened, openModal, openModalWithSubmit}), [isOpened, openModal, openModalWithSubmit]);

    return <>{render(renderProps)}</>;
};
import React, {ReactNode, useCallback, useEffect, useMemo, useRef} from "react";

import {openLeadModal} from "../../lead/state/lead_modal_slice";
import {ILeadModalOpenPayload} from "../../lead/types/ILeadModalOpenPayload";
import {useAppDispatch, useAppSelector} from "../../store/hooks";

export interface IApplicationModalButtonRenderProps {
    isOpened: boolean;
    openModal: () => void;
    openModalWithSubmit: () => void;
}

type IProps = {
    render: (props: IApplicationModalButtonRenderProps) => ReactNode;
    onOpen?: () => void;
    onClose?: () => void;
    openOnMount?: boolean;
} & Omit<ILeadModalOpenPayload, "openWithSubmit">;

export const ApplicationModalButton = (props: IProps) => {
    const {render, openOnMount, onOpen, onClose, ...openModalPayload} = props;
    const dispatch = useAppDispatch();

    const isOpened = useAppSelector((state) => state.leadModal.isModalOpen);
    const wasOpenedRef = useRef(false);

    const handleOpenModal = (withSubmit: boolean) => {
        dispatch(
            openLeadModal({
                ...openModalPayload,
                openWithSubmit: withSubmit
            })
        );
    };

    const openModal = useCallback(() => {
        handleOpenModal(false);
    }, []);

    const openModalWithSubmit = useCallback(() => {
        handleOpenModal(true);
    }, []);

    useEffect(() => {
        if (openOnMount) {
            openModal();
        }
    }, []);

    useEffect(() => {
        const wasOpened = Boolean(wasOpenedRef.current);
        if (!wasOpened && isOpened) {
            onOpen?.();
        }

        if (wasOpened && !isOpened) {
            onClose?.();
        }

        wasOpenedRef.current = isOpened;
    }, [isOpened]);

    const renderProps = useMemo(() => ({isOpened, openModal, openModalWithSubmit}), [isOpened, openModal, openModalWithSubmit]);

    return <>{render(renderProps)}</>;
};
