import {getRequest} from "@pg-mono/request";
import {apiV2Link, Scenario} from "@pg-mono/rp-api-routes";

import {clientRpRequestMeta} from "../../client/client_rp_request_meta";

export interface IRegion {
    id: number;
    parent: {
        id: number;
        slug: string;
    } | null;
    type: number;
    slug: string;
}

export const fetchRegionTree = async (regionId: number, breakFetching: (region: IRegion) => boolean): Promise<IRegion[]> => {
    let fetchId: number | null = regionId;

    const regionsTree = [];

    do {
        const url = apiV2Link.region.detail(Scenario.SEARCH_REGION_PARENT, {regionId: fetchId});
        const response: IRegion = await getRequest(clientRpRequestMeta, url);

        response && regionsTree.unshift(response);

        if (response && breakFetching(response)) {
            fetchId = null;
            break;
        }

        fetchId = response?.parent?.id ?? null;
    } while (fetchId);

    return regionsTree;
};
import {getRequest} from "@pg-mono/request";
import {apiV2Link, Scenario} from "@pg-mono/rp-api-routes";

import {clientRpRequestMeta} from "../../client/client_rp_request_meta";

export interface IRegion {
    id: number;
    parent: {
        id: number;
        slug: string;
    } | null;
    type: number;
    slug: string;
}

export const fetchRegionTree = async (regionId: number, breakFetching: (region: IRegion) => boolean): Promise<IRegion[]> => {
    let fetchId: number | null = regionId;

    const regionsTree = [];

    do {
        const url = apiV2Link.region.detail(Scenario.SEARCH_REGION_PARENT, {regionId: fetchId});
        const response: IRegion = await getRequest(clientRpRequestMeta, url);

        response && regionsTree.unshift(response);

        if (response && breakFetching(response)) {
            fetchId = null;
            break;
        }

        fetchId = response?.parent?.id ?? null;
    } while (fetchId);

    return regionsTree;
};
