import {PropsWithChildren} from "react";
import classNames from "classnames";

import {Text} from "@pg-design/text";

import {IMessageType} from "../types/IMessageType";

import * as cns from "./Message.module.css";

type IProps = PropsWithChildren<{
    type?: IMessageType;
    className?: string;
}>;

/**
 * A component that displays a message with different styles based on the `type` prop.
 *
 * @param props.type - If not provided, the default color is white (neutral type).
 * @param props.children - If it's not a simple string, CompoundComponent Message.Content can be used, for padding and color.
 * @constructor
 */
export function Message(props: IProps) {
    const {type, children, className} = props;

    const mCns = classNames(cns.message, className, [
        type === "action" && cns.messageAction,
        type === "success" && cns.messageSuccess,
        type === "info" && cns.messageInfo,
        type === "warning" && cns.messageWarning,
        type === "failure" && cns.messageFailure,
        (type === "neutral" || !type) && cns.messageNeutral
    ]);

    return <div className={mCns}>{typeof children === "string" ? <MessageContent type={type}>{children}</MessageContent> : children}</div>;
}

function MessageContent(props: PropsWithChildren<{type?: IMessageType; className?: string}>) {
    const {type, children, className} = props;

    const mcCns = classNames(className, cns.messageContent, [type === "failure" && cns.messageContentFailure]);

    return (
        <div className={mcCns}>
            <Text as="span" variant="info_txt_1">
                {children}
            </Text>
        </div>
    );
}

Message.Content = MessageContent;
import {PropsWithChildren} from "react";
import classNames from "classnames";

import {Text} from "@pg-design/text";

import {IMessageType} from "../types/IMessageType";

import * as cns from "./Message.module.css";

type IProps = PropsWithChildren<{
    type?: IMessageType;
    className?: string;
}>;

/**
 * A component that displays a message with different styles based on the `type` prop.
 *
 * @param props.type - If not provided, the default color is white (neutral type).
 * @param props.children - If it's not a simple string, CompoundComponent Message.Content can be used, for padding and color.
 * @constructor
 */
export function Message(props: IProps) {
    const {type, children, className} = props;

    const mCns = classNames(cns.message, className, [
        type === "action" && cns.messageAction,
        type === "success" && cns.messageSuccess,
        type === "info" && cns.messageInfo,
        type === "warning" && cns.messageWarning,
        type === "failure" && cns.messageFailure,
        (type === "neutral" || !type) && cns.messageNeutral
    ]);

    return <div className={mCns}>{typeof children === "string" ? <MessageContent type={type}>{children}</MessageContent> : children}</div>;
}

function MessageContent(props: PropsWithChildren<{type?: IMessageType; className?: string}>) {
    const {type, children, className} = props;

    const mcCns = classNames(className, cns.messageContent, [type === "failure" && cns.messageContentFailure]);

    return (
        <div className={mcCns}>
            <Text as="span" variant="info_txt_1">
                {children}
            </Text>
        </div>
    );
}

Message.Content = MessageContent;
