import {css} from "@emotion/react";

export const fadeInAnimation = (time: string) => css`
    animation: fade-in ${time} cubic-bezier(0.39, 0.575, 0.565, 1) both;

    @keyframes fade-in {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`;
import {css} from "@emotion/react";

export const fadeInAnimation = (time: string) => css`
    animation: fade-in ${time} cubic-bezier(0.39, 0.575, 0.565, 1) both;

    @keyframes fade-in {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`;
