import React from "react";
import {css} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {calculateRemSize, flex, onDesktop, p, zIndex} from "@pg-design/helpers-css";
import {ArrowIcon} from "@pg-design/icons-module";
import {Text} from "@pg-design/text";

import {CloseIconButton} from "../../../atoms/CloseIconButton";

interface IProps {
    handleBudgetChange: () => void;
    handleShowOffers: () => void;
    handleClose: () => void;
}

export const ApplicationPropertyPriceUserBudgetNoRecommendationsModal = (props: IProps) => {
    const {handleBudgetChange, handleShowOffers, handleClose} = props;

    return (
        <div css={modalContentWrapper}>
            <CloseIconButton css={zIndex(10)} onClick={handleClose} right={2.5} top={2.5} />
            <div>
                <Text variant="headline_3" mb="1.6rem" align="center">
                    Niestety, w tej inwestycji nie ma <br />
                    nieruchomości w tym budżecie
                </Text>
                <Text variant="body_copy_0" mb="3.2rem" align="center">
                    Możesz zobaczyć inne ciekawe oferty w tej lokalizacji
                </Text>

                <div css={buttonsWrapperStyle}>
                    <Button variant="outlined_secondary" iconLeft={ArrowIcon} css={buttonStyle} onClick={handleBudgetChange}>
                        ZMIEŃ BUDŻET
                    </Button>
                    <Button variant="filled_primary" css={buttonStyle} onClick={handleShowOffers}>
                        POKAŻ OFERTY
                    </Button>
                </div>
            </div>
        </div>
    );
};

const modalContentWrapper = css`
    ${p(10, 2)};

    ${onDesktop(css`
        ${flex("center", "center")};
        ${p(4)};
        height: 42rem;
        width: 86rem;
    `)};
`;

const buttonsWrapperStyle = css`
    ${flex("center", "center")};
    flex-direction: column;
    gap: ${calculateRemSize(3)};

    ${onDesktop(css`
        flex-direction: row;
    `)};
`;

const buttonStyle = css`
    width: 100%;

    ${onDesktop(css`
        width: 20rem;
    `)};
`;
import React from "react";
import {css} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {calculateRemSize, flex, onDesktop, p, zIndex} from "@pg-design/helpers-css";
import {ArrowIcon} from "@pg-design/icons-module";
import {Text} from "@pg-design/text";

import {CloseIconButton} from "../../../atoms/CloseIconButton";

interface IProps {
    handleBudgetChange: () => void;
    handleShowOffers: () => void;
    handleClose: () => void;
}

export const ApplicationPropertyPriceUserBudgetNoRecommendationsModal = (props: IProps) => {
    const {handleBudgetChange, handleShowOffers, handleClose} = props;

    return (
        <div css={modalContentWrapper}>
            <CloseIconButton css={zIndex(10)} onClick={handleClose} right={2.5} top={2.5} />
            <div>
                <Text variant="headline_3" mb="1.6rem" align="center">
                    Niestety, w tej inwestycji nie ma <br />
                    nieruchomości w tym budżecie
                </Text>
                <Text variant="body_copy_0" mb="3.2rem" align="center">
                    Możesz zobaczyć inne ciekawe oferty w tej lokalizacji
                </Text>

                <div css={buttonsWrapperStyle}>
                    <Button variant="outlined_secondary" iconLeft={ArrowIcon} css={buttonStyle} onClick={handleBudgetChange}>
                        ZMIEŃ BUDŻET
                    </Button>
                    <Button variant="filled_primary" css={buttonStyle} onClick={handleShowOffers}>
                        POKAŻ OFERTY
                    </Button>
                </div>
            </div>
        </div>
    );
};

const modalContentWrapper = css`
    ${p(10, 2)};

    ${onDesktop(css`
        ${flex("center", "center")};
        ${p(4)};
        height: 42rem;
        width: 86rem;
    `)};
`;

const buttonsWrapperStyle = css`
    ${flex("center", "center")};
    flex-direction: column;
    gap: ${calculateRemSize(3)};

    ${onDesktop(css`
        flex-direction: row;
    `)};
`;

const buttonStyle = css`
    width: 100%;

    ${onDesktop(css`
        width: 20rem;
    `)};
`;
