import React, {MouseEvent} from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {borderRadius, calculateRemSize, onDesktop, p} from "@pg-design/helpers-css";
import {useIsMobile} from "@pg-mono/hooks";

import {FinancingServicesCopyTypes} from "../../../../lead/types/FinancingServicesCopyTypes";
import {MessageType} from "../../../types/MessageType";
import {useApplicationFormContext} from "../ApplicationFormContext";
import {FinancingServicesField} from "./FinancingServicesField";
import {NotificationConsentField} from "./NotificationConsentField";

interface IProps {
    onMktOptinClick?: (event: MouseEvent<HTMLInputElement>) => void;
    showFinancingServicesField: boolean;
    showPropertyNotificationField: boolean;
    copyType: FinancingServicesCopyTypes;
    className?: string;
}

export const OptIns = (props: IProps) => {
    const {onMktOptinClick, showFinancingServicesField, showPropertyNotificationField, copyType, className} = props;
    const {
        hidePropertyNotification,
        enableLegalDetailsHiding,
        hideFinancingServices,
        applicationMessageLogic: {
            currentState: {messageType}
        }
    } = useApplicationFormContext();
    const isMobile = useIsMobile();

    return (
        <OptinsWrap isWithQuestions={messageType !== MessageType.NONE} className={className}>
            {!hidePropertyNotification && showPropertyNotificationField && (
                <NotificationConsentField name="new_property_notification_consent" css={optinFieldStyle} onFieldClick={onMktOptinClick} />
            )}

            {(!enableLegalDetailsHiding || !hideFinancingServices || showFinancingServicesField) && (
                <FinancingServicesField
                    name="financing_services"
                    css={financingServicesCheckboxStyle}
                    popoverPlace={isMobile ? "top" : "left"}
                    copyType={copyType}
                />
            )}
        </OptinsWrap>
    );
};

// Styles
const OptinsWrap = styled.div<{isWithQuestions: boolean}>`
    ${({isWithQuestions}) => css`
        display: flex;
        flex-direction: column;
        gap: ${calculateRemSize(2)};

        ${isWithQuestions
            ? css`
                  ${p(1, 2.5)};

                  ${onDesktop(css`
                      ${p(1, 1.5)};
                      background: white;
                      ${borderRadius(1)}
                  `)};
              `
            : ""}
    `}
`;

const optinFieldStyle = css`
    align-items: flex-start;
`;

const financingServicesCheckboxStyle = css`
    align-items: flex-start;
    position: relative;
    align-self: baseline;
`;
import React, {MouseEvent} from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {borderRadius, calculateRemSize, onDesktop, p} from "@pg-design/helpers-css";
import {useIsMobile} from "@pg-mono/hooks";

import {FinancingServicesCopyTypes} from "../../../../lead/types/FinancingServicesCopyTypes";
import {MessageType} from "../../../types/MessageType";
import {useApplicationFormContext} from "../ApplicationFormContext";
import {FinancingServicesField} from "./FinancingServicesField";
import {NotificationConsentField} from "./NotificationConsentField";

interface IProps {
    onMktOptinClick?: (event: MouseEvent<HTMLInputElement>) => void;
    showFinancingServicesField: boolean;
    showPropertyNotificationField: boolean;
    copyType: FinancingServicesCopyTypes;
    className?: string;
}

export const OptIns = (props: IProps) => {
    const {onMktOptinClick, showFinancingServicesField, showPropertyNotificationField, copyType, className} = props;
    const {
        hidePropertyNotification,
        enableLegalDetailsHiding,
        hideFinancingServices,
        applicationMessageLogic: {
            currentState: {messageType}
        }
    } = useApplicationFormContext();
    const isMobile = useIsMobile();

    return (
        <OptinsWrap isWithQuestions={messageType !== MessageType.NONE} className={className}>
            {!hidePropertyNotification && showPropertyNotificationField && (
                <NotificationConsentField name="new_property_notification_consent" css={optinFieldStyle} onFieldClick={onMktOptinClick} />
            )}

            {(!enableLegalDetailsHiding || !hideFinancingServices || showFinancingServicesField) && (
                <FinancingServicesField
                    name="financing_services"
                    css={financingServicesCheckboxStyle}
                    popoverPlace={isMobile ? "top" : "left"}
                    copyType={copyType}
                />
            )}
        </OptinsWrap>
    );
};

// Styles
const OptinsWrap = styled.div<{isWithQuestions: boolean}>`
    ${({isWithQuestions}) => css`
        display: flex;
        flex-direction: column;
        gap: ${calculateRemSize(2)};

        ${isWithQuestions
            ? css`
                  ${p(1, 2.5)};

                  ${onDesktop(css`
                      ${p(1, 1.5)};
                      background: white;
                      ${borderRadius(1)}
                  `)};
              `
            : ""}
    `}
`;

const optinFieldStyle = css`
    align-items: flex-start;
`;

const financingServicesCheckboxStyle = css`
    align-items: flex-start;
    position: relative;
    align-self: baseline;
`;
