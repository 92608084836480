const toggle = (list: number[], id: number) => {
    const isSelected = list.includes(id);
    return isSelected ? list.filter((value) => value !== id) : [...list, id];
};

type IAction = {type: "property" | "offer"; payload: number | number[]} | {type: "reset"};

export const selectedRecommendationsReducer = (state: {property: number[]; offer: number[]}, action: IAction) => {
    switch (action.type) {
        case "property": {
            const id = action.payload;

            if (Array.isArray(id)) {
                return {...state, property: [...id]};
            }

            return {...state, property: toggle(state.property, id)};
        }
        case "offer": {
            const id = action.payload;

            if (Array.isArray(id)) {
                return {...state, offer: [...id]};
            }

            return {...state, offer: toggle(state.offer, id)};
        }
        case "reset": {
            return {...state, offer: [], property: []};
        }
        default: {
            throw new Error("Unknown action for recommended reducer");
        }
    }
};
const toggle = (list: number[], id: number) => {
    const isSelected = list.includes(id);
    return isSelected ? list.filter((value) => value !== id) : [...list, id];
};

type IAction = {type: "property" | "offer"; payload: number | number[]} | {type: "reset"};

export const selectedRecommendationsReducer = (state: {property: number[]; offer: number[]}, action: IAction) => {
    switch (action.type) {
        case "property": {
            const id = action.payload;

            if (Array.isArray(id)) {
                return {...state, property: [...id]};
            }

            return {...state, property: toggle(state.property, id)};
        }
        case "offer": {
            const id = action.payload;

            if (Array.isArray(id)) {
                return {...state, offer: [...id]};
            }

            return {...state, offer: toggle(state.offer, id)};
        }
        case "reset": {
            return {...state, offer: [], property: []};
        }
        default: {
            throw new Error("Unknown action for recommended reducer");
        }
    }
};
