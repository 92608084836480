import React, {Fragment, useEffect, useState} from "react";
import {css, Theme, useTheme} from "@emotion/react";
import styled from "@emotion/styled";

import {Button as DesignButton} from "@pg-design/button-module";
import {calculateRemSize, flexAbsoluteCenter, flexDirection, h100, mb, mr, pointer} from "@pg-design/helpers-css";
import {CheckIcon, CloseIcon} from "@pg-design/icons-module";
import {Highlight, Text} from "@pg-design/text-module";
import {OFFER_TEST_IDS} from "@pg-mono/e2e-test-utils";
import {priceFormat} from "@pg-mono/string-utils";

import {AbsoluteContent} from "../../../atoms/AbsoluteContent";
import {getOfferTypeNameCapital} from "../../../offer/helpers/OfferType";
import {IOfferDetailApplication} from "../../../offer/types/IOfferDetailApplication";
import {IPropertyDetailApplication} from "../../../property/types/IPropertyDetailApplication";
import {Country} from "../../../region/types/Country";
import {getCurrency} from "../../../utils/get_currency";
import {IVendorDetailApplication} from "../../../vendor/types/IVendorDetailApplication";
import {conditionalPricesAnalytics, PriceModalGTMEvent} from "../../utils/conditional_prices_analytics";
import {ApplicationSentSuccess} from "../ApplicationSentSuccess";
import {ApplicationVendorInfo} from "../ApplicationVendorInfo";

export enum ApplicationPropertyPriceUserDecisionType {
    SENT = 1,
    CANCEL = 2
}

interface IProps {
    leadIsSubmitting: boolean;
    onSendConfirmation: () => void;
    onSendRejection: () => void;
    onModalClose: (forceClose?: boolean) => void;
    property: IPropertyDetailApplication;
    offer: IOfferDetailApplication;
    vendor: IVendorDetailApplication;
    userDecision: null | ApplicationPropertyPriceUserDecisionType;
}

enum ApplicationPropertyPriceButtonType {
    NONE,
    CANCEL,
    OK
}

export const ApplicationPropertyPrice = (props: IProps) => {
    const theme = useTheme();
    const [clickedActionButton, setClickedActionButton] = useState(ApplicationPropertyPriceButtonType.NONE);

    const showCloseButton = props.userDecision !== ApplicationPropertyPriceUserDecisionType.SENT;
    const currency = getCurrency({currency: props.offer.currency});
    const abroadOffer = props.offer.region.country !== Country.POLAND;

    useEffect(() => {
        conditionalPricesAnalytics.gtm.priceModalEvent(PriceModalGTMEvent.PRICE_MODAL_SHOW);
    }, []);

    const onClose = () => {
        props.onModalClose();

        conditionalPricesAnalytics.gtm.priceModalEvent(PriceModalGTMEvent.PRICE_MODAL_CLOSE);
    };

    const showLoaderOnBtn = clickedActionButton !== ApplicationPropertyPriceButtonType.NONE && props.leadIsSubmitting;

    const onSendRejection = () => {
        abroadOffer ? props.onModalClose() : props.onSendRejection();
    };

    const renderApplicationPropertyPriceContent = () => {
        if (props.userDecision && props.userDecision === ApplicationPropertyPriceUserDecisionType.SENT) {
            return <ApplicationSentSuccess />;
        }

        return (
            <Fragment>
                <ApplicationVendorInfo vendorLogo={props.vendor.logo.v_log_80x60} vendorName={props.vendor.name} headerText={props.offer.name} />

                <div css={[h100, flexAbsoluteCenter, flexDirection("column")]} data-testid={OFFER_TEST_IDS.LEAD_FORM.PROPERTY_PRICE_REVEALED_MODAL}>
                    <Text as="div" mb="1.6rem" variant="headline_2">
                        {`${getOfferTypeNameCapital(props.offer.type)} ${props.property.number}`}
                    </Text>
                    <Text as="div" mb="1.6rem" variant="headline_4">
                        Cena tej nieruchomości:
                    </Text>
                    {props.property.price && (
                        <Text as="div" mb="4.8rem" variant="headline_2">
                            <Highlight>{priceFormat(props.property.price, {unit: currency})}</Highlight>
                        </Text>
                    )}
                    {props.property.price &&
                        props.property.price > 0 &&
                        props.property.lowest_price_within_30_days &&
                        props.property.lowest_price_within_30_days > 0 &&
                        props.property.discount && (
                            <Text as="div" variant="info_txt_2" css={mb(3)}>
                                Najniższa cena z ostatnich 30 dni, przed obniżką:{" "}
                                <strong>{priceFormat(props.property.lowest_price_within_30_days, {unit: currency})}</strong>
                            </Text>
                        )}
                    <div css={buttonsSection}>
                        <div>
                            <div css={buttonLabel}>
                                <CheckIcon fill={theme.colors.secondary} wrapperColor={theme.colors.success} wrapperSize="1.6" css={mr(1)} />
                                <Text variant="body_copy_1">Cena mi odpowiada</Text>
                            </div>

                            <Button
                                onClick={() => {
                                    props.onSendConfirmation();
                                    setClickedActionButton(ApplicationPropertyPriceButtonType.OK);
                                }}
                                variant="filled_primary"
                                isLoading={clickedActionButton === ApplicationPropertyPriceButtonType.OK}
                                disabled={showLoaderOnBtn}
                                dataTestId={OFFER_TEST_IDS.LEAD_FORM.CONFIRM_SEND_LEAD_BUTTON}
                            >
                                Proszę o kontakt od dewelopera
                            </Button>
                        </div>

                        <div>
                            <div css={buttonLabel}>
                                <CloseIcon fill={theme.colors.secondary} wrapperColor={theme.colors.warning} wrapperSize="1.6" css={mr(1)} />
                                <Text variant="body_copy_1">Cena jest dla mnie za wysoka</Text>
                            </div>

                            <Button
                                onClick={() => {
                                    onSendRejection();
                                    setClickedActionButton(ApplicationPropertyPriceButtonType.CANCEL);
                                }}
                                variant="outlined_secondary"
                                isLoading={clickedActionButton === ApplicationPropertyPriceButtonType.CANCEL}
                                disabled={showLoaderOnBtn}
                            >
                                {abroadOffer ? "Zamknij" : "Chcę zobaczyć tańsze oferty"}
                            </Button>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    };

    return (
        <AbsoluteContent>
            {showCloseButton && <CloseIcon css={closeIcon} size="2" onClick={onClose} />}

            {renderApplicationPropertyPriceContent()}
        </AbsoluteContent>
    );
};

const buttonsSection = (theme: Theme) => css`
    width: 100%;
    ${flexAbsoluteCenter};
    ${flexDirection("column")};
    gap: 4rem;

    @media (min-width: ${theme.breakpoints.md}) {
        justify-content: space-between;
        ${flexDirection("row")};
    }
`;

const Button = styled(DesignButton)`
    min-width: 34rem;
`;

const buttonLabel = css`
    ${flexAbsoluteCenter};
    ${mb(2)};
`;

const closeIcon = css`
    position: absolute;
    top: ${calculateRemSize(3)};
    right: ${calculateRemSize(3)};
    ${pointer};
`;
import React, {Fragment, useEffect, useState} from "react";
import {css, Theme, useTheme} from "@emotion/react";
import styled from "@emotion/styled";

import {Button as DesignButton} from "@pg-design/button-module";
import {calculateRemSize, flexAbsoluteCenter, flexDirection, h100, mb, mr, pointer} from "@pg-design/helpers-css";
import {CheckIcon, CloseIcon} from "@pg-design/icons-module";
import {Highlight, Text} from "@pg-design/text-module";
import {OFFER_TEST_IDS} from "@pg-mono/e2e-test-utils";
import {priceFormat} from "@pg-mono/string-utils";

import {AbsoluteContent} from "../../../atoms/AbsoluteContent";
import {getOfferTypeNameCapital} from "../../../offer/helpers/OfferType";
import {IOfferDetailApplication} from "../../../offer/types/IOfferDetailApplication";
import {IPropertyDetailApplication} from "../../../property/types/IPropertyDetailApplication";
import {Country} from "../../../region/types/Country";
import {getCurrency} from "../../../utils/get_currency";
import {IVendorDetailApplication} from "../../../vendor/types/IVendorDetailApplication";
import {conditionalPricesAnalytics, PriceModalGTMEvent} from "../../utils/conditional_prices_analytics";
import {ApplicationSentSuccess} from "../ApplicationSentSuccess";
import {ApplicationVendorInfo} from "../ApplicationVendorInfo";

export enum ApplicationPropertyPriceUserDecisionType {
    SENT = 1,
    CANCEL = 2
}

interface IProps {
    leadIsSubmitting: boolean;
    onSendConfirmation: () => void;
    onSendRejection: () => void;
    onModalClose: (forceClose?: boolean) => void;
    property: IPropertyDetailApplication;
    offer: IOfferDetailApplication;
    vendor: IVendorDetailApplication;
    userDecision: null | ApplicationPropertyPriceUserDecisionType;
}

enum ApplicationPropertyPriceButtonType {
    NONE,
    CANCEL,
    OK
}

export const ApplicationPropertyPrice = (props: IProps) => {
    const theme = useTheme();
    const [clickedActionButton, setClickedActionButton] = useState(ApplicationPropertyPriceButtonType.NONE);

    const showCloseButton = props.userDecision !== ApplicationPropertyPriceUserDecisionType.SENT;
    const currency = getCurrency({currency: props.offer.currency});
    const abroadOffer = props.offer.region.country !== Country.POLAND;

    useEffect(() => {
        conditionalPricesAnalytics.gtm.priceModalEvent(PriceModalGTMEvent.PRICE_MODAL_SHOW);
    }, []);

    const onClose = () => {
        props.onModalClose();

        conditionalPricesAnalytics.gtm.priceModalEvent(PriceModalGTMEvent.PRICE_MODAL_CLOSE);
    };

    const showLoaderOnBtn = clickedActionButton !== ApplicationPropertyPriceButtonType.NONE && props.leadIsSubmitting;

    const onSendRejection = () => {
        abroadOffer ? props.onModalClose() : props.onSendRejection();
    };

    const renderApplicationPropertyPriceContent = () => {
        if (props.userDecision && props.userDecision === ApplicationPropertyPriceUserDecisionType.SENT) {
            return <ApplicationSentSuccess />;
        }

        return (
            <Fragment>
                <ApplicationVendorInfo vendorLogo={props.vendor.logo.v_log_80x60} vendorName={props.vendor.name} headerText={props.offer.name} />

                <div css={[h100, flexAbsoluteCenter, flexDirection("column")]} data-testid={OFFER_TEST_IDS.LEAD_FORM.PROPERTY_PRICE_REVEALED_MODAL}>
                    <Text as="div" mb="1.6rem" variant="headline_2">
                        {`${getOfferTypeNameCapital(props.offer.type)} ${props.property.number}`}
                    </Text>
                    <Text as="div" mb="1.6rem" variant="headline_4">
                        Cena tej nieruchomości:
                    </Text>
                    {props.property.price && (
                        <Text as="div" mb="4.8rem" variant="headline_2">
                            <Highlight>{priceFormat(props.property.price, {unit: currency})}</Highlight>
                        </Text>
                    )}
                    {props.property.price &&
                        props.property.price > 0 &&
                        props.property.lowest_price_within_30_days &&
                        props.property.lowest_price_within_30_days > 0 &&
                        props.property.discount && (
                            <Text as="div" variant="info_txt_2" css={mb(3)}>
                                Najniższa cena z ostatnich 30 dni, przed obniżką:{" "}
                                <strong>{priceFormat(props.property.lowest_price_within_30_days, {unit: currency})}</strong>
                            </Text>
                        )}
                    <div css={buttonsSection}>
                        <div>
                            <div css={buttonLabel}>
                                <CheckIcon fill={theme.colors.secondary} wrapperColor={theme.colors.success} wrapperSize="1.6" css={mr(1)} />
                                <Text variant="body_copy_1">Cena mi odpowiada</Text>
                            </div>

                            <Button
                                onClick={() => {
                                    props.onSendConfirmation();
                                    setClickedActionButton(ApplicationPropertyPriceButtonType.OK);
                                }}
                                variant="filled_primary"
                                isLoading={clickedActionButton === ApplicationPropertyPriceButtonType.OK}
                                disabled={showLoaderOnBtn}
                                dataTestId={OFFER_TEST_IDS.LEAD_FORM.CONFIRM_SEND_LEAD_BUTTON}
                            >
                                Proszę o kontakt od dewelopera
                            </Button>
                        </div>

                        <div>
                            <div css={buttonLabel}>
                                <CloseIcon fill={theme.colors.secondary} wrapperColor={theme.colors.warning} wrapperSize="1.6" css={mr(1)} />
                                <Text variant="body_copy_1">Cena jest dla mnie za wysoka</Text>
                            </div>

                            <Button
                                onClick={() => {
                                    onSendRejection();
                                    setClickedActionButton(ApplicationPropertyPriceButtonType.CANCEL);
                                }}
                                variant="outlined_secondary"
                                isLoading={clickedActionButton === ApplicationPropertyPriceButtonType.CANCEL}
                                disabled={showLoaderOnBtn}
                            >
                                {abroadOffer ? "Zamknij" : "Chcę zobaczyć tańsze oferty"}
                            </Button>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    };

    return (
        <AbsoluteContent>
            {showCloseButton && <CloseIcon css={closeIcon} size="2" onClick={onClose} />}

            {renderApplicationPropertyPriceContent()}
        </AbsoluteContent>
    );
};

const buttonsSection = (theme: Theme) => css`
    width: 100%;
    ${flexAbsoluteCenter};
    ${flexDirection("column")};
    gap: 4rem;

    @media (min-width: ${theme.breakpoints.md}) {
        justify-content: space-between;
        ${flexDirection("row")};
    }
`;

const Button = styled(DesignButton)`
    min-width: 34rem;
`;

const buttonLabel = css`
    ${flexAbsoluteCenter};
    ${mb(2)};
`;

const closeIcon = css`
    position: absolute;
    top: ${calculateRemSize(3)};
    right: ${calculateRemSize(3)};
    ${pointer};
`;
