import {useSelector} from "react-redux";

import {abTestingSelector} from "../utils/ab_testing_selector";

export const useABTestVariant = (abTestId: string): number | null => {
    return useSelector(abTestingSelector(abTestId)) ?? null;
};
import {useSelector} from "react-redux";

import {abTestingSelector} from "../utils/ab_testing_selector";

export const useABTestVariant = (abTestId: string): number | null => {
    return useSelector(abTestingSelector(abTestId)) ?? null;
};
