import {notifyBugsnag} from "../../errors/bugsnag/notify_bugsnag";
import {IGtmFinancingServices} from "../../lead/types/IGtmFinancingServices";
import {ILeadModalLeadType} from "../../lead/types/ILeadModalLeadType";
import {getSentLeadFormValuesFromStorage} from "../../lead/utils/sent_lead_form_values";
import {IOfferDetailApplication} from "../../offer/types/IOfferDetailApplication";
import {IPropertyDetailApplication} from "../../property/types/IPropertyDetailApplication";
import {getCountryName} from "../../region/utils/get_country_name";
import {IVendorDetailApplication} from "../../vendor/types/IVendorDetailApplication";
import {getViewTypeName, ViewType} from "../../view_type/ViewType";
import {IGtmProduct} from "./common_interfaces/IGtmProduct";
import {calculateGtmDimensions, IGtmOffer, IGtmProperty, IGtmRegion, IGtmVendor} from "./calculate_gtm_dimensions";
import {hitGoogleTagManager} from "./init_google_tag_manager";

interface IGtmProductAdd {
    event: "product_add";
    event_category: "conversation_form" | "standard";
    ecommerce: {
        currencyCode: "PLN";
        add: {
            products: [IGtmProduct];
        };
    };
}

interface IGtmProductAddData {
    vendor: {
        detail?: IVendorDetailApplication;
    } | null;
    offer: {
        detail?: IOfferDetailApplication;
    } | null;
    property: {
        detail?: IPropertyDetailApplication;
    } | null;
}

export function gtmProductAdd(data: IGtmProductAddData, viewType: ViewType | null, leadType: ILeadModalLeadType) {
    const vendorData = (data.vendor && data.vendor.detail) || undefined;
    const offerData = (data.offer && data.offer.detail) || undefined;
    const finalOfferData = offerData ? {...offerData, region: offerData.region.id} : offerData;
    const regionData = (offerData && offerData.region) || undefined;
    const propertyData = (data.property && data.property.detail) || undefined;

    if (!vendorData) {
        const errorText = "interactionHitBasedOnData: require vendor data";
        notifyBugsnag(errorText, errorText);
        return;
    }

    return hitGtmProductAdd(viewType, leadType, vendorData, regionData, finalOfferData, propertyData);
}

function hitGtmProductAdd(
    viewType: ViewType | null,
    leadType: ILeadModalLeadType,
    vendor: IGtmVendor,
    region?: IGtmRegion,
    offer?: IGtmOffer,
    property?: IGtmProperty
) {
    // calculate financing services
    const financingServices = getFinancingServicesInfo();

    // calculate product
    const product: IGtmProduct = {
        ...calculateGtmDimensions(financingServices, region, offer, property),
        name: (property && property.number) || "na",
        id: property ? property.id.toString() : "na",
        home_listing_id: offer ? (property ? `${offer.id}-${property.id}` : `${offer.id}-0`) : "na",
        group_id: offer ? `${offer.id}-0` : "na",
        price: "1.00",
        brand: vendor ? `${vendor.id}/${vendor.name}` : "na",
        category: offer ? `${offer.id}/${offer.name}` : "na",
        variant: viewType != null ? getViewTypeName(viewType) : "na",
        quantity: 1,
        custom_country: getCountryName(offer?.region)
    };

    // prepare gtm data and hit
    const gtmData: IGtmProductAdd = {
        event: "product_add",
        event_category: leadType === "conversation" ? "conversation_form" : "standard",
        ecommerce: {
            currencyCode: "PLN",
            add: {
                products: [product]
            }
        }
    };

    hitGoogleTagManager(gtmData);

    return true;
}

function getFinancingServicesInfo(): IGtmFinancingServices {
    const savedLsLead = getSentLeadFormValuesFromStorage();

    if (!savedLsLead) {
        return "na";
    }

    if (savedLsLead.financing_services) {
        return "tak";
    }

    return "nie";
}
import {notifyBugsnag} from "../../errors/bugsnag/notify_bugsnag";
import {IGtmFinancingServices} from "../../lead/types/IGtmFinancingServices";
import {ILeadModalLeadType} from "../../lead/types/ILeadModalLeadType";
import {getSentLeadFormValuesFromStorage} from "../../lead/utils/sent_lead_form_values";
import {IOfferDetailApplication} from "../../offer/types/IOfferDetailApplication";
import {IPropertyDetailApplication} from "../../property/types/IPropertyDetailApplication";
import {getCountryName} from "../../region/utils/get_country_name";
import {IVendorDetailApplication} from "../../vendor/types/IVendorDetailApplication";
import {getViewTypeName, ViewType} from "../../view_type/ViewType";
import {IGtmProduct} from "./common_interfaces/IGtmProduct";
import {calculateGtmDimensions, IGtmOffer, IGtmProperty, IGtmRegion, IGtmVendor} from "./calculate_gtm_dimensions";
import {hitGoogleTagManager} from "./init_google_tag_manager";

interface IGtmProductAdd {
    event: "product_add";
    event_category: "conversation_form" | "standard";
    ecommerce: {
        currencyCode: "PLN";
        add: {
            products: [IGtmProduct];
        };
    };
}

interface IGtmProductAddData {
    vendor: {
        detail?: IVendorDetailApplication;
    } | null;
    offer: {
        detail?: IOfferDetailApplication;
    } | null;
    property: {
        detail?: IPropertyDetailApplication;
    } | null;
}

export function gtmProductAdd(data: IGtmProductAddData, viewType: ViewType | null, leadType: ILeadModalLeadType) {
    const vendorData = (data.vendor && data.vendor.detail) || undefined;
    const offerData = (data.offer && data.offer.detail) || undefined;
    const finalOfferData = offerData ? {...offerData, region: offerData.region.id} : offerData;
    const regionData = (offerData && offerData.region) || undefined;
    const propertyData = (data.property && data.property.detail) || undefined;

    if (!vendorData) {
        const errorText = "interactionHitBasedOnData: require vendor data";
        notifyBugsnag(errorText, errorText);
        return;
    }

    return hitGtmProductAdd(viewType, leadType, vendorData, regionData, finalOfferData, propertyData);
}

function hitGtmProductAdd(
    viewType: ViewType | null,
    leadType: ILeadModalLeadType,
    vendor: IGtmVendor,
    region?: IGtmRegion,
    offer?: IGtmOffer,
    property?: IGtmProperty
) {
    // calculate financing services
    const financingServices = getFinancingServicesInfo();

    // calculate product
    const product: IGtmProduct = {
        ...calculateGtmDimensions(financingServices, region, offer, property),
        name: (property && property.number) || "na",
        id: property ? property.id.toString() : "na",
        home_listing_id: offer ? (property ? `${offer.id}-${property.id}` : `${offer.id}-0`) : "na",
        group_id: offer ? `${offer.id}-0` : "na",
        price: "1.00",
        brand: vendor ? `${vendor.id}/${vendor.name}` : "na",
        category: offer ? `${offer.id}/${offer.name}` : "na",
        variant: viewType != null ? getViewTypeName(viewType) : "na",
        quantity: 1,
        custom_country: getCountryName(offer?.region)
    };

    // prepare gtm data and hit
    const gtmData: IGtmProductAdd = {
        event: "product_add",
        event_category: leadType === "conversation" ? "conversation_form" : "standard",
        ecommerce: {
            currencyCode: "PLN",
            add: {
                products: [product]
            }
        }
    };

    hitGoogleTagManager(gtmData);

    return true;
}

function getFinancingServicesInfo(): IGtmFinancingServices {
    const savedLsLead = getSentLeadFormValuesFromStorage();

    if (!savedLsLead) {
        return "na";
    }

    if (savedLsLead.financing_services) {
        return "tak";
    }

    return "nie";
}
