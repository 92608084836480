import {ReactElement} from "react";

import {IRegionEstimatedPriceSummary} from "../../../../../region/hooks/use_region_estimated_price";
import {IUseEstimatedPriceByAreaResult, useEstimatedPriceByArea} from "../../../../hooks/use_estimated_price_by_area";
import {IMultiLeadData} from "../../../../types/IMultiLeadData";

interface IProps extends Pick<IMultiLeadData, "price" | "isPriceRevealed"> {
    regionEstimatedPriceSummary: IRegionEstimatedPriceSummary;
    area?: number;
    children?: (props: IUseEstimatedPriceByAreaResult) => ReactElement;
}

export const MultiLeadCardEstimatedPriceWrapper = (props: IProps) => {
    const estimatedPriceProps = useEstimatedPriceByArea(props);

    return props.children?.({...estimatedPriceProps}) || null;
};
import {ReactElement} from "react";

import {IRegionEstimatedPriceSummary} from "../../../../../region/hooks/use_region_estimated_price";
import {IUseEstimatedPriceByAreaResult, useEstimatedPriceByArea} from "../../../../hooks/use_estimated_price_by_area";
import {IMultiLeadData} from "../../../../types/IMultiLeadData";

interface IProps extends Pick<IMultiLeadData, "price" | "isPriceRevealed"> {
    regionEstimatedPriceSummary: IRegionEstimatedPriceSummary;
    area?: number;
    children?: (props: IUseEstimatedPriceByAreaResult) => ReactElement;
}

export const MultiLeadCardEstimatedPriceWrapper = (props: IProps) => {
    const estimatedPriceProps = useEstimatedPriceByArea(props);

    return props.children?.({...estimatedPriceProps}) || null;
};
