import {useEffect, useRef, useState} from "react";
import {css} from "@emotion/react";

import {calculateRemSize} from "@pg-design/helpers-css";

import {REVEALED_PRICE_ESTIMATED_LOWER_FACTOR, REVEALED_PRICE_ESTIMATED_UPPER_FACTOR} from "../../../../../../../application/constants/multi_lead_constants";
import {openApplicationFormHit} from "../../../../../../../application/tracking/tracking";
import {IApplication} from "../../../../../../../application/types/IApplication";
import {notifyBugsnag} from "../../../../../../../errors/bugsnag/notify_bugsnag";
import {useGetOfferDetailApplicationQuery} from "../../../../../../../offer/api/get_offer_detail";
import {IOfferDetailApplication} from "../../../../../../../offer/types/IOfferDetailApplication";
import {useGetPropertyDetailApplicationQuery} from "../../../../../../../property/api/get_property_detail";
import {IPropertyDetailApplication} from "../../../../../../../property/types/IPropertyDetailApplication";
import {useAppDispatch, useAppSelector} from "../../../../../../../store/hooks";
import {IAppDispatch} from "../../../../../../../store/store";
import {gtmCheckout} from "../../../../../../../tracking/google_tag_manager/gtm_checkout";
import {gtmProductAdd} from "../../../../../../../tracking/google_tag_manager/gtm_product_add";
import {useGetVendorDetailApplicationQuery} from "../../../../../../../vendor/api/get_vendor_detail";
import {useOpenedLeadModalRequiredParams} from "../../../../../../hooks/use_lead_modal_required_params";
import {useLeadModalSendMainLead} from "../../../../../../hooks/use_lead_modal_send_main_lead";
import {ILeadModalPriceConversationChatLeadFormHandleSubmit} from "../../../../../../types/ILeadModalPriceConversationChatLeadFormHandleSubmit";
import {ILeadModalPriceConversationChatLeadFormValues} from "../../../../../../types/ILeadModalPriceConversationChatLeadFormValues";
import {ILeadModalPriceConversationPrice} from "../../../../../../types/ILeadModalPriceConversationPrice";
import {getBigDataPriceRange} from "../../../../../../utils/get_big_data_price_range";
import {getLeadAnalyticsData} from "../../../../../../utils/get_lead_analytics_data";
import {LeadModalPriceConversationChatLeadForm} from "./LeadModalPriceConversationChatLeadForm";

interface IProps {
    initialValues: ILeadModalPriceConversationChatLeadFormValues;
    handleModalClose: (forceClose: boolean) => void;
    handleSuccess: (createdApplication: IApplication, price: ILeadModalPriceConversationPrice) => void;
}

export function LeadModalPriceConversationChatLead(props: IProps) {
    const {initialValues, handleModalClose, handleSuccess} = props;

    const dispatch = useAppDispatch();

    const isAuthenticated = useAppSelector((state) => state.isAuthenticated);

    const {vendorId, offerId, propertyId, source, sourceSection} = useOpenedLeadModalRequiredParams("property");
    const viewType = useAppSelector((state) => state.viewType.current);
    const {data: vendorDetail, isSuccess: vendorLoaded} = useGetVendorDetailApplicationQuery(vendorId);
    const {data: offerDetail, isSuccess: offerLoaded} = useGetOfferDetailApplicationQuery(offerId);
    const {data: propertyDetail, isSuccess: propertyLoaded} = useGetPropertyDetailApplicationQuery(propertyId);
    const sendLead = useLeadModalSendMainLead();

    const [isFormVisible, setIsFormVisible] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const isGtmProductAddHitRef = useRef(false);
    const isGtmCheckoutHitRef = useRef(false);
    const isAlgolyticsOpenFormHitRef = useRef(false);

    const hasInitialRequiredValues = initialValues.name && initialValues.email && initialValues.phone;
    const showForm = !isAuthenticated || isFormVisible || !hasInitialRequiredValues;

    const leadData = {
        vendor: {
            detail: vendorDetail
        },
        offer: {
            detail: offerDetail
        },
        property: {
            detail: propertyDetail
        }
    };

    useEffect(() => {
        if (!isAlgolyticsOpenFormHitRef.current && vendorLoaded && offerLoaded && propertyLoaded) {
            isAlgolyticsOpenFormHitRef.current = true;

            const leadAnalyticsData = getLeadAnalyticsData(leadData, viewType, source, sourceSection);
            openApplicationFormHit(leadAnalyticsData);
        }

        if (vendorLoaded && offerLoaded && propertyLoaded && !isGtmProductAddHitRef.current) {
            isGtmProductAddHitRef.current = true;

            gtmProductAdd(leadData, viewType, "conversation");
        }
    }, [vendorLoaded, offerLoaded, propertyLoaded]);

    useEffect(() => {
        if (!showForm && !isGtmCheckoutHitRef.current && vendorLoaded && offerLoaded && propertyLoaded) {
            isGtmCheckoutHitRef.current = true;

            gtmCheckout(leadData, viewType, "conversation");
        }
    }, [vendorLoaded, offerLoaded, propertyLoaded, showForm]);

    const handleFormInteraction = () => {
        if (!isGtmCheckoutHitRef.current && vendorLoaded && offerLoaded && propertyLoaded) {
            isGtmCheckoutHitRef.current = true;

            gtmCheckout(leadData, viewType, "conversation");
        }
    };

    const handleSubmit: ILeadModalPriceConversationChatLeadFormHandleSubmit = async (formValues, onError) => {
        if (!offerDetail || !propertyDetail) {
            return;
        }
        setIsSending(true);

        try {
            const application = (await sendLead(formValues, handleModalClose)) as unknown as IApplication;

            const priceRange = await getPrice(application, offerDetail, propertyDetail, dispatch);

            if (!priceRange) {
                const error = "LeadModalPriceConversation - Price not available despite fulfilled conditions";
                notifyBugsnag(error, error, {application, offerDetail, propertyDetail});
                return;
            }

            handleSuccess(application, priceRange);
        } catch (error) {
            if (onError) {
                onError(error);
            }
        } finally {
            setIsSending(false);
        }
    };

    // Render
    return (
        <div css={fields}>
            <LeadModalPriceConversationChatLeadForm
                showForm={showForm}
                initialValues={initialValues}
                disabled={isSending}
                handleSubmit={handleSubmit}
                handleFormInteraction={handleFormInteraction}
                handleChangeData={() => {
                    setIsFormVisible(true);
                }}
            />
        </div>
    );
}

// Utils
async function getPrice(application: IApplication, offer: IOfferDetailApplication, property: IPropertyDetailApplication, dispatch: IAppDispatch) {
    if (application.property_price) {
        return {
            min: application.property_price * REVEALED_PRICE_ESTIMATED_LOWER_FACTOR,
            max: application.property_price * REVEALED_PRICE_ESTIMATED_UPPER_FACTOR
        };
    }

    const bigDataPrice = await getBigDataPriceRange(offer, property, dispatch);

    if (bigDataPrice && bigDataPrice.length) {
        return {
            min: Math.min(...bigDataPrice),
            max: Math.max(...bigDataPrice)
        };
    }

    return null;
}

// Styles
const fields = css`
    display: flex;
    flex-direction: column;
    padding: ${calculateRemSize(1)} 0 0 0;
    gap: ${calculateRemSize(1)};
`;
import {useEffect, useRef, useState} from "react";
import {css} from "@emotion/react";

import {calculateRemSize} from "@pg-design/helpers-css";

import {REVEALED_PRICE_ESTIMATED_LOWER_FACTOR, REVEALED_PRICE_ESTIMATED_UPPER_FACTOR} from "../../../../../../../application/constants/multi_lead_constants";
import {openApplicationFormHit} from "../../../../../../../application/tracking/tracking";
import {IApplication} from "../../../../../../../application/types/IApplication";
import {notifyBugsnag} from "../../../../../../../errors/bugsnag/notify_bugsnag";
import {useGetOfferDetailApplicationQuery} from "../../../../../../../offer/api/get_offer_detail";
import {IOfferDetailApplication} from "../../../../../../../offer/types/IOfferDetailApplication";
import {useGetPropertyDetailApplicationQuery} from "../../../../../../../property/api/get_property_detail";
import {IPropertyDetailApplication} from "../../../../../../../property/types/IPropertyDetailApplication";
import {useAppDispatch, useAppSelector} from "../../../../../../../store/hooks";
import {IAppDispatch} from "../../../../../../../store/store";
import {gtmCheckout} from "../../../../../../../tracking/google_tag_manager/gtm_checkout";
import {gtmProductAdd} from "../../../../../../../tracking/google_tag_manager/gtm_product_add";
import {useGetVendorDetailApplicationQuery} from "../../../../../../../vendor/api/get_vendor_detail";
import {useOpenedLeadModalRequiredParams} from "../../../../../../hooks/use_lead_modal_required_params";
import {useLeadModalSendMainLead} from "../../../../../../hooks/use_lead_modal_send_main_lead";
import {ILeadModalPriceConversationChatLeadFormHandleSubmit} from "../../../../../../types/ILeadModalPriceConversationChatLeadFormHandleSubmit";
import {ILeadModalPriceConversationChatLeadFormValues} from "../../../../../../types/ILeadModalPriceConversationChatLeadFormValues";
import {ILeadModalPriceConversationPrice} from "../../../../../../types/ILeadModalPriceConversationPrice";
import {getBigDataPriceRange} from "../../../../../../utils/get_big_data_price_range";
import {getLeadAnalyticsData} from "../../../../../../utils/get_lead_analytics_data";
import {LeadModalPriceConversationChatLeadForm} from "./LeadModalPriceConversationChatLeadForm";

interface IProps {
    initialValues: ILeadModalPriceConversationChatLeadFormValues;
    handleModalClose: (forceClose: boolean) => void;
    handleSuccess: (createdApplication: IApplication, price: ILeadModalPriceConversationPrice) => void;
}

export function LeadModalPriceConversationChatLead(props: IProps) {
    const {initialValues, handleModalClose, handleSuccess} = props;

    const dispatch = useAppDispatch();

    const isAuthenticated = useAppSelector((state) => state.isAuthenticated);

    const {vendorId, offerId, propertyId, source, sourceSection} = useOpenedLeadModalRequiredParams("property");
    const viewType = useAppSelector((state) => state.viewType.current);
    const {data: vendorDetail, isSuccess: vendorLoaded} = useGetVendorDetailApplicationQuery(vendorId);
    const {data: offerDetail, isSuccess: offerLoaded} = useGetOfferDetailApplicationQuery(offerId);
    const {data: propertyDetail, isSuccess: propertyLoaded} = useGetPropertyDetailApplicationQuery(propertyId);
    const sendLead = useLeadModalSendMainLead();

    const [isFormVisible, setIsFormVisible] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const isGtmProductAddHitRef = useRef(false);
    const isGtmCheckoutHitRef = useRef(false);
    const isAlgolyticsOpenFormHitRef = useRef(false);

    const hasInitialRequiredValues = initialValues.name && initialValues.email && initialValues.phone;
    const showForm = !isAuthenticated || isFormVisible || !hasInitialRequiredValues;

    const leadData = {
        vendor: {
            detail: vendorDetail
        },
        offer: {
            detail: offerDetail
        },
        property: {
            detail: propertyDetail
        }
    };

    useEffect(() => {
        if (!isAlgolyticsOpenFormHitRef.current && vendorLoaded && offerLoaded && propertyLoaded) {
            isAlgolyticsOpenFormHitRef.current = true;

            const leadAnalyticsData = getLeadAnalyticsData(leadData, viewType, source, sourceSection);
            openApplicationFormHit(leadAnalyticsData);
        }

        if (vendorLoaded && offerLoaded && propertyLoaded && !isGtmProductAddHitRef.current) {
            isGtmProductAddHitRef.current = true;

            gtmProductAdd(leadData, viewType, "conversation");
        }
    }, [vendorLoaded, offerLoaded, propertyLoaded]);

    useEffect(() => {
        if (!showForm && !isGtmCheckoutHitRef.current && vendorLoaded && offerLoaded && propertyLoaded) {
            isGtmCheckoutHitRef.current = true;

            gtmCheckout(leadData, viewType, "conversation");
        }
    }, [vendorLoaded, offerLoaded, propertyLoaded, showForm]);

    const handleFormInteraction = () => {
        if (!isGtmCheckoutHitRef.current && vendorLoaded && offerLoaded && propertyLoaded) {
            isGtmCheckoutHitRef.current = true;

            gtmCheckout(leadData, viewType, "conversation");
        }
    };

    const handleSubmit: ILeadModalPriceConversationChatLeadFormHandleSubmit = async (formValues, onError) => {
        if (!offerDetail || !propertyDetail) {
            return;
        }
        setIsSending(true);

        try {
            const application = (await sendLead(formValues, handleModalClose)) as unknown as IApplication;

            const priceRange = await getPrice(application, offerDetail, propertyDetail, dispatch);

            if (!priceRange) {
                const error = "LeadModalPriceConversation - Price not available despite fulfilled conditions";
                notifyBugsnag(error, error, {application, offerDetail, propertyDetail});
                return;
            }

            handleSuccess(application, priceRange);
        } catch (error) {
            if (onError) {
                onError(error);
            }
        } finally {
            setIsSending(false);
        }
    };

    // Render
    return (
        <div css={fields}>
            <LeadModalPriceConversationChatLeadForm
                showForm={showForm}
                initialValues={initialValues}
                disabled={isSending}
                handleSubmit={handleSubmit}
                handleFormInteraction={handleFormInteraction}
                handleChangeData={() => {
                    setIsFormVisible(true);
                }}
            />
        </div>
    );
}

// Utils
async function getPrice(application: IApplication, offer: IOfferDetailApplication, property: IPropertyDetailApplication, dispatch: IAppDispatch) {
    if (application.property_price) {
        return {
            min: application.property_price * REVEALED_PRICE_ESTIMATED_LOWER_FACTOR,
            max: application.property_price * REVEALED_PRICE_ESTIMATED_UPPER_FACTOR
        };
    }

    const bigDataPrice = await getBigDataPriceRange(offer, property, dispatch);

    if (bigDataPrice && bigDataPrice.length) {
        return {
            min: Math.min(...bigDataPrice),
            max: Math.max(...bigDataPrice)
        };
    }

    return null;
}

// Styles
const fields = css`
    display: flex;
    flex-direction: column;
    padding: ${calculateRemSize(1)} 0 0 0;
    gap: ${calculateRemSize(1)};
`;
