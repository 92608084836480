import {css} from "@emotion/react";

export const zIndex = (value: number) => css`
    z-index: ${value};
`;
import {css} from "@emotion/react";

export const zIndex = (value: number) => css`
    z-index: ${value};
`;
