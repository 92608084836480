import {consoleError} from "@pg-mono/logger";
import {appendQueryString, getExternalRequest} from "@pg-mono/request";
import {apiBigDataLink} from "@pg-mono/rp-api-routes";

import {bigDataApiUrl} from "../../app/read_rp_environment_variables";

export interface IPropertyEstimatedPrice {
    date: string;
    slug_city: string;
    city: string;
    avg_price_m2: number;
    avg_price_m2_area_to_30m2: number;
    avg_price_m2_area_between_30_and_50m2: number;
    avg_price_m2_area_between_50_and_70m2: number;
    avg_price_m2_area_between_70_and_100m2: number;
    avg_price_m2_area_between_100_and_120m2: number;
    avg_price_m2_area_above_120m2: number;
    avg_price_m2_house: number;
}

export interface IFetchPropertyEstimatedPriceRequestParams {
    region_id?: string;
    slug_district?: string;
    slug_city?: string;
    date_start: string;
    date_end: string;
    scenario: "rp-price-avg" | string;
}

export const fetchPropertyEstimatedPrice = (
    query: IFetchPropertyEstimatedPriceRequestParams,
    fetchWithDistrict = false
): Promise<IPropertyEstimatedPrice[]> => {
    const endpointLink = fetchWithDistrict ? apiBigDataLink.monthDistrictsStats.base : apiBigDataLink.monthCityStats.base;
    const url = appendQueryString(endpointLink(bigDataApiUrl), {...query});

    return getExternalRequest({}, url, 5000).catch((err) => {
        consoleError("fetchPropertyEstimatedPrice error: ", err);
        return [];
    });
};
import {consoleError} from "@pg-mono/logger";
import {appendQueryString, getExternalRequest} from "@pg-mono/request";
import {apiBigDataLink} from "@pg-mono/rp-api-routes";

import {bigDataApiUrl} from "../../app/read_rp_environment_variables";

export interface IPropertyEstimatedPrice {
    date: string;
    slug_city: string;
    city: string;
    avg_price_m2: number;
    avg_price_m2_area_to_30m2: number;
    avg_price_m2_area_between_30_and_50m2: number;
    avg_price_m2_area_between_50_and_70m2: number;
    avg_price_m2_area_between_70_and_100m2: number;
    avg_price_m2_area_between_100_and_120m2: number;
    avg_price_m2_area_above_120m2: number;
    avg_price_m2_house: number;
}

export interface IFetchPropertyEstimatedPriceRequestParams {
    region_id?: string;
    slug_district?: string;
    slug_city?: string;
    date_start: string;
    date_end: string;
    scenario: "rp-price-avg" | string;
}

export const fetchPropertyEstimatedPrice = (
    query: IFetchPropertyEstimatedPriceRequestParams,
    fetchWithDistrict = false
): Promise<IPropertyEstimatedPrice[]> => {
    const endpointLink = fetchWithDistrict ? apiBigDataLink.monthDistrictsStats.base : apiBigDataLink.monthCityStats.base;
    const url = appendQueryString(endpointLink(bigDataApiUrl), {...query});

    return getExternalRequest({}, url, 5000).catch((err) => {
        consoleError("fetchPropertyEstimatedPrice error: ", err);
        return [];
    });
};
