import {sub} from "date-fns";

import {dateTimeFormat, formatDate} from "@pg-mono/string-utils";

import {IFetchPropertyEstimatedPriceRequestParams} from "../types/IFetchPropertyEstimatedPriceRequestParams";

export function getMonthStatsPayloadDate(): Pick<IFetchPropertyEstimatedPriceRequestParams, "date_start" | "date_end"> {
    // Statistics DB hasn't full data for current month, so we need to subtract one month from current date.
    const endDate = sub(new Date(), {months: 1});

    return {
        date_start: formatDate(sub(endDate, {months: 12}).toISOString(), dateTimeFormat.dashedDateFormat),
        date_end: formatDate(endDate.toISOString(), dateTimeFormat.dashedDateFormat)
    };
}
import {sub} from "date-fns";

import {dateTimeFormat, formatDate} from "@pg-mono/string-utils";

import {IFetchPropertyEstimatedPriceRequestParams} from "../types/IFetchPropertyEstimatedPriceRequestParams";

export function getMonthStatsPayloadDate(): Pick<IFetchPropertyEstimatedPriceRequestParams, "date_start" | "date_end"> {
    // Statistics DB hasn't full data for current month, so we need to subtract one month from current date.
    const endDate = sub(new Date(), {months: 1});

    return {
        date_start: formatDate(sub(endDate, {months: 12}).toISOString(), dateTimeFormat.dashedDateFormat),
        date_end: formatDate(endDate.toISOString(), dateTimeFormat.dashedDateFormat)
    };
}
