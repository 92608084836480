import React from "react";

import {Popover} from "@pg-design/notifications";
import {Text} from "@pg-design/text-module";
import {FormikForm, useFormikUtilsField} from "@pg-mono/formik-utils";
import {useUserDevice} from "@pg-mono/user-device";

import {FinancingServicesCheckboxPopoverText} from "../../../../lead/components/FinancingServicesCheckboxPopoverText";
import {leadModalZindex} from "../../../../lead/constants/lead_modal_zindex";
import {FinancingServicesCopyTypes} from "../../../../lead/types/FinancingServicesCopyTypes";
import {getFinancingServicesCopyText} from "../../../../lead/utils/get_financing_services_copy_text";
import {IApplicationFormValues} from "../../../types/IApplicationFormValues";

interface IProps {
    name: string;
    copyType?: FinancingServicesCopyTypes;
    className?: string;
    popoverPlace?: "top" | "right" | "bottom" | "left";
}

export const FinancingServicesField = (props: IProps) => {
    const {name, copyType, popoverPlace} = props;

    const [field] = useFormikUtilsField<IApplicationFormValues["financing_services"]>("financing_services");
    const {isMobile} = useUserDevice();

    return (
        <div className={props.className}>
            <Popover
                isActive
                popoverPlace={popoverPlace ? popoverPlace : isMobile ? "top" : "right"}
                body={<FinancingServicesCheckboxPopoverText />}
                toggleClickHandler
                toggleOnClickOutside
                appendTo="parent"
                zIndex={leadModalZindex}
            >
                {(state) => (
                    <FormikForm.Checkbox
                        name={name}
                        id="financingServicesConsentField"
                        labelContent={
                            <Text as="span" variant="info_txt_1">
                                {getFinancingServicesCopyText(copyType)}
                            </Text>
                        }
                        onClick={() => {
                            if (state.isOpen && field.value) {
                                state.close();
                            }

                            if (!state.isOpen && !field.value) {
                                state.open();
                            }
                        }}
                        checkedMarkColor="#02D054"
                    />
                )}
            </Popover>
        </div>
    );
};
import React from "react";

import {Popover} from "@pg-design/notifications";
import {Text} from "@pg-design/text-module";
import {FormikForm, useFormikUtilsField} from "@pg-mono/formik-utils";
import {useUserDevice} from "@pg-mono/user-device";

import {FinancingServicesCheckboxPopoverText} from "../../../../lead/components/FinancingServicesCheckboxPopoverText";
import {leadModalZindex} from "../../../../lead/constants/lead_modal_zindex";
import {FinancingServicesCopyTypes} from "../../../../lead/types/FinancingServicesCopyTypes";
import {getFinancingServicesCopyText} from "../../../../lead/utils/get_financing_services_copy_text";
import {IApplicationFormValues} from "../../../types/IApplicationFormValues";

interface IProps {
    name: string;
    copyType?: FinancingServicesCopyTypes;
    className?: string;
    popoverPlace?: "top" | "right" | "bottom" | "left";
}

export const FinancingServicesField = (props: IProps) => {
    const {name, copyType, popoverPlace} = props;

    const [field] = useFormikUtilsField<IApplicationFormValues["financing_services"]>("financing_services");
    const {isMobile} = useUserDevice();

    return (
        <div className={props.className}>
            <Popover
                isActive
                popoverPlace={popoverPlace ? popoverPlace : isMobile ? "top" : "right"}
                body={<FinancingServicesCheckboxPopoverText />}
                toggleClickHandler
                toggleOnClickOutside
                appendTo="parent"
                zIndex={leadModalZindex}
            >
                {(state) => (
                    <FormikForm.Checkbox
                        name={name}
                        id="financingServicesConsentField"
                        labelContent={
                            <Text as="span" variant="info_txt_1">
                                {getFinancingServicesCopyText(copyType)}
                            </Text>
                        }
                        onClick={() => {
                            if (state.isOpen && field.value) {
                                state.close();
                            }

                            if (!state.isOpen && !field.value) {
                                state.open();
                            }
                        }}
                        checkedMarkColor="#02D054"
                    />
                )}
            </Popover>
        </div>
    );
};
