import {useEffect, useState} from "react";

import {Loader} from "@pg-design/loader-module";

import {IApplication} from "../../../../../../application/types/IApplication";
import {IMultiLeadStepPayload} from "../../../../../../application/types/IMultiLeadStepPayload";
import {ISetMultiLeadSummaryStepPayload} from "../../../../../../application/types/ISetMultiLeadSummaryStepPayload";
import {useGetPropertyDetailApplicationQuery} from "../../../../../../property/api/get_property_detail";
import {useLeadModalParamsRecommendationsFetch} from "../../../../../hooks/use_lead_modal_params_recommendations_fetch";
import {useOpenedLeadModalRequiredParams} from "../../../../../hooks/use_lead_modal_required_params";

interface IProps {
    sentApplication: IApplication;
    handleSetMultiLeadStep: (payload: IMultiLeadStepPayload) => void;
    handleSetMultiLeadSummary: (payload: ISetMultiLeadSummaryStepPayload) => void;
}

export function LeadModalPriceConversationChatRedirect(props: IProps) {
    const {handleSetMultiLeadSummary, handleSetMultiLeadStep, sentApplication} = props;

    const {propertyId} = useOpenedLeadModalRequiredParams("property");
    const {data: propertyDetail} = useGetPropertyDetailApplicationQuery(propertyId);

    const [timeoutFinished, setTimeoutFinished] = useState(false);
    const [distanceInKm, setDistanceInKm] = useState(2);

    const {isLoading: recommendationsLoading, recommendedProperties} = useLeadModalParamsRecommendationsFetch({
        distanceInKm
    });

    useEffect(() => {
        const timeout = setTimeout(() => {
            setTimeoutFinished(true);
        }, 3000);

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    const isLoading = recommendationsLoading || !timeoutFinished;

    useEffect(() => {
        if (!isLoading && recommendedProperties && recommendedProperties.length) {
            handleSetMultiLeadStep({
                originalApplicationResponse: sentApplication,
                recommendedOffers: recommendedProperties,
                shouldCloseOnOverlayClick: false,
                multiLeadProperty: propertyDetail,
                recommendationsWithPriceFilter: false
            });
            return;
        }

        if (!isLoading && recommendedProperties && !recommendedProperties.length && distanceInKm === 2) {
            setDistanceInKm(5);
        }

        if (!isLoading && recommendedProperties && !recommendedProperties.length && distanceInKm === 5) {
            handleSetMultiLeadSummary({
                multiLeadProperty: propertyDetail,
                multiLeadSummaryItems: []
            });

            return;
        }
    }, [recommendedProperties, distanceInKm, isLoading, recommendedProperties]);

    if (isLoading) {
        return <Loader />;
    }

    return null;
}
import {useEffect, useState} from "react";

import {Loader} from "@pg-design/loader-module";

import {IApplication} from "../../../../../../application/types/IApplication";
import {IMultiLeadStepPayload} from "../../../../../../application/types/IMultiLeadStepPayload";
import {ISetMultiLeadSummaryStepPayload} from "../../../../../../application/types/ISetMultiLeadSummaryStepPayload";
import {useGetPropertyDetailApplicationQuery} from "../../../../../../property/api/get_property_detail";
import {useLeadModalParamsRecommendationsFetch} from "../../../../../hooks/use_lead_modal_params_recommendations_fetch";
import {useOpenedLeadModalRequiredParams} from "../../../../../hooks/use_lead_modal_required_params";

interface IProps {
    sentApplication: IApplication;
    handleSetMultiLeadStep: (payload: IMultiLeadStepPayload) => void;
    handleSetMultiLeadSummary: (payload: ISetMultiLeadSummaryStepPayload) => void;
}

export function LeadModalPriceConversationChatRedirect(props: IProps) {
    const {handleSetMultiLeadSummary, handleSetMultiLeadStep, sentApplication} = props;

    const {propertyId} = useOpenedLeadModalRequiredParams("property");
    const {data: propertyDetail} = useGetPropertyDetailApplicationQuery(propertyId);

    const [timeoutFinished, setTimeoutFinished] = useState(false);
    const [distanceInKm, setDistanceInKm] = useState(2);

    const {isLoading: recommendationsLoading, recommendedProperties} = useLeadModalParamsRecommendationsFetch({
        distanceInKm
    });

    useEffect(() => {
        const timeout = setTimeout(() => {
            setTimeoutFinished(true);
        }, 3000);

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    const isLoading = recommendationsLoading || !timeoutFinished;

    useEffect(() => {
        if (!isLoading && recommendedProperties && recommendedProperties.length) {
            handleSetMultiLeadStep({
                originalApplicationResponse: sentApplication,
                recommendedOffers: recommendedProperties,
                shouldCloseOnOverlayClick: false,
                multiLeadProperty: propertyDetail,
                recommendationsWithPriceFilter: false
            });
            return;
        }

        if (!isLoading && recommendedProperties && !recommendedProperties.length && distanceInKm === 2) {
            setDistanceInKm(5);
        }

        if (!isLoading && recommendedProperties && !recommendedProperties.length && distanceInKm === 5) {
            handleSetMultiLeadSummary({
                multiLeadProperty: propertyDetail,
                multiLeadSummaryItems: []
            });

            return;
        }
    }, [recommendedProperties, distanceInKm, isLoading, recommendedProperties]);

    if (isLoading) {
        return <Loader />;
    }

    return null;
}
