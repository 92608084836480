export const sortBy = <T extends object>(baseArray: T[], key: keyof T): T[] => {
    const sortFn = (key: keyof T) => {
        return (objectA: T, objectB: T) => (objectA[key] > objectB[key] ? 1 : objectB[key] > objectA[key] ? -1 : 0);
    };

    return baseArray.concat().sort(sortFn(key));
};
export const sortBy = <T extends object>(baseArray: T[], key: keyof T): T[] => {
    const sortFn = (key: keyof T) => {
        return (objectA: T, objectB: T) => (objectA[key] > objectB[key] ? 1 : objectB[key] > objectA[key] ? -1 : 0);
    };

    return baseArray.concat().sort(sortFn(key));
};
