import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {DsTooltip} from "@pg-design/floating-module";
import {calculateRemSize} from "@pg-design/helpers-css";
import {borderRadius, onDesktop} from "@pg-design/helpers-css/";
import {InfoIcon} from "@pg-design/icons-module";
import {Image} from "@pg-design/image-module";
import {getNumberFormat} from "@pg-design/inputs-module";
import {Text} from "@pg-design/text-module";
import {useUserDevice} from "@pg-mono/user-device";

import {OfferType} from "../../../../../offer/types/OfferType";
import {useGetPropertyDetailQuery} from "../../../../../property/api/get_property_detail";
import {IPropertyDetail} from "../../../../../property/types/IPropertyDetail";
import {leadModalZindex} from "../../../../constants/lead_modal_zindex";
import {useOpenedLeadModalRequiredParams} from "../../../../hooks/use_lead_modal_required_params";
import {ILeadModalPriceConversationPrice} from "../../../../types/ILeadModalPriceConversationPrice";

import * as cns from "./LeadModalPriceConversationPropertyInfo.module.css";

interface IProps {
    estimatedPrice: ILeadModalPriceConversationPrice | null;
}

export function LeadModalPriceConversationPropertyInfo(props: IProps) {
    const {estimatedPrice} = props;

    const {propertyId} = useOpenedLeadModalRequiredParams("property");
    const {data: propertyDetail} = useGetPropertyDetailQuery(propertyId);
    const {isMobile} = useUserDevice();

    if (!propertyDetail) {
        return null;
    }

    const priceRevealed = isPriceRevealed(estimatedPrice);
    const price = getPrice(estimatedPrice);

    return (
        <div css={propertyInfo}>
            <div css={planColumn}>
                <Image
                    width="760px"
                    height="428px"
                    alt="plan"
                    css={planImage}
                    imageClassName={cns.leadModalPropertyPlanImage}
                    src={propertyDetail.external_plan_url || propertyDetail.plan_image?.p_img_760x428 || undefined}
                />
            </div>
            <div css={priceColumn}>
                <div css={priceInfo}>
                    <Text variant="body_copy_2" as="span" css={priceTitle}>
                        {priceRevealed && (
                            <DsTooltip
                                popover="Prezentowana cena mieszkania to średnia wartość dla wybranej lokalizacji z uwzględnieniem metrażu, liczby pokoi oraz specyfiki mieszkania. Dokładną cenę tej konkretnej nieruchomości otrzymasz od dewelopera."
                                type="success"
                                zIndex={leadModalZindex}
                            >
                                {({refs, getReferenceProps}) => (
                                    <InfoIcon size="2" wrapperSize="2" wrapperColor="transparent" ref={refs.setReference} {...getReferenceProps()} />
                                )}
                            </DsTooltip>
                        )}
                        {getPriceTitle(propertyDetail.offer.type, estimatedPrice)}
                    </Text>
                    <Price variant={isMobile ? "headline_6" : "headline_2"} as="span" isPriceRevealed={priceRevealed} isMobile={Boolean(isMobile)}>
                        {price}
                    </Price>

                    <div css={priceInfoVendor}>
                        <Image
                            width="80px"
                            height="60px"
                            alt={propertyDetail.offer.vendor.name}
                            src={propertyDetail.offer.vendor.logo?.v_log_80x60 || undefined}
                            css={vendorLogo}
                            placeholderIconClasName={cns.leadModalPropertyVendorPlaceholder}
                            imageClassName={cns.leadModalPropertyVendorImage}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
// Utils
function getPriceTitle(offerType: IPropertyDetail["offer"]["type"], price: IProps["estimatedPrice"]) {
    const priceRevealed = isPriceRevealed(price);
    if (offerType === OfferType.HOUSE && priceRevealed) {
        return "Estymowana cena domu:";
    }
    if (offerType === OfferType.HOUSE && !priceRevealed) {
        return "Cena domu:";
    }

    if (priceRevealed) {
        return "Estymowana cena mieszkania:";
    }

    return "Cena mieszkania:";
}

function getPrice(price: IProps["estimatedPrice"]) {
    const priceRevealed = isPriceRevealed(price);
    if (!priceRevealed || !price) {
        return <span css={priceNumber}>250 000 zł</span>;
    }

    return (
        <>
            <span css={priceNumber}>{getNumberFormat(price.min, true)}</span> - <span css={priceNumber}>{getNumberFormat(price.max, true)} zł</span>
        </>
    );
}

function isPriceRevealed(price: IProps["estimatedPrice"]) {
    return price !== null;
}

// Styles
const propertyInfo = css`
    display: flex;
    gap: ${calculateRemSize(1)};
    padding: ${calculateRemSize(2)} ${calculateRemSize(1)};

    ${onDesktop(css`
        flex: 0 0 437px;
        flex-direction: column;
        padding: ${calculateRemSize(3)};
        gap: ${calculateRemSize(4)};
    `)}
`;

const planImage = css`
    height: auto;
    width: 100%;
    aspect-ratio: 162 / 129;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const planColumn = css`
    flex: 1 1 162px;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    ${onDesktop(css`
        flex: 0 1 320px;
    `)}
`;

const priceColumn = css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1 auto;
`;

const priceTitle = css`
    display: flex;
    gap: ${calculateRemSize(0.5)};
`;

const priceInfo = css`
    display: flex;
    flex-direction: column;
    gap: ${calculateRemSize(2)};
    align-items: center;
`;

const priceNumber = css`
    white-space: nowrap;
`;

const priceInfoVendor = css`
    flex: 0 1 32px;

    @media (min-width: 1024px) {
        flex: 0 1 60px;
    }
`;

const Price = styled(Text)<{isPriceRevealed: boolean; isMobile: boolean}>`
    ${({theme, isPriceRevealed, isMobile}) => css`
        text-align: center;
        background: ${theme.colors.success};
        padding: ${calculateRemSize(0.5)} ${calculateRemSize(1)};
        ${borderRadius(2)};
        user-select: none;

        ${!isPriceRevealed &&
        css`
            filter: blur(7px);
        `}

        ${isMobile &&
        css`
            font-weight: 700;
        `}
    `}
`;

const vendorLogo = css`
    width: 40px;
    height: 30px;

    ${onDesktop(css`
        width: 80px;
        height: 60px;
    `)}
`;
import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {DsTooltip} from "@pg-design/floating-module";
import {calculateRemSize} from "@pg-design/helpers-css";
import {borderRadius, onDesktop} from "@pg-design/helpers-css/";
import {InfoIcon} from "@pg-design/icons-module";
import {Image} from "@pg-design/image-module";
import {getNumberFormat} from "@pg-design/inputs-module";
import {Text} from "@pg-design/text-module";
import {useUserDevice} from "@pg-mono/user-device";

import {OfferType} from "../../../../../offer/types/OfferType";
import {useGetPropertyDetailQuery} from "../../../../../property/api/get_property_detail";
import {IPropertyDetail} from "../../../../../property/types/IPropertyDetail";
import {leadModalZindex} from "../../../../constants/lead_modal_zindex";
import {useOpenedLeadModalRequiredParams} from "../../../../hooks/use_lead_modal_required_params";
import {ILeadModalPriceConversationPrice} from "../../../../types/ILeadModalPriceConversationPrice";

import * as cns from "./LeadModalPriceConversationPropertyInfo.module.css";

interface IProps {
    estimatedPrice: ILeadModalPriceConversationPrice | null;
}

export function LeadModalPriceConversationPropertyInfo(props: IProps) {
    const {estimatedPrice} = props;

    const {propertyId} = useOpenedLeadModalRequiredParams("property");
    const {data: propertyDetail} = useGetPropertyDetailQuery(propertyId);
    const {isMobile} = useUserDevice();

    if (!propertyDetail) {
        return null;
    }

    const priceRevealed = isPriceRevealed(estimatedPrice);
    const price = getPrice(estimatedPrice);

    return (
        <div css={propertyInfo}>
            <div css={planColumn}>
                <Image
                    width="760px"
                    height="428px"
                    alt="plan"
                    css={planImage}
                    imageClassName={cns.leadModalPropertyPlanImage}
                    src={propertyDetail.external_plan_url || propertyDetail.plan_image?.p_img_760x428 || undefined}
                />
            </div>
            <div css={priceColumn}>
                <div css={priceInfo}>
                    <Text variant="body_copy_2" as="span" css={priceTitle}>
                        {priceRevealed && (
                            <DsTooltip
                                popover="Prezentowana cena mieszkania to średnia wartość dla wybranej lokalizacji z uwzględnieniem metrażu, liczby pokoi oraz specyfiki mieszkania. Dokładną cenę tej konkretnej nieruchomości otrzymasz od dewelopera."
                                type="success"
                                zIndex={leadModalZindex}
                            >
                                {({refs, getReferenceProps}) => (
                                    <InfoIcon size="2" wrapperSize="2" wrapperColor="transparent" ref={refs.setReference} {...getReferenceProps()} />
                                )}
                            </DsTooltip>
                        )}
                        {getPriceTitle(propertyDetail.offer.type, estimatedPrice)}
                    </Text>
                    <Price variant={isMobile ? "headline_6" : "headline_2"} as="span" isPriceRevealed={priceRevealed} isMobile={Boolean(isMobile)}>
                        {price}
                    </Price>

                    <div css={priceInfoVendor}>
                        <Image
                            width="80px"
                            height="60px"
                            alt={propertyDetail.offer.vendor.name}
                            src={propertyDetail.offer.vendor.logo?.v_log_80x60 || undefined}
                            css={vendorLogo}
                            placeholderIconClasName={cns.leadModalPropertyVendorPlaceholder}
                            imageClassName={cns.leadModalPropertyVendorImage}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
// Utils
function getPriceTitle(offerType: IPropertyDetail["offer"]["type"], price: IProps["estimatedPrice"]) {
    const priceRevealed = isPriceRevealed(price);
    if (offerType === OfferType.HOUSE && priceRevealed) {
        return "Estymowana cena domu:";
    }
    if (offerType === OfferType.HOUSE && !priceRevealed) {
        return "Cena domu:";
    }

    if (priceRevealed) {
        return "Estymowana cena mieszkania:";
    }

    return "Cena mieszkania:";
}

function getPrice(price: IProps["estimatedPrice"]) {
    const priceRevealed = isPriceRevealed(price);
    if (!priceRevealed || !price) {
        return <span css={priceNumber}>250 000 zł</span>;
    }

    return (
        <>
            <span css={priceNumber}>{getNumberFormat(price.min, true)}</span> - <span css={priceNumber}>{getNumberFormat(price.max, true)} zł</span>
        </>
    );
}

function isPriceRevealed(price: IProps["estimatedPrice"]) {
    return price !== null;
}

// Styles
const propertyInfo = css`
    display: flex;
    gap: ${calculateRemSize(1)};
    padding: ${calculateRemSize(2)} ${calculateRemSize(1)};

    ${onDesktop(css`
        flex: 0 0 437px;
        flex-direction: column;
        padding: ${calculateRemSize(3)};
        gap: ${calculateRemSize(4)};
    `)}
`;

const planImage = css`
    height: auto;
    width: 100%;
    aspect-ratio: 162 / 129;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const planColumn = css`
    flex: 1 1 162px;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    ${onDesktop(css`
        flex: 0 1 320px;
    `)}
`;

const priceColumn = css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1 auto;
`;

const priceTitle = css`
    display: flex;
    gap: ${calculateRemSize(0.5)};
`;

const priceInfo = css`
    display: flex;
    flex-direction: column;
    gap: ${calculateRemSize(2)};
    align-items: center;
`;

const priceNumber = css`
    white-space: nowrap;
`;

const priceInfoVendor = css`
    flex: 0 1 32px;

    @media (min-width: 1024px) {
        flex: 0 1 60px;
    }
`;

const Price = styled(Text)<{isPriceRevealed: boolean; isMobile: boolean}>`
    ${({theme, isPriceRevealed, isMobile}) => css`
        text-align: center;
        background: ${theme.colors.success};
        padding: ${calculateRemSize(0.5)} ${calculateRemSize(1)};
        ${borderRadius(2)};
        user-select: none;

        ${!isPriceRevealed &&
        css`
            filter: blur(7px);
        `}

        ${isMobile &&
        css`
            font-weight: 700;
        `}
    `}
`;

const vendorLogo = css`
    width: 40px;
    height: 30px;

    ${onDesktop(css`
        width: 80px;
        height: 60px;
    `)}
`;
