import {getApplicationSourceDisplay} from "../../application/utils/ApplicationSource";
import {ILeadModalLeadType} from "../../lead/types/ILeadModalLeadType";
import {IOfferDetail} from "../../offer/types/IOfferDetail";
import {Country} from "../../region/types/Country";
import {getCountryName} from "../../region/utils/get_country_name";
import {getViewTypeName, ViewType} from "../../view_type/ViewType";
import {IGtmApplication} from "../types/IGtmApplication";
import {IGtmProduct} from "./common_interfaces/IGtmProduct";
import {calculateGtmDimensions, IGtmOffer, IGtmProperty, IGtmRegion, IGtmVendor} from "./calculate_gtm_dimensions";
import {hitGoogleTagManager} from "./init_google_tag_manager";

interface IGtmInquiry {
    event: "inquiry";
    event_category: "conversation_form" | "standard";
    ecommerce: {
        purchase: {
            actionField: {
                affiliation: string;
                id: string;
                revenue: "1.00";
                shipping: "0.00";
                tax: "0.00";
                recommended?: boolean;
            };
            products: [IGtmProduct];
        };
    };
}

export function gtmInquiry(
    viewType: ViewType | null,
    application: IGtmApplication,
    leadType: ILeadModalLeadType,
    vendor?: IGtmVendor,
    region?: IGtmRegion,
    offer?: IGtmOffer & {
        status: IOfferDetail["status"];
    },
    property?: IGtmProperty,
    recommendedApplication?: boolean,
    affiliation?: string,
    customSourceSection?: string,
    country?: Country
): boolean {
    // calculate product

    const product: IGtmProduct = {
        ...calculateGtmDimensions(
            application.financing_services ? "tak" : "nie",
            region,
            offer,
            property,
            application.source_section,
            customSourceSection,
            application.new_property_notification_consent ? "tak" : "nie"
        ),
        name: (property && property.number) || "na",
        id: property ? property.id.toString() : "na",
        home_listing_id: offer ? (property ? `${offer.id}-${property.id}` : `${offer.id}-0`) : "na",
        group_id: offer ? `${offer.id}-0` : "na",
        price: "1.00",
        brand: vendor ? `${vendor.id}/${vendor.name}` : "na",
        category: offer ? `${offer.id}/${offer.name}` : "na",
        variant: viewType ? getViewTypeName(viewType) : "na",
        quantity: 1,
        custom_country: getCountryName(country)
    };
    // prepare gtm data and hit
    const gtmData: IGtmInquiry = {
        event: "inquiry",
        event_category: leadType === "conversation" ? "conversation_form" : "standard",
        ecommerce: {
            purchase: {
                actionField: {
                    affiliation: affiliation || getApplicationSourceDisplay(application.source),
                    id: application.uuid,
                    revenue: "1.00",
                    shipping: "0.00",
                    tax: "0.00",
                    recommended: !!recommendedApplication
                },
                products: [product]
            }
        }
    };

    hitGoogleTagManager(gtmData);
    return true;
}
import {getApplicationSourceDisplay} from "../../application/utils/ApplicationSource";
import {ILeadModalLeadType} from "../../lead/types/ILeadModalLeadType";
import {IOfferDetail} from "../../offer/types/IOfferDetail";
import {Country} from "../../region/types/Country";
import {getCountryName} from "../../region/utils/get_country_name";
import {getViewTypeName, ViewType} from "../../view_type/ViewType";
import {IGtmApplication} from "../types/IGtmApplication";
import {IGtmProduct} from "./common_interfaces/IGtmProduct";
import {calculateGtmDimensions, IGtmOffer, IGtmProperty, IGtmRegion, IGtmVendor} from "./calculate_gtm_dimensions";
import {hitGoogleTagManager} from "./init_google_tag_manager";

interface IGtmInquiry {
    event: "inquiry";
    event_category: "conversation_form" | "standard";
    ecommerce: {
        purchase: {
            actionField: {
                affiliation: string;
                id: string;
                revenue: "1.00";
                shipping: "0.00";
                tax: "0.00";
                recommended?: boolean;
            };
            products: [IGtmProduct];
        };
    };
}

export function gtmInquiry(
    viewType: ViewType | null,
    application: IGtmApplication,
    leadType: ILeadModalLeadType,
    vendor?: IGtmVendor,
    region?: IGtmRegion,
    offer?: IGtmOffer & {
        status: IOfferDetail["status"];
    },
    property?: IGtmProperty,
    recommendedApplication?: boolean,
    affiliation?: string,
    customSourceSection?: string,
    country?: Country
): boolean {
    // calculate product

    const product: IGtmProduct = {
        ...calculateGtmDimensions(
            application.financing_services ? "tak" : "nie",
            region,
            offer,
            property,
            application.source_section,
            customSourceSection,
            application.new_property_notification_consent ? "tak" : "nie"
        ),
        name: (property && property.number) || "na",
        id: property ? property.id.toString() : "na",
        home_listing_id: offer ? (property ? `${offer.id}-${property.id}` : `${offer.id}-0`) : "na",
        group_id: offer ? `${offer.id}-0` : "na",
        price: "1.00",
        brand: vendor ? `${vendor.id}/${vendor.name}` : "na",
        category: offer ? `${offer.id}/${offer.name}` : "na",
        variant: viewType ? getViewTypeName(viewType) : "na",
        quantity: 1,
        custom_country: getCountryName(country)
    };
    // prepare gtm data and hit
    const gtmData: IGtmInquiry = {
        event: "inquiry",
        event_category: leadType === "conversation" ? "conversation_form" : "standard",
        ecommerce: {
            purchase: {
                actionField: {
                    affiliation: affiliation || getApplicationSourceDisplay(application.source),
                    id: application.uuid,
                    revenue: "1.00",
                    shipping: "0.00",
                    tax: "0.00",
                    recommended: !!recommendedApplication
                },
                products: [product]
            }
        }
    };

    hitGoogleTagManager(gtmData);
    return true;
}
