import {appendQueryString, getRequest} from "@pg-mono/request";
import {apiLink} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";

export interface IMarketplaceArchitectOfferSummary {
    city: string;
    cover_photo: string;
    id: number;
    logo: string;
    min_price_m2: string;
    name: string;
    slug: string;
    package_count: number;
    offer: {
        has_guaranteed_price: boolean;
        has_guaranteed_deadline: boolean;
        has_insurance: boolean;
        warranty_duration: number;
        show_architect_supervision: boolean;
        show_cleaning_services: boolean;
        show_construction_crew: boolean;
        show_construction_supervision: boolean;
        show_custom_made_furniture: boolean;
        show_project_visualisation: boolean;
        show_work_acceptance_support: boolean;
    };
}

type IMarketplaceArchitectModalResponse = IMarketplaceArchitectOfferSummary[];

export const fetchMarketplaceArchitectPromotedOffersByRegion = async (meta: IRPRequestMeta, regionId: number): Promise<IMarketplaceArchitectOfferSummary[]> => {
    const url = appendQueryString(apiLink.marketplace.architect.promotions(), {
        region_remote_id: regionId,
        promoted: true
    });

    return getRequest(meta, url)
        .then(async (response: IMarketplaceArchitectModalResponse) => {
            return response || [];
        })
        .catch(() => {
            return [];
        });
};
import {appendQueryString, getRequest} from "@pg-mono/request";
import {apiLink} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";

export interface IMarketplaceArchitectOfferSummary {
    city: string;
    cover_photo: string;
    id: number;
    logo: string;
    min_price_m2: string;
    name: string;
    slug: string;
    package_count: number;
    offer: {
        has_guaranteed_price: boolean;
        has_guaranteed_deadline: boolean;
        has_insurance: boolean;
        warranty_duration: number;
        show_architect_supervision: boolean;
        show_cleaning_services: boolean;
        show_construction_crew: boolean;
        show_construction_supervision: boolean;
        show_custom_made_furniture: boolean;
        show_project_visualisation: boolean;
        show_work_acceptance_support: boolean;
    };
}

type IMarketplaceArchitectModalResponse = IMarketplaceArchitectOfferSummary[];

export const fetchMarketplaceArchitectPromotedOffersByRegion = async (meta: IRPRequestMeta, regionId: number): Promise<IMarketplaceArchitectOfferSummary[]> => {
    const url = appendQueryString(apiLink.marketplace.architect.promotions(), {
        region_remote_id: regionId,
        promoted: true
    });

    return getRequest(meta, url)
        .then(async (response: IMarketplaceArchitectModalResponse) => {
            return response || [];
        })
        .catch(() => {
            return [];
        });
};
