import {hitGoogleTagManager} from "./init_google_tag_manager";

interface GAEventApplicationRegister {
    event: "GAEvent";
    GAEventCategory: "ui";
    GAEventAction: "rejestracja";
    GAEventLabel: "formularz leadowy";
    GAEventNoInteraction: true;
}

export const gtmEventApplicationRegister = () => {
    const gtmData: GAEventApplicationRegister = {
        event: "GAEvent",
        GAEventCategory: "ui",
        GAEventAction: "rejestracja",
        GAEventLabel: "formularz leadowy",
        GAEventNoInteraction: true
    };
    hitGoogleTagManager(gtmData);
};
import {hitGoogleTagManager} from "./init_google_tag_manager";

interface GAEventApplicationRegister {
    event: "GAEvent";
    GAEventCategory: "ui";
    GAEventAction: "rejestracja";
    GAEventLabel: "formularz leadowy";
    GAEventNoInteraction: true;
}

export const gtmEventApplicationRegister = () => {
    const gtmData: GAEventApplicationRegister = {
        event: "GAEvent",
        GAEventCategory: "ui",
        GAEventAction: "rejestracja",
        GAEventLabel: "formularz leadowy",
        GAEventNoInteraction: true
    };
    hitGoogleTagManager(gtmData);
};
