import {compact} from "@pg-mono/nodash";

import {IHitApplicationData} from "../../lead/types/IHitApplicationData";
import {FacebookPixel} from "./track_facebook_pixel";

export const facebookPixelPurchase = (data?: IHitApplicationData) => {
    if (data) {
        const {offer, property} = data;

        const offerId = offer?.detail ? `${offer.detail.id.toString()}-0` : "";
        const propertyId = offer?.detail && property?.detail ? `${offer.detail.id.toString()}-${property.detail.id.toString()}` : "";

        FacebookPixel.track("Purchase", {
            value: 1,
            currency: "PLN",
            content_type: "home_listing",
            content_ids: compact([offerId, propertyId]),
            contents: [
                {
                    id: propertyId || offerId || "na",
                    quantity: 1,
                    price: 1
                }
            ],
            city: offer?.detail?.region?.stats?.region_type_city?.name ?? "na",
            region: offer?.detail?.region?.stats?.region_type_voivodeship?.name ?? "na",
            country: "Poland"
        });
    }
};
import {compact} from "@pg-mono/nodash";

import {IHitApplicationData} from "../../lead/types/IHitApplicationData";
import {FacebookPixel} from "./track_facebook_pixel";

export const facebookPixelPurchase = (data?: IHitApplicationData) => {
    if (data) {
        const {offer, property} = data;

        const offerId = offer?.detail ? `${offer.detail.id.toString()}-0` : "";
        const propertyId = offer?.detail && property?.detail ? `${offer.detail.id.toString()}-${property.detail.id.toString()}` : "";

        FacebookPixel.track("Purchase", {
            value: 1,
            currency: "PLN",
            content_type: "home_listing",
            content_ids: compact([offerId, propertyId]),
            contents: [
                {
                    id: propertyId || offerId || "na",
                    quantity: 1,
                    price: 1
                }
            ],
            city: offer?.detail?.region?.stats?.region_type_city?.name ?? "na",
            region: offer?.detail?.region?.stats?.region_type_voivodeship?.name ?? "na",
            country: "Poland"
        });
    }
};
