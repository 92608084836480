import {useMemo} from "react";
import {useSelector} from "react-redux";

import {IRPStore} from "../../app/rp_reducer";
import {fetchLastSeenPropertiesDetailsTypes} from "../actions/fetch_last_seen_properties_details";
import {LastSeenOfferPropertiesOrderType} from "../utils/user_last_seen_offer_properties";
import {useFetchPropertiesDetails} from "./use_fetch_properties_details";
import {useUserLastSeenProperties} from "./use_user_last_seen_properties";

interface IProps {
    excludeOfferIds?: number[];
    limit?: number;
}

export const useLastSeenPropertiesDetails = (props: IProps = {}) => {
    const currentPropertyOfferId = useSelector((state: IRPStore) => state.property.property?.offer.id);
    const offersToExclude = [currentPropertyOfferId, ...(props?.excludeOfferIds || [])].filter(Boolean) as number[];
    const {lastSeenPropertyIds} = useUserLastSeenProperties(offersToExclude, LastSeenOfferPropertiesOrderType.VARIOUS_OFFER_ONLY);
    const {propertiesDetails, isLoading} = useFetchPropertiesDetails(lastSeenPropertyIds, fetchLastSeenPropertiesDetailsTypes);
    const propertiesCount = useMemo(() => Object.values(propertiesDetails).length, [propertiesDetails]);
    const propertyDetailList = useMemo(() => {
        if (isLoading) {
            return [];
        }

        return lastSeenPropertyIds
            .map((id) => propertiesDetails[id])
            .filter(Boolean)
            .slice(0, props.limit || lastSeenPropertyIds.length);
    }, [lastSeenPropertyIds, propertiesDetails, isLoading]);

    return {
        propertyDetailList,
        propertiesDetails,
        propertiesCount,
        isLoading
    };
};
import {useMemo} from "react";
import {useSelector} from "react-redux";

import {IRPStore} from "../../app/rp_reducer";
import {fetchLastSeenPropertiesDetailsTypes} from "../actions/fetch_last_seen_properties_details";
import {LastSeenOfferPropertiesOrderType} from "../utils/user_last_seen_offer_properties";
import {useFetchPropertiesDetails} from "./use_fetch_properties_details";
import {useUserLastSeenProperties} from "./use_user_last_seen_properties";

interface IProps {
    excludeOfferIds?: number[];
    limit?: number;
}

export const useLastSeenPropertiesDetails = (props: IProps = {}) => {
    const currentPropertyOfferId = useSelector((state: IRPStore) => state.property.property?.offer.id);
    const offersToExclude = [currentPropertyOfferId, ...(props?.excludeOfferIds || [])].filter(Boolean) as number[];
    const {lastSeenPropertyIds} = useUserLastSeenProperties(offersToExclude, LastSeenOfferPropertiesOrderType.VARIOUS_OFFER_ONLY);
    const {propertiesDetails, isLoading} = useFetchPropertiesDetails(lastSeenPropertyIds, fetchLastSeenPropertiesDetailsTypes);
    const propertiesCount = useMemo(() => Object.values(propertiesDetails).length, [propertiesDetails]);
    const propertyDetailList = useMemo(() => {
        if (isLoading) {
            return [];
        }

        return lastSeenPropertyIds
            .map((id) => propertiesDetails[id])
            .filter(Boolean)
            .slice(0, props.limit || lastSeenPropertyIds.length);
    }, [lastSeenPropertyIds, propertiesDetails, isLoading]);

    return {
        propertyDetailList,
        propertiesDetails,
        propertiesCount,
        isLoading
    };
};
