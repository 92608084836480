import {hitGoogleTagManager} from "../../tracking/google_tag_manager/init_google_tag_manager";

export enum PMModalGTMEventAction {
    VIEW = "view",
    CALL = "call"
}

const PMModalEvent = (action: PMModalGTMEventAction) => {
    hitGoogleTagManager({event: "platforma_modal", platforma_action: action});
};

export const PMModalAnalytics = {
    gtm: {
        PMModalEvent
    }
};
import {hitGoogleTagManager} from "../../tracking/google_tag_manager/init_google_tag_manager";

export enum PMModalGTMEventAction {
    VIEW = "view",
    CALL = "call"
}

const PMModalEvent = (action: PMModalGTMEventAction) => {
    hitGoogleTagManager({event: "platforma_modal", platforma_action: action});
};

export const PMModalAnalytics = {
    gtm: {
        PMModalEvent
    }
};
