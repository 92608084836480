import {Country} from "../../region/types/Country";

export function isAbroad(originalApplicationCountry: Country | string | undefined) {
    return Boolean(originalApplicationCountry) && originalApplicationCountry != Country.POLAND;
}
import {Country} from "../../region/types/Country";

export function isAbroad(originalApplicationCountry: Country | string | undefined) {
    return Boolean(originalApplicationCountry) && originalApplicationCountry != Country.POLAND;
}
