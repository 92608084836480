import React, {useEffect} from "react";
import {css, useTheme} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {flexAlignCenter, flexDirection, mb, mt, onDesktop, p} from "@pg-design/helpers-css";
import {BrandCheckboxCheckedIcon} from "@pg-design/icons-module";
import {Text} from "@pg-design/text-module";

import {architectModalAnalytics, ArchitectModalGTMEventAction} from "../../architect_application/tracking/architect_modal_tracking";

interface IProps {
    architectOfferDetails: {name?: string} | null;
    onCloseButtonClick: () => void;
}

export const AppliedArchitectInteriorDesign = (props: IProps) => {
    const theme = useTheme();

    useEffect(() => {
        return () => {
            architectModalAnalytics.gtm.architectModalEvent(ArchitectModalGTMEventAction.MODAL_CLOSE);
        };
    }, []);

    return (
        <div css={[flexAlignCenter, flexDirection("column"), p(3)]}>
            <BrandCheckboxCheckedIcon size="9.6" wrapperColor={theme.colors.primary} wrapperSize="9.6" />

            <Text align="center" css={mt(2)} mb="1.6rem" variant="headline_3">
                Twoje zapytanie zostało wysłane.
            </Text>

            <Text align="center" css={[mb(2)]}>
                Oczekuj na kontakt od: <b>{props.architectOfferDetails?.name}</b>
            </Text>

            <Button variant="filled_primary" onClick={props.onCloseButtonClick} css={closeButtonStyle}>
                Zamknij
            </Button>
        </div>
    );
};

const closeButtonStyle = css`
    width: 100%;

    ${onDesktop(css`
        width: 20rem;
    `)};
`;
import React, {useEffect} from "react";
import {css, useTheme} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {flexAlignCenter, flexDirection, mb, mt, onDesktop, p} from "@pg-design/helpers-css";
import {BrandCheckboxCheckedIcon} from "@pg-design/icons-module";
import {Text} from "@pg-design/text-module";

import {architectModalAnalytics, ArchitectModalGTMEventAction} from "../../architect_application/tracking/architect_modal_tracking";

interface IProps {
    architectOfferDetails: {name?: string} | null;
    onCloseButtonClick: () => void;
}

export const AppliedArchitectInteriorDesign = (props: IProps) => {
    const theme = useTheme();

    useEffect(() => {
        return () => {
            architectModalAnalytics.gtm.architectModalEvent(ArchitectModalGTMEventAction.MODAL_CLOSE);
        };
    }, []);

    return (
        <div css={[flexAlignCenter, flexDirection("column"), p(3)]}>
            <BrandCheckboxCheckedIcon size="9.6" wrapperColor={theme.colors.primary} wrapperSize="9.6" />

            <Text align="center" css={mt(2)} mb="1.6rem" variant="headline_3">
                Twoje zapytanie zostało wysłane.
            </Text>

            <Text align="center" css={[mb(2)]}>
                Oczekuj na kontakt od: <b>{props.architectOfferDetails?.name}</b>
            </Text>

            <Button variant="filled_primary" onClick={props.onCloseButtonClick} css={closeButtonStyle}>
                Zamknij
            </Button>
        </div>
    );
};

const closeButtonStyle = css`
    width: 100%;

    ${onDesktop(css`
        width: 20rem;
    `)};
`;
