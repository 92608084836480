import {IApplication} from "../types/IApplication";

export const shouldApplicationPriceBeenShown = (application?: IApplication | null) => {
    return Boolean(application?.price_revealed || application?.force_show_price || application?.show_price);
};
import {IApplication} from "../types/IApplication";

export const shouldApplicationPriceBeenShown = (application?: IApplication | null) => {
    return Boolean(application?.price_revealed || application?.force_show_price || application?.show_price);
};
