import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const CalendarClearCheckIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M 8.466958 2.067162 L 7.399958 2.067162 L 7.399958 1.000163 L 6.866958 1.000163 L 6.866958 2.067162 L 3.132958 2.067162 L 3.132958 1.000163 L 2.599958 1.000163 L 2.599958 2.067162 L 0.999958 2.067162 L 0.999958 9.000167 L 8.999958 9.000167 L 8.999958 2.067162 L 8.466958 2.067162 Z M 8.466958 8.467167 L 1.532958 8.467167 L 1.532958 2.600163 L 2.599958 2.600163 L 2.599958 3.667162 L 3.132958 3.667162 L 3.132958 2.600163 L 6.866958 2.600163 L 6.866958 3.667162 L 7.399958 3.667162 L 7.399958 2.600163 L 8.466958 2.600163 L 8.466958 8.467167 Z" />
            <path d="M 4.466959 6.756208 L 3.334966 5.625208 L 2.957967 6.002209 L 4.089967 7.133208 L 4.466959 7.511208 L 4.843958 7.133208 L 7.105958 4.871208 L 6.728958 4.494208 L 4.466959 6.756208 Z" />
        </SvgIcon>
    );
};
import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const CalendarClearCheckIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M 8.466958 2.067162 L 7.399958 2.067162 L 7.399958 1.000163 L 6.866958 1.000163 L 6.866958 2.067162 L 3.132958 2.067162 L 3.132958 1.000163 L 2.599958 1.000163 L 2.599958 2.067162 L 0.999958 2.067162 L 0.999958 9.000167 L 8.999958 9.000167 L 8.999958 2.067162 L 8.466958 2.067162 Z M 8.466958 8.467167 L 1.532958 8.467167 L 1.532958 2.600163 L 2.599958 2.600163 L 2.599958 3.667162 L 3.132958 3.667162 L 3.132958 2.600163 L 6.866958 2.600163 L 6.866958 3.667162 L 7.399958 3.667162 L 7.399958 2.600163 L 8.466958 2.600163 L 8.466958 8.467167 Z" />
            <path d="M 4.466959 6.756208 L 3.334966 5.625208 L 2.957967 6.002209 L 4.089967 7.133208 L 4.466959 7.511208 L 4.843958 7.133208 L 7.105958 4.871208 L 6.728958 4.494208 L 4.466959 6.756208 Z" />
        </SvgIcon>
    );
};
