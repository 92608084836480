import {toJsonValues} from "../../../form/translate/to_json_values";
import {createMultipleApplications} from "../../../lead/api/create_multiple_applications";
import {ICreateMultipleLeadsPayload} from "../../../lead/types/ICreateMultipleLeadsPayload";
import {addSuccessNotification} from "../../../notifications/utils/add_success_notification";
import {addAppliedOffers} from "../../../offer/map/actions/add_applied_offers";
import {IRecommendedOffer} from "../../../recommendations/types/IRecommendedOffer";
import {IAppDispatch} from "../../../store/store";
import {ViewType} from "../../../view_type/ViewType";
import {RecommendedOfferApplicationsType} from "../../actions/track_recommended_offers_application";
import {IApplicationAdditionalQuestionTypes} from "../../components/application_form/ApplicationFormContext";
import {applicationFormFields} from "../../constants/application_form";
import {IApplication} from "../../types/IApplication";
import {IApplicationFormValues} from "../../types/IApplicationFormValues";
import {MultiLeadRecommendationType} from "../../types/MultiLeadRecommendationType";
import {alertFormSuccessMessage} from "../alert_text";
import {ApplicationSource, ApplicationSourceSection} from "../ApplicationSource";
import {addOffersToStorageSentApplication} from "../manage_storage_sent_applications";
import {getGeneratedMessage} from "../text_generator/get_generated_message";
import {report} from "./analytics_events";

// Data used in reporting purposes both by backend and platforms like algolytics tracker and GTM
interface IPropertyMultiApplicationMetadata {
    refererApplicationUUID: string | undefined;
    viewType: ViewType | null;
    sourceSection: ApplicationSourceSection;
    isAuthenticated: boolean;
    application: IApplication | undefined | null;
    originalFormParams: {
        rooms: number[];
        questions?: IApplicationAdditionalQuestionTypes[];
    };
}

interface IPropertyMultiApplicationPayload {
    selectedRecommendations: IRecommendedOffer[];
    formValues: IApplicationFormValues;
}

export const sendOfferMultiLead = (payload: IPropertyMultiApplicationPayload, metadata: IPropertyMultiApplicationMetadata) => (dispatch: IAppDispatch) => {
    // TODO: this shouldn't be JsonValues type, refactor when migrating to rhf
    const jsonValues = toJsonValues(applicationFormFields, payload.formValues) as Pick<IApplicationFormValues, "name">;
    const source = ApplicationSource.RecommendedOffer;

    if ("text" in jsonValues) {
        delete jsonValues.text;
    }

    const createPayload: ICreateMultipleLeadsPayload = {
        ...jsonValues,
        referer_application_uuid: metadata.refererApplicationUUID,
        source,
        source_section: metadata.sourceSection,
        applications: payload.selectedRecommendations.map((recommendedOffer) => ({
            text: getGeneratedMessage({
                offerType: recommendedOffer.type,
                offerName: recommendedOffer.name,
                name: payload.formValues.name
            }),
            offer: recommendedOffer.id
        }))
    };

    return dispatch(createMultipleApplications.initiate(createPayload))
        .unwrap()
        .then((response) => {
            dispatch(addSuccessNotification(alertFormSuccessMessage));

            const applications = response.applications;

            if (!applications.filter(Boolean).length) {
                return [];
            }

            const offerIDs = applications.reduce<number[]>((acc, application) => {
                return application ? acc.concat(application.offer) : acc;
            }, []);

            if (metadata.isAuthenticated) {
                dispatch(addAppliedOffers(offerIDs));
            }

            addOffersToStorageSentApplication(
                payload.selectedRecommendations.map((offer) => {
                    return {id: offer.id, coordinates: offer.geo_point.coordinates, country: offer.region.country, regionId: offer.region.parent?.id};
                })
            );

            const createdApplications = applications.reduce<RecommendedOfferApplicationsType[]>((acc, application) => {
                if (application) {
                    const recommendedOffer = payload.selectedRecommendations.find(({id}) => id === application.offer);

                    report.singleApplicationSuccess(application, metadata.sourceSection, metadata.viewType);

                    if (recommendedOffer) {
                        acc.push([application, recommendedOffer]);
                    }
                }
                return acc;
            }, []);

            report.finishedSending(MultiLeadRecommendationType.OFFER, createdApplications, metadata.viewType, "standard", metadata.application);

            return createdApplications;
        });
};
import {toJsonValues} from "../../../form/translate/to_json_values";
import {createMultipleApplications} from "../../../lead/api/create_multiple_applications";
import {ICreateMultipleLeadsPayload} from "../../../lead/types/ICreateMultipleLeadsPayload";
import {addSuccessNotification} from "../../../notifications/utils/add_success_notification";
import {addAppliedOffers} from "../../../offer/map/actions/add_applied_offers";
import {IRecommendedOffer} from "../../../recommendations/types/IRecommendedOffer";
import {IAppDispatch} from "../../../store/store";
import {ViewType} from "../../../view_type/ViewType";
import {RecommendedOfferApplicationsType} from "../../actions/track_recommended_offers_application";
import {IApplicationAdditionalQuestionTypes} from "../../components/application_form/ApplicationFormContext";
import {applicationFormFields} from "../../constants/application_form";
import {IApplication} from "../../types/IApplication";
import {IApplicationFormValues} from "../../types/IApplicationFormValues";
import {MultiLeadRecommendationType} from "../../types/MultiLeadRecommendationType";
import {alertFormSuccessMessage} from "../alert_text";
import {ApplicationSource, ApplicationSourceSection} from "../ApplicationSource";
import {addOffersToStorageSentApplication} from "../manage_storage_sent_applications";
import {getGeneratedMessage} from "../text_generator/get_generated_message";
import {report} from "./analytics_events";

// Data used in reporting purposes both by backend and platforms like algolytics tracker and GTM
interface IPropertyMultiApplicationMetadata {
    refererApplicationUUID: string | undefined;
    viewType: ViewType | null;
    sourceSection: ApplicationSourceSection;
    isAuthenticated: boolean;
    application: IApplication | undefined | null;
    originalFormParams: {
        rooms: number[];
        questions?: IApplicationAdditionalQuestionTypes[];
    };
}

interface IPropertyMultiApplicationPayload {
    selectedRecommendations: IRecommendedOffer[];
    formValues: IApplicationFormValues;
}

export const sendOfferMultiLead = (payload: IPropertyMultiApplicationPayload, metadata: IPropertyMultiApplicationMetadata) => (dispatch: IAppDispatch) => {
    // TODO: this shouldn't be JsonValues type, refactor when migrating to rhf
    const jsonValues = toJsonValues(applicationFormFields, payload.formValues) as Pick<IApplicationFormValues, "name">;
    const source = ApplicationSource.RecommendedOffer;

    if ("text" in jsonValues) {
        delete jsonValues.text;
    }

    const createPayload: ICreateMultipleLeadsPayload = {
        ...jsonValues,
        referer_application_uuid: metadata.refererApplicationUUID,
        source,
        source_section: metadata.sourceSection,
        applications: payload.selectedRecommendations.map((recommendedOffer) => ({
            text: getGeneratedMessage({
                offerType: recommendedOffer.type,
                offerName: recommendedOffer.name,
                name: payload.formValues.name
            }),
            offer: recommendedOffer.id
        }))
    };

    return dispatch(createMultipleApplications.initiate(createPayload))
        .unwrap()
        .then((response) => {
            dispatch(addSuccessNotification(alertFormSuccessMessage));

            const applications = response.applications;

            if (!applications.filter(Boolean).length) {
                return [];
            }

            const offerIDs = applications.reduce<number[]>((acc, application) => {
                return application ? acc.concat(application.offer) : acc;
            }, []);

            if (metadata.isAuthenticated) {
                dispatch(addAppliedOffers(offerIDs));
            }

            addOffersToStorageSentApplication(
                payload.selectedRecommendations.map((offer) => {
                    return {id: offer.id, coordinates: offer.geo_point.coordinates, country: offer.region.country, regionId: offer.region.parent?.id};
                })
            );

            const createdApplications = applications.reduce<RecommendedOfferApplicationsType[]>((acc, application) => {
                if (application) {
                    const recommendedOffer = payload.selectedRecommendations.find(({id}) => id === application.offer);

                    report.singleApplicationSuccess(application, metadata.sourceSection, metadata.viewType);

                    if (recommendedOffer) {
                        acc.push([application, recommendedOffer]);
                    }
                }
                return acc;
            }, []);

            report.finishedSending(MultiLeadRecommendationType.OFFER, createdApplications, metadata.viewType, "standard", metadata.application);

            return createdApplications;
        });
};
