import {getRequest} from "@pg-mono/request";
import {apiV2Link, Scenario} from "@pg-mono/rp-api-routes";

import {clientRpRequestMeta} from "../../../client/client_rp_request_meta";
import {gtmInquiryOnApplicationSuccess} from "../../lead/tracking/gtm_inquiry_on_application_success";
import {ILeadModalLeadType} from "../../lead/types/ILeadModalLeadType";
import {IOfferDetailApplication} from "../../offer/types/IOfferDetailApplication";
import {IRecommendedOffer} from "../../recommendations/types/IRecommendedOffer";
import {ViewType} from "../../view_type/ViewType";
import {IApplicationData} from "../reducers/application_reducer";
import {IApplication} from "../types/IApplication";

export type RecommendedOfferApplicationsType = [IApplication, IRecommendedOffer];

// Application lacks information like region, therefore we need to do another fetch in order to suplement the data required by GTM
const fetchRecommendedOffer = (offerId: number): Promise<IOfferDetailApplication> => {
    return getRequest(clientRpRequestMeta, apiV2Link.offer.detail(Scenario.OFFER_APPLICATION, {offerId}));
};

export const trackRecommendedOffersApplication = (
    applications: RecommendedOfferApplicationsType[],
    viewType: ViewType | null,
    leadType: ILeadModalLeadType
): void => {
    // We need to fetch property offer data in order to make GTM event after application submit success
    Promise.all(applications.map(([application]) => fetchRecommendedOffer(application.offer))).then((offers) => {
        offers.forEach((offer, index) => {
            // Order of response from Promise.all method is preserved so we can get value from `applications` basing on the index
            const [application, recommendedOffer] = applications[index];

            //GTM purchase event
            const data = {
                offer: {detail: offer},
                vendor: {detail: recommendedOffer.vendor}
            } as unknown as IApplicationData;

            gtmInquiryOnApplicationSuccess(application, data, viewType, leadType, true);
        });
    });
};
import {getRequest} from "@pg-mono/request";
import {apiV2Link, Scenario} from "@pg-mono/rp-api-routes";

import {clientRpRequestMeta} from "../../../client/client_rp_request_meta";
import {gtmInquiryOnApplicationSuccess} from "../../lead/tracking/gtm_inquiry_on_application_success";
import {ILeadModalLeadType} from "../../lead/types/ILeadModalLeadType";
import {IOfferDetailApplication} from "../../offer/types/IOfferDetailApplication";
import {IRecommendedOffer} from "../../recommendations/types/IRecommendedOffer";
import {ViewType} from "../../view_type/ViewType";
import {IApplicationData} from "../reducers/application_reducer";
import {IApplication} from "../types/IApplication";

export type RecommendedOfferApplicationsType = [IApplication, IRecommendedOffer];

// Application lacks information like region, therefore we need to do another fetch in order to suplement the data required by GTM
const fetchRecommendedOffer = (offerId: number): Promise<IOfferDetailApplication> => {
    return getRequest(clientRpRequestMeta, apiV2Link.offer.detail(Scenario.OFFER_APPLICATION, {offerId}));
};

export const trackRecommendedOffersApplication = (
    applications: RecommendedOfferApplicationsType[],
    viewType: ViewType | null,
    leadType: ILeadModalLeadType
): void => {
    // We need to fetch property offer data in order to make GTM event after application submit success
    Promise.all(applications.map(([application]) => fetchRecommendedOffer(application.offer))).then((offers) => {
        offers.forEach((offer, index) => {
            // Order of response from Promise.all method is preserved so we can get value from `applications` basing on the index
            const [application, recommendedOffer] = applications[index];

            //GTM purchase event
            const data = {
                offer: {detail: offer},
                vendor: {detail: recommendedOffer.vendor}
            } as unknown as IApplicationData;

            gtmInquiryOnApplicationSuccess(application, data, viewType, leadType, true);
        });
    });
};
