import {ApplicationSource, ApplicationSourceSection} from "../../application/utils/ApplicationSource";
import {useAppSelector} from "../../store/hooks";

type IModalType = "vendor" | "offer" | "property" | "all";

type ModalParams<T extends IModalType> = T extends "offer"
    ? {vendorId: number; offerId: number; propertyId: number | null; source: ApplicationSource; sourceSection: ApplicationSourceSection}
    : T extends "property"
      ? {vendorId: number; offerId: number; propertyId: number; source: ApplicationSource; sourceSection: ApplicationSourceSection}
      : {vendorId: number; offerId: number | null; propertyId: number | null; source: ApplicationSource; sourceSection: ApplicationSourceSection};

export function useOpenedLeadModalRequiredParams<T extends IModalType>(_type?: T): ModalParams<T> {
    const vendorId = useAppSelector((state) => state.leadModal.vendorId);
    const offerId = useAppSelector((state) => state.leadModal.offerId);
    const propertyId = useAppSelector((state) => state.leadModal.propertyId);
    const source = useAppSelector((state) => state.leadModal.source);
    const sourceSection = useAppSelector((state) => state.leadModal.sourceSection);

    return {
        vendorId,
        offerId,
        propertyId,
        source,
        sourceSection
    } as ModalParams<T>;
}
import {ApplicationSource, ApplicationSourceSection} from "../../application/utils/ApplicationSource";
import {useAppSelector} from "../../store/hooks";

type IModalType = "vendor" | "offer" | "property" | "all";

type ModalParams<T extends IModalType> = T extends "offer"
    ? {vendorId: number; offerId: number; propertyId: number | null; source: ApplicationSource; sourceSection: ApplicationSourceSection}
    : T extends "property"
      ? {vendorId: number; offerId: number; propertyId: number; source: ApplicationSource; sourceSection: ApplicationSourceSection}
      : {vendorId: number; offerId: number | null; propertyId: number | null; source: ApplicationSource; sourceSection: ApplicationSourceSection};

export function useOpenedLeadModalRequiredParams<T extends IModalType>(_type?: T): ModalParams<T> {
    const vendorId = useAppSelector((state) => state.leadModal.vendorId);
    const offerId = useAppSelector((state) => state.leadModal.offerId);
    const propertyId = useAppSelector((state) => state.leadModal.propertyId);
    const source = useAppSelector((state) => state.leadModal.source);
    const sourceSection = useAppSelector((state) => state.leadModal.sourceSection);

    return {
        vendorId,
        offerId,
        propertyId,
        source,
        sourceSection
    } as ModalParams<T>;
}
