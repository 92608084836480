import {Dispatch} from "redux";

import {IOfferDetailApplication} from "../../../offer/types/IOfferDetailApplication";
import {IPropertyDetailApplication} from "../../../property/types/IPropertyDetailApplication";
import {recommendationsDistanceInKm} from "../../constants/multi_lead_constants";
import {IApplication} from "../../types/IApplication";
import {getStorageSentApplicationsIdListByOffer} from "../get_storage_sent_applications_id_list_by_offer";
import {fetchLeadRecommendations} from "./fetch_lead_recommendations";

interface IGetSimpleMultiLeadRecommendationsParams {
    dispatch: Dispatch;
    sentApplication: IApplication | null;
    offer?: IOfferDetailApplication;
    property?: IPropertyDetailApplication;
    recommendedFilters?: Record<string, string>;
}

export async function fetchSimpleMultiLeadRecommendations(params: IGetSimpleMultiLeadRecommendationsParams) {
    const {sentApplication, offer, property, recommendedFilters, dispatch} = params;

    const excludedOfferIds = getStorageSentApplicationsIdListByOffer(offer, {limit: 50, distance: recommendationsDistanceInKm * 1000});

    // In case of NOT ADDING the radius to fetch payload, update FormSuccessInfoContainer tracking hit
    return await fetchLeadRecommendations({
        dispatch,
        offerId: offer?.id,
        propertyId: property?.id,
        numberOfRooms: sentApplication?.rooms,
        excludedOfferIds,
        filters: {
            ...recommendedFilters,
            radius: recommendationsDistanceInKm.toString()
        }
    });

    /**
     *  Because of CU-869648paa, we don't want to re-fetch anymore
     *  Keeping it here for future reference, just in case, we'll change a lot in ApplicationModalArray soon
     *  TODO: Remove this while it happens (ApplicationModalArray refactor)
     */
    // if (Array.isArray(recommendations) && recommendations.length > 0) {
    //     return recommendations;
    // }
    //
    // // If list is empty, re-fetch recommended list with longer radius
    // return await fetchLeadRecommendations({
    //     dispatch,
    //     offerId: offer?.id,
    //     propertyId: property?.id,
    //     numberOfRooms: sentApplication?.rooms,
    //     excludedOfferIds,
    //     filters: {
    //         ...recommendedFilters,
    //         radius: MULTI_LEAD_DISTANCES_IN_KM[1].toString()
    //     }
    // });
}
import {Dispatch} from "redux";

import {IOfferDetailApplication} from "../../../offer/types/IOfferDetailApplication";
import {IPropertyDetailApplication} from "../../../property/types/IPropertyDetailApplication";
import {recommendationsDistanceInKm} from "../../constants/multi_lead_constants";
import {IApplication} from "../../types/IApplication";
import {getStorageSentApplicationsIdListByOffer} from "../get_storage_sent_applications_id_list_by_offer";
import {fetchLeadRecommendations} from "./fetch_lead_recommendations";

interface IGetSimpleMultiLeadRecommendationsParams {
    dispatch: Dispatch;
    sentApplication: IApplication | null;
    offer?: IOfferDetailApplication;
    property?: IPropertyDetailApplication;
    recommendedFilters?: Record<string, string>;
}

export async function fetchSimpleMultiLeadRecommendations(params: IGetSimpleMultiLeadRecommendationsParams) {
    const {sentApplication, offer, property, recommendedFilters, dispatch} = params;

    const excludedOfferIds = getStorageSentApplicationsIdListByOffer(offer, {limit: 50, distance: recommendationsDistanceInKm * 1000});

    // In case of NOT ADDING the radius to fetch payload, update FormSuccessInfoContainer tracking hit
    return await fetchLeadRecommendations({
        dispatch,
        offerId: offer?.id,
        propertyId: property?.id,
        numberOfRooms: sentApplication?.rooms,
        excludedOfferIds,
        filters: {
            ...recommendedFilters,
            radius: recommendationsDistanceInKm.toString()
        }
    });

    /**
     *  Because of CU-869648paa, we don't want to re-fetch anymore
     *  Keeping it here for future reference, just in case, we'll change a lot in ApplicationModalArray soon
     *  TODO: Remove this while it happens (ApplicationModalArray refactor)
     */
    // if (Array.isArray(recommendations) && recommendations.length > 0) {
    //     return recommendations;
    // }
    //
    // // If list is empty, re-fetch recommended list with longer radius
    // return await fetchLeadRecommendations({
    //     dispatch,
    //     offerId: offer?.id,
    //     propertyId: property?.id,
    //     numberOfRooms: sentApplication?.rooms,
    //     excludedOfferIds,
    //     filters: {
    //         ...recommendedFilters,
    //         radius: MULTI_LEAD_DISTANCES_IN_KM[1].toString()
    //     }
    // });
}
