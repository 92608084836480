import React, {CSSProperties, useRef, useState} from "react";
import {getTrackBackground, Range as ReactRange} from "react-range";
import classNames from "classnames";

import {getThemeVariable} from "@pg-design/styles-strings";
import {Text} from "@pg-design/text-module";

import {IRange} from "../types/IRange";
import {RangeType} from "../types/RangeType";
import {getInitialValue} from "../utils/get_initial_values";
import {shortenBigNumbers} from "../utils/shorten_big_numbers";

import * as cns from "./Range.module.css";

interface IProps {
    name: string;
    value: IRange<RangeType>;
    error?: string[];
    onChange: (fieldName: string, value: IRange<RangeType>) => void;
    step: number;
    min: number;
    max: number;
}

const getLabelVisibility = (values: number[], min: number, max: number) => {
    const diff = max - min;
    const minValue = Math.min(...values);
    const maxValue = Math.max(...values);
    const factor = 0.15;

    return {
        showLeftLabel: minValue > min + diff * factor,
        showRightLabel: maxValue < max - diff * factor
    };
};

export const Range = (props: IProps) => {
    const {min, max, step} = props;
    const rangeRef = useRef<ReactRange>(null);
    const [localValues, setLocalValues] = useState(getInitialValue(props.value, props.min));

    const {showLeftLabel, showRightLabel} = getLabelVisibility(localValues, min, max);
    const leftTextCn = classNames(cns.rangeTitleText, !showLeftLabel && cns.rangeTitleTextHidden);
    const rightTextCn = classNames(cns.rangeTitleText, !showRightLabel && cns.rangeTitleTextHidden);

    return (
        <div className={cns.range}>
            <div className={cns.rangeTitle}>
                <Text variant="body_copy_1" align="left" className={leftTextCn}>
                    {shortenBigNumbers(props.min)}
                </Text>
                <Text variant="body_copy_1" align="right" className={rightTextCn}>
                    {shortenBigNumbers(props.max)}
                </Text>
            </div>
            <ReactRange
                ref={rangeRef}
                onFinalChange={(newValues) => {
                    /**
                     * TODO that makes it actually a single value range, like a "MaxRange"
                     *  We should change the name or implementation
                     */
                    props.onChange(props.name, {
                        lower: 0,
                        upper: newValues[0]
                    });
                }}
                onChange={setLocalValues}
                values={localValues}
                step={step}
                min={min}
                max={max}
                renderTrack={({props: trackProps, children}) => (
                    <div onMouseDown={trackProps.onMouseDown} onTouchStart={trackProps.onTouchStart} className={cns.rangeTrack}>
                        <div
                            ref={trackProps.ref}
                            className={cns.rangeTrackContent}
                            style={
                                {
                                    "--track-bg": getTrackBackground({
                                        values: localValues,
                                        colors: getTrackBgColors(props.value),
                                        min,
                                        max
                                    })
                                } as CSSProperties
                            }
                        >
                            {children}
                        </div>
                    </div>
                )}
                renderThumb={({props: {key, ...props}}) => (
                    <div {...props} key={key} className={cns.rangeThumb}>
                        <div className={cns.rangeThumbLabel}>{shortenBigNumbers(props["aria-valuenow"])}</div>
                    </div>
                )}
            />
        </div>
    );
};

function getTrackBgColors(value: IProps["value"]) {
    if ("lower" in value && "upper" in value) {
        return [getThemeVariable("colors.background.200"), getThemeVariable("colors.primary"), getThemeVariable("colors.background.200")];
    }

    return [getThemeVariable("colors.primary"), getThemeVariable("colors.background.200")];
}
import React, {CSSProperties, useRef, useState} from "react";
import {getTrackBackground, Range as ReactRange} from "react-range";
import classNames from "classnames";

import {getThemeVariable} from "@pg-design/styles-strings";
import {Text} from "@pg-design/text-module";

import {IRange} from "../types/IRange";
import {RangeType} from "../types/RangeType";
import {getInitialValue} from "../utils/get_initial_values";
import {shortenBigNumbers} from "../utils/shorten_big_numbers";

import * as cns from "./Range.module.css";

interface IProps {
    name: string;
    value: IRange<RangeType>;
    error?: string[];
    onChange: (fieldName: string, value: IRange<RangeType>) => void;
    step: number;
    min: number;
    max: number;
}

const getLabelVisibility = (values: number[], min: number, max: number) => {
    const diff = max - min;
    const minValue = Math.min(...values);
    const maxValue = Math.max(...values);
    const factor = 0.15;

    return {
        showLeftLabel: minValue > min + diff * factor,
        showRightLabel: maxValue < max - diff * factor
    };
};

export const Range = (props: IProps) => {
    const {min, max, step} = props;
    const rangeRef = useRef<ReactRange>(null);
    const [localValues, setLocalValues] = useState(getInitialValue(props.value, props.min));

    const {showLeftLabel, showRightLabel} = getLabelVisibility(localValues, min, max);
    const leftTextCn = classNames(cns.rangeTitleText, !showLeftLabel && cns.rangeTitleTextHidden);
    const rightTextCn = classNames(cns.rangeTitleText, !showRightLabel && cns.rangeTitleTextHidden);

    return (
        <div className={cns.range}>
            <div className={cns.rangeTitle}>
                <Text variant="body_copy_1" align="left" className={leftTextCn}>
                    {shortenBigNumbers(props.min)}
                </Text>
                <Text variant="body_copy_1" align="right" className={rightTextCn}>
                    {shortenBigNumbers(props.max)}
                </Text>
            </div>
            <ReactRange
                ref={rangeRef}
                onFinalChange={(newValues) => {
                    /**
                     * TODO that makes it actually a single value range, like a "MaxRange"
                     *  We should change the name or implementation
                     */
                    props.onChange(props.name, {
                        lower: 0,
                        upper: newValues[0]
                    });
                }}
                onChange={setLocalValues}
                values={localValues}
                step={step}
                min={min}
                max={max}
                renderTrack={({props: trackProps, children}) => (
                    <div onMouseDown={trackProps.onMouseDown} onTouchStart={trackProps.onTouchStart} className={cns.rangeTrack}>
                        <div
                            ref={trackProps.ref}
                            className={cns.rangeTrackContent}
                            style={
                                {
                                    "--track-bg": getTrackBackground({
                                        values: localValues,
                                        colors: getTrackBgColors(props.value),
                                        min,
                                        max
                                    })
                                } as CSSProperties
                            }
                        >
                            {children}
                        </div>
                    </div>
                )}
                renderThumb={({props: {key, ...props}}) => (
                    <div {...props} key={key} className={cns.rangeThumb}>
                        <div className={cns.rangeThumbLabel}>{shortenBigNumbers(props["aria-valuenow"])}</div>
                    </div>
                )}
            />
        </div>
    );
};

function getTrackBgColors(value: IProps["value"]) {
    if ("lower" in value && "upper" in value) {
        return [getThemeVariable("colors.background.200"), getThemeVariable("colors.primary"), getThemeVariable("colors.background.200")];
    }

    return [getThemeVariable("colors.primary"), getThemeVariable("colors.background.200")];
}
