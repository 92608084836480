import {createSlice, PayloadAction} from "@reduxjs/toolkit";

import {ILeadModalLeadType} from "../types/ILeadModalLeadType";
import {ILeadModalOpenPayload} from "../types/ILeadModalOpenPayload";
import {ILeadModalState} from "../types/ILeadModalState";

const initialState: ILeadModalState = {
    isModalOpen: false,
    openWithSubmit: false,
    vendorId: null,
    offerId: null,
    propertyId: null,
    consultant: null,
    mainLeadType: null,
    applicationVariant: null,
    source: null,
    sourceSection: null,
    hideQuestions: false,
    affiliation: null,
    forceClose: false,
    creditPayload: null,
    salesOfficeId: null,
    initialStep: null,
    preventClosingWhenNoData: false,
    customSourceSection: null,
    customFormText: null
};

export const leadModalSlice = createSlice({
    name: "leadModal",
    initialState,
    reducers: {
        openLeadModal: (state, action: PayloadAction<ILeadModalOpenPayload>) => {
            state.isModalOpen = true;
            state.openWithSubmit = action.payload.openWithSubmit || false;

            state.vendorId = action.payload.vendorId;
            state.offerId = action.payload.offerId || initialState.offerId;
            state.propertyId = action.payload.propertyId || initialState.vendorId;
            state.source = action.payload.source;
            state.sourceSection = action.payload.sourceSection;
            state.applicationVariant = action.payload.applicationVariant || initialState.applicationVariant;
            state.hideQuestions = action.payload.hideQuestions || initialState.hideQuestions;
            state.affiliation = action.payload.affiliation || initialState.affiliation;
            state.forceClose = action.payload.forceClose || initialState.forceClose;
            state.creditPayload = action.payload.creditPayload || initialState.creditPayload;
            state.salesOfficeId = action.payload.salesOfficeId || initialState.salesOfficeId;
            state.initialStep = action.payload.initialStep || initialState.initialStep;
            state.preventClosingWhenNoData = action.payload.preventClosingWhenNoData || initialState.preventClosingWhenNoData;
            state.customSourceSection = action.payload.customSourceSection || initialState.customSourceSection;
        },
        closeLeadModal: () => {
            return initialState;
        },
        setPriceConversationConsultant: (state, action: PayloadAction<ILeadModalState["consultant"]>) => {
            state.consultant = action.payload;
        },
        setLeadModalMainLeadType: (state, action: PayloadAction<ILeadModalLeadType>) => {
            state.mainLeadType = action.payload;
        }
    }
});

export const {openLeadModal, closeLeadModal, setPriceConversationConsultant, setLeadModalMainLeadType} = leadModalSlice.actions;
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

import {ILeadModalLeadType} from "../types/ILeadModalLeadType";
import {ILeadModalOpenPayload} from "../types/ILeadModalOpenPayload";
import {ILeadModalState} from "../types/ILeadModalState";

const initialState: ILeadModalState = {
    isModalOpen: false,
    openWithSubmit: false,
    vendorId: null,
    offerId: null,
    propertyId: null,
    consultant: null,
    mainLeadType: null,
    applicationVariant: null,
    source: null,
    sourceSection: null,
    hideQuestions: false,
    affiliation: null,
    forceClose: false,
    creditPayload: null,
    salesOfficeId: null,
    initialStep: null,
    preventClosingWhenNoData: false,
    customSourceSection: null,
    customFormText: null
};

export const leadModalSlice = createSlice({
    name: "leadModal",
    initialState,
    reducers: {
        openLeadModal: (state, action: PayloadAction<ILeadModalOpenPayload>) => {
            state.isModalOpen = true;
            state.openWithSubmit = action.payload.openWithSubmit || false;

            state.vendorId = action.payload.vendorId;
            state.offerId = action.payload.offerId || initialState.offerId;
            state.propertyId = action.payload.propertyId || initialState.vendorId;
            state.source = action.payload.source;
            state.sourceSection = action.payload.sourceSection;
            state.applicationVariant = action.payload.applicationVariant || initialState.applicationVariant;
            state.hideQuestions = action.payload.hideQuestions || initialState.hideQuestions;
            state.affiliation = action.payload.affiliation || initialState.affiliation;
            state.forceClose = action.payload.forceClose || initialState.forceClose;
            state.creditPayload = action.payload.creditPayload || initialState.creditPayload;
            state.salesOfficeId = action.payload.salesOfficeId || initialState.salesOfficeId;
            state.initialStep = action.payload.initialStep || initialState.initialStep;
            state.preventClosingWhenNoData = action.payload.preventClosingWhenNoData || initialState.preventClosingWhenNoData;
            state.customSourceSection = action.payload.customSourceSection || initialState.customSourceSection;
        },
        closeLeadModal: () => {
            return initialState;
        },
        setPriceConversationConsultant: (state, action: PayloadAction<ILeadModalState["consultant"]>) => {
            state.consultant = action.payload;
        },
        setLeadModalMainLeadType: (state, action: PayloadAction<ILeadModalLeadType>) => {
            state.mainLeadType = action.payload;
        }
    }
});

export const {openLeadModal, closeLeadModal, setPriceConversationConsultant, setLeadModalMainLeadType} = leadModalSlice.actions;
