import {IOfferDetailApplication} from "../../offer/types/IOfferDetailApplication";
import {Country} from "../../region/types/Country";
import {getStorageSentApplicationsIdListByDistance, getStorageSentApplicationsIdListByRegion} from "./manage_storage_sent_applications";

export function getStorageSentApplicationsIdListByOffer(
    offer?: {geo_point: Pick<IOfferDetailApplication["geo_point"], "coordinates">; region: Pick<IOfferDetailApplication["region"], "country" | "parent">},
    params: {limit?: number; distance?: number} = {}
) {
    const limit = params.limit || 50;
    const distance = params.distance || 5000;

    return offer?.region.country && offer?.region.country !== Country.POLAND
        ? getStorageSentApplicationsIdListByRegion(limit, offer?.region.parent?.id, offer?.geo_point.coordinates, offer?.region.country)
        : getStorageSentApplicationsIdListByDistance(limit, distance, offer?.geo_point.coordinates, offer?.region.country);
}
import {IOfferDetailApplication} from "../../offer/types/IOfferDetailApplication";
import {Country} from "../../region/types/Country";
import {getStorageSentApplicationsIdListByDistance, getStorageSentApplicationsIdListByRegion} from "./manage_storage_sent_applications";

export function getStorageSentApplicationsIdListByOffer(
    offer?: {geo_point: Pick<IOfferDetailApplication["geo_point"], "coordinates">; region: Pick<IOfferDetailApplication["region"], "country" | "parent">},
    params: {limit?: number; distance?: number} = {}
) {
    const limit = params.limit || 50;
    const distance = params.distance || 5000;

    return offer?.region.country && offer?.region.country !== Country.POLAND
        ? getStorageSentApplicationsIdListByRegion(limit, offer?.region.parent?.id, offer?.geo_point.coordinates, offer?.region.country)
        : getStorageSentApplicationsIdListByDistance(limit, distance, offer?.geo_point.coordinates, offer?.region.country);
}
