import {useMemo} from "react";

import {IRecommendedOffer} from "../../recommendations/types/IRecommendedOffer";
import {IRecommendedProperty} from "../../recommendations/types/IRecommendedProperty";
import {MultiLeadRecommendationType} from "../types/MultiLeadRecommendationType";
import {
    transformRecommendedOfferIntoMultiLeadData,
    transformRecommendedPropertyIntoMultiLeadData
} from "../utils/recommended_application/transform_recommended_into_multi_lead_data";

interface IProps {
    recommendedOffers: IRecommendedOffer[];
    recommendedProperties: IRecommendedProperty[];
    recommendationType: MultiLeadRecommendationType;
    rejectedRecommendations?: number[];
    acceptedRecommendations?: number[];
}
export const useTransformRecommendations = ({
    recommendedOffers,
    recommendedProperties,
    recommendationType,
    rejectedRecommendations,
    acceptedRecommendations
}: IProps) => {
    return useMemo(() => {
        if (recommendationType === MultiLeadRecommendationType.OFFER) {
            return recommendedOffers
                .filter((offer) => !(rejectedRecommendations || []).includes(offer.id))
                .filter((offer) => (acceptedRecommendations || []).includes(offer.id))
                .map((item) => transformRecommendedOfferIntoMultiLeadData(item));
        }

        return recommendedProperties
            .filter((property) => !(rejectedRecommendations || []).includes(property.id))
            .filter((property) => (acceptedRecommendations || []).includes(property.id))
            .map((item) => transformRecommendedPropertyIntoMultiLeadData(item));
    }, [recommendedOffers, recommendedProperties, recommendationType, rejectedRecommendations, acceptedRecommendations]);
};
import {useMemo} from "react";

import {IRecommendedOffer} from "../../recommendations/types/IRecommendedOffer";
import {IRecommendedProperty} from "../../recommendations/types/IRecommendedProperty";
import {MultiLeadRecommendationType} from "../types/MultiLeadRecommendationType";
import {
    transformRecommendedOfferIntoMultiLeadData,
    transformRecommendedPropertyIntoMultiLeadData
} from "../utils/recommended_application/transform_recommended_into_multi_lead_data";

interface IProps {
    recommendedOffers: IRecommendedOffer[];
    recommendedProperties: IRecommendedProperty[];
    recommendationType: MultiLeadRecommendationType;
    rejectedRecommendations?: number[];
    acceptedRecommendations?: number[];
}
export const useTransformRecommendations = ({
    recommendedOffers,
    recommendedProperties,
    recommendationType,
    rejectedRecommendations,
    acceptedRecommendations
}: IProps) => {
    return useMemo(() => {
        if (recommendationType === MultiLeadRecommendationType.OFFER) {
            return recommendedOffers
                .filter((offer) => !(rejectedRecommendations || []).includes(offer.id))
                .filter((offer) => (acceptedRecommendations || []).includes(offer.id))
                .map((item) => transformRecommendedOfferIntoMultiLeadData(item));
        }

        return recommendedProperties
            .filter((property) => !(rejectedRecommendations || []).includes(property.id))
            .filter((property) => (acceptedRecommendations || []).includes(property.id))
            .map((item) => transformRecommendedPropertyIntoMultiLeadData(item));
    }, [recommendedOffers, recommendedProperties, recommendationType, rejectedRecommendations, acceptedRecommendations]);
};
