/* eslint-disable @typescript-eslint/no-unused-vars */
import {IABTestConfiguration} from "@pg-mono/ab-testing";
import {rpAppPath} from "@pg-mono/rp-routes";

export enum OngoingABTests {
    PLANER_VERIFICATION = "PLANER_VERIFICATION",
    MULTI_LEAD_SUMMARY = "MULTI_LEAD_SUMMARY",
    OPINIONS_USER_INTEREST = "OPINIONS_USER_INTEREST",
    CONVERSATION_LEAD_MODAL = "CONVERSATION_LEAD_MODAL",
    BOOSTER = "BOOSTER",
    ARTICLE_ATTACHMENT_PAYWALL = "ARTICLE_ATTACHMENT_PAYWALL"
}

/**
 * Example of a test configuration:
 * {
 *     id: "xyz123" // Generated id, received from business,
 *     expires: new Date(2024, 3, 30), // Date when the test will expire,
 *     name: OngoingABTests.SOME_FEATURE, // Name of the test,
 *     path: ["some-path"], // Paths where the test will be active,
 *     matchOnAllPaths: false, // If true, the test will be active on all paths,
 *     variants: 3, // Number of variants, the actual amount is variants + 1 (first variant is 0)
 *     withSSR: false // If true, the test will be active on server side rendering
 * }
 */

export const ongoingABTests: IABTestConfiguration[] = [
    {
        id: "Yy99k333zk71O3b0bG1y",
        expires: new Date(2025, 9, 1),
        name: OngoingABTests.MULTI_LEAD_SUMMARY,
        path: [],
        matchOnAllPaths: true,
        variants: 2,
        withSSR: false
    },
    {
        id: "zxIMyz207kz7og0ulmkq",
        expires: new Date(2025, 3, 30),
        name: OngoingABTests.OPINIONS_USER_INTEREST,
        path: [rpAppPath.offer.detail.base, rpAppPath.property.detail.base],
        variants: 1,
        withSSR: false
    },
    {
        id: "zzKZyh207po7og0ulmpg",
        expires: new Date(2025, 8, 1),
        name: OngoingABTests.CONVERSATION_LEAD_MODAL,
        path: [],
        matchOnAllPaths: true,
        variants: 1,
        withSSR: false
    },
    {
        id: "zxIMyz207kz7og0ulmqk",
        expires: new Date(2025, 3, 28),
        name: OngoingABTests.BOOSTER,
        path: [rpAppPath.offer.detail.base, rpAppPath.property.detail.base],
        matchOnAllPaths: false,
        variants: 1,
        withSSR: false
    },
    {
        id: "kjKPhh297pzx57xxlmoi",
        expires: new Date(2026, 11, 1),
        name: OngoingABTests.ARTICLE_ATTACHMENT_PAYWALL,
        path: [rpAppPath.article.detail],
        matchOnAllPaths: false,
        variants: 1,
        withSSR: true
    }
];
/* eslint-disable @typescript-eslint/no-unused-vars */
import {IABTestConfiguration} from "@pg-mono/ab-testing";
import {rpAppPath} from "@pg-mono/rp-routes";

export enum OngoingABTests {
    PLANER_VERIFICATION = "PLANER_VERIFICATION",
    MULTI_LEAD_SUMMARY = "MULTI_LEAD_SUMMARY",
    OPINIONS_USER_INTEREST = "OPINIONS_USER_INTEREST",
    CONVERSATION_LEAD_MODAL = "CONVERSATION_LEAD_MODAL",
    BOOSTER = "BOOSTER",
    ARTICLE_ATTACHMENT_PAYWALL = "ARTICLE_ATTACHMENT_PAYWALL"
}

/**
 * Example of a test configuration:
 * {
 *     id: "xyz123" // Generated id, received from business,
 *     expires: new Date(2024, 3, 30), // Date when the test will expire,
 *     name: OngoingABTests.SOME_FEATURE, // Name of the test,
 *     path: ["some-path"], // Paths where the test will be active,
 *     matchOnAllPaths: false, // If true, the test will be active on all paths,
 *     variants: 3, // Number of variants, the actual amount is variants + 1 (first variant is 0)
 *     withSSR: false // If true, the test will be active on server side rendering
 * }
 */

export const ongoingABTests: IABTestConfiguration[] = [
    {
        id: "Yy99k333zk71O3b0bG1y",
        expires: new Date(2025, 9, 1),
        name: OngoingABTests.MULTI_LEAD_SUMMARY,
        path: [],
        matchOnAllPaths: true,
        variants: 2,
        withSSR: false
    },
    {
        id: "zxIMyz207kz7og0ulmkq",
        expires: new Date(2025, 3, 30),
        name: OngoingABTests.OPINIONS_USER_INTEREST,
        path: [rpAppPath.offer.detail.base, rpAppPath.property.detail.base],
        variants: 1,
        withSSR: false
    },
    {
        id: "zzKZyh207po7og0ulmpg",
        expires: new Date(2025, 8, 1),
        name: OngoingABTests.CONVERSATION_LEAD_MODAL,
        path: [],
        matchOnAllPaths: true,
        variants: 1,
        withSSR: false
    },
    {
        id: "zxIMyz207kz7og0ulmqk",
        expires: new Date(2025, 3, 28),
        name: OngoingABTests.BOOSTER,
        path: [rpAppPath.offer.detail.base, rpAppPath.property.detail.base],
        matchOnAllPaths: false,
        variants: 1,
        withSSR: false
    },
    {
        id: "kjKPhh297pzx57xxlmoi",
        expires: new Date(2026, 11, 1),
        name: OngoingABTests.ARTICLE_ATTACHMENT_PAYWALL,
        path: [rpAppPath.article.detail],
        matchOnAllPaths: false,
        variants: 1,
        withSSR: true
    }
];
