import {useCallback, useState} from "react";
import {useLocation} from "react-router";

import {notifyBugsnag} from "../../errors/bugsnag/notify_bugsnag";
import {fetchClipPhoneByPropertyId} from "../api/fetch_clip_phone_by_property_id";

export const useClipPhoneNumber = (fallbackPhoneNumber?: string | null) => {
    const [phoneNumber, setPhoneNumer] = useState<string | null>();
    const [isFetching, setIsFetching] = useState(false);
    const [isClipPhoneBlocked, setClipPhoneBlocked] = useState(false);
    const location = useLocation();

    const fetchClipPhoneNumber = useCallback(
        async (propertyId?: number) => {
            if (propertyId) {
                setIsFetching(true);

                await fetchClipPhoneByPropertyId(propertyId)
                    .then((res) => setPhoneNumer(res?.clip_number || fallbackPhoneNumber))
                    .catch((error) => {
                        if (error.response?.status === 404) {
                            notifyBugsnag({name: "Phone clip display blocked", message: "Phone clip display blocked"}, "Phone clip on an archived offer", {
                                error,
                                propertyId,
                                location
                            });

                            setClipPhoneBlocked(true);
                        }
                        setPhoneNumer(fallbackPhoneNumber);
                    })
                    .finally(() => setIsFetching(false));
            } else {
                setPhoneNumer(fallbackPhoneNumber);
            }
        },
        [fallbackPhoneNumber]
    );

    return {
        phoneNumber,
        isClipPhoneBlocked,
        fetchClipPhoneNumber,
        isFetching
    };
};
import {useCallback, useState} from "react";
import {useLocation} from "react-router";

import {notifyBugsnag} from "../../errors/bugsnag/notify_bugsnag";
import {fetchClipPhoneByPropertyId} from "../api/fetch_clip_phone_by_property_id";

export const useClipPhoneNumber = (fallbackPhoneNumber?: string | null) => {
    const [phoneNumber, setPhoneNumer] = useState<string | null>();
    const [isFetching, setIsFetching] = useState(false);
    const [isClipPhoneBlocked, setClipPhoneBlocked] = useState(false);
    const location = useLocation();

    const fetchClipPhoneNumber = useCallback(
        async (propertyId?: number) => {
            if (propertyId) {
                setIsFetching(true);

                await fetchClipPhoneByPropertyId(propertyId)
                    .then((res) => setPhoneNumer(res?.clip_number || fallbackPhoneNumber))
                    .catch((error) => {
                        if (error.response?.status === 404) {
                            notifyBugsnag({name: "Phone clip display blocked", message: "Phone clip display blocked"}, "Phone clip on an archived offer", {
                                error,
                                propertyId,
                                location
                            });

                            setClipPhoneBlocked(true);
                        }
                        setPhoneNumer(fallbackPhoneNumber);
                    })
                    .finally(() => setIsFetching(false));
            } else {
                setPhoneNumer(fallbackPhoneNumber);
            }
        },
        [fallbackPhoneNumber]
    );

    return {
        phoneNumber,
        isClipPhoneBlocked,
        fetchClipPhoneNumber,
        isFetching
    };
};
