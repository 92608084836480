import React from "react";
import {css, Theme} from "@emotion/react";

import {backgroundColor, borderRadius, calculateRemSize, display, flex, mb, mr, onDesktop} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";

import {useReadArchitectDetailsQuery} from "../../api/read_architect_details";
import {packagesPluralize, yearsPluralize} from "../../constants/architect_pluralize";

interface IProps {
    architectId: number;
}

export const MultileadArchitectDetailsOffer = (props: IProps) => {
    const {data} = useReadArchitectDetailsQuery(props.architectId);

    const offer = data?.offer;
    const packagesSize = offer?.packages.length;

    return (
        <div css={[display("none"), onDesktop(display("block")), mb(2)]}>
            {!!packagesSize && (
                <Text variant="info_txt_2" css={textStyle}>
                    <span css={dotStyle} />
                    {`${packagesSize} ${packagesPluralize(packagesSize)}`}
                </Text>
            )}
            {offer?.has_guaranteed_price && (
                <Text variant="info_txt_2" css={textStyle}>
                    <span css={dotStyle} />
                    Gwarantowana cena
                </Text>
            )}
            {offer?.has_guaranteed_deadline && (
                <Text variant="info_txt_2" css={textStyle}>
                    <span css={dotStyle} />
                    Gwarantowany termin realizacji
                </Text>
            )}
            {offer?.warranty_duration && (
                <Text variant="info_txt_2" css={textStyle}>
                    <span css={dotStyle} />
                    {`${offer.warranty_duration} ${yearsPluralize(offer.warranty_duration)} gwarancji`}
                </Text>
            )}
            {offer?.has_insurance && (
                <Text variant="info_txt_2" css={flex("center", "flex-start")}>
                    <span css={dotStyle} />
                    Ubezpieczenie
                </Text>
            )}
        </div>
    );
};

const textStyle = css`
    ${flex("center", "flex-start")};
    ${mb(1)};
`;

const dotStyle = (theme: Theme) => css`
    width: ${calculateRemSize(1.5)};
    height: ${calculateRemSize(1.5)};
    ${backgroundColor(theme.colors.primary)};
    ${display("inline-block")};
    ${mr(0.5)};
    ${borderRadius(1)};
`;
import React from "react";
import {css, Theme} from "@emotion/react";

import {backgroundColor, borderRadius, calculateRemSize, display, flex, mb, mr, onDesktop} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";

import {useReadArchitectDetailsQuery} from "../../api/read_architect_details";
import {packagesPluralize, yearsPluralize} from "../../constants/architect_pluralize";

interface IProps {
    architectId: number;
}

export const MultileadArchitectDetailsOffer = (props: IProps) => {
    const {data} = useReadArchitectDetailsQuery(props.architectId);

    const offer = data?.offer;
    const packagesSize = offer?.packages.length;

    return (
        <div css={[display("none"), onDesktop(display("block")), mb(2)]}>
            {!!packagesSize && (
                <Text variant="info_txt_2" css={textStyle}>
                    <span css={dotStyle} />
                    {`${packagesSize} ${packagesPluralize(packagesSize)}`}
                </Text>
            )}
            {offer?.has_guaranteed_price && (
                <Text variant="info_txt_2" css={textStyle}>
                    <span css={dotStyle} />
                    Gwarantowana cena
                </Text>
            )}
            {offer?.has_guaranteed_deadline && (
                <Text variant="info_txt_2" css={textStyle}>
                    <span css={dotStyle} />
                    Gwarantowany termin realizacji
                </Text>
            )}
            {offer?.warranty_duration && (
                <Text variant="info_txt_2" css={textStyle}>
                    <span css={dotStyle} />
                    {`${offer.warranty_duration} ${yearsPluralize(offer.warranty_duration)} gwarancji`}
                </Text>
            )}
            {offer?.has_insurance && (
                <Text variant="info_txt_2" css={flex("center", "flex-start")}>
                    <span css={dotStyle} />
                    Ubezpieczenie
                </Text>
            )}
        </div>
    );
};

const textStyle = css`
    ${flex("center", "flex-start")};
    ${mb(1)};
`;

const dotStyle = (theme: Theme) => css`
    width: ${calculateRemSize(1.5)};
    height: ${calculateRemSize(1.5)};
    ${backgroundColor(theme.colors.primary)};
    ${display("inline-block")};
    ${mr(0.5)};
    ${borderRadius(1)};
`;
