import {notifyBugsnag} from "../../errors/bugsnag/notify_bugsnag";
import {gtmInquiry} from "../../tracking/google_tag_manager/gtm_inquiry";
import {IGtmApplication} from "../../tracking/types/IGtmApplication";
import {ViewType} from "../../view_type/ViewType";
import {IHitApplicationData} from "../types/IHitApplicationData";
import {ILeadModalLeadType} from "../types/ILeadModalLeadType";

export function gtmInquiryOnApplicationSuccess(
    application: IGtmApplication,
    data: IHitApplicationData,
    viewType: ViewType | null,
    leadType: ILeadModalLeadType,
    recommendedApplication?: boolean,
    affiliation?: string,
    customSourceSection?: string
) {
    if (application.uuid == null) {
        const error = "gtmInquiryOnApplicationSuccess: application UUID is not defined";
        notifyBugsnag(error, error, {application, data});

        return;
    }

    const vendorData = (data.vendor && data.vendor.detail) || undefined;
    const offerData = (data.offer && data.offer.detail) || undefined;
    const finalOfferData = offerData ? {...offerData, region: offerData.region.id} : offerData;
    const regionData = (offerData && offerData.region) || undefined;
    const propertyData = (data.property && data.property.detail) || undefined;
    const country = (data.offer && data.offer.detail?.region.country) || undefined;

    if (vendorData == null) {
        const error = "gtmInquiryOnApplicationSuccess: require vendor data";
        notifyBugsnag(error, error, {application, data});

        return;
    }

    return gtmInquiry(
        viewType,
        application,
        leadType,
        vendorData,
        regionData,
        finalOfferData,
        propertyData,
        recommendedApplication,
        affiliation,
        customSourceSection,
        country
    );
}
import {notifyBugsnag} from "../../errors/bugsnag/notify_bugsnag";
import {gtmInquiry} from "../../tracking/google_tag_manager/gtm_inquiry";
import {IGtmApplication} from "../../tracking/types/IGtmApplication";
import {ViewType} from "../../view_type/ViewType";
import {IHitApplicationData} from "../types/IHitApplicationData";
import {ILeadModalLeadType} from "../types/ILeadModalLeadType";

export function gtmInquiryOnApplicationSuccess(
    application: IGtmApplication,
    data: IHitApplicationData,
    viewType: ViewType | null,
    leadType: ILeadModalLeadType,
    recommendedApplication?: boolean,
    affiliation?: string,
    customSourceSection?: string
) {
    if (application.uuid == null) {
        const error = "gtmInquiryOnApplicationSuccess: application UUID is not defined";
        notifyBugsnag(error, error, {application, data});

        return;
    }

    const vendorData = (data.vendor && data.vendor.detail) || undefined;
    const offerData = (data.offer && data.offer.detail) || undefined;
    const finalOfferData = offerData ? {...offerData, region: offerData.region.id} : offerData;
    const regionData = (offerData && offerData.region) || undefined;
    const propertyData = (data.property && data.property.detail) || undefined;
    const country = (data.offer && data.offer.detail?.region.country) || undefined;

    if (vendorData == null) {
        const error = "gtmInquiryOnApplicationSuccess: require vendor data";
        notifyBugsnag(error, error, {application, data});

        return;
    }

    return gtmInquiry(
        viewType,
        application,
        leadType,
        vendorData,
        regionData,
        finalOfferData,
        propertyData,
        recommendedApplication,
        affiliation,
        customSourceSection,
        country
    );
}
