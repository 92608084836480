import {getRequest} from "@pg-mono/request";
import {apiLink} from "@pg-mono/rp-api-routes";

interface IResponse {
    clip_number: string;
}

export const fetchClipPhoneByPropertyId = (propertyId: number): Promise<IResponse> => {
    const url = apiLink.clipPhones.property({})({propertyId});
    return getRequest({}, url);
};
import {getRequest} from "@pg-mono/request";
import {apiLink} from "@pg-mono/rp-api-routes";

interface IResponse {
    clip_number: string;
}

export const fetchClipPhoneByPropertyId = (propertyId: number): Promise<IResponse> => {
    const url = apiLink.clipPhones.property({})({propertyId});
    return getRequest({}, url);
};
