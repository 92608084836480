import React, {Fragment, type ReactNode, useRef, useState} from "react";
import {
    arrow,
    autoUpdate,
    type ExtendedRefs,
    flip,
    FloatingArrow,
    FloatingPortal,
    offset,
    type Placement,
    shift,
    useClick,
    useDismiss,
    useFloating,
    useFocus,
    useHover,
    useInteractions,
    useRole,
    useTransitionStyles
} from "@floating-ui/react";
import cns from "classnames";

import {CloseIcon} from "@pg-design/icons-module";
import {getMessageColor, IMessageType, Message} from "@pg-design/message-module";
import {useUserDevice} from "@pg-mono/user-device";

import * as classNames from "./DsTooltip.module.css";

interface IProps {
    children: ({refs, getReferenceProps}: {refs: ExtendedRefs<unknown>; getReferenceProps: () => Record<string, unknown>}) => ReactNode;
    popover: ReactNode | string;
    className?: string;
    messageClassName?: string;
    placement?: Placement;
    type?: IMessageType;
    zIndex?: number;
}

export const DsTooltip = (props: IProps) => {
    const {children, popover, className, placement, type, messageClassName, zIndex} = props;

    const [isOpen, setIsOpen] = useState(false);
    const {isMobile} = useUserDevice();

    const arrowRef = useRef<SVGSVGElement | null>(null);

    const {refs, floatingStyles, context} = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        placement: placement || "bottom",
        whileElementsMounted: autoUpdate,
        middleware: [
            offset(5),
            flip({
                fallbackAxisSideDirection: "start"
            }),
            shift(),
            arrow({element: arrowRef})
        ]
    });

    const hover = useHover(context, {move: false, mouseOnly: true, enabled: Boolean(!isMobile)});
    const click = useClick(context, {enabled: Boolean(isMobile)});
    const focus = useFocus(context);
    const dismiss = useDismiss(context);
    const role = useRole(context, {role: "tooltip"});
    const {getReferenceProps, getFloatingProps} = useInteractions([hover, click, focus, dismiss, role]);

    const {isMounted, styles: transitionStyles} = useTransitionStyles(context, {
        duration: 200
    });

    const arrowConfig = {
        fill: getMessageColor(type)
    };

    return (
        <Fragment>
            {children({refs, getReferenceProps})}

            {isMounted && (
                <FloatingPortal>
                    <div className={className} ref={refs.setFloating} style={{...floatingStyles, ...transitionStyles, zIndex}} {...getFloatingProps()}>
                        {typeof popover === "string" ? (
                            <Message type={type} className={cns(classNames.message, messageClassName)}>
                                {popover}
                                {isMobile && (
                                    <CloseIcon
                                        onClick={() => {
                                            setIsOpen(false);
                                        }}
                                        className={classNames.closeIcon}
                                        size="1.6"
                                        wrapperSize="1.6"
                                        wrapperColor="transparent"
                                    />
                                )}
                            </Message>
                        ) : (
                            popover
                        )}
                        <FloatingArrow ref={arrowRef} width={32} context={context} fill={arrowConfig.fill} />
                    </div>
                </FloatingPortal>
            )}
        </Fragment>
    );
};
import React, {Fragment, type ReactNode, useRef, useState} from "react";
import {
    arrow,
    autoUpdate,
    type ExtendedRefs,
    flip,
    FloatingArrow,
    FloatingPortal,
    offset,
    type Placement,
    shift,
    useClick,
    useDismiss,
    useFloating,
    useFocus,
    useHover,
    useInteractions,
    useRole,
    useTransitionStyles
} from "@floating-ui/react";
import cns from "classnames";

import {CloseIcon} from "@pg-design/icons-module";
import {getMessageColor, IMessageType, Message} from "@pg-design/message-module";
import {useUserDevice} from "@pg-mono/user-device";

import * as classNames from "./DsTooltip.module.css";

interface IProps {
    children: ({refs, getReferenceProps}: {refs: ExtendedRefs<unknown>; getReferenceProps: () => Record<string, unknown>}) => ReactNode;
    popover: ReactNode | string;
    className?: string;
    messageClassName?: string;
    placement?: Placement;
    type?: IMessageType;
    zIndex?: number;
}

export const DsTooltip = (props: IProps) => {
    const {children, popover, className, placement, type, messageClassName, zIndex} = props;

    const [isOpen, setIsOpen] = useState(false);
    const {isMobile} = useUserDevice();

    const arrowRef = useRef<SVGSVGElement | null>(null);

    const {refs, floatingStyles, context} = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        placement: placement || "bottom",
        whileElementsMounted: autoUpdate,
        middleware: [
            offset(5),
            flip({
                fallbackAxisSideDirection: "start"
            }),
            shift(),
            arrow({element: arrowRef})
        ]
    });

    const hover = useHover(context, {move: false, mouseOnly: true, enabled: Boolean(!isMobile)});
    const click = useClick(context, {enabled: Boolean(isMobile)});
    const focus = useFocus(context);
    const dismiss = useDismiss(context);
    const role = useRole(context, {role: "tooltip"});
    const {getReferenceProps, getFloatingProps} = useInteractions([hover, click, focus, dismiss, role]);

    const {isMounted, styles: transitionStyles} = useTransitionStyles(context, {
        duration: 200
    });

    const arrowConfig = {
        fill: getMessageColor(type)
    };

    return (
        <Fragment>
            {children({refs, getReferenceProps})}

            {isMounted && (
                <FloatingPortal>
                    <div className={className} ref={refs.setFloating} style={{...floatingStyles, ...transitionStyles, zIndex}} {...getFloatingProps()}>
                        {typeof popover === "string" ? (
                            <Message type={type} className={cns(classNames.message, messageClassName)}>
                                {popover}
                                {isMobile && (
                                    <CloseIcon
                                        onClick={() => {
                                            setIsOpen(false);
                                        }}
                                        className={classNames.closeIcon}
                                        size="1.6"
                                        wrapperSize="1.6"
                                        wrapperColor="transparent"
                                    />
                                )}
                            </Message>
                        ) : (
                            popover
                        )}
                        <FloatingArrow ref={arrowRef} width={32} context={context} fill={arrowConfig.fill} />
                    </div>
                </FloatingPortal>
            )}
        </Fragment>
    );
};
