import {ReactNode} from "react";
import {css, Theme} from "@emotion/react";

import {borderRadius, mb, p} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";

interface IProps {
    children: ReactNode;
    className?: string;
}

export function ChatBotMessage(props: IProps) {
    const {children, className} = props;

    return (
        <div css={messageItem} className={className}>
            <Text variant="info_txt_1">{children}</Text>
        </div>
    );
}

const messageItem = (theme: Theme) => css`
    align-self: flex-start;
    background-color: ${theme.colors.background[100]};
    overflow: hidden;
    ${p()};
    ${mb()};
    ${borderRadius(2)};
`;
import {ReactNode} from "react";
import {css, Theme} from "@emotion/react";

import {borderRadius, mb, p} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";

interface IProps {
    children: ReactNode;
    className?: string;
}

export function ChatBotMessage(props: IProps) {
    const {children, className} = props;

    return (
        <div css={messageItem} className={className}>
            <Text variant="info_txt_1">{children}</Text>
        </div>
    );
}

const messageItem = (theme: Theme) => css`
    align-self: flex-start;
    background-color: ${theme.colors.background[100]};
    overflow: hidden;
    ${p()};
    ${mb()};
    ${borderRadius(2)};
`;
