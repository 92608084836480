import {IRecommendedOffer} from "../../../recommendations/types/IRecommendedOffer";
import {IRecommendedProperty} from "../../../recommendations/types/IRecommendedProperty";
import {IMultiLeadData} from "../../types/IMultiLeadData";

export const transformRecommendedPropertyIntoMultiLeadData = (property: IRecommendedProperty, overrideData: Partial<IMultiLeadData> = {}): IMultiLeadData => ({
    id: property.id,
    offerType: property.offer.type,
    offerName: property.offer.name,
    offerAddress: property.offer.address,
    offerVendorName: property.offer.vendor.name,
    offerPrivacyDataProcessorName: property.offer.configuration.personal_data_processor_name,
    offerPrivacyPolicyUrl: property.offer.configuration.privacy_policy_url,
    offerPersonalDataProcessorUrl: property.offer.configuration.personal_data_processor_url,
    offerVendorLogo: property.offer.vendor.logo.v_log_80x60,
    offerDistanceFromTheAirport: property.offer.distance_from_the_airport,
    offerDistanceFromTheBeach: property.offer.distance_from_the_beach,
    coordinates: property.offer.geo_point.coordinates,
    price: property.price,
    rooms: property.rooms,
    area: property.area,
    constructionDateRange: property.offer.construction_date_range,
    mainImage: {
        desktop: property.offer.main_image?.m_img_224x147,
        mobile: property.offer.main_image?.m_img_155x87
    },
    distance: property.distance,
    region: {
        country: property.region.country
    },
    ...overrideData
});

export const transformRecommendedOfferIntoMultiLeadData = (offer: IRecommendedOffer, overrideData: Partial<IMultiLeadData> = {}): IMultiLeadData => ({
    id: offer.id,
    offerType: offer.type,
    offerName: offer.name,
    offerAddress: offer.address,
    offerVendorName: offer.vendor.name,
    offerPrivacyDataProcessorName: offer.configuration.personal_data_processor_name,
    offerPrivacyPolicyUrl: offer.configuration.privacy_policy_url,
    offerPersonalDataProcessorUrl: offer.configuration.personal_data_processor_url,
    offerVendorLogo: offer.vendor.logo.v_log_80x60,
    offerDistanceFromTheAirport: offer.distance_from_the_airport,
    offerDistanceFromTheBeach: offer.distance_from_the_beach,
    coordinates: offer.geo_point.coordinates,
    mainImage: {
        desktop: offer.main_image?.m_img_277x160,
        mobile: offer.main_image?.m_img_155x87
    },
    distance: offer.distance,
    region: {
        country: offer.region.country
    },
    ...overrideData
});
import {IRecommendedOffer} from "../../../recommendations/types/IRecommendedOffer";
import {IRecommendedProperty} from "../../../recommendations/types/IRecommendedProperty";
import {IMultiLeadData} from "../../types/IMultiLeadData";

export const transformRecommendedPropertyIntoMultiLeadData = (property: IRecommendedProperty, overrideData: Partial<IMultiLeadData> = {}): IMultiLeadData => ({
    id: property.id,
    offerType: property.offer.type,
    offerName: property.offer.name,
    offerAddress: property.offer.address,
    offerVendorName: property.offer.vendor.name,
    offerPrivacyDataProcessorName: property.offer.configuration.personal_data_processor_name,
    offerPrivacyPolicyUrl: property.offer.configuration.privacy_policy_url,
    offerPersonalDataProcessorUrl: property.offer.configuration.personal_data_processor_url,
    offerVendorLogo: property.offer.vendor.logo.v_log_80x60,
    offerDistanceFromTheAirport: property.offer.distance_from_the_airport,
    offerDistanceFromTheBeach: property.offer.distance_from_the_beach,
    coordinates: property.offer.geo_point.coordinates,
    price: property.price,
    rooms: property.rooms,
    area: property.area,
    constructionDateRange: property.offer.construction_date_range,
    mainImage: {
        desktop: property.offer.main_image?.m_img_224x147,
        mobile: property.offer.main_image?.m_img_155x87
    },
    distance: property.distance,
    region: {
        country: property.region.country
    },
    ...overrideData
});

export const transformRecommendedOfferIntoMultiLeadData = (offer: IRecommendedOffer, overrideData: Partial<IMultiLeadData> = {}): IMultiLeadData => ({
    id: offer.id,
    offerType: offer.type,
    offerName: offer.name,
    offerAddress: offer.address,
    offerVendorName: offer.vendor.name,
    offerPrivacyDataProcessorName: offer.configuration.personal_data_processor_name,
    offerPrivacyPolicyUrl: offer.configuration.privacy_policy_url,
    offerPersonalDataProcessorUrl: offer.configuration.personal_data_processor_url,
    offerVendorLogo: offer.vendor.logo.v_log_80x60,
    offerDistanceFromTheAirport: offer.distance_from_the_airport,
    offerDistanceFromTheBeach: offer.distance_from_the_beach,
    coordinates: offer.geo_point.coordinates,
    mainImage: {
        desktop: offer.main_image?.m_img_277x160,
        mobile: offer.main_image?.m_img_155x87
    },
    distance: offer.distance,
    region: {
        country: offer.region.country
    },
    ...overrideData
});
