import * as React from "react";
import {useEffect} from "react";
import {useSelector} from "react-redux";

import {rpAppLink} from "@pg-mono/rp-routes";

import {IRPStore} from "../../app/rp_reducer";
import {gtmEventPostApplicationModal} from "../../tracking/google_tag_manager/gtm_event_modal";
import {IModalStepState} from "../types/IModalStepState";
import {FormSuccessInfo} from "./FormSuccessInfo";

interface IProps {
    className?: string;
    onClick: () => void;
    buttonClassName?: string;
    disableRedirectButton?: boolean;
    modalStepState: IModalStepState;
    isModal?: boolean;
    shouldRenderRecommendations?: boolean;
    onModalClose?: () => void;
}

export const FormSuccessInfoContainer = (props: IProps) => {
    const {modalStepState} = props;

    const isAuthenticated = useSelector((state: IRPStore) => state.isAuthenticated);

    const applicationUuid = modalStepState.originalApplicationResponse ? modalStepState.originalApplicationResponse.uuid : null;

    useEffect(() => {
        // gtm
        gtmEventPostApplicationModal("rekomendacje", "wyswietlenie");
    }, []);

    const onClickShowSentApplications = () => {
        // gtm
        gtmEventPostApplicationModal("rekomendacje", "klikniecie");
        // continue redirect
        const applicationUuidOrEmptyString = applicationUuid === null || applicationUuid === undefined ? "" : `#${applicationUuid}`;
        window.location.href = rpAppLink.myOffer() + applicationUuidOrEmptyString;
    };

    return (
        <FormSuccessInfo
            className={props.className}
            buttonClassName={props.buttonClassName}
            onClick={props.onClick}
            onClickShowSentApplications={onClickShowSentApplications}
            disableRedirectButton={props.disableRedirectButton}
            isModal={props.isModal}
            isAuthenticated={isAuthenticated}
            onModalClose={props.onModalClose}
        />
    );
};
import * as React from "react";
import {useEffect} from "react";
import {useSelector} from "react-redux";

import {rpAppLink} from "@pg-mono/rp-routes";

import {IRPStore} from "../../app/rp_reducer";
import {gtmEventPostApplicationModal} from "../../tracking/google_tag_manager/gtm_event_modal";
import {IModalStepState} from "../types/IModalStepState";
import {FormSuccessInfo} from "./FormSuccessInfo";

interface IProps {
    className?: string;
    onClick: () => void;
    buttonClassName?: string;
    disableRedirectButton?: boolean;
    modalStepState: IModalStepState;
    isModal?: boolean;
    shouldRenderRecommendations?: boolean;
    onModalClose?: () => void;
}

export const FormSuccessInfoContainer = (props: IProps) => {
    const {modalStepState} = props;

    const isAuthenticated = useSelector((state: IRPStore) => state.isAuthenticated);

    const applicationUuid = modalStepState.originalApplicationResponse ? modalStepState.originalApplicationResponse.uuid : null;

    useEffect(() => {
        // gtm
        gtmEventPostApplicationModal("rekomendacje", "wyswietlenie");
    }, []);

    const onClickShowSentApplications = () => {
        // gtm
        gtmEventPostApplicationModal("rekomendacje", "klikniecie");
        // continue redirect
        const applicationUuidOrEmptyString = applicationUuid === null || applicationUuid === undefined ? "" : `#${applicationUuid}`;
        window.location.href = rpAppLink.myOffer() + applicationUuidOrEmptyString;
    };

    return (
        <FormSuccessInfo
            className={props.className}
            buttonClassName={props.buttonClassName}
            onClick={props.onClick}
            onClickShowSentApplications={onClickShowSentApplications}
            disableRedirectButton={props.disableRedirectButton}
            isModal={props.isModal}
            isAuthenticated={isAuthenticated}
            onModalClose={props.onModalClose}
        />
    );
};
