import {IOfferDetailApplication} from "../../offer/types/IOfferDetailApplication";
import {IPropertyDetailApplication} from "../../property/types/IPropertyDetailApplication";
import {getMonthDistrictCityTypeStats} from "../../region/api/get_district_city_type_stats_api";
import {getMonthCityTypeStats} from "../../region/api/get_month_city_type_stats_api";
import {IGetMonthDistrictTypeStatsPayload} from "../../region/api/IGetMonthDistrictTypeStatsPayload";
import {IMonthTypeStats} from "../../region/types/IMonthTypeStats";
import {IAppDispatch} from "../../store/store";
import {getLeadModalBigDataPayload} from "./get_lead_modal_bigdata_payload";

export async function getBigDataPriceRange(offer: IOfferDetailApplication, property: IPropertyDetailApplication, dispatch: IAppDispatch) {
    const regionType = offer.region.type;

    if (!regionType) {
        return null;
    }

    const bdPayload = getLeadModalBigDataPayload(offer);

    if (!bdPayload) {
        return null;
    }

    if (bdPayload.type === "city") {
        const cityPrices = await dispatch(getMonthCityTypeStats.initiate(bdPayload.payload)).unwrap();
        return getMonthStatsAvg(cityPrices, property.rooms);
    }

    if (bdPayload.type === "district") {
        const districtPrices = await dispatch(getMonthDistrictCityTypeStats.initiate(bdPayload.payload as IGetMonthDistrictTypeStatsPayload)).unwrap();
        return getMonthStatsAvg(districtPrices, property.rooms);
    }

    return null;
}

function getMonthStatsAvg(monthStats: IMonthTypeStats[], roomCount: number) {
    let avg = null;

    if (roomCount >= 4) {
        avg = monthStats.map((item) => item.avg_price_4_plus_rooms).filter(Boolean);
    }

    if (roomCount === 3) {
        avg = monthStats.map((item) => item.avg_price_3_rooms).filter(Boolean);
    }

    if (roomCount === 2) {
        avg = monthStats.map((item) => item.avg_price_2_rooms).filter(Boolean);
    }

    if (roomCount === 1) {
        avg = monthStats.map((item) => item.avg_price_studio).filter(Boolean);
    }

    avg = avg || monthStats.map((item) => item.avg_price).filter(Boolean);

    if (!avg || (avg && avg.length < 2)) {
        return null;
    }

    return avg as number[];
}
import {IOfferDetailApplication} from "../../offer/types/IOfferDetailApplication";
import {IPropertyDetailApplication} from "../../property/types/IPropertyDetailApplication";
import {getMonthDistrictCityTypeStats} from "../../region/api/get_district_city_type_stats_api";
import {getMonthCityTypeStats} from "../../region/api/get_month_city_type_stats_api";
import {IGetMonthDistrictTypeStatsPayload} from "../../region/api/IGetMonthDistrictTypeStatsPayload";
import {IMonthTypeStats} from "../../region/types/IMonthTypeStats";
import {IAppDispatch} from "../../store/store";
import {getLeadModalBigDataPayload} from "./get_lead_modal_bigdata_payload";

export async function getBigDataPriceRange(offer: IOfferDetailApplication, property: IPropertyDetailApplication, dispatch: IAppDispatch) {
    const regionType = offer.region.type;

    if (!regionType) {
        return null;
    }

    const bdPayload = getLeadModalBigDataPayload(offer);

    if (!bdPayload) {
        return null;
    }

    if (bdPayload.type === "city") {
        const cityPrices = await dispatch(getMonthCityTypeStats.initiate(bdPayload.payload)).unwrap();
        return getMonthStatsAvg(cityPrices, property.rooms);
    }

    if (bdPayload.type === "district") {
        const districtPrices = await dispatch(getMonthDistrictCityTypeStats.initiate(bdPayload.payload as IGetMonthDistrictTypeStatsPayload)).unwrap();
        return getMonthStatsAvg(districtPrices, property.rooms);
    }

    return null;
}

function getMonthStatsAvg(monthStats: IMonthTypeStats[], roomCount: number) {
    let avg = null;

    if (roomCount >= 4) {
        avg = monthStats.map((item) => item.avg_price_4_plus_rooms).filter(Boolean);
    }

    if (roomCount === 3) {
        avg = monthStats.map((item) => item.avg_price_3_rooms).filter(Boolean);
    }

    if (roomCount === 2) {
        avg = monthStats.map((item) => item.avg_price_2_rooms).filter(Boolean);
    }

    if (roomCount === 1) {
        avg = monthStats.map((item) => item.avg_price_studio).filter(Boolean);
    }

    avg = avg || monthStats.map((item) => item.avg_price).filter(Boolean);

    if (!avg || (avg && avg.length < 2)) {
        return null;
    }

    return avg as number[];
}
