export interface ITrackedProperty {
    area: number;
    discount: number | null;
    floor: number;
    floors: number;
    id: number;
    is_luxury: boolean;
    is_reserved: boolean;
    number: string;
    plan: string | null;
    rooms: number;
}

export const getTrackedPropertyData = (property: ITrackedProperty) => ({
    property_area: property.area,
    property_discount: property.discount,
    property_floor: property.floor,
    property_floors: property.floors,
    property_id: property.id,
    property_luxury: property.is_luxury,
    property_reserved: property.is_reserved,
    property_name: property.number,
    property_plan: property.plan,
    property_rooms: property.rooms
});
export interface ITrackedProperty {
    area: number;
    discount: number | null;
    floor: number;
    floors: number;
    id: number;
    is_luxury: boolean;
    is_reserved: boolean;
    number: string;
    plan: string | null;
    rooms: number;
}

export const getTrackedPropertyData = (property: ITrackedProperty) => ({
    property_area: property.area,
    property_discount: property.discount,
    property_floor: property.floor,
    property_floors: property.floors,
    property_id: property.id,
    property_luxury: property.is_luxury,
    property_reserved: property.is_reserved,
    property_name: property.number,
    property_plan: property.plan,
    property_rooms: property.rooms
});
