import {useEffect, useRef, useState} from "react";

import {useGetPropertyDetailApplicationQuery} from "../../property/api/get_property_detail";
import {useAppSelector} from "../../store/hooks";

export function useLeadModalConversationFormPriceCheck() {
    const [checkingPrice, setCheckingPrice] = useState(true);
    const [didCheckPrice, setDidCheckPrice] = useState(false);
    const checkPriceTimeoutRef = useRef<null | ReturnType<typeof setTimeout>>(null);
    const propertyId = useAppSelector((state) => state.leadModal.propertyId) as number;

    const {isUninitialized, isFetching} = useGetPropertyDetailApplicationQuery(propertyId);

    const isPropertyDetailsLoading = isUninitialized || isFetching;

    useEffect(() => {
        checkPriceTimeoutRef.current = setTimeout(() => {
            setDidCheckPrice(true);
        }, 3000);

        return () => {
            if (checkPriceTimeoutRef.current) {
                clearTimeout(checkPriceTimeoutRef.current);
            }
        };
    }, []);

    useEffect(() => {
        if (!isPropertyDetailsLoading && didCheckPrice) {
            setCheckingPrice(false);
        }
    }, [isPropertyDetailsLoading, didCheckPrice]);

    return checkingPrice;
}
import {useEffect, useRef, useState} from "react";

import {useGetPropertyDetailApplicationQuery} from "../../property/api/get_property_detail";
import {useAppSelector} from "../../store/hooks";

export function useLeadModalConversationFormPriceCheck() {
    const [checkingPrice, setCheckingPrice] = useState(true);
    const [didCheckPrice, setDidCheckPrice] = useState(false);
    const checkPriceTimeoutRef = useRef<null | ReturnType<typeof setTimeout>>(null);
    const propertyId = useAppSelector((state) => state.leadModal.propertyId) as number;

    const {isUninitialized, isFetching} = useGetPropertyDetailApplicationQuery(propertyId);

    const isPropertyDetailsLoading = isUninitialized || isFetching;

    useEffect(() => {
        checkPriceTimeoutRef.current = setTimeout(() => {
            setDidCheckPrice(true);
        }, 3000);

        return () => {
            if (checkPriceTimeoutRef.current) {
                clearTimeout(checkPriceTimeoutRef.current);
            }
        };
    }, []);

    useEffect(() => {
        if (!isPropertyDetailsLoading && didCheckPrice) {
            setCheckingPrice(false);
        }
    }, [isPropertyDetailsLoading, didCheckPrice]);

    return checkingPrice;
}
