import {apiV2Link, Scenario} from "@pg-mono/rp-api-routes";

import {rpApi} from "../../app/api/rp_api";
import {IOfferDetail} from "../types/IOfferDetail";
import {IOfferDetailApplication} from "../types/IOfferDetailApplication";

export const getOfferDetailApi = rpApi.injectEndpoints({
    endpoints: (build) => ({
        getOfferDetail: build.query<IOfferDetail, number>({
            query: (offerId) => apiV2Link.offer.detail(Scenario.OFFER_DETAIL, {offerId})
        }),
        getOfferDetailApplication: build.query<IOfferDetailApplication, number>({
            query: (offerId) => apiV2Link.offer.detail(Scenario.OFFER_APPLICATION, {offerId})
        })
    })
});

export const {useGetOfferDetailQuery, useGetOfferDetailApplicationQuery} = getOfferDetailApi;
import {apiV2Link, Scenario} from "@pg-mono/rp-api-routes";

import {rpApi} from "../../app/api/rp_api";
import {IOfferDetail} from "../types/IOfferDetail";
import {IOfferDetailApplication} from "../types/IOfferDetailApplication";

export const getOfferDetailApi = rpApi.injectEndpoints({
    endpoints: (build) => ({
        getOfferDetail: build.query<IOfferDetail, number>({
            query: (offerId) => apiV2Link.offer.detail(Scenario.OFFER_DETAIL, {offerId})
        }),
        getOfferDetailApplication: build.query<IOfferDetailApplication, number>({
            query: (offerId) => apiV2Link.offer.detail(Scenario.OFFER_APPLICATION, {offerId})
        })
    })
});

export const {useGetOfferDetailQuery, useGetOfferDetailApplicationQuery} = getOfferDetailApi;
