import {useEffect, useReducer} from "react";

import {IRecommendedOffer} from "../../recommendations/types/IRecommendedOffer";
import {IRecommendedProperty} from "../../recommendations/types/IRecommendedProperty";
import {MultiLeadRecommendationType} from "../types/MultiLeadRecommendationType";
import {selectedRecommendationsReducer} from "../utils/recommended_application/selected_recommendations_reducer";

interface IProps {
    recommendedProperties: IRecommendedProperty[];
    recommendedOffers: IRecommendedOffer[];
    recommendationType: MultiLeadRecommendationType;
    maxRecommendationsShown?: number;
    selectAllOnRefresh?: boolean;
}

export const useRecommendationsSelection = (props: IProps) => {
    const {recommendedProperties, recommendedOffers, recommendationType, maxRecommendationsShown, selectAllOnRefresh} = props;

    const isMinimumSelection = typeof maxRecommendationsShown === "number" && maxRecommendationsShown === 0;
    const selectedProperties = isMinimumSelection ? [] : recommendedProperties.slice(0, maxRecommendationsShown || recommendedProperties.length);
    const selectedOffers = isMinimumSelection ? [] : recommendedOffers.slice(0, maxRecommendationsShown || recommendedOffers.length);

    const [selectedRecommendations, dispatchSelectedRecommendations] = useReducer(selectedRecommendationsReducer, {
        property: selectedProperties.map((item) => item.id),
        offer: selectedOffers.map((item) => item.id)
    });

    useEffect(() => {
        if (selectAllOnRefresh) {
            selectAll();
        }
    }, [recommendedProperties, recommendedOffers]);

    const selectedRecommendationsCount =
        recommendationType === MultiLeadRecommendationType.PROPERTY ? selectedRecommendations.property.length : selectedRecommendations.offer.length;

    const isRecommendationSelected = (recommendationId: number): boolean => {
        switch (recommendationType) {
            case MultiLeadRecommendationType.PROPERTY: {
                return selectedRecommendations.property.includes(recommendationId);
            }
            case MultiLeadRecommendationType.OFFER: {
                return selectedRecommendations.offer.includes(recommendationId);
            }
            default:
                return false;
        }
    };

    const toggleRecommendation = (recommendationId: number) => {
        const isSelected = isRecommendationSelected(recommendationId);

        switch (recommendationType) {
            case MultiLeadRecommendationType.PROPERTY:
                dispatchSelectedRecommendations({type: "property", payload: recommendationId});
                return !isSelected;
            case MultiLeadRecommendationType.OFFER:
                dispatchSelectedRecommendations({type: "offer", payload: recommendationId});
                return !isSelected;
            default:
                throw new Error("Unknown recommendation type");
        }
    };

    const selectAll = () => {
        switch (recommendationType) {
            case MultiLeadRecommendationType.PROPERTY:
                dispatchSelectedRecommendations({type: "property", payload: recommendedProperties.map((item) => item.id)});
                break;
            case MultiLeadRecommendationType.OFFER:
                dispatchSelectedRecommendations({type: "offer", payload: recommendedOffers.map((item) => item.id)});
                break;
            default:
                throw new Error("Unknown recommendation type");
        }
    };

    const resetSelection = () => {
        dispatchSelectedRecommendations({type: "reset"});
    };

    return {
        selectedRecommendations: recommendationType === MultiLeadRecommendationType.PROPERTY ? selectedRecommendations.property : selectedRecommendations.offer,
        isRecommendationSelected,
        selectedRecommendationsCount,
        toggleRecommendation,
        resetSelection
    };
};
import {useEffect, useReducer} from "react";

import {IRecommendedOffer} from "../../recommendations/types/IRecommendedOffer";
import {IRecommendedProperty} from "../../recommendations/types/IRecommendedProperty";
import {MultiLeadRecommendationType} from "../types/MultiLeadRecommendationType";
import {selectedRecommendationsReducer} from "../utils/recommended_application/selected_recommendations_reducer";

interface IProps {
    recommendedProperties: IRecommendedProperty[];
    recommendedOffers: IRecommendedOffer[];
    recommendationType: MultiLeadRecommendationType;
    maxRecommendationsShown?: number;
    selectAllOnRefresh?: boolean;
}

export const useRecommendationsSelection = (props: IProps) => {
    const {recommendedProperties, recommendedOffers, recommendationType, maxRecommendationsShown, selectAllOnRefresh} = props;

    const isMinimumSelection = typeof maxRecommendationsShown === "number" && maxRecommendationsShown === 0;
    const selectedProperties = isMinimumSelection ? [] : recommendedProperties.slice(0, maxRecommendationsShown || recommendedProperties.length);
    const selectedOffers = isMinimumSelection ? [] : recommendedOffers.slice(0, maxRecommendationsShown || recommendedOffers.length);

    const [selectedRecommendations, dispatchSelectedRecommendations] = useReducer(selectedRecommendationsReducer, {
        property: selectedProperties.map((item) => item.id),
        offer: selectedOffers.map((item) => item.id)
    });

    useEffect(() => {
        if (selectAllOnRefresh) {
            selectAll();
        }
    }, [recommendedProperties, recommendedOffers]);

    const selectedRecommendationsCount =
        recommendationType === MultiLeadRecommendationType.PROPERTY ? selectedRecommendations.property.length : selectedRecommendations.offer.length;

    const isRecommendationSelected = (recommendationId: number): boolean => {
        switch (recommendationType) {
            case MultiLeadRecommendationType.PROPERTY: {
                return selectedRecommendations.property.includes(recommendationId);
            }
            case MultiLeadRecommendationType.OFFER: {
                return selectedRecommendations.offer.includes(recommendationId);
            }
            default:
                return false;
        }
    };

    const toggleRecommendation = (recommendationId: number) => {
        const isSelected = isRecommendationSelected(recommendationId);

        switch (recommendationType) {
            case MultiLeadRecommendationType.PROPERTY:
                dispatchSelectedRecommendations({type: "property", payload: recommendationId});
                return !isSelected;
            case MultiLeadRecommendationType.OFFER:
                dispatchSelectedRecommendations({type: "offer", payload: recommendationId});
                return !isSelected;
            default:
                throw new Error("Unknown recommendation type");
        }
    };

    const selectAll = () => {
        switch (recommendationType) {
            case MultiLeadRecommendationType.PROPERTY:
                dispatchSelectedRecommendations({type: "property", payload: recommendedProperties.map((item) => item.id)});
                break;
            case MultiLeadRecommendationType.OFFER:
                dispatchSelectedRecommendations({type: "offer", payload: recommendedOffers.map((item) => item.id)});
                break;
            default:
                throw new Error("Unknown recommendation type");
        }
    };

    const resetSelection = () => {
        dispatchSelectedRecommendations({type: "reset"});
    };

    return {
        selectedRecommendations: recommendationType === MultiLeadRecommendationType.PROPERTY ? selectedRecommendations.property : selectedRecommendations.offer,
        isRecommendationSelected,
        selectedRecommendationsCount,
        toggleRecommendation,
        resetSelection
    };
};
