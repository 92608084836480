import {hitAlgolytics} from "@pg-mono/algolytics";

import {getTrackedSiteData} from "../../tracking/algolytics_hits/tracked_utils/tracked_site";
import {ViewType} from "../../view_type/ViewType";
import {IApplicationAdditionalQuestionTypes} from "../components/application_form/ApplicationFormContext";
import {ContactType} from "../types/ContactType";
import {ApplicationSource, ApplicationSourceSection} from "../utils/ApplicationSource";

export interface IApplicationAnalyticsData {
    offer_id?: number;
    source_id: ApplicationSource;
    source_section: ApplicationSourceSection;
    view_type: ViewType | null;
    property_id?: number;
}

export function openApplicationFormHit(analyticsData: IApplicationAnalyticsData) {
    hitAlgolytics("form_with_questions_open", {
        ...{event_type: "application_open"},
        ...analyticsData
    });
}

export function questionClickHit(selectedQuestion: IApplicationAdditionalQuestionTypes, checked: boolean, analyticsData: IApplicationAnalyticsData) {
    const optionNumber = getQuestionOrder(selectedQuestion);
    const siteData = getTrackedSiteData();

    hitAlgolytics("form_with_questions_message", {
        ...{event_type: "questions_message", option: optionNumber, checked: checked, site_id: siteData.site_id},
        ...analyticsData
    });
}

export function selectContactTypeHit(contactType: ContactType, analyticsData: IApplicationAnalyticsData) {
    hitAlgolytics("form_with_questions_contact_form", {
        ...{event_type: "contact_form", option: contactType},
        ...analyticsData
    });
}

export function sendApplicationHit(
    selectedQuestions: IApplicationAdditionalQuestionTypes[],
    name: string,
    phone: string,
    email: string,
    analyticsData: IApplicationAnalyticsData,
    generatedMessage: string,
    modifiedMessage?: string
) {
    let eventData = null;
    if (generatedMessage && modifiedMessage) {
        eventData = {
            event_type: "click_send",
            questions: selectedQuestions.map((selectedQuestion) => getQuestionOrder(selectedQuestion)),
            name,
            phone,
            email,
            generated_message: generatedMessage,
            modified_message: modifiedMessage
        };
    } else {
        eventData = {
            event_type: "click_send",
            questions: selectedQuestions.map((selectedQuestion) => getQuestionOrder(selectedQuestion)),
            name,
            phone,
            email,
            generated_message: generatedMessage
        };
    }

    hitAlgolytics("form_with_questions_click_send", {
        ...eventData,
        ...analyticsData
    });
}

export const sendUserTypeHit = (user_segment: number) => {
    hitAlgolytics("modal_user_segment", {
        user_segment
    });
};

//  Utils
function getQuestionOrder(question: IApplicationAdditionalQuestionTypes) {
    const questionsOrder: {[key in IApplicationAdditionalQuestionTypes]: number} = {
        price: 1,
        parkAndStorage: 2,
        rent: 3,
        payPlan: 4,
        areaInfr: 5,
        similarApts: 6,
        heatingType: 7,
        gardenSize: 8
    };

    return questionsOrder[question];
}
import {hitAlgolytics} from "@pg-mono/algolytics";

import {getTrackedSiteData} from "../../tracking/algolytics_hits/tracked_utils/tracked_site";
import {ViewType} from "../../view_type/ViewType";
import {IApplicationAdditionalQuestionTypes} from "../components/application_form/ApplicationFormContext";
import {ContactType} from "../types/ContactType";
import {ApplicationSource, ApplicationSourceSection} from "../utils/ApplicationSource";

export interface IApplicationAnalyticsData {
    offer_id?: number;
    source_id: ApplicationSource;
    source_section: ApplicationSourceSection;
    view_type: ViewType | null;
    property_id?: number;
}

export function openApplicationFormHit(analyticsData: IApplicationAnalyticsData) {
    hitAlgolytics("form_with_questions_open", {
        ...{event_type: "application_open"},
        ...analyticsData
    });
}

export function questionClickHit(selectedQuestion: IApplicationAdditionalQuestionTypes, checked: boolean, analyticsData: IApplicationAnalyticsData) {
    const optionNumber = getQuestionOrder(selectedQuestion);
    const siteData = getTrackedSiteData();

    hitAlgolytics("form_with_questions_message", {
        ...{event_type: "questions_message", option: optionNumber, checked: checked, site_id: siteData.site_id},
        ...analyticsData
    });
}

export function selectContactTypeHit(contactType: ContactType, analyticsData: IApplicationAnalyticsData) {
    hitAlgolytics("form_with_questions_contact_form", {
        ...{event_type: "contact_form", option: contactType},
        ...analyticsData
    });
}

export function sendApplicationHit(
    selectedQuestions: IApplicationAdditionalQuestionTypes[],
    name: string,
    phone: string,
    email: string,
    analyticsData: IApplicationAnalyticsData,
    generatedMessage: string,
    modifiedMessage?: string
) {
    let eventData = null;
    if (generatedMessage && modifiedMessage) {
        eventData = {
            event_type: "click_send",
            questions: selectedQuestions.map((selectedQuestion) => getQuestionOrder(selectedQuestion)),
            name,
            phone,
            email,
            generated_message: generatedMessage,
            modified_message: modifiedMessage
        };
    } else {
        eventData = {
            event_type: "click_send",
            questions: selectedQuestions.map((selectedQuestion) => getQuestionOrder(selectedQuestion)),
            name,
            phone,
            email,
            generated_message: generatedMessage
        };
    }

    hitAlgolytics("form_with_questions_click_send", {
        ...eventData,
        ...analyticsData
    });
}

export const sendUserTypeHit = (user_segment: number) => {
    hitAlgolytics("modal_user_segment", {
        user_segment
    });
};

//  Utils
function getQuestionOrder(question: IApplicationAdditionalQuestionTypes) {
    const questionsOrder: {[key in IApplicationAdditionalQuestionTypes]: number} = {
        price: 1,
        parkAndStorage: 2,
        rent: 3,
        payPlan: 4,
        areaInfr: 5,
        similarApts: 6,
        heatingType: 7,
        gardenSize: 8
    };

    return questionsOrder[question];
}
