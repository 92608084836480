import {Dispatch} from "redux";

import {IApplication} from "../../application/types/IApplication";
import {patchUserConsent} from "../api/patch_user_consent";
import {MarketingConsentType} from "../ts/enums/MarketingConsentType";
import {IUserProfile} from "../ts/interfaces/IUserProfile";
import {IUserProfilePatch, patchUserProfile} from "./patch_user_profile";

export const patchUserProfileFromApplication =
    (userProfileData: IUserProfile, {name, new_property_notification_consent, phone}: IApplication) =>
    async (dispatch: Dispatch) => {
        if (new_property_notification_consent) {
            await patchUserConsent({[MarketingConsentType.PHONE]: true, [MarketingConsentType.EMAIL]: true});
        }

        const userProfileDataToUpdate: IUserProfilePatch = {};

        if (!userProfileData.name && name) {
            userProfileDataToUpdate.name = name;
        }

        if (!userProfileData.phone && phone) {
            userProfileDataToUpdate.phone = phone;
        }

        if (!!Object.keys(userProfileDataToUpdate).length) {
            dispatch(patchUserProfile(userProfileDataToUpdate));
        }
    };
import {Dispatch} from "redux";

import {IApplication} from "../../application/types/IApplication";
import {patchUserConsent} from "../api/patch_user_consent";
import {MarketingConsentType} from "../ts/enums/MarketingConsentType";
import {IUserProfile} from "../ts/interfaces/IUserProfile";
import {IUserProfilePatch, patchUserProfile} from "./patch_user_profile";

export const patchUserProfileFromApplication =
    (userProfileData: IUserProfile, {name, new_property_notification_consent, phone}: IApplication) =>
    async (dispatch: Dispatch) => {
        if (new_property_notification_consent) {
            await patchUserConsent({[MarketingConsentType.PHONE]: true, [MarketingConsentType.EMAIL]: true});
        }

        const userProfileDataToUpdate: IUserProfilePatch = {};

        if (!userProfileData.name && name) {
            userProfileDataToUpdate.name = name;
        }

        if (!userProfileData.phone && phone) {
            userProfileDataToUpdate.phone = phone;
        }

        if (!!Object.keys(userProfileDataToUpdate).length) {
            dispatch(patchUserProfile(userProfileDataToUpdate));
        }
    };
