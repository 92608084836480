import React from "react";
import {css, Theme, useTheme} from "@emotion/react";

import {borderRadius, flexAbsoluteCenter, mb, mt, onDesktop, p, pr} from "@pg-design/helpers-css";
import {CheckIcon} from "@pg-design/icons-module";
import {Image} from "@pg-design/image-module";
import {Text} from "@pg-design/text-module";
import {areaFormat, pluralize} from "@pg-mono/string-utils";
import {priceFormat} from "@pg-mono/string-utils";

import {ILeadSummaryItem} from "../../../../../lead/types/ILeadSummaryItem";
import {getOfferTypeNameCapital} from "../../../../../offer/helpers/OfferType";
import {Country} from "../../../../../region/types/Country";
import {useEstimatedPriceByArea} from "../../../../hooks/use_estimated_price_by_area";

type IProps = {
    className?: string;
} & ILeadSummaryItem;

const roomsPluralize = pluralize(["pokój", "pokoje", "pokoi"]);

export const MultiLeadApplicationSummaryBox = (props: IProps) => {
    const {
        className,
        regionEstimatedPriceSummary,
        offerType,
        area,
        region,
        mainImage,
        rooms,
        offerName,
        offerVendorName,
        offerAddress,
        isPriceRevealed,
        price
    } = props;
    const theme = useTheme();
    const displayType = getOfferTypeNameCapital(offerType);
    const {estimatedPriceMin, estimatedPriceMax} = useEstimatedPriceByArea({
        price,
        isPriceRevealed,
        regionEstimatedPriceSummary,
        area
    });
    const priceFormatWithUnit = (price: number) => priceFormat(price, {unit: region.country === Country.POLAND ? "zł" : "eur"});

    return (
        <div css={wrapperStyle} className={className}>
            <div css={propertyImageColumn}>
                <Image css={propertyImage} src={mainImage.desktop} width="auto" height="auto" alt={`${offerVendorName} - ${offerName}`} />
            </div>
            <div css={contentStyle}>
                <Text variant="body_copy_1">
                    {rooms || area ? `${displayType}, ${rooms ? `${rooms} ${roomsPluralize(rooms)}, ` : ""}${area ? areaFormat(area) : ""}` : offerName}
                </Text>
                <Text variant="info_txt_3" color={theme.colors.gray[800]} css={mb(1)}>
                    {offerVendorName}
                </Text>
                <Text variant="info_txt_2" color={theme.colors.gray[800]} css={mb(2)}>
                    {offerAddress}
                </Text>

                {isPriceRevealed && price && price > 0 ? (
                    <>
                        <Text variant="info_txt_2" css={mb(0.5)}>
                            Cena:
                        </Text>
                        <Text variant="body_copy_2" css={estimatedPricesWrapperStyle}>
                            {priceFormatWithUnit(price)}
                        </Text>
                    </>
                ) : estimatedPriceMin || estimatedPriceMax ? (
                    <>
                        <Text variant="info_txt_2" css={mb(0.5)}>
                            Cena estymowana:
                        </Text>
                        <Text variant="body_copy_2" css={estimatedPricesWrapperStyle}>
                            {estimatedPriceMin ? priceFormatWithUnit(estimatedPriceMin) : null}
                            {estimatedPriceMin && estimatedPriceMax ? ` - ` : null}
                            {estimatedPriceMax ? priceFormatWithUnit(estimatedPriceMax) : null}
                        </Text>
                    </>
                ) : null}
            </div>
            <div css={[pr(1), onDesktop(pr(2)), flexAbsoluteCenter]}>
                <CheckIcon wrapperSize="2.4" size="2.4" wrapperColor={theme.colors.success} />
            </div>
        </div>
    );
};

const wrapperStyle = (theme: Theme) => css`
    display: flex;
    flex-direction: row;
    overflow: hidden;
    background-color: ${theme.colors.gray[100]};
    width: 100%;
`;

const contentStyle = css`
    ${p(1)}
    flex: 1 1 auto;

    ${onDesktop(css`
        ${p(1, 2)};
    `)};
`;

const propertyImageColumn = css`
    width: 125px;
    flex: 0 0 125px;

    ${onDesktop(css`
        width: 160px;
        flex: 0 0 160px;
    `)};
`;

const propertyImage = css`
    width: 100%;
    height: 100%;

    & > img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
`;

const estimatedPricesWrapperStyle = (theme: Theme) => css`
    display: inline-flex;
    background-color: ${theme.colors.success};
    ${borderRadius(2)};
    color: ${theme.colors.secondary};
    ${mt(1)};
    ${p(0.5, 1)};

    @media (max-width: ${theme.breakpoints.xs}) {
        font-size: 1rem;
    }
`;
import React from "react";
import {css, Theme, useTheme} from "@emotion/react";

import {borderRadius, flexAbsoluteCenter, mb, mt, onDesktop, p, pr} from "@pg-design/helpers-css";
import {CheckIcon} from "@pg-design/icons-module";
import {Image} from "@pg-design/image-module";
import {Text} from "@pg-design/text-module";
import {areaFormat, pluralize} from "@pg-mono/string-utils";
import {priceFormat} from "@pg-mono/string-utils";

import {ILeadSummaryItem} from "../../../../../lead/types/ILeadSummaryItem";
import {getOfferTypeNameCapital} from "../../../../../offer/helpers/OfferType";
import {Country} from "../../../../../region/types/Country";
import {useEstimatedPriceByArea} from "../../../../hooks/use_estimated_price_by_area";

type IProps = {
    className?: string;
} & ILeadSummaryItem;

const roomsPluralize = pluralize(["pokój", "pokoje", "pokoi"]);

export const MultiLeadApplicationSummaryBox = (props: IProps) => {
    const {
        className,
        regionEstimatedPriceSummary,
        offerType,
        area,
        region,
        mainImage,
        rooms,
        offerName,
        offerVendorName,
        offerAddress,
        isPriceRevealed,
        price
    } = props;
    const theme = useTheme();
    const displayType = getOfferTypeNameCapital(offerType);
    const {estimatedPriceMin, estimatedPriceMax} = useEstimatedPriceByArea({
        price,
        isPriceRevealed,
        regionEstimatedPriceSummary,
        area
    });
    const priceFormatWithUnit = (price: number) => priceFormat(price, {unit: region.country === Country.POLAND ? "zł" : "eur"});

    return (
        <div css={wrapperStyle} className={className}>
            <div css={propertyImageColumn}>
                <Image css={propertyImage} src={mainImage.desktop} width="auto" height="auto" alt={`${offerVendorName} - ${offerName}`} />
            </div>
            <div css={contentStyle}>
                <Text variant="body_copy_1">
                    {rooms || area ? `${displayType}, ${rooms ? `${rooms} ${roomsPluralize(rooms)}, ` : ""}${area ? areaFormat(area) : ""}` : offerName}
                </Text>
                <Text variant="info_txt_3" color={theme.colors.gray[800]} css={mb(1)}>
                    {offerVendorName}
                </Text>
                <Text variant="info_txt_2" color={theme.colors.gray[800]} css={mb(2)}>
                    {offerAddress}
                </Text>

                {isPriceRevealed && price && price > 0 ? (
                    <>
                        <Text variant="info_txt_2" css={mb(0.5)}>
                            Cena:
                        </Text>
                        <Text variant="body_copy_2" css={estimatedPricesWrapperStyle}>
                            {priceFormatWithUnit(price)}
                        </Text>
                    </>
                ) : estimatedPriceMin || estimatedPriceMax ? (
                    <>
                        <Text variant="info_txt_2" css={mb(0.5)}>
                            Cena estymowana:
                        </Text>
                        <Text variant="body_copy_2" css={estimatedPricesWrapperStyle}>
                            {estimatedPriceMin ? priceFormatWithUnit(estimatedPriceMin) : null}
                            {estimatedPriceMin && estimatedPriceMax ? ` - ` : null}
                            {estimatedPriceMax ? priceFormatWithUnit(estimatedPriceMax) : null}
                        </Text>
                    </>
                ) : null}
            </div>
            <div css={[pr(1), onDesktop(pr(2)), flexAbsoluteCenter]}>
                <CheckIcon wrapperSize="2.4" size="2.4" wrapperColor={theme.colors.success} />
            </div>
        </div>
    );
};

const wrapperStyle = (theme: Theme) => css`
    display: flex;
    flex-direction: row;
    overflow: hidden;
    background-color: ${theme.colors.gray[100]};
    width: 100%;
`;

const contentStyle = css`
    ${p(1)}
    flex: 1 1 auto;

    ${onDesktop(css`
        ${p(1, 2)};
    `)};
`;

const propertyImageColumn = css`
    width: 125px;
    flex: 0 0 125px;

    ${onDesktop(css`
        width: 160px;
        flex: 0 0 160px;
    `)};
`;

const propertyImage = css`
    width: 100%;
    height: 100%;

    & > img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
`;

const estimatedPricesWrapperStyle = (theme: Theme) => css`
    display: inline-flex;
    background-color: ${theme.colors.success};
    ${borderRadius(2)};
    color: ${theme.colors.secondary};
    ${mt(1)};
    ${p(0.5, 1)};

    @media (max-width: ${theme.breakpoints.xs}) {
        font-size: 1rem;
    }
`;
