import {useMemo} from "react";

import {useReadArchitectListQuery} from "../api/read_architect_list";

export const useArchitectsFromRegion = (region?: string) => {
    const {data: architectDetails, isLoading} = useReadArchitectListQuery({pageSize: 1000}, {skip: !region});

    const architectsFromRegion = useMemo(() => {
        if (!region) {
            return;
        }

        return architectDetails?.results?.filter((architect) => architect.offer.regions.map((region) => region.name).includes(region));
    }, [architectDetails, region]);

    return {
        architectsFromRegion,
        isLoading
    };
};
import {useMemo} from "react";

import {useReadArchitectListQuery} from "../api/read_architect_list";

export const useArchitectsFromRegion = (region?: string) => {
    const {data: architectDetails, isLoading} = useReadArchitectListQuery({pageSize: 1000}, {skip: !region});

    const architectsFromRegion = useMemo(() => {
        if (!region) {
            return;
        }

        return architectDetails?.results?.filter((architect) => architect.offer.regions.map((region) => region.name).includes(region));
    }, [architectDetails, region]);

    return {
        architectsFromRegion,
        isLoading
    };
};
