import {apiV2Link, Scenario} from "@pg-mono/rp-api-routes";

import {rpApi} from "../../app/api/rp_api";
import {IPropertyDetail} from "../types/IPropertyDetail";
import {IPropertyDetailApplication} from "../types/IPropertyDetailApplication";

export const getPropertyDetailApi = rpApi.injectEndpoints({
    endpoints: (builder) => ({
        getPropertyDetail: builder.query<IPropertyDetail, number>({
            query: (propertyId) => ({
                url: apiV2Link.property.detail(Scenario.PROPERTY_DETAIL, {propertyId})
            })
        }),
        getPropertyDetailApplication: builder.query<IPropertyDetailApplication, number>({
            query: (propertyId) => ({
                url: apiV2Link.property.detail(Scenario.PROPERTY_APPLICATION, {propertyId})
            })
        })
    })
});

export const {useGetPropertyDetailApplicationQuery, useGetPropertyDetailQuery} = getPropertyDetailApi;
import {apiV2Link, Scenario} from "@pg-mono/rp-api-routes";

import {rpApi} from "../../app/api/rp_api";
import {IPropertyDetail} from "../types/IPropertyDetail";
import {IPropertyDetailApplication} from "../types/IPropertyDetailApplication";

export const getPropertyDetailApi = rpApi.injectEndpoints({
    endpoints: (builder) => ({
        getPropertyDetail: builder.query<IPropertyDetail, number>({
            query: (propertyId) => ({
                url: apiV2Link.property.detail(Scenario.PROPERTY_DETAIL, {propertyId})
            })
        }),
        getPropertyDetailApplication: builder.query<IPropertyDetailApplication, number>({
            query: (propertyId) => ({
                url: apiV2Link.property.detail(Scenario.PROPERTY_APPLICATION, {propertyId})
            })
        })
    })
});

export const {useGetPropertyDetailApplicationQuery, useGetPropertyDetailQuery} = getPropertyDetailApi;
